import React, { useState, useEffect, useMemo } from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import Radio from "@mui/material/Radio";
import MDButton from "components/MDButton";
import MenuItem from "@mui/material/MenuItem";
import FormGroup from "@mui/material/FormGroup";
import Modal from "@mui/material/Modal";
import { Chip, Typography } from "@mui/material";
import Menu from "@mui/material/Menu";
import Select from "@mui/material/Select";
import Grid from "@mui/material/Grid";
import Filter from "../../components/Filter";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import User from "../../assets/images/icons/user.png";
import Checkbox from "@mui/material/Checkbox";
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useTranslation } from "react-i18next";
import { useMaterialUIController, setSelecetdCustomerrec } from "context";
import { profilePictureStyle } from "layouts/staff/functions";
import Loader from "components/Loader";
import { getInitials } from "layouts/staff/functions";
import { get_users } from "backendservice/firebasefunctions,";
import {
  ShowassignDate,
  activitycollision,
  calculateBucket,
  calculate_schedularview,
  calenderMode,
  check_clinic_slot,
  checkcollision,
  compareTimes,
  createFirebaseTimestamp,
  createYearDropdown,
  delete_user_activities,
  detectactivitySlot,
  generateDatesOfMonth,
  generateMonthsArray,
  generateWeekNumbersArray,
  generateYeararray,
  getDateRangeArray,
  getDates,
  getDatesForWeek,
  get_pending_leave_date,
  getweekdates,
  onCallActivityCollision,
  removeOC_ACtivity,
  remove_schedule_date,
  remove_schedule_task,
  repeatDropdownOptions,
} from "./functions";
import Sortlist from "components/Sortlist";
import SearchComponent from "components/SearchInput";
import moment from "moment";
import { get_activities } from "backendservice/firebasefunctions,";
import { get_clinic } from "backendservice/firebasefunctions,";
import { DateRange } from "react-date-range";
import { ClickAwayListener } from "@mui/material";
import ReactCalendar from "react-calendar";
import { get_staff_leave } from "backendservice/firebasefunctions,";
import { get_customer } from "backendservice/firebasefunctions,";
import { v4 } from "uuid";
import 'react-calendar/dist/Calendar.css';
import { add_shedule } from "backendservice/firebasefunctions,";
import { get_shedule } from "backendservice/firebasefunctions,";
import { updateScheduleData } from "backendservice/firebasefunctions,";
import { get_clinic_id } from "backendservice/firebasefunctions,";
import { Category, ConstructionOutlined, TramRounded } from "@mui/icons-material";
import { get_staff_clinic } from "backendservice/firebasefunctions,";
import { get_employeeType } from "backendservice/firebasefunctions,";
import { get_staff_leave_pending } from "backendservice/firebasefunctions,";
import { enUS, fr } from 'date-fns/locale';
import { calculateTime } from "layouts/activities/helperFunctions/helper";
import { assign_OC_activity } from "./functions/objectStructure";
import DeleteModal from "components/DeleteModal";
import { CounterUpdate } from "backendservice/firebasefunctions,";
import  { Activity, Activitycell, Oncallactivity, Otheractivity } from "./component/activities";
import { calculate_Time_Passed } from "backendservice/firebasefunctions,";
import { getMonthsBetweenDates } from "backendservice/firebasefunctions,";
import { Collisionmodal } from "./component/modal";

const locales = {
  en: enUS,
  fr: fr,
};
let loopIndex = 0;
let selectedUser = {};
var scheduletime = 1;
var startTime = new Date()
var userlist_global = []
var monthlist_global = []
var clinicColide_sp = {
  open:false,
  dates:"",
  users:"",
  checkKey:false,

}
function Customer() {
  const { t ,i18n} = useTranslation();
  const [controller, dispatch] = useMaterialUIController();
  const {
    selecetdCustomer,
    selected_clinic,

    permission,
  } = controller;
  const locale =  locales.fr;
  const [isDivVisible, setIsDivVisible] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [answersPost, setAnswersPost] = useState(false);
  const [selectedItem, setSelectedItem] = useState(""); // Set default value here
  const [selectedWeek, setSelecetdWeek] = useState(moment().isoWeek()); // Set default value here
  const [collideModal,setcollideModal] = useState({
    open:false,
    dates:"",
    users:"",
    checkKey:false,
  
  })
  const isButtonDisabled = (moduleId) => {
    return !(
      !permission.module ||
      !permission.module[moduleId] ||
      (permission.module[moduleId].accesstype === 0 && permission.module[moduleId].admin )
    );
  };
  const [userlist, setUserlist] = useState([]);
  const [isHovered, setIsHovered] = useState("");

  const [userlistbackup, setUserlistBackip] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dateRange, setDaterange] = useState([]);
  const [activityList, setActivitylist] = useState([]);
  const [clinicList, setCliniclist] = useState([]);
  const [customerdata, setcustomerData] = useState([]);
  const [scheduleRecord, setScheduleRecord] = useState([]);
  const [category,setcategory] = useState(selecetdCustomer.categoryType=="clinic"?["asv"]:["oth"])
  const [selectedRepeat, setSelectedRepeat] = useState(
    repeatDropdownOptions[3]
  );
  const [selectedRepeatvalue, setSelectedRepeatvalue] = useState(3);
  // const [scheduletime, setScheduletime] = useState(1);
  // const [selectedUser, setSelectedUSer] = useState([]);
  const [selectedActivity, setselectedActivity] = useState({});
  const [selectedUserlist, setSelectedUserlist] = useState([]);
  const [userDropdown,setUserDropDown] = useState([])
  const [dateSelected, setDateSelected] = useState([]);
  const [employeeType, setemployeeType] = useState([]);
  const weekList = generateWeekNumbersArray();
  const yearList = generateYeararray();
  const generateMonthList = generateMonthsArray();
  // const yearList = createYearDropdown(  )
  const [selectedyear, setselectedyear] = useState(moment().year());
  const [selectDay, setselectDay] = useState(new Date());
  const [mode, setMode] = useState(calenderMode[1]);
  const [holidayList, setholidaylist] = useState([]);
  const [OC_modal,setOcModal] = useState(false)
  const open = Boolean(anchorEl);


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  //modal open hook
  const [modalopen, setmodalopen] = useState(false);
  const [weekModal, setweekmodal] = useState(false);
  const [rescheduleModal, setreschedulemodal] = useState(false);
  const [deleteModal, setdeletemodal] = useState({
    open:false,
    openconfirm:false,
    user:"",
    brand:"",
    clinic:"",
    startDate:new Date(),
    startcal:false,
    endcal:false,
    endDate:new Date(),
    deletetype:"all"

  });
  const [assignDate, setassigndate] = useState(null);
  const [selectionRange, setSelectionRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
    startCal: false,
    endCal: false,
    showstartcal: true,
    showEndCal: false,
    showWeekButton:false,
  });
  const [anchorElDate, setAnchorElDate] = useState(null);
  const [activityModal, setactivitymodal] = useState(null);
  var language = i18n.language;
  var executionHalt = true;
  const handleSelecteuser = (e) => {
    var temp = [...selectedUserlist];

    e.flag = true;
    if (temp.some((d) => d.id === e.id)) {
      temp = temp.filter((data) => data.id !== e.id);
      // setSelectedDates([...selectedDates, date]);
    } else {
      temp.push(e);
    }
    setSelectedUserlist(temp);
  };
  
  const [viewActivity, setViewActivity] = useState({
    userdata: {},
    viewactvitycal: false,
    viewactvitycalval: new Date(),
    totalWorkinghrs: "__",
    availableSlots: "__",
    listofActivity: [],
    activityRepeate: false,
    deleteConfirmModal: false,
    activitytodelete: {},
    deleteconfirmmodalresp: 0,
    confirmModal: false,
  });
  const [assignActivity, setAssignActivity] = useState({
    userdata: {},
    assignactivityCollidemodal: false,
    collision: false,
    collisiondata: [],
    schedule: {},
    activity: [],
    activitySP: false,
    activitynames: "",
    activityRepeate: false,
    collideDate: null,
    deleteConfirmModal: false,
    activitytodelete: {},
    deleteconfirmmodalresp: 0,
    confirmModal: false,
  })
  const [daySelection, setDayselection] = useState([
    { name: 'Mon', enable: false, value: 1 },
    { name: 'Tue', enable: false, value: 2 },
    { name: 'Wed', enable: false, value: 3 },
    { name: 'Thru', enable: false, value: 4 },
    { name: 'Fri', enable: false, value: 5 },
    { name: 'Sat', enable: false, value: 6 },
    { name: 'Sun', enable: false, value: 0 }
  ]);
  const handleActivityModalopen = () => {
    setactivitymodal(true);
  };
 
  const handleActivityModalclose = () => {
    setViewActivity({
      userdata: {},
      viewactvitycal: false,
      viewactvitycalval: new Date(),
      totalWorkinghrs: "__",
      availableSlots: "__",
      listofActivity: [],
      activityRepeate: false,
      deleteConfirmModal: false,
      activitytodelete: {},
      deleteconfirmmodalresp: 0,
      confirmModal: false,
    });
    setactivitymodal(false);
  };
  const cleandaysselection = ()=>{
    setDayselection([
      { name: 'Mon', enable: false, value: 1 },
    { name: 'Tue', enable: false, value: 2 },
    { name: 'Wed', enable: false, value: 3 },
    { name: 'Thru', enable: false, value: 4 },
    { name: 'Fri', enable: false, value: 5 },
    { name: 'Sat', enable: false, value: 6 },
    { name: 'Sun', enable: false, value: 0 }
    ])
    setweekmodal(false)
  }
  const openDate = Boolean(anchorElDate);
  const handleCloseDate = () => {
    // setSelectdatePicker(false)
    setAnchorElDate(null);
  };
  const handleDateclick = (event) => {
    // setSelectdatePicker(!selectDatePicker)
    // console.log(event.currentTarget)
    setAnchorElDate(event.currentTarget);
  };
  const handleCalendarClick = () => {
    setIsDivVisible(!isDivVisible);
  };
  const handleCalendarClose = () => {
    setIsDivVisible(false);
  };

  function addYearAndMonth(dateStr) {
    const date = new Date(dateStr); // Create a date object from the input string
    date.setFullYear(date.getFullYear() + 1); // Add one year
    date.setMonth(date.getMonth() + 1); // Add one month
  
    // Return the new date in YYYY-MM-DD format
    return date
  }
  const handleSelect = (ranges) => {
    var temp = {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    };
    if (selectedRepeat.code !== 3) {
      temp.startDate = ranges[0];
      temp.endDate = ranges[1];
    } else {
      temp.startDate = ranges;
      temp.endDate = ranges;
    }
    setassigndate(ranges);
    setSelectionRange(temp);
  };
  const handleDeleteRecords = async(data,modal=false,resp)=>{
  
    if(modal)
      {
        setdeletemodal({
          open:true,
          openconfirm:false,
          user:data.id,
          brand:selecetdCustomer.id,
          clinic:selected_clinic.id,
          startDate:new Date(),
          startcal:false,
          endcal:false,
          endDate:new Date(),
          deletetype:"all"
        })
      }else{
        setLoading(true)
        if(resp)
          {
            
           var del = await delete_user_activities(deleteModal.brand,deleteModal.clinic,deleteModal.user,deleteModal.startDate,deleteModal.endDate,deleteModal.deletetype)
            setdeletemodal({
              open:false,
              openconfirm:false,
              user:"",
              brand:"",
              clinic:"",
              startDate:new Date(),
              startcal:false,
              endcal:false,
              endDate:new Date(),
              deletetype:"all"
            })
            if(true)
           { setRefresh(!refresh)}
            else{alert(t("some error occurred"))}
          }else{
            setdeletemodal({
              open:false,
              openconfirm:false,
              user:"",
              brand:"",
              clinic:"",
              startDate:new Date(),
              startcal:false,
              endcal:false,
              endDate:new Date(),
              deletetype:"all"
            })
          }
          setLoading(false)
         
      }
  }
  //modal open close functionn
  const handlemodalopen = () => {
    setmodalopen(true);
  };
  const handlemodalClose = () => {
    setSelectedUserlist([]);
    setselectedActivity({});
    cleandaysselection()
    setcategory(["asv"])
    loopIndex = 0;
    selectedUser = {};
    setmodalopen(false);
  };
  const handleRepeatSelect = (e) => {
    var temp = repeatDropdownOptions[e];
    setSelectedRepeatvalue(e);
    setSelectedRepeat(temp);
    if (temp.code !== 3) {
      setSelectionRange((prev) => ({ ...prev, showEndCal: true ,showWeekButton: false}));
    } else {
      setSelectionRange((prev) => ({ ...prev, showEndCal: false,showWeekButton: false }));
    }
    if(temp.code==4)
      {
        setSelectionRange((prev) => ({ ...prev,showEndCal: true, showWeekButton: true }));
  
      }
  };
  const loadData = async () => {
    const customer = selecetdCustomer.id;
    const clinicId = selected_clinic.id;
  
    if (customer && clinicId) {
        // console.log("hello ")
      setLoading(true);
      try {
        // Fetch data concurrently
        const [staff, clinic, customerDetails, activityDatabase, employeeTypes] = await Promise.all([
          get_staff_clinic(customer, clinicId),
          get_clinic_id(clinicId),
          get_customer(customer),
          get_activities(customer, clinicId),
          get_employeeType(customer)
        ]);
  
        // Process staff details
        const staffDetails = await Promise.all(staff.map(async (data) => {
          // const scheduleDetails = await get_shedule(customer, clinicId, data.id);
          const employeeType = employeeTypes.find((obj) => obj.id === data.employeeTypeId)?.label || "";
          const pendingLeave = await get_staff_leave_pending(data.id, clinicId);
          const pendingLeaveList = get_pending_leave_date(pendingLeave);
  
          return {
            ...data,
            employeeType,
            employee:employeeTypes.find((obj) => obj.id === data.employeeTypeId),
            pendingLeave: pendingLeaveList,
            schedule: {}
          };
        }));
        var vetlist  =  staffDetails
        .filter((staff) => staff.employee.category=="vet")
        var asv  =  staffDetails
        .filter((staff) => staff.employee.category=="asv")
        var other  =  staffDetails
        .filter((staff) => staff.employee.category=="oth")
        var remaning  =  staffDetails
        .filter((staff) => !(staff.employee.category=="vet" || staff.employee.category=="asv" || staff.employee.category=="oth"))
        userlist_global = [...vetlist,...asv,...other,...remaning]
        // Filter inactive staff
        staff.map((data)=>{
          data.employee = employeeTypes.find((obj) => obj.id === data.employeeTypeId) || {};
        })
        const filterInactiveStaff = staff.filter((i) => i.active);
  
        // Set selected date based on mode

        switch (mode.value) {
          case 0:
            handleselectedday(selectDay || new Date());
            break;
          case 1:
            handleSelectedweek(selectedWeek || moment().isoWeek());
            break;
          case 2:
            handleSelectedMonth(selectedWeek || moment().month() + 1);
            break;
          default:
            handleSelectedweek(moment().isoWeek());
        }
  
        // Process holiday list
        const holidayList = customerDetails.holidayList.map((timestamp) => moment(timestamp).format("YYYY-MM-DD"));
  
        // Set states
        setholidaylist(holidayList);
        setActivitylist(activityDatabase);
        setCliniclist(clinic);
        // setUserlist(staffDetails);
        setemployeeType(employeeTypes);
        setUserDropDown(filterInactiveStaff);
      } catch (error) {
        console.error("Error loading data:", error);
        setUserlist([]);
        setActivitylist([]);
      } finally {
        setLoading(false);
      }
    }
  };
  const setDateOnSchedular = (date) => {
    const given_date = moment(date)
    // console.log("given date ",date)
    switch (mode.value) {
      case 0:
      
         handleselectedday(new Date(given_date));
        break;

      case 1:
      handleSelectedweek(given_date.isoWeek());
        break;

      case 2:
       handleSelectedMonth(given_date.month() + 1);
        break;

      default:
        handleSelectedweek(given_date.isoWeek());
    }

  }
  
  const handleAssignActivity = async (e) => {
    e.preventDefault();
    if (
      selectedActivity.rate.onCall ||
      selectedActivity.rate.onCallIn ||
      selectedActivity.rate.onDuty
    ) {
      setOcModal(true)
    }else
 {  
   setLoading(true);

    await conditionIsMet();
  }
  };
  const conditionIsMet = async () => {
    startTime = new Date()
    var check = true;
    if (selectedRepeat.code !== 3) {
      check = moment(selectionRange.startDate).isBefore(
        moment(selectionRange.endDate)
      );
    }
    
    // console.log("slection date mode ",selectedRepeat.code,daySelection.filter(day => day.enable).length)
    if(selectedRepeat.code==4 && daySelection.filter(day => day.enable).length==0)
    {
      alert(t("Please select custom days for assigning activity in custom mode."))
      setLoading(false)
      return
    }
    if (Object.keys(selectedActivity).length > 0) {
      setLoading(true);
      scheduletime = await detectactivitySlot(
        selected_clinic.id,
        selectedActivity
      );
   
      if (check) {
        if (selectedUserlist.length > 0) {
          // Check if a condition is met
          if (selectedUserlist.length > loopIndex) {
         
            selectedUser = selectedUserlist[loopIndex];
            loopIndex++;

            try {
              var dates = [];
              let startDate =  moment(selectionRange.startDate)
              let currentDate = moment(selectionRange.startDate);
              setDateOnSchedular(selectionRange.startDate)
              const finalDate = moment(selectionRange.endDate);
              var repeat = false;
              var repeatId = v4();
              // var repeattime = {};
              const endDateWeekly = moment(selectionRange.endDate); // December 31, 2024
          
              switch (selectedRepeat.code) {
                case 0:
                  while (currentDate <= finalDate) {
                    dates.push(currentDate.format("YYYY-MM-DD"));
                    currentDate = currentDate.clone().add(1, "days");
                  }
                  repeat = true;

                  break;
                case 1:
                  while (currentDate <= endDateWeekly) {
                    dates.push(currentDate.format("YYYY-MM-DD"));
                    currentDate = currentDate.clone().add(7, "days"); // Add 7 days for weekly repeat
                  }
                  repeat = true;
                  break;
                case 2:
                  while (currentDate <= endDateWeekly) {
                    dates.push(currentDate.format("YYYY-MM-DD"));
                    currentDate = currentDate.clone().add(1, "month"); // Add 1 month for monthly repeat
                  }
                  repeat = true;
                  break;
                case 3:
                  dates.push(currentDate.format("YYYY-MM-DD"));
                  repeat = false;

                  break;
                  case 4:
                    const enabledDays = daySelection.filter(day => day.enable).map(day => day.value);
                
                    while (currentDate <= endDateWeekly) {
                    if(enabledDays.includes(currentDate.day()))
                      {
                      dates.push(currentDate.format("YYYY-MM-DD"));

                      }
                      currentDate = currentDate.clone().add(1, "days"); // Add 1 month for monthly repeat
                    }
              
                  repeat = true;

                  break;
              }
              var fnDate = startDate.isAfter(finalDate)?startDate.format("YYYY-MM-DD"):finalDate.format("YYYY-MM-DD")
              if (
                selectedActivity.rate.onCall ||
                selectedActivity.rate.onCallIn ||
                selectedActivity.rate.onDuty
              ) {
                var activityDays = selectedActivity.duration.timeBreakup
                let cuudate = startDate.isAfter(finalDate)?startDate:finalDate
                if(activityDays?.length>1)
                  {
                    
                    cuudate.add(activityDays.length, 'day');
                  }
                  fnDate = cuudate.format("YYYY-MM-DD")

              }
              var currentSchedule = await get_shedule(
                selecetdCustomer.id,
                selected_clinic.id,
                selectedUser.id,
                startDate.format("YYYY-MM-DD"),
                fnDate
              );
              // console.log("current schedule ==== ",currentSchedule, startDate.format("YYYY-MM-DD"),startDate.isAfter(finalDate)?startDate.format("YYYY-MM-DD"):finalDate.format("YYYY-MM-DD"),finalDate.format("YYYY-MM-DD"),)
              selectedActivity.repeat = repeat;
              selectedActivity.repeatId = repeatId;
              selectedActivity.repeatTime = selectionRange;
              selectedActivity.repeatmode = selectedRepeat;
              // return 
              // var datelisterror = [];
              var collision = false;
              var repetedcolition = "";
              var collitionSchedule = "";
              var coll_activity_pos = [];
              var coll_activity_no = 0
              var leaveFlag = { status: false, dates: "" };
              var slotbooked = {status:false , dates:""}
              if (
                selectedActivity.rate.onCall ||
                selectedActivity.rate.onCallIn ||
                selectedActivity.rate.onDuty
              ) {
                selectedActivity.activityType = "oc"
                dates.map((data, index) => {
                  var temp = currentSchedule[data];

                  if (
                    temp?.leave !== undefined &&
                    temp?.leave === true &&
                    temp?.leaveValue === 0
                  ) {
                    leaveFlag.dates += data + " ,";
                    leaveFlag.status = true;
                    dates.splice(index, 1);
                  } else {
                    // console.log("selected activity ",selectedActivity)
                    if (temp?.specialActivity) {
                      var resp = onCallActivityCollision(selectedActivity,temp.activitySP,data)
                   
                    collision = resp.status;
                    collitionSchedule = resp.collided
                    coll_activity_no = resp.count
                    coll_activity_pos = resp.pos
                    // console.log(resp)
                  }
                    // return
                  }
               if(!check_clinic_slot(clinicList, selectedActivity, data,holidayList) && !holidayList.includes(data) )
                    {
                      clinicColide_sp.dates += data+"," 
                      clinicColide_sp.checkKey = true
                    }
                  });
                  if(clinicColide_sp.checkKey)
                  {
                    clinicColide_sp.users += selectedUser.name+ + " " + selectedUser.surname+", "
                  }
                setLoading(false);
                //
                if (collision) {
                  setAssignActivity((prev) => ({
                    ...prev,
                    assignactivityCollidemodal: true,
                    collision: true,
                    collisiondata: [],
                    schedule: currentSchedule,
                    activitySP: true,
                    collideDate: dates,
                    userdata: selectedUser,
                    activitynames: collitionSchedule,
                    activity: selectedActivity,
                    activityRepeate: repeat,
                  }));
                } else {
                  handleSpecialActivity(
                    dates,
                    currentSchedule,
                    selectedActivity
                  );
                }
              } else {
                dates.map((data, index) => {
                  var temp = currentSchedule[data];
                  if (
                    (temp?.leave !== undefined &&
                      temp?.leave === true &&
                      temp?.leaveValue === 0) ||
                    (temp?.holiday !== undefined &&
                      (temp?.holiday === true || holidayList.includes(data)))
                  ) {
                    leaveFlag.dates += data + " ,";
                    leaveFlag.status = true;
                    dates.splice(index, 1);
                  } else {
                    if (
                      typeof currentSchedule[data] === "object" &&
                      currentSchedule[data] !== null
                    ) {
                      if (scheduletime == 1) {
                      
                        var col = checkcollision(
                          selectedActivity,
                          temp.activityM
                        ); // true = no collision and false = collision
                        selectedActivity.activityType = "mor"
                        if (!col.status) {
                          collision = true;

                          //update the schedule
                          col.collideActivity.map((data, index) => {
                            collitionSchedule += data.activityName + ", ";
                          });

                          repetedcolition += data + ",";
                        }
                      } else {
                        selectedActivity.activityType = "aft"
                        var col = checkcollision(
                          selectedActivity,
                          temp.activityAf
                        ); // true = no collision and false = collision
                        if (!col.status) {
                          repetedcolition += data + ",";
                          collision = true;
                          col.collideActivity.map((data, index) => {
                            collitionSchedule += data.activityName + ", ";
                          });
                        }
                      }
                    }
                  }

                  // if(check_clinic_slot(clinicList, selectedActivity, data))
                  // {
                  //   clinicColide_sp.dates += data+"," 
                  //   clinicColide_sp.checkKey = true
                  // }
                });
                // if(clinicColide_sp.checkKey)
                // {
                //   clinicColide_sp.label += selectedUser.fullname+", "
                // }
                selectedActivity.repeat = repeat;
                setLoading(false);
                if (dates.length > 0) {
                  if (collision == true && repeat == false) {
                    setAssignActivity((prev) => ({
                      ...prev,
                      assignactivityCollidemodal: true,
                      collision: true,
                      collisiondata: [],
                      schedule: currentSchedule,
                      collideDate: currentDate.format("YYYY-MM-DD"),
                      userdata: selectedUser,
                      activitynames: collitionSchedule,
                      activity: selectedActivity,
                      activityRepeate: repeat,
                    }));
                  } else if (collision == true && repeat == true) {
                    setAssignActivity((prev) => ({
                      ...prev,
                      assignactivityCollidemodal: true,
                      collision: true,
                      collisiondata: [],
                      schedule: currentSchedule,
                      collideDate: dates,
                      userdata: selectedUser,
                      activitynames: repetedcolition,
                      activity: selectedActivity,
                      activityRepeate: repeat,
                    }));
                  } else if (collision == false && repeat == true) {
                    handleScheduleActivity(
                      dates,
                      currentSchedule,
                      selectedActivity
                    );
                  } else {
                    handleaddactivity(
                      currentDate.format("YYYY-MM-DD"),
                      currentSchedule,
                      selectedActivity
                    );
                  }
                }
              }
              if (leaveFlag.status) {
                alert(
                  t("Task cannot be assigned for dates - ") +
                    leaveFlag.dates +
                    t(" as its assigned for leave or holiday")
                );
                setLoading(false);
              }

              // setLoading(false);
            } catch (error) {
              throw error
              setLoading(false)
              console.log(error);
            }

            // Wait for the condition to change
          } else {

            handlemodalClose();
           if(clinicColide_sp.checkKey)
              {
                clinicColide_sp.open = true
               setcollideModal(clinicColide_sp)
              }
            setRefresh(!refresh);
          }

          // setLoading(false);
        } else {
          alert(t("Scheduler1.alerts.no_user_selected"));
          setLoading(false);
        }
      } else {
        alert(t("Scheduler1.alerts.date_is_greater"));
        setLoading(false);
      }
    } else {
      alert(t("Scheduler1.alerts.no_selected_activity"));
      setLoading(false);
    }
   
  };

  const handleScheduleActivity = async (
    dates,
    currentSchedule,
    selectedActivity
  ) => {
    setLoading(true);
    // console.log("loader onn ")
   var afternoonTiming = clinicList.time_slot_afternoon;
    var morningTiming = clinicList.time_slot_morning;
    var temp = {};
    var schedule = currentSchedule;
    dates.map((data, index) => {

      var bucket = calculateBucket(clinicList, data);
      var msg = compareTimes(
        selectedActivity,
        morningTiming[moment(data).format("dddd")],
        afternoonTiming[moment(data).format("dddd")],
        scheduletime
      );
      delete selectedActivity.clinics
      var slot = true
      if(scheduletime==1)
        {
           selectedActivity.activityType = "mor"

            if(   typeof currentSchedule[data] === "object" &&
              currentSchedule[data] !== null && currentSchedule[data].activityM.length>=2)
              {
                slot = false
                msg.message = "Slot full"
                console.log("slot full  ---- ")
              }
        }else{
           selectedActivity.activityType = "aft"
          if(   typeof currentSchedule[data] === "object" &&
            currentSchedule[data] !== null && currentSchedule[data].activityAf.length>=2)
            {
              slot = false
              msg.message = "Slot full"
              console.log("slot full  ---- ")
            }
        }
      if (msg.status == true ) {
        CounterUpdate(process.env.REACT_APP_ACTIVITY,1,selectedActivity.id)
        if (
          typeof currentSchedule[data] === "object" &&
          currentSchedule[data] !== null
        ) {
          temp = currentSchedule[data];
          temp.activitylunch = [{ activityName: "Lunch", color: "#FFD700" }];
          if (selectedActivity.duration.durationHours > temp.bucket1initial  || !slot) {
            // flagNoSpace = true;
            // alert(t("Slot full"));
          } else {
            if (scheduletime == 1) {
              var col = checkcollision(selectedActivity, temp.activityM);

              if (col.status) {
                if(slot){   temp.activityM.push(selectedActivity);
                temp.bucket1 =
                  parseFloat(temp.bucket1) -
                  parseFloat(selectedActivity.duration.durationHours);
                }
                } else {
                //update the schedule
                col.collideActivity.map((data, index) => {
                  var indexToRemove = temp.activityM.findIndex(
                    (obj) => obj.repeatId === data.repeatId
                  );

                  if (indexToRemove !== -1) {
                    temp.activityM.splice(indexToRemove, 1);

                    temp.bucket1 =
                      parseFloat(temp.bucket1) +
                      parseFloat(data.duration.durationHours);
                  }
                });
                temp.bucket1 =
                  parseFloat(temp.bucket1) -
                  parseFloat(selectedActivity.duration.durationHours);
                temp.activityM.push(selectedActivity);
              }
            } else {
              var col = checkcollision(selectedActivity, temp.activityAf);

              if (col.status) {
                if(slot){  
                temp.activityAf.push(selectedActivity);
                temp.bucket2 =
                  parseFloat(temp.bucket2) -
                  parseFloat(selectedActivity.duration.durationHours);
                }
                } else {
                //update the schedule
                col.collideActivity.map((data, index) => {
                  var indexToRemove = temp.activityAf.findIndex(
                    (obj) => obj.repeatId === data.repeatId
                  );

                  if (indexToRemove !== -1) {
                    temp.activityAf.splice(indexToRemove, 1);

                    temp.bucket2 =
                      parseFloat(temp.bucket2) +
                      parseFloat(data.duration.durationHours);
                  }
                });
                temp.bucket2 =
                  parseFloat(temp.bucket2) -
                  parseFloat(selectedActivity.duration.durationHours);
                temp.activityAf.push(selectedActivity);
              }
            }
          }
        } else {
          var bucket = calculateBucket(clinicList, data);
          temp = {
            bucket1:
              scheduletime == 1
                ? parseFloat(bucket.bucket1) -
                  parseFloat(selectedActivity.duration.durationHours)
                : parseFloat(bucket.bucket1),
            bucket2:
              scheduletime == 2
                ? parseFloat(bucket.bucket2) -
                  parseFloat(selectedActivity.duration.durationHours)
                : parseFloat(bucket.bucket2),
            bucket1initial: parseFloat(bucket.bucket1),
            bucket2initial: parseFloat(bucket.bucket2),
            bucketFree: 2,
            holiday: false,
            leave: false,
            leaveValue: 0,
            leaveactivity: [],
            lunch: false,
            specialActivity: false,
            activitySP: [],
            otherActivity: false,
            activityOT: [],
            activityM: scheduletime == 1 ? [selectedActivity] : [],
            activityAf: scheduletime == 2 ? [selectedActivity] : [],
            activitylunch: [{ activityName: "Lunch", color: "#FFD700" }],
          };
        }
        schedule[data] = temp;
        // console.log("new merged ou3",temp)
      } else {
        //  alert(msg.message)
      }
    });
    await updateScheduleData(
      selecetdCustomer.id,
      selected_clinic.id,
      selectedUser.id,
      schedule,
      dates[0],
      dates[dates.length-1]
    );
   handleassignactivitymodalclose();
    setLoading(false);
    conditionIsMet();
  };
  const handleSpecialActivity = async (
    dates,
    currentSchedule,
    selectedActivity
  ) => {
    setLoading(true);

    var temp = {};
    var schedule = currentSchedule;
    var activityTOAdd = {...selectedActivity}
    var activityDays = selectedActivity.duration.timeBreakup?selectedActivity.duration.timeBreakup:[{
      startingTime:moment.unix(selectedActivity.duration.startingTime.seconds).format('HH:mm'),
      closingTime:moment.unix(selectedActivity.duration.closingTime.seconds).format('HH:mm'),
      durationHours:calculateTime(moment(moment.unix(selectedActivity.duration.startingTime.seconds).format('HH:mm'), 'HH:mm'),moment(moment.unix(selectedActivity.duration.closingTime.seconds).format('HH:mm'), 'HH:mm'))
    }]
    var startDate = moment(dates[0],"YYYY-MM-DD")
    var finalDate = moment( dates[dates.length-1],"YYYY-MM-DD")
  
      var activityDays = activityTOAdd.duration.timeBreakup
      let cuudate = startDate.isAfter(finalDate)?startDate:finalDate
      if(activityDays?.length>1)
        {
          
          cuudate.add(activityDays.length, 'day');
        }
        var fnDate = cuudate.format("YYYY-MM-DD")

    
    dates.map((data, index) => {
      var starting_date = data
      let currentDate = moment(starting_date,"YYYY-MM-DD");
      activityTOAdd.startDate = currentDate.format("YYYY-MM-DD")
      activityTOAdd.ocId = v4()

      if(check_clinic_slot(clinicList,selectedActivity,data,holidayList) || holidayList.includes(data))
    {  activityDays.map((ocDates,ind)=>{
      
        var temp =  currentSchedule[currentDate.format("YYYY-MM-DD")]
        CounterUpdate(process.env.REACT_APP_ACTIVITY,1,selectedActivity.id)

        if (
          typeof currentSchedule[currentDate.format("YYYY-MM-DD")] === "object" &&
          currentSchedule[currentDate.format("YYYY-MM-DD")] !== null
        ) {
          var collision = onCallActivityCollision(selectedActivity,temp.activitySP,data)
          if(collision.status)
            {
              collision.pos.map((d,i)=>{
                removeOC_ACtivity(d,schedule,temp)
              })
            }
            var ocact = temp.activitySP
            ocact.push(assign_OC_activity(activityTOAdd,ocDates))
           ocact.sort((a, b) => {
              let [hoursA, minutesA] = moment.unix(a.duration.startingTime.seconds).format('HH:mm').split(':').map(Number);
              let [hoursB, minutesB] =moment.unix(b.duration.startingTime.seconds).format('HH:mm').split(':').map(Number);
          
              if (hoursA !== hoursB) {
                  return hoursA - hoursB;
              } else {
                  return minutesA - minutesB;
              }
          });
         temp.activitySP=ocact
            temp.specialActivity= true

            var collision = activitycollision(assign_OC_activity(activityTOAdd,ocDates),temp.activityM)
         
            if(collision.status)
              {
              
                var mrAct =  [...temp.activityM] 
                
               
                mrAct = mrAct.filter(item => !collision.pos.includes(item.id));
              
                mrAct.sort((a, b) => {
                  let [hoursA, minutesA] = moment.unix(a.duration.startingTime.seconds).format('HH:mm').split(':').map(Number);
                  let [hoursB, minutesB] =moment.unix(b.duration.startingTime.seconds).format('HH:mm').split(':').map(Number);
              
                  if (hoursA !== hoursB) {
                      return hoursA - hoursB;
                  } else {
                      return minutesA - minutesB;
                  }
              });
              
                temp.activityM = mrAct;
            }

            var collision = activitycollision(assign_OC_activity(activityTOAdd,ocDates),temp.activityAf)
         
            if(collision.status)
              {
              
                var afact =  [...temp.activityAf] 
               
               
                afact = afact.filter(item => !collision.pos.includes(item.id));
              
                afact.sort((a, b) => {
                  let [hoursA, minutesA] = moment.unix(a.duration.startingTime.seconds).format('HH:mm').split(':').map(Number);
                  let [hoursB, minutesB] =moment.unix(b.duration.startingTime.seconds).format('HH:mm').split(':').map(Number);
              
                  if (hoursA !== hoursB) {
                      return hoursA - hoursB;
                  } else {
                      return minutesA - minutesB;
                  }
              });
              
                temp.activityAf = afact;
            }

        }else{
         var bucket = calculateBucket(clinicList, data);
        
         
            temp = {
            bucket1: parseFloat(bucket.bucket1),
            bucket2: parseFloat(bucket.bucket2),
            bucket1initial: parseFloat(bucket.bucket1),
            bucket2initial: parseFloat(bucket.bucket2),
            bucketFree: 2,
            holiday: false,
            leave: false,
            leaveValue: 0,
            leaveactivity: [],
            lunch: false,
            specialActivity: true,
            activitySP: [assign_OC_activity(activityTOAdd,ocDates)],
            otherActivity: false,
            activityOT: [],
            activityM: [],
            activityAf: [],
            activitylunch: [{ activityName: "Lunch", color: "#FFD700" }],
          };
        }
      schedule[currentDate.format("YYYY-MM-DD")] = temp;
      
        currentDate.add(1, 'day');
      })
}

    })
   
    // return
    // console.log(schedule)
    await updateScheduleData(
      selecetdCustomer.id,
      selected_clinic.id,
      selectedUser.id,
      schedule,
      dates[0],
      fnDate
    );
   // setRefresh(!refresh);
    // handlemodalClose()
    handleassignactivitymodalclose();
    setLoading(false);
    conditionIsMet();
  };
  const handleaddactivity = async (date, currentSchedule, selectedActivity) => {
    setLoading(true);
    var temp = {};
    var schedule = currentSchedule;
    var data = date;
    var afternoonTiming = clinicList.time_slot_afternoon;
    var morningTiming = clinicList.time_slot_morning;
    var bucket = calculateBucket(clinicList, data);
    var msg = compareTimes(
      selectedActivity,
      morningTiming[moment(data).format("dddd")],
      afternoonTiming[moment(data).format("dddd")],
      scheduletime
    );
    var slot = true
    if(scheduletime==1)
      {
         selectedActivity.activityType = "mor"
       if(   typeof currentSchedule[data] === "object" &&
            currentSchedule[data] !== null && currentSchedule[data] && currentSchedule[data]?.activityM.length>=2)
            {
              slot = false
              msg.message = t("Slot full")
            }
      }else{
         selectedActivity.activityType = "aft"
        if(   typeof currentSchedule[data] === "object" &&
          currentSchedule[data] !== null && currentSchedule[data] && currentSchedule[data]?.activityAf.length>=2)
          {
            slot = false
            msg.message = t("Slot full")
           
          }
      }
    if (msg.status == true ) {
    
      CounterUpdate(process.env.REACT_APP_ACTIVITY,1,selectedActivity.id)
     if (
        typeof currentSchedule[data] === "object" &&
        currentSchedule[data] !== null
      ) {
        temp = currentSchedule[data];

        if (selectedActivity.duration.durationHours > temp.bucket1initial  ) {
          // flagNoSpace = true;
          alert(t("Slot full"));
        } else {
          if (scheduletime == 1) {
            var col = checkcollision(selectedActivity, temp.activityM);
          
            if (col.status ) {
            if(slot){  temp.activityM.push(selectedActivity);
              temp.bucket1 =
                parseFloat(temp.bucket1) -
                parseFloat(selectedActivity.duration.durationHours);
           }
              } else  {
              //update the schedule
              col.collideActivity.map((data, index) => {
                var indexToRemove = temp.activityM.findIndex(
                  (obj) => obj.repeatId === data.repeatId
                );

                if (indexToRemove !== -1) {
                  temp.activityM.splice(indexToRemove, 1);

                  temp.bucket1 =
                    parseFloat(temp.bucket1) +
                    parseFloat(data.duration.durationHours);
                }
              });
              temp.bucket1 =
                parseFloat(temp.bucket1) -
                parseFloat(selectedActivity.duration.durationHours);
              temp.activityM.push(selectedActivity);
            }
          } else {
            var col = checkcollision(selectedActivity, temp.activityAf);

            if (col.status) {
              if(slot){  temp.activityAf.push(selectedActivity);
              temp.bucket2 =
                parseFloat(temp.bucket2) -
                parseFloat(selectedActivity.duration.durationHours);
              }
              } else if(slot)  {
              //update the schedule
              col.collideActivity.map((data, index) => {
                var indexToRemove = temp.activityAf.findIndex(
                  (obj) => obj.repeatId === data.repeatId
                );

                if (indexToRemove !== -1) {
                  temp.activityAf.splice(indexToRemove, 1);

                  temp.bucket2 =
                    parseFloat(temp.bucket2) +
                    parseFloat(data.duration.durationHours);
                }
              });
              temp.bucket2 =
                parseFloat(temp.bucket2) -
                parseFloat(selectedActivity.duration.durationHours);
              temp.activityAf.push(selectedActivity);
            }
          }
        }
      } else {
        var bucket = calculateBucket(clinicList, data);
        temp = {
          bucket1:
            scheduletime == 1
              ? parseFloat(bucket.bucket1) -
                parseFloat(selectedActivity.duration.durationHours)
              : parseFloat(bucket.bucket1),
          bucket2:
            scheduletime == 2
              ? parseFloat(bucket.bucket2) -
                parseFloat(selectedActivity.duration.durationHours)
              : parseFloat(bucket.bucket2),
          bucket1initial: parseFloat(bucket.bucket1),
          bucket2initial: parseFloat(bucket.bucket2),
          bucketFree: 2,
          holiday: false,
          leave: false,
          leaveValue: 0,
          leaveactivity: [],
          lunch: false,
          specialActivity: false,
          activitySP: [],

          otherActivity: false,
          activityOT: [],
          activityM: scheduletime == 1 ? [selectedActivity] : [],
          activityAf: scheduletime == 2 ? [selectedActivity] : [],
          activitylunch: [{ activityName: "Lunch", color: "#FFD700" }],
        };
      }
      schedule[data] = temp;
    } else {
      alert(msg.message);
    }

    await updateScheduleData(
      selecetdCustomer.id,
      selected_clinic.id,
      selectedUser.id,
      schedule,
      date,
      date
    );
    // setRefresh(!refresh);
    // handlemodalClose()
    handleassignactivitymodalclose();
    setLoading(false);
    conditionIsMet();
  };
 
  const handleassignactivitymodalclose = () => {
   
    setAssignActivity({
      userdata: {},
      assignactivityCollidemodal: false,
      collision: false,
      collisiondata: [],
      schedule: {},
      activity: [],
      activitynames: "",
      activityRepeate: false,
      collideDate: null,
      deleteConfirmModal: false,
      activitytodelete: {},
      deleteconfirmmodalresp: 0,
      confirmModal: false,
    });
  };
  const handleSelectedweek = async (weekno) => {
    var range = getDatesForWeek(weekno,selectedyear);

    reloaddata_schedule(range[0].date,range[range.length-1].date)
  
    setDaterange(range);
    setSelecetdWeek(weekno);
  };

  const handleselectedday = async (e) => {
    let currentDate = moment(new Date(e));

    var datearray = [];
    var range = [];
    datearray.push(currentDate.format("YYYY-MM-DD"));

    const isActive = currentDate.isSame(new Date(), "day");
    range.push({ date: currentDate.format("YYYY-MM-DD"), active: isActive });

    reloaddata_schedule(currentDate.format("YYYY-MM-DD"),currentDate.format("YYYY-MM-DD"))
    setDaterange(range);
    setselectDay(e);
    setSelecetdWeek(e);
  };
  const handleSelectedMonth = async (month) => {
    // return
    var resp = generateDatesOfMonth(month, selectedyear);
    var range = resp.dates;
    reloaddata_schedule(range[0].date,range[0].date)
  
    setDaterange(range);

    setSelecetdWeek(month);
  };
  const handleSelectyear = (e)=>{
    switch (e.target.value) {
      case 0:
       //do nothing
        break;

      case 1:
        var range = getDatesForWeek(moment().isoWeek(),e.target.value);
        setDaterange(range);
        reloaddata_schedule(range[0].date,range[range.length-1].date)
  
        break;

      case 2:
        var resp = generateDatesOfMonth(moment().month(), e.target.value);
        var range = resp.dates;
        reloaddata_schedule(range[0].date,range[range.length-1].date)
  
        setDaterange(range);
        break;

      default:
        var range = getDatesForWeek(moment().isoWeek(),e.target.value);
        setDaterange(range);
    }
    setselectedyear(e.target.value)
  }
  const reloaddata_schedule =async(stdate,endate)=>{
  if(userlist_global.length>0)
  {  var flag = false
    var monthdates = getMonthsBetweenDates(stdate,endate)
    monthdates.forEach(element => {
      if (!monthlist_global.includes(element)) {
        monthlist_global.push(element);
        flag = true
      }
    });
  if(flag)
{    const promises = userlist_global.map(async (data, index) => {
      // var leaveresp = await get_staff_leave(data.id)
      var scheduledetails = await get_shedule(
        selecetdCustomer.id,
        selected_clinic.id,
        data.id,
        stdate,
        endate
      )
      Object.assign(data.schedule,scheduledetails)
   
      return data
    });
    const staffDetails = await Promise.all(promises);
 
    setUserlist(staffDetails);
  }}
  }
  const handleMode = async (e) => {
    switch (e.target.value) {
      case 0:
        handleselectedday(new Date());
        
        break;

      case 1:
        handleSelectedweek(moment().isoWeek());
        break;

      case 2:
        handleSelectedMonth(moment().month() + 1);
        break;

      default:
        handleSelectedweek(moment().isoWeek());
    }
    setMode(calenderMode[e.target.value]);
  };
  const handleViewActivity = async (index) => {
    var user = userlist[index];
    setViewActivity((prev) => ({ ...prev, userdata: user }));
    var schedule = user.schedule;
    var record = schedule[moment(new Date()).format("YYYY-MM-DD")];
    var totalWorkinghrs = "__";
    var availableSlots = "__";
    var listofActivity = [];
    if (typeof record === "object" && record !== null) {
      totalWorkinghrs =
        record.bucket1initial +
        record.bucket2initial -
        parseFloat(record.bucket1 + record.bucket2);
      availableSlots = record.bucket1 + record.bucket2;
      listofActivity = [
        ...record.activityM,
        ...record.activityAf,
        ...record.activitySP,
      ];
    } else {
      totalWorkinghrs = "__";
      availableSlots = "__";
      listofActivity = [];
    }

    setViewActivity((prev) => ({
      ...prev,
      viewactvitycalval: new Date(),
      totalWorkinghrs: totalWorkinghrs,
      availableSlots: availableSlots,
      listofActivity: listofActivity,
    }));
    handleActivityModalopen();
  };
  const handleViewActivityDate = async (e) => {
    var schedule = viewActivity.userdata.schedule;
    var record = schedule[moment(e).format("YYYY-MM-DD")];
    var totalWorkinghrs = "__";
    var availableSlots = "__";
    var listofActivity = [];

    if (typeof record === "object" && record !== null) {
      totalWorkinghrs =
        parseFloat(record.bucket1initial + record.bucket2initial) -
        parseFloat(record.bucket1 + record.bucket2);
      availableSlots = record.bucket1 + record.bucket2;
      listofActivity = [
        ...record.activityM,
        ...record.activityAf,
        ...record.activitySP,
      ];
    } else {
      totalWorkinghrs = "__";
      availableSlots = "__";
      listofActivity = [];
    }

    setViewActivity((prev) => ({
      ...prev,
      viewactvitycalval: e,
      totalWorkinghrs: totalWorkinghrs,
      availableSlots: availableSlots,
      listofActivity: listofActivity,
    }));
  };
  const handleRemoveActivity = async (data) => {
    setViewActivity((prev) => ({
      ...prev,
      activitytodelete: data,
      deleteConfirmModal: true,
      activityRepeate: data.repeat,
    }));
    remove_schedule_task(
      data,
      viewActivity.userdata.schedule,
      viewActivity.userdata.viewactvitycalval,
      false
    );
  };
  const handleCalendarClick2 = (e) => {
    e.stopPropagation(); 
  };
  useEffect(() => {
     userlist_global = []
     monthlist_global = []
     setUserlist([])
    if(selecetdCustomer.id && selected_clinic.id && permission)
      {
        loadData(selecetdCustomer.id);
      }
    
  }, [selecetdCustomer, selected_clinic, refresh]);
  const newLocal = <h2>{t("Select Data range")}</h2>;
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Collisionmodal modalinfo = {collideModal} handleModalClose={(e)=>{
        clinicColide_sp.open = false
        clinicColide_sp.users = ""
        clinicColide_sp.dates = ""
        clinicColide_sp.checkKey = false
        setcollideModal(
          {
            open:false,
            dates:"",
            users:"",
            checkKey:false,
          
          }
        )
      }}/>
      <Loader loading={loading} />
      <MDBox pt={4} pb={3} className="xl-container2 scheduler-page">
        <Box>
          <MDBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            sx={{ padding: { xs: "18px 0", sm: "0" } }}
          >
            <MDBox className="page-heading">
              <MDTypography variant="h5" gutterBottom sx={{ display: "flex" }}>
                {t("Scheduler1.pageHeading")}
              </MDTypography>
            </MDBox>
          </MDBox>
          <Box>
            <Box
              sx={{
                margin: "10px 0 0 0",
              }}
            >
              <MDBox
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              ></MDBox>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              minWidth: 120,
            }}
          >
            <Box className="report-heading">
              <Box className="cs-dropdown icon ">
                <Select
                  className="form-select"
                  id="languageSelector"
                  value={mode.value}
                  label="Daily"
                  onChange={(e) => handleMode(e)}
                >
                  {calenderMode.map((data, index) => (
                    <MenuItem value={data.value} key={index} select>
                      {t(`Scheduler1.dropdown.${data.label}`)}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
            </Box>
            <Box className="right-heading ">
              <Box className="navigation-link">
                <MDButton
                  className="primary-btn btn xl"
                  onClick={() => handlemodalopen()}
                  disabled={isButtonDisabled("REST10")}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="13"
                    height="13"
                    viewBox="0 0 13 13"
                    fill="none"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M7.86739 1.86739C7.86739 1.1122 7.25519 0.5 6.5 0.5C5.74481 0.5 5.13261 1.1122 5.13261 1.86739V5.13261H1.86739C1.1122 5.13261 0.5 5.74481 0.5 6.5C0.5 7.25519 1.1122 7.86739 1.86739 7.86739H5.13261V11.1326C5.13261 11.8878 5.74481 12.5 6.5 12.5C7.25519 12.5 7.86739 11.8878 7.86739 11.1326V7.86739H11.1326C11.8878 7.86739 12.5 7.25519 12.5 6.5C12.5 5.74481 11.8878 5.13261 11.1326 5.13261H7.86739V1.86739Z"
                      fill="white"
                    />
                  </svg>
                  &nbsp; {t("Scheduler1.assignActivityButton")}
                </MDButton>
              </Box>
              <Box className="report-heading ">
                {mode.value == 1 && (
                  <>
                    <div>
                      <button
                        className="btn-left"
                        onClick={() =>
                          selectedWeek !== 1 &&
                          handleSelectedweek(selectedWeek - 1)
                        }
                        style={{ padding: "5px" }}
                      >
                        {" "}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="10"
                          height="18"
                          viewBox="0 0 10 18"
                          fill="none"
                        >
                          <path
                            d="M9 17L1 9L9 1"
                            stroke="#16273C"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>{" "}
                      </button>
                    </div>
                    <Box className="cs-dropdown icon fieldset">
                      <Select
                        className="form-select"
                        id="languageSelector"
                        value={selectedWeek}
                        onChange={(e) => handleSelectedweek(e.target.value)}
                      >
                        {weekList.map((data, index) => (
                          <MenuItem value={data.value} key={index} select>
                            {t(`Scheduler1.${data.label.split(" ")[0]}`)}{" "}
                            {data.label.split(" ")[1]}
                          </MenuItem>
                        ))}
                      </Select>
                    </Box>

                    <div>
                      <button
                        className="btn-right"
                        onClick={() =>
                          selectedWeek !== 52 &&
                          handleSelectedweek(selectedWeek + 1)
                        }
                        style={{ padding: "5px" }}
                      >
                        {" "}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="10"
                          height="18"
                          viewBox="0 0 10 18"
                          fill="none"
                        >
                          <path
                            d="M1 17L9 9L1 1"
                            stroke="#16273C"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>{" "}
                      </button>
                    </div>
                  </>
                )}
                {mode.value == 0 && (
                  <>
                    <div>
                      <button
                        className="btn-left"
                        onClick={() =>
                          handleselectedday(
                            moment(selectDay).subtract(1, "days")
                          )
                        }
                        style={{ padding: "5px" }}
                      >
                        {" "}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="10"
                          height="18"
                          viewBox="0 0 10 18"
                          fill="none"
                        >
                          <path
                            d="M9 17L1 9L9 1"
                            stroke="#16273C"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>{" "}
                      </button>
                    </div>
                    <Box className="calendar-view">
                      <MDButton onClick={handleCalendarClick}>
                        {moment(selectDay).format("DD")}{" "}
                        {t(
                          `Scheduler1.months.${
                            moment(selectDay).format("MMMM")
                          }`
                        )}{" "}
                         {
                            moment(selectDay).format("YYYY")
                          }
                        
                        {/* {t(`Scheduler1.months.${moment(selectDay).format("DD MMMM")}`)} */}
                      </MDButton>

                      {isDivVisible && (
                        <ClickAwayListener
                          onClickAway={(e) => setIsDivVisible(false)}
                        >
                          <div onClick={handleCalendarClick2}>
                          <ReactCalendar
                          locale={language == "fr" ? "fr-FR" :"en-EN"}
                            onChange={(e) => handleselectedday(e)}
                            value={selectDay}
                          />
                          </div>
                        </ClickAwayListener>
                      )}
                    </Box>

                    <div>
                      <button
                        className="btn-right"
                        onClick={() =>
                          handleselectedday(moment(selectDay).add(1, "days"))
                        }
                        style={{ padding: "5px" }}
                      >
                        {" "}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="10"
                          height="18"
                          viewBox="0 0 10 18"
                          fill="none"
                        >
                          <path
                            d="M1 17L9 9L1 1"
                            stroke="#16273C"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>{" "}
                      </button>
                    </div>
                  </>
                )}
                {mode.value == 2 && (
                  <>
                    <div>
                      {" "}
                      <button
                        className="btn-left"
                        onClick={() =>
                          selectedWeek !== 1 &&
                          handleSelectedMonth(selectedWeek - 1)
                        }
                        style={{ padding: "5px" }}
                      >
                        {" "}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="10"
                          height="18"
                          viewBox="0 0 10 18"
                          fill="none"
                        >
                          <path
                            d="M9 17L1 9L9 1"
                            stroke="#16273C"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>{" "}
                      </button>
                    </div>
                    <Box className="cs-dropdown icon">
                      <Select
                        className="form-select"
                        id="languageSelector"
                        value={selectedWeek}
                        label="Monthly"
                        onChange={(e) => handleSelectedMonth(e.target.value)}
                      >
                        {generateMonthList.map((data, index) => (
                          <MenuItem value={data.value} key={index} select>
                            {t(`Scheduler1.months.${data.label}`)}
                          </MenuItem>
                        ))}
                      </Select>
                    </Box>

                    <div>
                      <button
                        className="btn-right"
                        onClick={() =>
                          selectedWeek !== 12 &&
                          handleSelectedMonth(selectedWeek + 1)
                        }
                        style={{ padding: "5px" }}
                      >
                        {" "}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="10"
                          height="18"
                          viewBox="0 0 10 18"
                          fill="none"
                        >
                          <path
                            d="M1 17L9 9L1 1"
                            stroke="#16273C"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>{" "}
                      </button>
                    </div>
                  </>
                )}

              
                {(mode.value == 2 || mode.value == 1) && (
                  <Box className="cs-dropdown icon">
                    <Select
                      className="form-select"
                      id="languageSelector"
                      value={selectedyear}
                      onChange={(e) => handleSelectyear(e)}
                      label="Year"
                    >
                      {yearList.map((year) => (
                        <MenuItem key={year} value={year}>
                          {year}
                        </MenuItem>
                      ))}
                    </Select>
                  </Box>
                )}
              </Box>
              <Box className="report-heading ">
                
              </Box>
            </Box>
          </Box>
        
                        <Box className="top-month-bar">
                        <h5>{dateRange.length>0 && t(`Scheduler1.months.${moment(dateRange[0]?.date).format("MMMM")}`)} {dateRange.length>0 && moment(dateRange[0]?.date).format("YY")}</h5>
                        </Box>
          <Box
            className={
              "group-main " +
              (mode.value == 0 ? "daily-view" : "") +
              (mode.value == 2 ? "monthly-view" : "")
            }
          >
            <Box className="user-data">
              
              <ul>
                {userlist.map((data, index) => (
                  <li key={index}>
                    <Box className="sch-main">
                      {/* {data.profile == "" ? (
                        <a style={{ width: 30, height: 30, marginRight: 10 }}>
                          {" "}
                          {getInitials(data.name, data.surname)}
                        </a>
                      ) : (
                        <img
                          src={data.profile}
                          style={{ width: 30, height: 30, marginRight: 10 }}
                        />
                      )} */}
                      <Box className="sch-heading">
                        <Box className="title">{data.label}</Box>
                        <Box className="designation">{data.employeeType}</Box>
                        <Box className="activity">
                          <MDButton
                            onClick={(e) => handleViewActivity(index)}
                            disabled={isButtonDisabled("REST10")}
                          >
                            {t("Scheduler1.viewActivityButton")}
                          </MDButton>
                          <MDButton className="delete-btn" onClick = {(e)=>handleDeleteRecords(data,true)}
                        disabled={isButtonDisabled("REST10")}
                        >
                            {t("Scheduler1.delete")} &nbsp;
                        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="19" viewBox="0 0 13 15" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.151367 3.59384C0.151367 3.32412 0.370019 3.10547 0.639739 3.10547H12.3607C12.6304 3.10547 12.849 3.32412 12.849 3.59384C12.849 3.86356 12.6304 4.08221 12.3607 4.08221H0.639739C0.370019 4.08221 0.151367 3.86356 0.151367 3.59384Z" fill="#16273C"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M5.19829 1.47674C4.98241 1.47674 4.77538 1.5625 4.62274 1.71515C4.47009 1.86779 4.38433 2.07482 4.38433 2.2907V3.59302C4.38433 3.86274 4.16568 4.0814 3.89596 4.0814C3.62624 4.0814 3.40759 3.86274 3.40759 3.59302V2.2907C3.40759 1.81577 3.59625 1.3603 3.93207 1.02448C4.26789 0.688662 4.72337 0.5 5.19829 0.5H7.80294C8.27786 0.5 8.73333 0.688662 9.06915 1.02448C9.40497 1.3603 9.59364 1.81577 9.59364 2.2907V3.59302C9.59364 3.86274 9.37498 4.0814 9.10526 4.0814C8.83554 4.0814 8.61689 3.86274 8.61689 3.59302V2.2907C8.61689 2.07482 8.53114 1.86779 8.37849 1.71515C8.22584 1.5625 8.01881 1.47674 7.80294 1.47674H5.19829ZM1.94247 3.10465C2.21219 3.10465 2.43085 3.3233 2.43085 3.59302V12.7093C2.43085 12.9252 2.5166 13.1322 2.66925 13.2849C2.82189 13.4375 3.02893 13.5233 3.2448 13.5233H9.75643C9.9723 13.5233 10.1793 13.4375 10.332 13.2849C10.4846 13.1322 10.5704 12.9252 10.5704 12.7093V3.59302C10.5704 3.3233 10.789 3.10465 11.0588 3.10465C11.3285 3.10465 11.5471 3.3233 11.5471 3.59302V12.7093C11.5471 13.1842 11.3585 13.6397 11.0226 13.9755C10.6868 14.3113 10.2314 14.5 9.75643 14.5H3.2448C2.76988 14.5 2.31441 14.3113 1.97858 13.9755C1.64276 13.6397 1.4541 13.1842 1.4541 12.7093V3.59302C1.4541 3.3233 1.67275 3.10465 1.94247 3.10465Z" fill="#16273C"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M5.19833 6.35938C5.46805 6.35938 5.68671 6.57803 5.68671 6.84775V10.7547C5.68671 11.0244 5.46805 11.2431 5.19833 11.2431C4.92861 11.2431 4.70996 11.0244 4.70996 10.7547V6.84775C4.70996 6.57803 4.92861 6.35938 5.19833 6.35938Z" fill="#16273C"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M7.80283 6.35938C8.07255 6.35938 8.2912 6.57803 8.2912 6.84775V10.7547C8.2912 11.0244 8.07255 11.2431 7.80283 11.2431C7.5331 11.2431 7.31445 11.0244 7.31445 10.7547V6.84775C7.31445 6.57803 7.5331 6.35938 7.80283 6.35938Z" fill="#16273C"/>
                        </svg>
                      </MDButton>
                        </Box>
                      </Box>
                    
                    </Box>
                  </li>
                ))}
              </ul>
            </Box>
            <Box className="activity-grid">
              <Box className="calender-list">
                <ul>
                  {dateRange.length > 0 &&
                    dateRange.map((data, index) =>
                      clinicList.metadata?.daysoperational.find(
                        (day) => day.name === moment(data.date).format("dddd")
                      ).enabled ? (
                        <li className={data.active && "active"} key={index}>
                          <Box className="card">
                            <span>{moment(data.date).date()}</span>&nbsp;
                            <span>
                              {t(
                                `${moment(data.date).format(
                                  "dddd"
                                )}`
                              )}
                            </span>
                          </Box>
                        </li>
                      ) : (
                        <li>
                          <Box className="holiday-tag">
                            {t("Scheduler1.closed")}
                          </Box>
                          <Box className="card">
                            <h6>{moment(data.date).date()}</h6>
                            <span>
                              {t(
                                `Scheduler1.days.${moment(data.date).format(
                                  "ddd"
                                )}`
                              )}
                            </span>
                          </Box>
                        </li>
                      )
                    )}
                </ul>
              </Box>
              <Box className="event-main">
                {userlist.length > 0 &&
                  userlist.map((data, index) => (
                    <ul>
                      {dateRange.map((date, i) =>
                        data.schedule[date.date]?.holiday !== true &&
                        !holidayList.includes(date.date) &&
                        data.schedule[date.date]?.leave !== true ? (
                          <li key={i}>
                              <Box className={data.pendingLeave.includes(date.date) && "leave-request" } >
                                <Box>
            <Box className="bg" sx={{
            background: selecetdCustomer.bgcolor,
          }}></Box>
                            {/* {t("Leave Request")} */}
                            {data.pendingLeave.includes(date.date) && t("Leave Request")  }
                            </Box>
                            </Box>
                            
                            {/* <Box className={data.pendingLeave.includes(date.date) && "grey-section"}>
                          
                            </Box> */}
                            <Box className="event-group">
                              <Box className="event-scroll">
                            
                              
                                <Activitycell  schedule={data.schedule} date={date.date}  holidays={holidayList} clinicClose ={ clinicList.metadata?.daysoperational.find(
                        (day) => day.name === moment(date.date).format("dddd")
                      ).enabled} clinic = {clinicList}/>

                              
                              
                              </Box>
                            </Box>
                          </li>
                        ) : (
                          <>
                            {data.schedule[date.date]?.holiday === true ||
                            holidayList.includes(date.date) ? (
                              data.schedule[date.date]?.specialActivity !==
                                undefined &&
                              data.schedule[date.date]?.specialActivity &&
                              data.schedule[date.date]?.activitySP.length >
                                0 ? (
                                <li>
                                  <Box className={data.pendingLeave.includes(date.date) && "grey-section"}></Box>
                                  <Box className="event-group">
                                    <Box className="event-scroll">
                                  
                                            <Activitycell  schedule={data.schedule} date={date.date}  holiday={holidayList} clinicClose ={ false} clinic = {clinicList}/>
                                             {/* <Oncallactivity name = {r.activityName} duration ={r.duration} color={r.color}  type={r.activityType?r.activityType:"oc"}/>
                                  */}
                                    
                                       
                                    </Box>
                                  </Box>
                                </li>
                              ) : (
                                <li  className="leave-box">
                                  <Box className={data.pendingLeave.includes(date.date) && "grey-section"}></Box>
                                  <Box className="leave">
                                    {t("Scheduler1.leaves.Holiday")}
                                  </Box>
                                </li>
                              )
                            ) : data.schedule[date.date]?.leave == true &&
                              data.schedule[date.date]?.leaveValue == 0 ? (
                              <li className="leave-box">
                                <Box className={data.pendingLeave.includes(date.date) && "grey-section"}></Box>
                                <Box className="leave">
                                  {t(
                                    `Scheduler1.leave`
                                  ) +
                                    " " +
                                    t("Full day")}
                                </Box>
                              </li>
                            ) : data.schedule[date.date]?.leave == true &&
                              data.schedule[date.date]?.leaveValue == 1 ? (
                              <li>
                                <Box className={data.pendingLeave.includes(date.date) && "grey-section"}></Box>
                                <Box className="event-group">
                                  <Box className="event-scroll">
                                
                                    <Box className="leave">
                                      {t(
                                        `Scheduler1.leave`
                                      ) +
                                        " " +
                                        t("Morning")}
                                    </Box>
                                    {data.schedule[date.date] !== undefined &&
                                      data.schedule[date.date].activityAf !==
                                        undefined &&
                                      data.schedule[date.date].activityAf.map(
                                        (r, ri) => (
                                          <Activity name = {r.activityName} duration ={r.duration} color={r.color}  type={r.activityType?r.activityType:"oc"}/>
                                  )
                                      )}

                                    {data.schedule[date.date] !== undefined &&
                                      data.schedule[date.date]
                                        ?.specialActivity === true &&
                                      data.schedule[date.date]?.activitySP !==
                                        undefined &&
                                      data.schedule[date.date].activitySP.map(
                                        (r, ri) => (
                                          <Oncallactivity name = {r.activityName} duration ={r.duration} color={r.color}  type={r.activityType?r.activityType:"oc"}/>
                                 )
                                      )}
                                  </Box>
                                </Box>
                              </li>
                            ) : data.schedule[date.date]?.leave == true &&
                              data.schedule[date.date]?.leaveValue == 2 ? (
                              <li>
                                <Box className="event-group">
                                  <Box className="event-scroll">
                              
                                    {data.schedule[date.date] !== undefined &&
                                      data.schedule[date.date].activityM !==
                                        undefined &&
                                      data.schedule[date.date].activityM.map(
                                        (r, ri) => (
                                        <Box className="cell">
                                         <Activity name = {r.activityName} duration ={r.duration} color={r.color}  type={r.activityType?r.activityType:"oc"}/>
                                         </Box>
                                        )
                                      )}

                                    <Box className="leave">
                                      {t(
                                        `Scheduler1.leave`
                                      ) +
                                        " " +
                                        t("Afternoon")}
                                    </Box>

                                    {data.schedule[date.date] !== undefined &&
                                      data.schedule[date.date]
                                        ?.specialActivity === true &&
                                      data.schedule[date.date]?.activitySP !==
                                        undefined &&
                                      data.schedule[date.date].activitySP.map(
                                        (r, ri) => (
                                          <Oncallactivity name = {r.activityName} duration ={r.duration} color={r.color}  type={r.activityType?r.activityType:"oc"}/>
                                  )
                                      )}
                                  </Box>
                                </Box>
                              </li>
                            ) : null}

                          
                          </>
                        )
                      )}
                    </ul>
                  ))}
              </Box>
            </Box>
          </Box>
          <Modal
            open={modalopen}
            onClose={handlemodalClose}
            aria-labelledby="modal-modal-title"
            className="cs-modal slack-md xxl scheduler-modal"
            aria-describedby="modal-modal-description"
          >
            <Box className="modal-dialog">
              <Box
                className="modal-content"
                component="form"
                onSubmit={(e) => handleAssignActivity(e)}
              >
                <Box className="modal-header">
                  <MDButton
                    className="modal-close"
                    onClick={() => handlemodalClose()}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                    >
                      <path
                        d="M13 1L1 13"
                        stroke="#fff"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M1 1L13 13"
                        stroke="#fff"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </MDButton>
                </Box>
                <Box className="modal-heading">
                  <Typography variant="h2" component="h2">
                    {t("Scheduler1.addActivityHeader")}
                  </Typography>
                </Box>
                <Box
                  className="modal-body"
                  component="form"
                  onSubmit={handleAssignActivity}
                >
                  <Grid container spacing={2}>
                
                    <Grid item xs={12} md={12}>
                    <Box style={{ display: "flex" }}>
                    
                   {selecetdCustomer.categoryType!=="oth" && <FormGroup sx={{ mb: 2, mt: 0, mr: 8 }}>
                      {/* <InputLabel className="formlabel">{t("Rate")}</InputLabel> */}
                      <Box
                        sx={{
                          display: { xs: "block", sm: "flex" },
                        }}
                      >
                        <FormControlLabel
                          sx={{
                            display: "flex",
                            textAlign: "center",
                          }}
                          className="cs-radio"
                          label={t("ASV")}
                          name = "category"
                          value="asv"
                          disabled = {selecetdCustomer.categoryType!=="clinic"}
                          control={
                            <Radio
                            checked={category.includes("asv")}
                            onChange={(e) =>
                              {
                       
                              var temp = []
                              temp.push(e.target.value)
                             setcategory(temp)
                              }
                            }
                            />
                          }
                        />
                     
                        <FormControlLabel
                          sx={{
                            display: "flex",
                            textAlign: "center",
                            ml: 6,
                          }}
                          className="cs-radio"
                          label={t("Vétérinaire")}
                          name = "category"
                          disabled = {selecetdCustomer.categoryType!=="clinic"}
                          value="vet"
                       
                          control={
                            <Radio
                            checked={category.includes("vet")}
                              onChange={(e) =>
                             {
                            //   var temp = [...category]
                            //   if(temp.includes(e.target.value))
                            //   {
                            //     temp =   temp.filter(item => item !== e.target.value)
                            //   }else{
                            //     temp.push(e.target.value)
                            //   }
                            //   setcategory(temp)
                            var temp = []
                             temp.push(e.target.value)
                            setcategory(temp)
                             }
                           
                              }
                              />
                            }
                        />
                         <FormControlLabel
                          sx={{
                            display: "flex",
                            textAlign: "center",
                            ml: 6,
                          }}
                          className="cs-radio"
                          label={t("Other")}
                          name = "category"
                          // disabled = {selecetdCustomer.categoryType!=="oth"}
                          value="oth"
                          control={
                            <Radio
                            checked={category.includes("oth")}
                            onChange={(e) =>
                              {
                              //  var temp = [...category]
                              //  if(temp.includes(e.target.value))
                              //  {
                              //   temp =  temp.filter(item => item !== e.target.value)
                              //  }else{
                              //    temp.push(e.target.value)
                              //  }
                              //  setcategory(temp)
                              var temp = []
                              temp.push(e.target.value)
                             setcategory(temp)
                              }
                            }
                            />
                          }
                        />
                      </Box>
                    </FormGroup>}
                    <div
                      style={{
                        backgroundColor: "red",
                        height: "100%",
                        width: "2px",
                      }}
                    ></div>
                  </Box>
                  </Grid>
                  <Grid item xs={12} md={4}>
                      <Box>
                        <InputLabel className="formlabel">
                          {t("Scheduler1.activityLabel")}
                        </InputLabel>
                        <Box className="cs-dropdown icon">
                          <Select
                            className="form-select"
                            id="languageSelector"
                            label="Country"
                            value={selectedActivity}
                            required
                            onChange={(e) => {
                              e.target.value.duration.startingTime =
                                createFirebaseTimestamp(
                                  e.target.value.duration.startingTime
                                );
                              e.target.value.duration.closingTime =
                                createFirebaseTimestamp(
                                  e.target.value.duration.closingTime
                                );
                              // console.log("event select ", e.target.value);
                              setselectedActivity(e.target.value);
                            }}
                          >
                            {activityList
                            .filter((data) =>{if(category.length>0)
                            {
                              return category.includes(data.category)
                              return true
                            }else{
                             
                              return true
                            }
                              
                            
                            })
                            .map((data, index) => (
                              <MenuItem value={data} select key={index}>
                               
                                {data.fullname ||data.activityName}
                              </MenuItem>
                            ))}
                          </Select>
                        </Box>
                      </Box>
                    </Grid>
                    
                    <Grid item xs={12} md={8}>
                      <Box sx={{ display: "flex" }}>
                    <Box
                      sx={{
                        marginTop:"30px",
                      }}
                    >
                    <FormControlLabel
                                      sx={{
                                        display: "flex",
                                        textAlign: "center",
                                        marginRight:"16px",
                                      }}
                                      className="cs-checkbox"
                                      label={t("Individual Rate")}
                                      name="afternoon"
                                   
                                      control={<Checkbox />}
                                    />
                    </Box>
                      {selectedActivity.duration !== undefined &&
                        typeof selectedActivity == "object" && (
                          <Box className="label-tag">
                            <span>
                              <strong>{t("Start time")}:</strong>{" "}
                              {moment
                                .unix(
                                  selectedActivity.duration.startingTime.seconds
                                )
                                .format("HH:mm")}
                            </span>
                            <span>
                              <strong>{t("End time")}:</strong>{" "}
                              {moment
                                .unix(
                                  selectedActivity.duration.closingTime.seconds
                                )
                                .format("HH:mm")}
                            </span>
                           
                            <span>
                              <strong>{t("Duration")}:</strong>{" "}
                              {
                             typeof(selectedActivity.duration.durationHours) === "string" && selectedActivity.duration.durationHours
                                .split(":")
                                .map((val, index) =>
                                  index === 0
                                    ? `${val} ${t("hours")}`
                                    : `${val} ${t("minutes")}`
                                )
                                .join(" ")
                                
                                }
                            </span>
                          </Box>
                        )}
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormGroup mt={2}>
                        <InputLabel className="formlabel">
                          {t("Scheduler1.repeatLabel")}
                        </InputLabel>
                        <Box className="cs-dropdown icon">
                          <Select
                            className="form-select"
                            id="languageSelector"
                            label="Country"
                            value={selectedRepeatvalue}
                            required
                            disabled = {Object.keys(selectedActivity).length==0}
                            onChange={(e) => {
                              handleRepeatSelect(e.target.value);
                            }}
                          >
                            {repeatDropdownOptions.map((data, index) => (
                              <MenuItem value={data.value} select key={index} disabled = {(data.value==0 || data.value == 4)&& Object.keys(selectedActivity).length>0 &&( 
                                (selectedActivity.rate.onCall ||
                                selectedActivity.rate.onCallIn ||
                                selectedActivity.rate.onDuty) && selectedActivity.duration.timeBreakup.length>1
                                )}>
                                {t(`Scheduler1.repeatDropdown.${data.label}`)}
                              </MenuItem>
                            ))}
                          </Select>
                        </Box>
                      
                      </FormGroup>
                      
                    </Grid>
                    <Grid item xs={12} md={8}>
                   <Box  sx={{
                        display: "flex",
                      }}>
                  
                     <Box className="btn-align">
                       <MDButton
                      className="secondary-btn"
                      disabled = {! selectionRange.showWeekButton }
                      sx={{
                        padding: "11px 30px",
                        fontSize: "16px",
                        fontWeight: "normal",
                        minWidth: "160px",
                      }}
                     onClick={(e)=>setweekmodal(true)}
                      color="dark"
                    >
                      {t("Custom days")}
                    </MDButton>
                    
                     </Box>
                     { selectionRange.showWeekButton &&<strong style={{ color: "red" }}> *</strong>}
                
                   { daySelection.filter(day => day.enable).map(day => day.value).length>0 && <Box className="label-tag">
                           {daySelection.map((d,i)=>(
                           d.enable && <span key = {i}>
                             {t(d.name)}
                            </span>
                           )) }
                           
                          </Box>}
                   </Box>
                  </Grid>
                    <Grid item xs={12} md={12}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                          <FormGroup
                            mt={1}
                            className="input-icon calendar-view"
                            onClick={() =>
                              setSelectionRange((prev) => ({
                                ...prev,
                                startCal: true,
                              }))
                            }
                          >
                            <InputLabel className="formlabel">
                              {t("From (including)")}
                            </InputLabel>
                            <TextField
                              className="forminput"
                              placeholder={t("Scheduler1.calenderPlaceholder")}
                              variant="outlined"
                              value={moment(selectionRange.startDate).format(
                                "DD-MM-YYYY"
                              )}
                              InputProps={{
                                readOnly: true,
                              }}
                            />
                            <MDButton>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="17"
                                height="18"
                                viewBox="0 0 15 16"
                                fill="none"
                              >
                                <path
                                  d="M12.4111 2.12109H2.12047C1.30857 2.12109 0.650391 2.77927 0.650391 3.59118V13.8818C0.650391 14.6937 1.30857 15.3518 2.12047 15.3518H12.4111C13.223 15.3518 13.8811 14.6937 13.8811 13.8818V3.59118C13.8811 2.77927 13.223 2.12109 12.4111 2.12109Z"
                                  fill="#16273C"
                                />
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M2.11966 2.76789C1.6665 2.76789 1.29914 3.13525 1.29914 3.58841V13.879C1.29914 14.3321 1.6665 14.6995 2.11966 14.6995H12.4102C12.8634 14.6995 13.2308 14.3322 13.2308 13.879V3.58841C13.2308 3.13525 12.8634 2.76789 12.4102 2.76789H2.11966ZM0 3.58841C0 2.41775 0.949002 1.46875 2.11966 1.46875H12.4102C13.5809 1.46875 14.5299 2.41775 14.5299 3.58841V13.879C14.5299 15.0496 13.5809 15.9986 12.4102 15.9986H2.11966C0.949003 15.9986 0 15.0496 0 13.879V3.58841Z"
                                  fill="white"
                                />
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M10.2062 0C10.565 0 10.8558 0.290823 10.8558 0.649572V3.58974C10.8558 3.94849 10.565 4.23931 10.2062 4.23931C9.84746 4.23931 9.55664 3.94849 9.55664 3.58974V0.649572C9.55664 0.290823 9.84746 0 10.2062 0Z"
                                  fill="white"
                                />
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M4.32438 0C4.68313 0 4.97395 0.290823 4.97395 0.649572V3.58974C4.97395 3.94849 4.68313 4.23931 4.32438 4.23931C3.96563 4.23931 3.6748 3.94849 3.6748 3.58974V0.649572C3.6748 0.290823 3.96563 0 4.32438 0Z"
                                  fill="white"
                                />
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M0 6.52848C0 6.16973 0.290823 5.87891 0.649572 5.87891H13.8803C14.2391 5.87891 14.5299 6.16973 14.5299 6.52848C14.5299 6.88723 14.2391 7.17805 13.8803 7.17805H0.649572C0.290823 7.17805 0 6.88723 0 6.52848Z"
                                  fill="white"
                                />
                              </svg>
                            </MDButton>
                            {selectionRange.startCal && (
                              <ClickAwayListener
                                onClickAway={() =>
                                  setSelectionRange((prev) => ({
                                    ...prev,
                                    startCal: false,
                                  }))
                                }
                              >
                                <div>
                                  <ReactCalendar
                                    // onChange={(e) => console.log(e)}
                                    value={selectionRange.startDate}
                                    selectRange={false}
                                    // minDate={new Date()}
                                    locale={language == "fr" ? "fr-FR" :"en-EN"}
                                    onChange={(e) =>
                                      setSelectionRange((prev) => ({
                                        ...prev,
                                        startDate: e,
                                      }))
                                    }
                                  />
                                </div>
                              </ClickAwayListener>
                            )}
                          </FormGroup>
                        </Grid>

                        {selectionRange.showEndCal && (
                          <Grid item xs={12} md={4}>
                            <FormGroup
                              mt={1}
                              className="input-icon calendar-view"
                              onClick={() =>
                                setSelectionRange((prev) => ({
                                  ...prev,
                                  endCal: true,
                                }))
                              }
                            >
                              <InputLabel className="formlabel">
                                {t("Up to (including)")}
                              </InputLabel>
                              <TextField
                                className="forminput"
                                placeholder={t(
                                  "Scheduler1.calenderPlaceholder"
                                )}
                                variant="outlined"
                                value={moment(selectionRange.endDate).format(
                                  "DD-MM-YYYY"
                                )}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                              <MDButton>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="17"
                                  height="18"
                                  viewBox="0 0 15 16"
                                  fill="none"
                                >
                                  <path
                                    d="M12.4111 2.12109H2.12047C1.30857 2.12109 0.650391 2.77927 0.650391 3.59118V13.8818C0.650391 14.6937 1.30857 15.3518 2.12047 15.3518H12.4111C13.223 15.3518 13.8811 14.6937 13.8811 13.8818V3.59118C13.8811 2.77927 13.223 2.12109 12.4111 2.12109Z"
                                    fill="#16273C"
                                  />
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M2.11966 2.76789C1.6665 2.76789 1.29914 3.13525 1.29914 3.58841V13.879C1.29914 14.3321 1.6665 14.6995 2.11966 14.6995H12.4102C12.8634 14.6995 13.2308 14.3322 13.2308 13.879V3.58841C13.2308 3.13525 12.8634 2.76789 12.4102 2.76789H2.11966ZM0 3.58841C0 2.41775 0.949002 1.46875 2.11966 1.46875H12.4102C13.5809 1.46875 14.5299 2.41775 14.5299 3.58841V13.879C14.5299 15.0496 13.5809 15.9986 12.4102 15.9986H2.11966C0.949003 15.9986 0 15.0496 0 13.879V3.58841Z"
                                    fill="white"
                                  />
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M10.2062 0C10.565 0 10.8558 0.290823 10.8558 0.649572V3.58974C10.8558 3.94849 10.565 4.23931 10.2062 4.23931C9.84746 4.23931 9.55664 3.94849 9.55664 3.58974V0.649572C9.55664 0.290823 9.84746 0 10.2062 0Z"
                                    fill="white"
                                  />
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M4.32438 0C4.68313 0 4.97395 0.290823 4.97395 0.649572V3.58974C4.97395 3.94849 4.68313 4.23931 4.32438 4.23931C3.96563 4.23931 3.6748 3.94849 3.6748 3.58974V0.649572C3.6748 0.290823 3.96563 0 4.32438 0Z"
                                    fill="white"
                                  />
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M0 6.52848C0 6.16973 0.290823 5.87891 0.649572 5.87891H13.8803C14.2391 5.87891 14.5299 6.16973 14.5299 6.52848C14.5299 6.88723 14.2391 7.17805 13.8803 7.17805H0.649572C0.290823 7.17805 0 6.88723 0 6.52848Z"
                                    fill="white"
                                  />
                                </svg>
                              </MDButton>
                              {selectionRange.endCal && (
                                <ClickAwayListener
                                  onClickAway={() =>
                                    setSelectionRange((prev) => ({
                                      ...prev,
                                      endCal: false,
                                    }))
                                  }
                                >
                                  <div>
                                    <ReactCalendar
                                      // onChange={(e) => console.log(e)}
                                      value={selectionRange.endDate}
                                      selectRange={false}
                                      // minDate={new Date()}/
                                      minDate={selectionRange.startDate}
                                      maxDate={addYearAndMonth(selectionRange.startDate)}
                                      locale={language == "fr" ? "fr-FR" :"en-EN"}
                                      onChange={(e) =>
                                        setSelectionRange((prev) => ({
                                          ...prev,
                                          endDate: e,
                                        }))
                                      }
                                    />
                                  </div>
                                </ClickAwayListener>
                              )}
                            </FormGroup>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormGroup
                        sx={{
                          paddingRight: "20px",
                          display: "flex",
                          flexDirection: "row",
                          marginTop: "10px",
                        }}
                      >
                        {/* <Box
                          sx={{
                            marginRight: "10px",
                          }}
                        >
                          <FormControlLabel
                            sx={{
                              display: "flex",
                              textAlign: "center",
                            }}
                            className="cs-checkbox"
                            label={t("Scheduler1.bfrLunch")}
                            onClick={() => setScheduletime(1)}
                            control={<Checkbox checked={scheduletime == 1} />}
                          />
                        </Box> */}
                        {/* <Box>
                          <FormControlLabel
                            sx={{
                              display: "flex",
                              textAlign: "center",
                            }}
                            className="cs-checkbox"
                            label={t("Scheduler1.aftLunch")}
                            onClick={() => setScheduletime(2)}
                            control={<Checkbox checked={scheduletime == 2} />}
                          />
                        </Box> */}
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12} md={6}></Grid>
                    <Grid item xs={12} md={6}>
                      <FormGroup mt={1}>
                        <InputLabel className="formlabel">
                          {t("Scheduler1.selectstaff")}
                        </InputLabel>
                        <Box className="cs-dropdown icon">
                          <Select
                            className="form-select"
                            id="languageSelector"
                            label="Country"
                            value={selectedUserlist}
                            required
                            onChange={(e) => {
                              handleSelecteuser(e.target.value);
                            }}
                            renderValue={(userrecords) => (
                              <div
                                style={{ display: "flex", flexWrap: "wrap" }}
                              >
                                {userrecords.map((useracc, index) => (
                                  <Chip key={index} label={useracc.name} />
                                ))}
                              </div>
                            )}
                          >
                            {userDropdown
                            .filter((data) =>{if(category.length>0)
                            {
                              // console.log("userdata ",data)
                              if(data.employee.category)
                              {
                                return  category.includes(data.employee.category)
                              }else{
                                return false
                              }
                              
                            }else{
                             
                              return true
                            }
                              
                            
                            }).map((data, index) => (
                              <MenuItem value={data} select key={index}>
                                {data.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </Box>
                      </FormGroup>
                    </Grid>
                  </Grid>

                  <Box className="tag-schedule" mt={3} mb={2}>
                    {selectedUserlist.map((data, index) => (
                      <Box className="list">
                        {data.profile == "" ? (
                          <div style={profilePictureStyle}>
                            {" "}
                            {getInitials(data.name, data.surname)}
                          </div>
                        ) : (
                          <img src={data.profile} style={profilePictureStyle} />
                        )}
                        &nbsp;
                        <h5>{data.name + " " + data.surname}</h5>
                        <MDButton onClick={(e) => handleSelecteuser(data)}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="14"
                            viewBox="0 0 9 9"
                            fill="none"
                          >
                            <path
                              d="M8 1L1 8"
                              stroke="#16273C"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M1 1L8 8"
                              stroke="#16273C"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </MDButton>
                      </Box>
                    ))}
                  </Box>
                  <Grid container spacing={2}></Grid>
                </Box>
                <Box className="modal-footer">
                  <MDButton
                    className="secondary-btn"
                    sx={{
                      padding: "11px 30px",
                      fontSize: "16px",
                      fontWeight: "normal",
                      minWidth: "160px",
                    }}
                    type="submit"
                    color="dark"
                  >
                    {t("Scheduler1.submit")}
                  </MDButton>
                </Box>
              </Box>
            </Box>
          </Modal>
          <Modal
            open={weekModal}
            onClose={()=>setweekmodal(false)}
            aria-labelledby="modal-modal-title"
            className="cs-modal checkbox-modal xs"
            aria-describedby="modal-modal-description"
          >
            <Box className="modal-dialog">
              <Box className="modal-content">
                <Box className="modal-heading">
                  <Typography variant="h2" component="h2">
                    {t("weekPopup.Custom")}
                  </Typography>
                </Box>
                <Box className="modal-body">
                  <Box className="stream-dropdown">
                    <InputLabel className="formlabel">
                      {t("weekPopup.select_days")}
                    </InputLabel>
                    <Box className="checkbox-click">
                     {daySelection.map((day,ind)=>(
                          <FormControlLabel
                          key={ind}
                          className="formlabel cs-checkbox"
                          label={t(day.name)}
                          name   = {day.value}
                          checked={day.enable}
                          onChange={(e) => 
                            {
                              const {name,checked} = e.target
                              var temp = [...daySelection]
                              temp[ind].enable=checked
                              setDayselection(temp)
                            }
                          }
                          control={<Checkbox />}
                          />
                     ))}
                     
                     
                    </Box>
                  </Box>
                </Box>
                <Box className="modal-footer">
                  <MDButton
                    className="secondary-btn white"
                    sx={{
                      padding: "11px 30px",
                      fontSize: "16px",
                      fontWeight: "normal",
                      minWidth: "160px",
                    }}
                    color="dark"
                    onClick={() => cleandaysselection()}
                    // onClick={(e) => handleSubmit(e)}
                  >
                    {t("weekPopup.clear")}
                  </MDButton>
                  <MDButton
                    className="secondary-btn"
                    sx={{
                      padding: "11px 30px",
                      fontSize: "16px",
                      fontWeight: "normal",
                      minWidth: "160px",
                    }}
                    color="dark"
                    onClick={(e) => setweekmodal(false)}
                  >
                    {t("Save")}
                  </MDButton>
                </Box>
              </Box>
            </Box>
          </Modal>
          <Modal
            open={assignActivity.assignactivityCollidemodal}
            onClose={(e) => {
              loopIndex=0
    
              clinicColide_sp = {
                open:false,
                dates:"",
                users:"",
                checkKey:false,
              
              }
              handleassignactivitymodalclose()}}
            aria-labelledby="modal-modal-title"
            className="cs-modal xs info"
            aria-describedby="modal-modal-description"
          >
            <Box className="modal-dialog">
              <Box className="modal-content">
                <Box className="modal-body">
                <Box className="scroll-bar">
                <h4>{t("alerts.activitycollision.Oops")}</h4>
                  <p>
                    {assignActivity.userdata.name +
                      t("alerts.activitycollision.willBeOccupied") +
                      assignActivity.activitynames +
                      "\n" +
                      t("alerts.activitycollision.wouldYouLikeTo")}
                  </p>
                  </Box>
                  <Box>
                    {!assignActivity.activityRepeate && (
                      <MDButton
                        className="secondary-btn"
                        onClick={(e) => {
                          assignActivity.activitySP
                            ? handleSpecialActivity(
                                assignActivity.collideDate,
                                assignActivity.schedule,
                                assignActivity.activity
                              )
                            : handleaddactivity(
                                assignActivity.collideDate,
                                assignActivity.schedule,
                                assignActivity.activity
                              );
                        }}
                      >
                        {t("Scheduler1.overrideActivity")}
                      </MDButton>
                    )}
                    {assignActivity.activityRepeate && (
                      <MDButton
                        className="secondary-btn white"
                        onClick={(e) =>
                          assignActivity.activitySP
                            ? handleSpecialActivity(
                                assignActivity.collideDate,
                                assignActivity.schedule,
                                assignActivity.activity
                              )
                            : handleScheduleActivity(
                                assignActivity.collideDate,
                                assignActivity.schedule,
                                assignActivity.activity
                              )
                        }
                      >
                        {t("Scheduler1.changePermanent")}
                      </MDButton>
                    )}
                    <MDButton
                      className="secondary-btn transparent"
                      onClick={(e) =>{ 
                        loopIndex=0
    
                        clinicColide_sp = {
                          open:false,
                          dates:"",
                          users:"",
                          checkKey:false,
                        
                        }
                        handleassignactivitymodalclose()}}
                    >
                      {t("Scheduler1.cancelbtn")}
                    </MDButton>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Modal>
          <Modal
            open={activityModal}
            onClose={handleActivityModalclose}
            aria-labelledby="modal-modal-title"
            className="cs-modal xs activity-modal"
            aria-describedby="modal-modal-description"
          >
            <Box className="modal-dialog">
              <Box className="modal-content">
                <Box className="modal-header">
                  <MDButton
                    className="modal-close"
                    onClick={() => handleActivityModalclose()}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                    >
                      <path
                        d="M13 1L1 13"
                        stroke="#fff"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M1 1L13 13"
                        stroke="#fff"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </MDButton>
                </Box>
                <Box className="modal-heading">
                  <Typography variant="h2" component="h2">
                    {viewActivity.userdata?.name +
                      " " +
                      viewActivity.userdata?.surname +
                      t("Scheduler1.-activities")}
                  </Typography>
                </Box>
                <Box className="modal-body">
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Box>
                      <p>
                        {t("Scheduler1.selectedDate")}:{" "}
                        {moment(viewActivity.viewactvitycalval).format(
                          "DD/MM/YYYY"
                        )}
                      </p>
                      <p>
                        {t("Scheduler1.totalhours") +
                          viewActivity.totalWorkinghrs +
                          " hour"}
                      </p>
                      <p>
                        {t("Scheduler1.available") +
                          viewActivity.availableSlots +
                          " hour"}
                      </p>
                    </Box>
                    <Box className="calendar-view">
                      <MDButton
                        onClick={(e) => {
                          setViewActivity((prev) => ({
                            ...prev,
                            viewactvitycal: !viewActivity.viewactvitycal,
                          }));
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="26"
                          height="26"
                          viewBox="0 0 17 18"
                          fill="none"
                        >
                          <path
                            d="M14.519 2.75H2.47796C1.52795 2.75 0.757812 3.44938 0.757812 4.3121V15.2468C0.757812 16.1095 1.52795 16.8089 2.47796 16.8089H14.519C15.469 16.8089 16.2392 16.1095 16.2392 15.2468V4.3121C16.2392 3.44938 15.469 2.75 14.519 2.75Z"
                            fill="white"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M2.47924 3.44101C1.949 3.44101 1.51916 3.83136 1.51916 4.31288V15.2476C1.51916 15.7291 1.949 16.1194 2.47924 16.1194H14.5203C15.0505 16.1194 15.4804 15.7291 15.4804 15.2476V4.31288C15.4804 3.83136 15.0505 3.44101 14.5203 3.44101H2.47924ZM-0.000976562 4.31288C-0.000976562 3.06895 1.10945 2.06055 2.47924 2.06055H14.5203C15.8901 2.06055 17.0005 3.06895 17.0005 4.31288V15.2476C17.0005 16.4915 15.8901 17.4999 14.5203 17.4999H2.47924C1.10945 17.4999 -0.000976562 16.4915 -0.000976562 15.2476V4.31288Z"
                            fill="#16273C"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M11.9407 0.5C12.3605 0.5 12.7008 0.809027 12.7008 1.19023V4.31443C12.7008 4.69563 12.3605 5.00466 11.9407 5.00466C11.521 5.00466 11.1807 4.69563 11.1807 4.31443V1.19023C11.1807 0.809027 11.521 0.5 11.9407 0.5Z"
                            fill="#16273C"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M5.05889 0.5C5.47867 0.5 5.81896 0.809027 5.81896 1.19023V4.31443C5.81896 4.69563 5.47867 5.00466 5.05889 5.00466C4.63912 5.00466 4.29883 4.69563 4.29883 4.31443V1.19023C4.29883 0.809027 4.63912 0.5 5.05889 0.5Z"
                            fill="#16273C"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M-0.000976562 7.44023C-0.000976562 7.05903 0.339317 6.75 0.759089 6.75H16.2404C16.6602 6.75 17.0005 7.05903 17.0005 7.44023C17.0005 7.82143 16.6602 8.13046 16.2404 8.13046H0.759089C0.339317 8.13046 -0.000976562 7.82143 -0.000976562 7.44023Z"
                            fill="#16273C"
                          />
                        </svg>
                      </MDButton>
                      {/* {console.log("callendeadsca",calendaropen.viewactvitycal)} */}
                      {viewActivity.viewactvitycal && (
                        <ClickAwayListener
                          onClickAway={(e) =>
                            setViewActivity((prev) => ({
                              ...prev,
                              viewactvitycal: false,
                            }))
                          }
                        >
                          <div>
                            <ReactCalendar
                              onChange={(e) => handleViewActivityDate(e)}
                              value={viewActivity.viewactvitycalval}
                              // minDate={new Date()}
                              locale={language == "fr" ? "fr-FR" :"en-EN"}
                            />
                          </div>
                        </ClickAwayListener>
                      )}
                    </Box>
                  </Box>
                  <p>{t("Scheduler1.activityList")}</p>
                  <Box className="event-group">
                    <Box className="event-scroll">
                      {viewActivity.listofActivity.map((data, index) => (
                        <Box
                          className={"event " + data.color}
                          sx={{
                            borderColor: data.color,
                            color: data.color,
                          }}
                        >
                          <label>{data.activityName}</label>
                          <MDButton
                            onClick={(e) => {
                              handleRemoveActivity(data);
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              viewBox="0 0 10 10"
                              fill="none"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M9.79079 0.209209C9.51184 -0.0697365 9.05958 -0.0697365 8.78064 0.209209L0.209209 8.78064C-0.0697365 9.05958 -0.0697365 9.51184 0.209209 9.79079C0.488155 10.0697 0.940416 10.0697 1.21936 9.79079L9.79079 1.21936C10.0697 0.940416 10.0697 0.488155 9.79079 0.209209Z"
                                fill="#000"
                              />
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M0.209209 0.209209C0.488155 -0.0697365 0.940416 -0.0697365 1.21936 0.209209L9.79079 8.78064C10.0697 9.05958 10.0697 9.51184 9.79079 9.79079C9.51184 10.0697 9.05958 10.0697 8.78064 9.79079L0.209209 1.21936C-0.0697365 0.940416 -0.0697365 0.488155 0.209209 0.209209Z"
                                fill="#000"
                              />
                            </svg>
                          </MDButton>
                          <Box
                            className="bg"
                            sx={{
                              background: data.color,
                            }}
                          ></Box>
                        </Box>
                      ))}
                    </Box>
                  </Box>
                  {/* <Box sx={{ display: "flex", justifyContent: "end" }}>
                    <MDButton className="secondary-btn">{t("Save")}</MDButton>
                  </Box> */}
                </Box>
              </Box>
            </Box>
          </Modal>
          <Modal
            open={viewActivity.deleteConfirmModal}
            onClose={(e) =>
              setViewActivity((prev) => ({
                ...prev,
                deleteConfirmModal: false,
              }))
            }
            aria-labelledby="modal-modal-title"
            className="cs-modal xs info"
            aria-describedby="modal-modal-description"
          >
            <Box className="modal-dialog">
              <Box className="modal-content">
                <Box className="modal-body">
                  <p>{t("alerts.activitycollision.wouldYouLikeTo")}</p>
                  <Box>
                    <MDButton
                      className="secondary-btn"
                      onClick={(e) =>
                        setViewActivity((prev) => ({
                          ...prev,
                          confirmModal: true,
                          deleteconfirmmodalresp: 0,
                        }))
                      }
                    >
                      {t("Scheduler1.deleteCurrent")}
                    </MDButton>
                    <MDButton
                      className="secondary-btn white"
                      disabled={!viewActivity.activityRepeate}
                      onClick={(e) =>
                        setViewActivity((prev) => ({
                          ...prev,
                          confirmModal: true,
                          deleteconfirmmodalresp: 1,
                        }))
                      }
                    >
                      {t("Scheduler1.deleteSeries")}
                    </MDButton>
                    <MDButton
                      className="secondary-btn transparent"
                      onClick={(e) =>
                        setViewActivity((prev) => ({
                          ...prev,
                          deleteConfirmModal: false,
                        }))
                      }
                    >
                      {t("Scheduler1.cancelbtn")}
                    </MDButton>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Modal>
          <Modal
            open={viewActivity.confirmModal}
            onClose={(e) =>
              setViewActivity((prev) => ({ ...prev, confirmModal: false }))
            }
            aria-labelledby="modal-modal-title"
            className="cs-modal xs info"
            aria-describedby="modal-modal-description"
          >
            <Box className="modal-dialog">
              <Box className="modal-content">
                <Box className="modal-body confirmation">
                  <p>{t("Scheduler1.confirmation")}</p>
                  <Box>
                    <MDButton
                      className="secondary-btn"
                      onClick={async (e) => {
                        
                        setLoading(true)
                        await remove_schedule_task(
                          selecetdCustomer.id,
                          selected_clinic.id,
                          viewActivity.userdata.id,
                          viewActivity.activitytodelete,
                          viewActivity.userdata.schedule,
                          viewActivity.viewactvitycalval,
                          viewActivity.deleteconfirmmodalresp == 1
                        );
                        setLoading(false)
                        handleActivityModalclose();
                        setRefresh(!refresh);
                      }}
                    >
                      {t("Scheduler1.yes")}
                    </MDButton>
                    <MDButton
                      className="secondary-btn white"
                      onClick={(e) =>
                        setViewActivity((prev) => ({
                          ...prev,
                          confirmModal: false,
                        }))
                      }
                    >
                      {t("Scheduler1.no")}
                    </MDButton>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Modal>
          <Modal
            open={OC_modal}
            onClose={(e) =>{
              setOcModal(false);
              handlemodalClose()
            }}
            aria-labelledby="modal-modal-title"
            className="cs-modal xs info"
            aria-describedby="modal-modal-description"
          >
            <Box className="modal-dialog">
              <Box className="modal-content">
              <Box className="modal-heading">
                  <Typography variant="h2" component="h2">
                    {t("Warning")}
                  </Typography>
                </Box>
                <Box className="modal-body confirmation">
                  <p>{t("Scheduler1.oc_alert")}</p>
                  <Box>
                    <MDButton
                      className="secondary-btn"
                      onClick={async (e) => {
                        setOcModal(false);
                        conditionIsMet()
                      }}
                    >
                      {t("Scheduler1.confirmation")}
                    </MDButton>
                    <MDButton
                      className="secondary-btn white"
                      onClick={(e) =>
                      {  setOcModal(false);
                        handlemodalClose()
                      }
                      }
                    >
                      {t("Scheduler1.no")}
                    </MDButton>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Modal>
         
          <Modal
            open={deleteModal.open}
            onClose={()=>handleDeleteRecords({},false,false)}
            aria-labelledby="modal-modal-title"
            className="cs-modal slack-md sm  range"
            aria-describedby="modal-modal-description"
          >
            <Box className="modal-dialog">
              <Box
                className="modal-content"
                component="form"
                onSubmit={(e) =>{
                  e.preventDefault()
                  setdeletemodal((prev)=>({
                  ...prev,
                  openconfirm:true
                }))}}
              >
                <Box className="modal-header">
                  <MDButton
                    className="modal-close"
                    onClick={() => handleDeleteRecords({},false,false)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                    >
                      <path
                        d="M13 1L1 13"
                        stroke="#fff"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M1 1L13 13"
                        stroke="#fff"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </MDButton>
                </Box>
                <Box className="modal-heading">
                  <Typography variant="h2" component="h2">
                    {t("Select Date Range")}
                  </Typography>
                </Box>
                <Box
                  className="modal-body"
                  component="form"
                  // onSubmit={handleAssignActivity}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                      <Box>
                        {/* <InputLabel className="formlabel">
                          {t("Scheduler1.activityLabel")}
                        </InputLabel> */}
                        <FormGroup sx={{ mb: 2, mt: 0, mr: 8 }}>
                      <Box
                        sx={{
                          display: { xs: "block", sm: "flex" },
                        }}
                      >
                        <FormControlLabel
                          sx={{
                            display: "flex",
                            textAlign: "center",
                          }}
                          className="cs-radio"
                          label={t("All")}
                          value="all"
                          name = "deletetype"
                          control={
                            <Radio
                            checked = {deleteModal.deletetype=="all"}
                            onChange={(e) =>
                              setdeletemodal((prev) => ({
                                ...prev,
                                [e.target.name]: e.target.value
                              }))
                            }

                            />
                          }
                        />
                    
                        <FormControlLabel
                          sx={{
                            display: "flex",
                            textAlign: "center",
                            ml: 6,
                          }}
                          className="cs-radio"
                          label={t("Custom range")}
                          value="range"
                          name = "deletetype"
                          control={
                            <Radio
                            checked = {deleteModal.deletetype=="range"}
                            onChange={(e) =>
                              setdeletemodal((prev) => ({
                                ...prev,
                                [e.target.name]: e.target.value
                              }))
                            }
                            
                            />
                          }
                        />
                      </Box>
                    </FormGroup>
                      </Box>
                    </Grid>
                    
                  
                    
                     
                    <Grid item xs={12} md={12}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                          <FormGroup
                            mt={1}
                            className="input-icon calendar-view"
                            disabled={deleteModal.deletetype !== "range"}
                            onClick={() =>
                              setdeletemodal((prev) => ({
                                ...prev,
                                startcal: true,
                              }))
                            }
                          >
                            <InputLabel className="formlabel">
                              {t("From (including)")}
                            </InputLabel>
                            <TextField
                              className="forminput"
                              placeholder={t("Scheduler1.calenderPlaceholder")}
                              variant="outlined"
                              disabled={deleteModal.deletetype !== "range"}
                              value={moment(deleteModal.startDate).format(
                                "DD-MM-YYYY"
                              )}
                              InputProps={{
                                readOnly: true,
                              }}
                            />
                            <MDButton>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="17"
                                height="18"
                                viewBox="0 0 15 16"
                                fill="none"
                              >
                                <path
                                  d="M12.4111 2.12109H2.12047C1.30857 2.12109 0.650391 2.77927 0.650391 3.59118V13.8818C0.650391 14.6937 1.30857 15.3518 2.12047 15.3518H12.4111C13.223 15.3518 13.8811 14.6937 13.8811 13.8818V3.59118C13.8811 2.77927 13.223 2.12109 12.4111 2.12109Z"
                                  fill="#16273C"
                                />
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M2.11966 2.76789C1.6665 2.76789 1.29914 3.13525 1.29914 3.58841V13.879C1.29914 14.3321 1.6665 14.6995 2.11966 14.6995H12.4102C12.8634 14.6995 13.2308 14.3322 13.2308 13.879V3.58841C13.2308 3.13525 12.8634 2.76789 12.4102 2.76789H2.11966ZM0 3.58841C0 2.41775 0.949002 1.46875 2.11966 1.46875H12.4102C13.5809 1.46875 14.5299 2.41775 14.5299 3.58841V13.879C14.5299 15.0496 13.5809 15.9986 12.4102 15.9986H2.11966C0.949003 15.9986 0 15.0496 0 13.879V3.58841Z"
                                  fill="white"
                                />
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M10.2062 0C10.565 0 10.8558 0.290823 10.8558 0.649572V3.58974C10.8558 3.94849 10.565 4.23931 10.2062 4.23931C9.84746 4.23931 9.55664 3.94849 9.55664 3.58974V0.649572C9.55664 0.290823 9.84746 0 10.2062 0Z"
                                  fill="white"
                                />
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M4.32438 0C4.68313 0 4.97395 0.290823 4.97395 0.649572V3.58974C4.97395 3.94849 4.68313 4.23931 4.32438 4.23931C3.96563 4.23931 3.6748 3.94849 3.6748 3.58974V0.649572C3.6748 0.290823 3.96563 0 4.32438 0Z"
                                  fill="white"
                                />
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M0 6.52848C0 6.16973 0.290823 5.87891 0.649572 5.87891H13.8803C14.2391 5.87891 14.5299 6.16973 14.5299 6.52848C14.5299 6.88723 14.2391 7.17805 13.8803 7.17805H0.649572C0.290823 7.17805 0 6.88723 0 6.52848Z"
                                  fill="white"
                                />
                              </svg>
                            </MDButton>
                            {deleteModal.startcal && (
                              <ClickAwayListener
                                onClickAway={() =>
                                  setdeletemodal((prev) => ({
                                    ...prev,
                                    startcal: false,
                                  }))
                                }
                              >
                                <div>
                                  <ReactCalendar
                                    // onChange={(e) => console.log(e)}
                                    value={deleteModal.startDate}
                                    selectRange={false}
                                    // minDate={new Date()}
                                    locale={language == "fr" ? "fr-FR" :"en-EN"}
                                    onChange={(e) =>
                                      setdeletemodal((prev) => ({
                                        ...prev,
                                        startDate: e,
                                      }))
                                    }
                                  />
                                </div>
                              </ClickAwayListener>
                            )}
                          </FormGroup>
                        </Grid>

                      
                          <Grid item xs={12} md={6}>
                            <FormGroup
                              mt={1}
                              className="input-icon calendar-view"
                              disabled={deleteModal.deletetype !== "range"}
                              onClick={() =>
                                setdeletemodal((prev) => ({
                                  ...prev,
                                  endcal: true,
                                }))
                              }
                            >
                              <InputLabel className="formlabel">
                                {t("Up to (including)")}
                              </InputLabel>
                              <TextField
                                className="forminput"
                                placeholder={t(
                                  "Scheduler1.calenderPlaceholder"
                                )}
                                variant="outlined"
                                value={moment(deleteModal.endDate).format(
                                  "DD-MM-YYYY"
                                )}
                                disabled={deleteModal.deletetype !== "range"}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                              <MDButton>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="17"
                                  height="18"
                                  viewBox="0 0 15 16"
                                  fill="none"
                                >
                                  <path
                                    d="M12.4111 2.12109H2.12047C1.30857 2.12109 0.650391 2.77927 0.650391 3.59118V13.8818C0.650391 14.6937 1.30857 15.3518 2.12047 15.3518H12.4111C13.223 15.3518 13.8811 14.6937 13.8811 13.8818V3.59118C13.8811 2.77927 13.223 2.12109 12.4111 2.12109Z"
                                    fill="#16273C"
                                  />
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M2.11966 2.76789C1.6665 2.76789 1.29914 3.13525 1.29914 3.58841V13.879C1.29914 14.3321 1.6665 14.6995 2.11966 14.6995H12.4102C12.8634 14.6995 13.2308 14.3322 13.2308 13.879V3.58841C13.2308 3.13525 12.8634 2.76789 12.4102 2.76789H2.11966ZM0 3.58841C0 2.41775 0.949002 1.46875 2.11966 1.46875H12.4102C13.5809 1.46875 14.5299 2.41775 14.5299 3.58841V13.879C14.5299 15.0496 13.5809 15.9986 12.4102 15.9986H2.11966C0.949003 15.9986 0 15.0496 0 13.879V3.58841Z"
                                    fill="white"
                                  />
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M10.2062 0C10.565 0 10.8558 0.290823 10.8558 0.649572V3.58974C10.8558 3.94849 10.565 4.23931 10.2062 4.23931C9.84746 4.23931 9.55664 3.94849 9.55664 3.58974V0.649572C9.55664 0.290823 9.84746 0 10.2062 0Z"
                                    fill="white"
                                  />
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M4.32438 0C4.68313 0 4.97395 0.290823 4.97395 0.649572V3.58974C4.97395 3.94849 4.68313 4.23931 4.32438 4.23931C3.96563 4.23931 3.6748 3.94849 3.6748 3.58974V0.649572C3.6748 0.290823 3.96563 0 4.32438 0Z"
                                    fill="white"
                                  />
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M0 6.52848C0 6.16973 0.290823 5.87891 0.649572 5.87891H13.8803C14.2391 5.87891 14.5299 6.16973 14.5299 6.52848C14.5299 6.88723 14.2391 7.17805 13.8803 7.17805H0.649572C0.290823 7.17805 0 6.88723 0 6.52848Z"
                                    fill="white"
                                  />
                                </svg>
                              </MDButton>
                              {deleteModal.endcal && (
                                <ClickAwayListener
                                  onClickAway={() =>
                                    setdeletemodal((prev) => ({
                                      ...prev,
                                      endcal: false,
                                    }))
                                  }
                                >
                                  <div>
                                    <ReactCalendar
                                      // onChange={(e) => console.log(e)}
                                      value={deleteModal.endDate}
                                      selectRange={false}
                                      // minDate={new Date()}/
                                      minDate={deleteModal.startDate}
                                      maxDate={addYearAndMonth(selectionRange.startDate)}
                                      locale={language == "fr" ? "fr-FR" :"en-EN"}
                                      onChange={(e) =>
                                        setdeletemodal((prev) => ({
                                          ...prev,
                                          endDate: e,
                                        }))
                                      }
                                    />
                                  </div>
                                </ClickAwayListener>
                              )}
                            </FormGroup>
                          </Grid>
                        
                      </Grid>
                    </Grid>
                
                 
                  </Grid>
                </Box>
                <Box className="modal-footer">
                  <MDButton
                    className="secondary-btn"
                    sx={{
                      padding: "11px 30px",
                      fontSize: "16px",
                      fontWeight: "normal",
                      minWidth: "160px",
                    }}
                    type="submit"
                    color="dark"
                  >
                    {t("Scheduler1.submit")}
                  </MDButton>
                </Box>
              </Box>
            </Box>
          </Modal>
        <DeleteModal open={deleteModal.openconfirm} onChange={(e)=>handleDeleteRecords({},false,e)}/>
        </Box>
      </MDBox>
    </DashboardLayout>
  );
}

export default Customer;
