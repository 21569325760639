import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import MDButton from "components/MDButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@material-ui/core/Typography";
import Sort from "../../assets/images/icons/sort.png";
import BoxArrow from "../../assets/images/resonante/box-arrow.png";
import SortWhite from "../../assets/images/icons/sortwhite.png";
import Filter from "../../components/Filter";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
//modal import
import Modal from "@mui/material/Modal";
import { useTranslation } from "react-i18next";
import Loader from "components/Loader";
import { categoryType } from "backendservice/firebase";
import { v4 as uuidv4 } from "uuid";
import { useNavigate } from "react-router-dom";
import { useMaterialUIController } from "context";
import SearchComponent from "components/SearchInput";
import Sortlist from "components/Sortlist";
import { get_staff_clinic } from "backendservice/firebasefunctions,";
import { get_contract } from "backendservice/firebasefunctions,";
import moment from "moment";

function Profile() {
  const [controller, dispatch] = useMaterialUIController();
  const { selecetdCustomer, permission,selected_clinic } = controller;
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const open = Boolean(anchorEl);

  const { t } = useTranslation();
  const [stafflist, setStafflist] = useState([]);
  const [stafflistbackup, setstafflistbackup] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [conract,setContract] = useState([])
  const [loading, setLoading] = useState(false);
  const [show,setshow] = useState("")
  // console.log("show",show)
  const loadData = async () => {
 
    
    let customer = selecetdCustomer.id;
    let clinic = selected_clinic.id;
    // console.log("selected clinic",selected_clinic)
    if (customer !== "" && customer !== undefined && clinic !== "" && clinic !== undefined) {
      try {
        setLoading(true);
       
        var stafflist = await get_staff_clinic(customer,clinic);
        var contractList  = await get_contract(customer)

        //  var roleList = await get_roles(customer)
        //  console.log(contractList,roleList)
        // var data = stafflist.map((doc) => ({
        //   id: doc.id,
        //   data: doc.data,
        //   viewdetails: false,
        //   active: doc.active,
        //   // contractType:getContractType(doc.data.contractTypeId,contractList),
        //   // employeeType:getEmployeeType(doc.data.employeeTypeId,employeetype),
        //   // roleDescription :getRoleDescription(doc.data.group,roleList)
        // }));
        // console.log("data ",data)
        setContract(contractList)
        setStafflist(stafflist);
        setstafflistbackup(stafflist);

        setLoading(false);
      } catch (error) {
        console.log("error ==>", error);
        setLoading(false);
        setStafflist([]);
        setstafflistbackup([]);
        // 
      }
    }
    setLoading(false);
  };
  useEffect(() => {
    if(selecetdCustomer.id!=="" && selecetdCustomer.id !==undefined && selected_clinic.id!==undefined && selected_clinic.id!=="" && permission)
      {
        loadData();
      }
  }, [refresh, selecetdCustomer,selected_clinic,permission]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Loader loading={loading} />
      <MDBox pt={4} pb={3} className="xl-container">
        <Box>
          <Box>
            <MDBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <MDBox className="page-heading">
                <MDTypography
                  variant="h5"
                  gutterBottom
                  sx={{ pb: "8px", display: "flex" }}
                >
                  <Box
                    sx={{
                      pt: "2px",
                      width: "22px;",
                      display: { xs: "none", sm: "flex" },
                    }}
                  >
                    <MDButton onClick={() => navigate(-1)}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M0 5.99961C0 5.4524 0.443608 5.00879 0.990826 5.00879H11.0092C11.5564 5.00879 12 5.4524 12 5.99961C12 6.54683 11.5564 6.99044 11.0092 6.99044H0.990826C0.443608 6.99044 0 6.54683 0 5.99961Z"
                          fill="#16273C"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M6.70062 0.290206C7.08756 0.677148 7.08756 1.3045 6.70062 1.69145L2.39206 6L6.70062 10.3086C7.08756 10.6955 7.08756 11.3229 6.70062 11.7098C6.31368 12.0967 5.68632 12.0967 5.29938 11.7098L0.290206 6.70062C-0.0967354 6.31368 -0.0967354 5.68632 0.290206 5.29938L5.29938 0.290206C5.68632 -0.0967354 6.31368 -0.0967354 6.70062 0.290206Z"
                          fill="#16273C"
                        />
                      </svg>
                    </MDButton>
                  </Box>
                  {t("Contract report")}
                </MDTypography>
              </MDBox>
            </MDBox>

            <Box>
              <Box
                sx={{
                  margin: "10px 0 0 0",
                }}
              >
                <MDBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                     <SearchComponent
                    searchList={stafflistbackup}
                    onSearch={(e) => setStafflist(e)}
                  />
                  <Box className="filter-mobile">
                    <MDBox
                      color="text"
                      px={0}
                      sx={{ display: { xs: "block", sm: "flex" } }}
                    >
                      <Box
                        sx={{
                          display: { xs: "block", sm: "flex" },
                        }}
                      >
                            <Sortlist
                          onSort={(e) => setStafflist(e)}
                          sortlist={stafflistbackup}
                        />

                        {/* <Filter   onFilter={(e)=>{setStafflist(e)}} filterlist={stafflistbackup} brandId ={selecetdCustomer.id} /> */}
                   
                      </Box>
                    </MDBox>
                  </Box>
                </MDBox>
              </Box>
              <MDBox
                sx={{
                  marginTop: "20px",
                }}
              ></MDBox>
            </Box>
          </Box>
        </Box>
        <Box className="grid-datatable">
        <Box className="grid-heading">
          <Grid container className="grid">
            <Grid item xs={12} md={3}>
              <label>{t("Staff")}</label>
            </Grid>
            <Grid item xs={12} md={3}>
              <label>{t("Contract")} </label>
            </Grid>

            <Grid item xs={12} md={3}>
              <label>{t("Contract from")}</label>
            </Grid>
            <Grid item xs={12} md={3}>
              <label>{t("Contract end")}</label>
            </Grid>
          </Grid>
        </Box>
      { stafflist.length>0 && stafflist.map((data,index)=>(
 <Box className="grid-data" key = {index}>
 <Grid container className="grid">
   <Grid item xs={12} md={3}>
     <label>{data.label}</label>
   </Grid>
   <Grid item xs={12} md={3}>
   <label><span>{conract.find((item)=>item.id==data.contractTypeId)?.label}</span> 

<MDButton onClick={(e)=>
{  
  if(show===index)
    {
      setshow("")
    }else{
      setshow(index)
    }
 
  }
  }><img src={BoxArrow}/></MDButton>
</label>

   </Grid>

   <Grid item xs={12} md={3}>
     <label>{t("Contract from")}</label>
   </Grid>
   <Grid item xs={12} md={3}>
     <label>{t("Contract end")}</label>
   </Grid>
 </Grid>
{ data.contractList && data.contractList.length>0 && data.contractList.map((d,i)=>(
  <>{ show===index && <Grid container className="grid">
   <Grid item xs={12} md={3}>
     <label></label>
   </Grid>
   <Grid item xs={12} md={3}>
   <label>{conract.find((item)=>item.id==d.contractId)?.label} </label>
   </Grid>

   <Grid item xs={12} md={3}>
     <label>{moment(d.start,"YYYY-MM_DD").format("MM/DD/YYYY")}</label>
   </Grid>
   <Grid item xs={12} md={3}>
     <label>{d.end?moment(d.end,"YYYY-MM_DD").format("MM/DD/YYYY"):""}</label>
   </Grid>
 </Grid>}
 </>
))}

</Box>

      ))}
      

        
        </Box>
      </MDBox>
    </DashboardLayout>
  );
}

export default Profile;
