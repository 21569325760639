import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import MDButton from "components/MDButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

import Typography from "@material-ui/core/Typography";
import Sort from "../../assets/images/icons/sort.png";
import SortWhite from "../../assets/images/icons/sortwhite.png";
import Filter from "../../components/Filter";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
//modal import
import Modal from "@mui/material/Modal";
import { useTranslation } from "react-i18next";
import Loader from "components/Loader";
import { categoryType } from "backendservice/firebase";
import { v4 as uuidv4 } from "uuid";
import { useNavigate } from "react-router-dom";
import {
  and,
  doc,
  getDocs,
  or,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "backendservice/firebase";
import alasql from "alasql";
import { activities } from "backendservice/firebase";
import Nodata from "components/Nodata";
import moment from "moment";
import SearchComponent from "components/SearchInput";
import Sortlist from "components/Sortlist";
import {
  useMaterialUIController,

} from "context";
import { createFirebaseTimestamp } from "layouts/scheduler/functions";
import { deleteDocument } from "backendservice/firebasefunctions,";
import { CounterUpdate } from "backendservice/firebasefunctions,";
function Clinics() {
  const [controller, dispatch] = useMaterialUIController();
  const {

  selecetdCustomer,
  selected_clinic,

  module,
  permission
} = controller;
  const [followsMe, setFollowsMe] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  
  const { t } = useTranslation();
  const [answersPost, setAnswersPost] = useState(false);
  //modal open hook
  const [modalopen, setmodalopen] = useState(false);
  //modal open close functionn
  //   const handlemodalopen = () => {
  //     setmodalopen(true);
  //   };
  //   const handlemodalClose = () => {
  //     setmodalopen(false);
  //   };
  const [categoryOptions, setcategoryOption] = useState([]);
  const [categoryOptions2, setcategoryOption2] = useState([]);

  const [name, setName] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);
  const [customerID, setCustomer] = useState("");
  const [activitiesData,setActivitiesData] = useState([])
  const [activitiesDatabackup,setActivitiesDatabackup] = useState([])

  const loadData = async()=>{
    let customerid = selecetdCustomer.id
    if(customerid!=="" && customerid!==undefined && selected_clinic.id!=="" && selected_clinic.id !==undefined)
    {
    setLoading(true)
    try{
      var snapshot = await getDocs(
        query(activities,and(  where("customer", "==", customerid), or(
          // Condition 1: "clinic" field is in a list of specific values
          where("selectedClinic", "array-contains", selected_clinic.id),
          // Condition 2: "defaultclinic" field matches a specific value
          where("defaultClinicId", "==", selected_clinic.id)
        )))
      );
      var activitiesData = snapshot.docs.map((data) => ({
        ...data.data(),
        id:data.id,
        data:data.data(),
        label:data.data().data.activityName,
        createdTime:data.data().createdTime
      }));
      // console.log(activitiesData,"act data");
      setActivitiesData(activitiesData)
      setActivitiesDatabackup(activitiesData)
      setLoading(false)
    }catch(error)
    {
      setLoading(false)
      console.log(error)
    }
  }
  
  }

  const handlemodalopen = () => {
    setmodalopen(true);
  };
  const handlemodalClose = () => {
    setName("");

    setmodalopen(false);
  };

  const handleSubmit = async (e) => {

    setLoading(true);
    var id = uuidv4();

    var data = {
      name: name,
      customer: selecetdCustomer.id,
      status: true,
    };
    try {
      const docref = await setDoc(
        doc(db, process.env.REACT_APP_CATEGORYTYPE, id),
        data
      );
      handlemodalClose();
    } catch (error) {
      console.log(error);
      handlemodalClose();
    }
    handlemodalClose();
    setRefresh(!refresh);
    // setLoading(false)
  };
  const formatDuration = (duration) => {

    const [hours, minutes] = duration.split(':').map(Number);

    // Convert total hours to days, hours, and minutes
    const totalMinutes = (hours * 60) + minutes;
    const days = Math.floor(totalMinutes / (24 * 60));
    const remainingMinutesAfterDays = totalMinutes % (24 * 60);
    const displayHours = Math.floor(remainingMinutesAfterDays / 60);
    const displayMinutes = remainingMinutesAfterDays % 60;

    let result = '';
    if (days > 0) {
        result += `${days}${t("d")} `;
    }
    if (displayHours > 0) {
        result += `${displayHours}${t("hrs")} `;
    }
    if (displayMinutes > 0) {
        result += `${displayMinutes}${t("min")} `;
    }

    return result.trim();
}
  const handleSearch = (e) => {
    var res = alasql(
      "SELECT * FROM ? WHERE label LIKE '%" + e.target.value + "%'",
      [categoryOptions]
    );
    setcategoryOption2(res);
  };
  const handleSort = (e, type) => {
    if (type == "name") {
      var res = alasql("SELECT * FROM ? ORDER BY label", [categoryOptions2]);
      setcategoryOption2(res);
    }
    handleClose();
  };
  const isButtonDisabled = (moduleId) => {
    return !(
      !permission.module ||
      !permission.module[moduleId] ||
      permission.module[moduleId].accesstype === 2
    );
  };
const handleDelete = async(id)=>{
  setLoading(true)
  if(await deleteDocument(process.env.REACT_APP_ACTIVITY,id))
    {
      alert(t("delete.success"))
    }else{
      alert(t("delete.restricted"))
    }
    setLoading(false)
  setRefresh(!refresh)
}

  useEffect(() => {
    if (selected_clinic.id && selecetdCustomer.id) {
      loadData().then(() => setLoading(false));
    }
  }, [refresh, selected_clinic, selecetdCustomer]); 

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Loader loading={loading} />
      <MDBox pt={4} pb={3} className="xl-container1 activity">
        <Box>
          <Box>
            <MDBox className="page-heading">
              <MDTypography
                variant="h5"
                gutterBottom
                sx={{ pb: "8px", display: "flex" }}
              >
                {t("Activities")}
              </MDTypography>
            </MDBox>

            <Box>
              <Box
                sx={{
                  margin: "10px 0 0 0",
                }}
              >
                <MDBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
               <SearchComponent searchList = {activitiesDatabackup} onSearch={(e)=> setActivitiesData(e)} />
            
                  <Box className="filter-mobile">
                    <MDBox
                      color="text"
                      px={0}
                      sx={{ display: { xs: "block", sm: "flex" } }}
                    >
                      <Box
                        sx={{
                          display: { xs: "block", sm: "flex" },
                        }}
                      >
                        <Sortlist onSort={(e)=>setActivitiesData(e)} sortlist={activitiesDatabackup} module = "activityDatabase"/>
                       
                        <Filter onFilter={(e)=>{setActivitiesData(e)}} filterlist={activitiesDatabackup} module = "activityDatabase"/>
                      </Box>
                    </MDBox>
                  </Box>
                </MDBox>
              </Box>
            
              <MDBox
                sx={{
                  marginTop: "20px",
                }}
              >
                {activitiesData.length == 0 ? <Nodata /> : 
                <Box className="table-heading">
                <table>
                  <tr class="bg-color">
                    <th className="activity-table">{t("Activity")}</th>
                    <th className="activity-table">{t("Activity short name")}</th>
                    <th>
  <table>
    <tr>
      <td>
        <span>{t("From hour")}</span>
      </td>
      <td>
        <span>{t("To hour")}</span>
      </td>
      <td>
        <span>{t("Duration")}</span>
      </td>
      <td>
        <span>{t("Days")}</span>
      </td>
    </tr>
  </table>
</th>
                    <th>
                      <table>
                        <tr>
                          <td>
                            <label>{t("Subtotal countdown")}</label>
                          </td>
                        </tr>
                        <tr>
                          <table>
                            <tr>
                              <td className="border-top border-right">
                                <span>{t("Hours")}</span>
                              </td>
                              <td className="border-top border-right">
                                <span>{t("Days")}</span>
                              </td>
                              <td className="border-top">
                                <span>{t("Quantity")}</span>
                              </td>
                            </tr>
                          </table>
                        </tr>
                      </table>
                    </th>
                    <th>
                      <table>
                        <tr>
                          <td>
                            <label>{t("Rate")}</label>
                          </td>
                        </tr>
                        <tr>
                          <table>
                            <tr>
                              {/* <td className="border-top border-right">
                                <span>{t("Classic")}</span>
                              </td>
                              <td className="border-top border-right">
                                <span>{t("Individual rate")}</span>
                              </td>
                              <td className="border-top border-right">
                                <span>{t("Extra hours")}</span>
                              </td> */}
                              <td className="border-top border-right">
                                <span>{t("On duty")}</span>
                              </td>
                              <td className="border-top border-right">
                                <span>{t("On call")}</span>
                              </td>
                              {/* <td className="border-top">
                                <span>{t("On call & called in")}</span>
                              </td> */}
                            </tr>
                          </table>
                        </tr>
                     
                      </table>
                    </th>
                   </tr>
                 { 

                 activitiesData.map((activity,index) => (
                  <tr>
                  <td>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <span>
                        <Box className="dark-green"
                            sx={{borderColor:`${activity.data.data.color} !important`}}
                            >
                          <label>{activity.data.data.fullname || activity.data.data.activityName}</label>
                          <Box> 
                <MDButton onClick={() => navigate("/database/edit/"+activity.id)} 
                            disabled={isButtonDisabled("REST06")}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="18"
                              height="17"
                              viewBox="0 0 13 12"
                              fill="none"
                            >
                              <path
                                d="M9.32139 1.4178C9.46709 1.28534 9.64007 1.18027 9.83044 1.10858C10.0208 1.0369 10.2248 1 10.4309 1C10.637 1 10.841 1.0369 11.0314 1.10858C11.2217 1.18027 11.3947 1.28534 11.5404 1.4178C11.6861 1.55026 11.8017 1.70751 11.8806 1.88057C11.9594 2.05364 12 2.23913 12 2.42645C12 2.61377 11.9594 2.79926 11.8806 2.97233C11.8017 3.14539 11.6861 3.30265 11.5404 3.4351L4.05118 10.2435L1 11L1.83214 8.22621L9.32139 1.4178Z"
                                stroke="#16273C"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </MDButton>
                          <MDButton
                  className=""
                  onClick={() => handleDelete(activity.id)}
                  disabled={isButtonDisabled("REST06")}
                >
                 <svg xmlns="http://www.w3.org/2000/svg" width="17" height="19" viewBox="0 0 13 15" fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M0.151367 3.59384C0.151367 3.32412 0.370019 3.10547 0.639739 3.10547H12.3607C12.6304 3.10547 12.849 3.32412 12.849 3.59384C12.849 3.86356 12.6304 4.08221 12.3607 4.08221H0.639739C0.370019 4.08221 0.151367 3.86356 0.151367 3.59384Z" fill="#16273C"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M5.19829 1.47674C4.98241 1.47674 4.77538 1.5625 4.62274 1.71515C4.47009 1.86779 4.38433 2.07482 4.38433 2.2907V3.59302C4.38433 3.86274 4.16568 4.0814 3.89596 4.0814C3.62624 4.0814 3.40759 3.86274 3.40759 3.59302V2.2907C3.40759 1.81577 3.59625 1.3603 3.93207 1.02448C4.26789 0.688662 4.72337 0.5 5.19829 0.5H7.80294C8.27786 0.5 8.73333 0.688662 9.06915 1.02448C9.40497 1.3603 9.59364 1.81577 9.59364 2.2907V3.59302C9.59364 3.86274 9.37498 4.0814 9.10526 4.0814C8.83554 4.0814 8.61689 3.86274 8.61689 3.59302V2.2907C8.61689 2.07482 8.53114 1.86779 8.37849 1.71515C8.22584 1.5625 8.01881 1.47674 7.80294 1.47674H5.19829ZM1.94247 3.10465C2.21219 3.10465 2.43085 3.3233 2.43085 3.59302V12.7093C2.43085 12.9252 2.5166 13.1322 2.66925 13.2849C2.82189 13.4375 3.02893 13.5233 3.2448 13.5233H9.75643C9.9723 13.5233 10.1793 13.4375 10.332 13.2849C10.4846 13.1322 10.5704 12.9252 10.5704 12.7093V3.59302C10.5704 3.3233 10.789 3.10465 11.0588 3.10465C11.3285 3.10465 11.5471 3.3233 11.5471 3.59302V12.7093C11.5471 13.1842 11.3585 13.6397 11.0226 13.9755C10.6868 14.3113 10.2314 14.5 9.75643 14.5H3.2448C2.76988 14.5 2.31441 14.3113 1.97858 13.9755C1.64276 13.6397 1.4541 13.1842 1.4541 12.7093V3.59302C1.4541 3.3233 1.67275 3.10465 1.94247 3.10465Z" fill="#16273C"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M5.19833 6.35938C5.46805 6.35938 5.68671 6.57803 5.68671 6.84775V10.7547C5.68671 11.0244 5.46805 11.2431 5.19833 11.2431C4.92861 11.2431 4.70996 11.0244 4.70996 10.7547V6.84775C4.70996 6.57803 4.92861 6.35938 5.19833 6.35938Z" fill="#16273C"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M7.80283 6.35938C8.07255 6.35938 8.2912 6.57803 8.2912 6.84775V10.7547C8.2912 11.0244 8.07255 11.2431 7.80283 11.2431C7.5331 11.2431 7.31445 11.0244 7.31445 10.7547V6.84775C7.31445 6.57803 7.5331 6.35938 7.80283 6.35938Z" fill="#16273C"/>
                            </svg>
                
                </MDButton>
                </Box>
                        
                        </Box>
                      </span>
                    </Box>
                  </td>
                  <td>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <span>
                        {/* <Box className="dark-green"
                            sx={{borderColor:`${activity.data.data.color} !important`}}
                            > */}
                          <label>{ activity.data.data.activityName}</label>
                          
                        
                        {/* </Box> */}
                      </span>
                    </Box>
                  </td>
                  <td>
                    <table>
                      <tr>
                        <td className="border-right">
                          <span>{activity.data.data.duration.startingTime != "Invalid date" ? activity.data.data.duration.startingTime : null}</span>
                        </td>
                        <td className="border-right">
                          <span>{activity.data.data.duration.closingTime != "Invalid date" ? activity.data.data.duration.closingTime : null}</span>
                        </td>
                        <td className="border-right">
                          <span>{formatDuration(activity.data.data.duration.durationHours)}</span>
                        </td>
                        <td>
                          <span>{activity.data.data.duration.numberOfDays}</span>
                        </td>
                      </tr>
                    </table>
                  </td>
                  <td>
                    <table>
                      <tr>
                        <td className="border-right">
                          <span>
                           {activity.data.data.subtotalCountdown.hours ? 
                          <>
                           {" "}
                           <svg
                             xmlns="http://www.w3.org/2000/svg"
                             width="17"
                             height="12"
                             viewBox="0 0 17 12"
                             fill="none"
                           >
                             <path
                               d="M16 1L5.6875 11L1 6.45455"
                               stroke="#13273B"
                               stroke-linecap="round"
                               stroke-linejoin="round"
                             />
                           </svg>
                          </>:
                           <span></span>
                           }
                          </span>
                        </td>
                        <td className="border-right">
                        {activity.data.data.duration.days ? 
                          <>
                           {" "}
                           <svg
                             xmlns="http://www.w3.org/2000/svg"
                             width="17"
                             height="12"
                             viewBox="0 0 17 12"
                             fill="none"
                           >
                             <path
                               d="M16 1L5.6875 11L1 6.45455"
                               stroke="#13273B"
                               stroke-linecap="round"
                               stroke-linejoin="round"
                             />
                           </svg>
                          </>:
                           <span></span>
                           }
                        </td>
                        <td>
                        {activity.data.data.subtotalCountdown.numbers ? 
                          <>
                           {" "}
                           <svg
                             xmlns="http://www.w3.org/2000/svg"
                             width="17"
                             height="12"
                             viewBox="0 0 17 12"
                             fill="none"
                           >
                             <path
                               d="M16 1L5.6875 11L1 6.45455"
                               stroke="#13273B"
                               stroke-linecap="round"
                               stroke-linejoin="round"
                             />
                           </svg>
                          </>:
                           <span></span>
                           }
                        </td>
                      </tr>
                    </table>
                  </td>
                  <td>
                    <table>
                      <tr>
                  
            
                        <td className="border-right">
                        {activity.data.data.rate.onDuty ? 
                          <>
                           {" "}
                           <svg
                             xmlns="http://www.w3.org/2000/svg"
                             width="17"
                             height="12"
                             viewBox="0 0 17 12"
                             fill="none"
                           >
                             <path
                               d="M16 1L5.6875 11L1 6.45455"
                               stroke="#13273B"
                               stroke-linecap="round"
                               stroke-linejoin="round"
                             />
                           </svg>
                          </>:
                           <span></span>
                           }
                        </td>
                        <td className="border-right">
                        {activity.data.data.rate.onCall ? 
                          <>
                           {" "}
                           <svg
                             xmlns="http://www.w3.org/2000/svg"
                             width="17"
                             height="12"
                             viewBox="0 0 17 12"
                             fill="none"
                           >
                             <path
                               d="M16 1L5.6875 11L1 6.45455"
                               stroke="#13273B"
                               stroke-linecap="round"
                               stroke-linejoin="round"
                             />
                           </svg>
                          </>:
                           <span></span>
                           }
                        </td>
                        {/* <td>
                        {activity.data.data.rate.onCallIn ? 
                          <>
                           {" "}
                           <svg
                             xmlns="http://www.w3.org/2000/svg"
                             width="17"
                             height="12"
                             viewBox="0 0 17 12"
                             fill="none"
                           >
                             <path
                               d="M16 1L5.6875 11L1 6.45455"
                               stroke="#13273B"
                               stroke-linecap="round"
                               stroke-linejoin="round"
                             />
                           </svg>
                          </>:
                           <span></span>
                           }
                        </td> */}
                      </tr>
                    </table>
                  </td>
                </tr>
               
                ))}
                </table>
              </Box>}
              </MDBox>
              <Box className="navigation-link">
                <MDButton
                  className="primary-btn btn xl"
                  onClick={() => navigate("/database/detail")}
                  disabled={isButtonDisabled("REST06")}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="13"
                    viewBox="0 0 12 13"
                    fill="none"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M7.36739 1.86739C7.36739 1.1122 6.75519 0.5 6 0.5C5.24481 0.5 4.63261 1.1122 4.63261 1.86739V5.13261H1.36739C0.612202 5.13261 0 5.74481 0 6.5C0 7.25519 0.612202 7.86739 1.36739 7.86739H4.63261V11.1326C4.63261 11.8878 5.24481 12.5 6 12.5C6.75519 12.5 7.36739 11.8878 7.36739 11.1326V7.86739H10.6326C11.3878 7.86739 12 7.25519 12 6.5C12 5.74481 11.3878 5.13261 10.6326 5.13261H7.36739V1.86739Z"
                      fill="white"
                    />
                  </svg>
                  &nbsp;&nbsp;{t("Add activity")}
                </MDButton>
              </Box>
            </Box>
          </Box>
        </Box>
      </MDBox>

      {/* Modal mm-04 */}
      <Modal
        open={modalopen}
        onClose={handlemodalClose}
        aria-labelledby="modal-modal-title"
        className="cs-modal slack-md"
        aria-describedby="modal-modal-description"
      >
        <Box className="modal-dialog">
          <Box className="modal-content">
            <Box className="modal-header">
              <MDButton
                className="modal-close"
                onClick={() => handlemodalClose()}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                >
                  <path
                    d="M13 1L1 13"
                    stroke="#282B3B"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M1 1L13 13"
                    stroke="#282B3B"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </MDButton>
            </Box>
            <Box className="modal-heading">
              <Typography variant="h2" component="h2">
                {t("Category")}
              </Typography>
            </Box>
            <Box className="modal-body">
              <FormGroup sx={{ mb: 2, mr: 2, width: "100%" }}>
                <InputLabel className="formlabel">{t("Name")}</InputLabel>
                <TextField
                  className="forminput"
                  placeholder={t("Enter name")}
                  variant="outlined"
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                />
              </FormGroup>
            </Box>
            <Box className="modal-footer">
              <MDButton
                className="secondary-btn"
                sx={{
                  padding: "11px 30px",
                  fontSize: "16px",
                  fontWeight: "normal",
                  minWidth: "160px",
                }}
                color="dark"
                onClick={(e) => handleSubmit(e)}
              >
                {t("Submit")}
              </MDButton>
            </Box>
          </Box>
        </Box>
      </Modal>
    </DashboardLayout>
  );
}

export default Clinics;
