import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import MDButton from "components/MDButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Radio from "@mui/material/Radio";
import Checkbox from "@mui/material/Checkbox";
import Select from "@mui/material/Select";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Filter from "../../components/Filter";
// Material Dashboard 2 React example components
import Explanation from "components/Explanation";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
//modal import
import Modal from "@mui/material/Modal";
import { useTranslation } from "react-i18next";
import Loader from "components/Loader";

import { useNavigate,useParams } from "react-router-dom";
import { useMaterialUIController } from "context";
import { get_module } from "backendservice/firebasefunctions,";
import { nameProps } from "backendservice/validation";
import { get_staff } from "backendservice/firebasefunctions,";
import { get_users } from "backendservice/firebasefunctions,";
import Nodata from "components/Nodata";
import { profilePictureStyle } from "layouts/staff/functions";
import { getInitials } from "layouts/staff/functions";
import { add_roles } from "backendservice/firebasefunctions,";
import { update_User } from "backendservice/firebasefunctions,";
import { get_role } from "backendservice/firebasefunctions,";
import { update_role } from "backendservice/firebasefunctions,";

function Staff() {
  const [followsMe, setFollowsMe] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [name,setname]  = useState("")
  const [page, setpage] = useState(0);
  const [controller, dispatch] = useMaterialUIController();
  const {
    selecetdCustomer,

  } = controller;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [list, setlist] = useState([]);
  const [listbackup, setlistbackup] = useState([]);


  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);
  const [customerID, setCustomer] = useState("");
  const [users,setUsers]=useState([])
  const [usersbackup,setUsersbackup]=useState([])
  const [editmode,setMode] = useState(false)
  const [type,setType] = useState("OT_00")
  var typeOption = [{label:"Admin",value:"BA_00"},{label:"Non-Admin",value:"OT_00"}]
  const loadData = async () => {
    let customer = selecetdCustomer.id
    if (customer !== "" && customer !== undefined) {
      setLoading(true)
      console.log("data =====>", customer)
      try {
        var data = await get_module()
        console.log("module",data)
        var staff = await get_users(customer)
        setUsers(staff)
        // console.log("module data",data[0].module)
        setlist(data[0].module)
        setlistbackup(data[0].module)
        // console.log(data.module)
        setLoading(false)
      } catch (error) {
        setLoading(false)
        setlist([])
        setlistbackup([])
        // console.log("error ==>",error)
      }
    }
  }
  const loadOldData = async (id) => {
    let customer = selecetdCustomer.id
    if (customer !== "" && customer !== undefined) {
      setLoading(true)
      console.log("data =====>", customer)
      try {
        var data = await get_role(id)
        console.log("data",data)
        setname(data.role)
        var module = data.module
        console.log("module",data)
        var staff = await get_users(customer)
          staff.map((data,index)=>{
            if(data.group==id){
              data.checked = true
            }
          })
        setUsers(staff)
        // console.log("module data",data[0].module)
        setlist(module)
        setlistbackup(module)
        setType(data.roleCode)
        // console.log(data.module)
        setLoading(false)
      } catch (error) {
        setLoading(false)
        setlist([])
        setlistbackup([])
        // console.log("error ==>",error)
      }
    }
  }
  const handlemoduleCheck = async (data, key) => {
    var temp = { ...list };
    var active = !temp[key].active;
    temp[key].active = active;
  
    var temp2 = { ...listbackup };
    temp2[key].active = active;
  
    setlist(temp);
    setlistbackup(temp2);
  };
  
  const handlepermission = async (data, key, access) => {
    var temp = { ...list };
    temp[key].accesstype = access;
  
    // Toggle the admin property based on the access type
    if (access === 0) {
      temp[key].admin = true;
    } else {
      temp[key].admin = false;
    }
  
    var temp2 = { ...listbackup };
    temp2[key].accesstype = access;
  
    // Toggle the admin property based on the access type
    if (access === 0) {
      temp2[key].admin = true;
    } else {
      temp2[key].admin = false;
    }
  
    setlist(temp);
    setlistbackup(temp2);
  };
  
  
  const handleCheck = async(index)=>{
    var temp = [...users]
    temp[index].checked = !  temp[index].checked
    setUsers(temp)
  }

  const addaccess = async(e)=>{
   e.preventDefault()
   setLoading(true)
    if(name!=="")
    {
      var addresp =""
      if(!editmode)
   {   var data = {

        module: listbackup,
      
        otheraccess: {
            NAV01: {
                id: "NAV01",
                active: false,
                level: 0
      
            }
             
        },
        // "plan":selecetdplan,
        "customer":selecetdCustomer.id,
        "accessType": 2,
        "role": name,
        // "group": id,
        // accessRight:type.label,
        "roleCode":type,
      active:true,
      status:true,
        "priority":2
      }
       addresp = await add_roles(data)
      console.log("addess" , addresp)
    
    }else{
    
       var data = {


        module: listbackup,
        "roleCode":type,
        "role": name,
      }

      addresp = await update_role(id,data)
    }
      if(addresp)
      {
        users.map(async (data,index)=>{
          if(data.checked){
            console.log(" addresp usersss ==",data.name,data)
            await update_User({id:addresp,name:name},data.value)
          }
        })
        setLoading(false)
        navigate(-1)
      }else{
        setLoading(false)
      alert(t("some error occured"))
      }
     
      setLoading(false)
    }else{
      setLoading(false)
      alert(t("Please fillup role field"))
    }
    setLoading(false)
  }
  const {id}=useParams()
  useEffect(() => {
    if(id!==null && id!== undefined && id!=="")
    {
      setMode(true)
      loadOldData(id)
        
        
      }else {
        loadData()
      }
  }, [refresh,selecetdCustomer]);


  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Loader loading={loading} />
      <MDBox pt={4} pb={3} className="xl-container">
        <Box>
          <Box>
            <MDBox className="page-heading">
              <MDTypography
                variant="h5"
                gutterBottom
                sx={{ pb: "8px", display: "flex" }}
              >
                <Box
                  sx={{
                    pt: "2px",
                    width: "22px;",
                    display: { xs: "none", sm: "flex" },
                  }}
                >
                  <MDButton onClick={() => navigate("/management")}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M0 5.99961C0 5.4524 0.443608 5.00879 0.990826 5.00879H11.0092C11.5564 5.00879 12 5.4524 12 5.99961C12 6.54683 11.5564 6.99044 11.0092 6.99044H0.990826C0.443608 6.99044 0 6.54683 0 5.99961Z"
                        fill="#16273C"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M6.70062 0.290206C7.08756 0.677148 7.08756 1.3045 6.70062 1.69145L2.39206 6L6.70062 10.3086C7.08756 10.6955 7.08756 11.3229 6.70062 11.7098C6.31368 12.0967 5.68632 12.0967 5.29938 11.7098L0.290206 6.70062C-0.0967354 6.31368 -0.0967354 5.68632 0.290206 5.29938L5.29938 0.290206C5.68632 -0.0967354 6.31368 -0.0967354 6.70062 0.290206Z"
                        fill="#16273C"
                      />
                    </svg>
                  </MDButton>
                </Box>
                {t("Access profile details")}
              </MDTypography>
            </MDBox>

            <Box component="form" onSubmit={(e)=>addaccess(e)}>
              <Box
                sx={{
                  margin: "10px 0 0 0",
                }}
              >
                 <FormGroup sx={{ mb: 2 }}>
                          <InputLabel className="formlabel">
                            {t("Profile name")}<strong style={{color:"red"}}> *</strong>
                          </InputLabel>
                          <TextField
                            className="forminput"
                            placeholder={t("Enter profile name")}
                            variant="outlined"
                            value = {name}
                            required
                            InputProps={nameProps}
                            onChange = {(e)=>setname(e.target.value)}
                          />
                        </FormGroup>
                        <Box className="tab-info" >
                        <Box className="tab-main" sx={{ mb: 2}}>
                        <Tabs value={page} >
              <Tab label={t("Page Access")} className={page ==0 && "active"} onClick={(e,value)=>setpage(0)} />
              <Tab label={t("Staff")} className={page ==1 && "active"} onClick={(e,value)=>setpage(1)}/>
              
            </Tabs>
            
        </Box>
        <Explanation
                        toggle="left"
                        contentLine1={t("Choose 'Read' for viewing only, 'Read & Write' for viewing and performing actions, and 'Admin' for full page access with administrator privileges.")}
                      />
        </Box>
                {/* <MDBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  
                  search to be added 
                  <Box className="filter-mobile">
                    <MDBox
                      color="text"
                      px={0}
                      sx={{ display: { xs: "block", sm: "flex" } }}
                    >
                      <Box
                        sx={{
                          display: { xs: "block", sm: "flex" },
                        }}
                      >
                        sort list to be added 
                         <Filter/> 
                      </Box>
                    </MDBox>
                  </Box>
                </MDBox> */}
              </Box>
              <MDBox
                sx={{
                  marginTop: "20px",
                }}
              >
            {page === 0 && (
  <List className="datatable">
    {list &&
      Object.entries(list)
        .sort(([, a], [, b]) => a.pos - b.pos)
        .map(([key, data], index) =>
          data.view ? (
            <ListItem key={index}>
              <Box className="list-content">
                <Box className="left-content">
                  <label className="heading">
                    <FormControlLabel
                      sx={{
                        display: "flex",
                        textAlign: "center",
                      }}
                      className="cs-checkbox"
                      label={t(data.name)}
                      checked={data.active}
                      onClick={() => {
                        data.lock ? console.log() : handlemoduleCheck(data, key);
                      }}
                      disabled={data.lock}
                      control={<Checkbox />}
                    />
                  </label>
                </Box>
                <Box className="blue-bg">
                  <FormGroup
                    sx={{
                      mt: 2,
                      marginRight: "16px",
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                     <Box className="box">
                    {data.adminaccess && (
                      <FormControlLabel
                        sx={{ marginRight: "16px" }}
                        className="formlabel cs-radio"
                        checked={data.accesstype === 0} // Assuming 0 for Admin
                        control={
                          <Radio
                            onClick={(e) => {
                              handlepermission(data, key, 0);
                            }}
                            disabled={!data.active}
                          />
                        }
                        label={t("Admin")}
                      />
                    )}</Box>
                     <Box className="box">
                    <FormControlLabel
                      sx={{ marginRight: "16px" }}
                      className="formlabel cs-radio"
                      checked={data.accesstype === 1}
                      control={
                        <Radio
                          onClick={(e) => {
                            handlepermission(data, key, 1);
                          }}
                          disabled={!data.active}
                        />
                      }
                      label={t("Read")}
                    />
                    </Box>
                    <Box className="box">
                  { (data.id!=="REST10" && data.id!=="REST14" && data.id!=="REST15" && data.id!=="REST16") && (
                  <FormControlLabel
                      className="formlabel cs-radio"
                      checked={data.accesstype === 2}
                      control={
                        <Radio
                          onClick={(e) => {
                            handlepermission(data, key, 2);
                          }}
                          disabled={!data.active}
                        />
                      }
                      label={t("Read & Write")}
                    />
                  )
                }
                 </Box>
                  </FormGroup>
                </Box>
              </Box>
            </ListItem>
          ) : null
        )}
  </List>
)}

                {
                  page==1 &&   
                  <>
                  {users.length==0?
                    <Nodata/>:
                    <List className="datatable">
                       {users.map((data, index) => (
                         <ListItem>
                           <Box className="list-content">
                             <Box className="left-content">
                             <Box className="checkbox-section">
                              <FormControlLabel
                                sx={{
                                  display: "grid",
                                  textAlign: "center",
                                  marginRight: "0",
                                }}
                                className="cs-checkbox"
                                label=""
                                onClick={()=>handleCheck(index)}
                                control={<Checkbox checked = {data.checked}/>}
                              />
                              
                            </Box>
                               <label className="heading">
                                 <span>
                                   {data.profile == "" ? <div style={profilePictureStyle} >  {getInitials(data.name,data.surname)}</div> : <img src={data.profile} style={profilePictureStyle} />}
     
                                 </span>
                                 {data.name}
                               </label>
                             </Box>
                             <Box className="blue-bg">
                             
                             </Box>
                           </Box>
                         </ListItem>
                       ))}
     
                     </List>}
                     </>
                }
          
              </MDBox>
              <Box className="navigation-link">
                <MDButton
                  className="primary-btn btn xl"
                  type="submit"
                  // onClick={() => navigate(-1)}
                >
                  {editmode?t("Update"):t("Save")}
                </MDButton>
              </Box>
            </Box>
          </Box>
        </Box>
      </MDBox>

  
    </DashboardLayout>
  );
}

export default Staff;
