const get_added_country = (operatedRegion)=>{
    let item_to_remove =  operatedRegion
    let countrys = []
    item_to_remove.map((data,index)=>{
      data.country.map((d,i)=>{
        countrys.push(d.data)
      })
    })
    return countrys

}
const filter_usedCountry = (allcountry,usedcountry)=>{
    var pos = 0
    let filteredArray = allcountry.filter(data => {
        for (let filter of usedcountry) {
            let match = true;
            for (let key in filter) {
              // console.log(filter,key)
                if (data.data.Latitude !== filter.Latitude) {
                    match = false;
                    break;
                }
            }
            if (match) {
                return false;
            }
        }
        return true;
    });
    filteredArray.map((d, i) => {
        d.pos = pos++;
      });
      return filteredArray

    
}
const filter_country_selection = (allcountry,operatedRegion,selectedResgion)=>{
  var pos = 0
  let item_to_remove =  [...operatedRegion]
  let usedcountry = []
  item_to_remove.map((data,index)=>{
    if(selectedResgion!==index)
    {
      data.country.map((d,i)=>{
        usedcountry.push(d.data)
      })
    }
  
  })
  let filteredArray = allcountry.filter(data => {
    for (let filter of usedcountry) {
        let match = true;
        for (let key in filter) {
          // console.log(filter,key)
            if (data.data.Latitude !== filter.Latitude) {
                match = false;
                break;
            }
        }
        if (match) {
            return false;
        }
    }
    return true;
});
filteredArray.map((data, n) => {
  data.pos = pos++;
    operatedRegion[selectedResgion].country.map((data2, n2) => {
      if (data2.label === data.label) {
        data.checked = true;
      }
    });
  });

 
    return filteredArray

  
}
function generatelist(json){
  console.log(json)
  var resp = {}
json.map((d)=>{
  resp[d.label]=d.label
})
console.log(resp)
}
export {get_added_country,filter_usedCountry,filter_country_selection,generatelist}