import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import MDButton from "components/MDButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

import Typography from "@material-ui/core/Typography";
import Sort from "../../assets/images/icons/sort.png";
import SortWhite from "../../assets/images/icons/sortwhite.png";
import User from "../../assets/images/icons/user.png";
import User2 from "../../assets/images/icons/user2.png";
import User3 from "../../assets/images/icons/user3.png";
import User4 from "../../assets/images/icons/user4.png";
import User5 from "../../assets/images/icons/user5.png";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Filter from "../../components/Filter";
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
//modal import
import Modal from "@mui/material/Modal";
import { useTranslation } from "react-i18next";
import Loader from "components/Loader";
import { categoryType } from "backendservice/firebase";
import { v4 as uuidv4 } from "uuid";
import { useNavigate ,useParams} from "react-router-dom";
import {
  doc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "backendservice/firebase";
import alasql from "alasql";
import {
  useMaterialUIController,
  setSelecetdCustomerrec
} from "context";
import { add_roles } from "backendservice/firebasefunctions,";
import SearchComponent from "components/SearchInput";
import Sortlist from "components/Sortlist";
import { get_role } from "backendservice/firebasefunctions,";
import { get_roles } from "backendservice/firebasefunctions,";
import { get_rolebased_users } from "backendservice/firebasefunctions,";
import { updateRoleStatus } from "backendservice/firebasefunctions,";
import { temp_role } from "backendservice/firebasefunctions,";
function Staff() {
  const [controller, dispatch] = useMaterialUIController();
  const {
    selecetdCustomer,
   permission
  } = controller;
  const [followsMe, setFollowsMe] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const { t } = useTranslation();
  const [answersPost, setAnswersPost] = useState(false);
  //modal open hook
  const [modalopen, setmodalopen] = useState(false);

  const [rolelist, setrolelist] = useState([]);
  const [rolebackup, setrolebackup] = useState([]);
  const [userlist, setuserlist] = useState([]);
  // const [rolebackup, setrolebackup] = useState([]);

  const [name, setName] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);



  const handlemodalopen = () => {
    setmodalopen(true);
  };
  const handlemodalClose = () => {
    setName("");

    setmodalopen(false);
  };

  const loadData=async()=>{
    let customer  = selecetdCustomer.id
    if(customer!=="" && customer!==undefined)
    {
        setLoading(true)
        try{
            var rolesdata = await get_roles(customer)
            console.log("role== ",rolesdata)
           setrolelist(rolesdata)
            setrolebackup(rolesdata)
     
            setLoading(false)
        }catch(error)
        {
            setLoading(false)
            setrolelist([])
            setrolebackup([])
            // console.log("error ==>",error)
        }
    }
  }

  const handleStatus=async(roleData)=>{
    if(roleData.priority==0){
      alert(t("this role cannot be updated"))
    }else{
      if(await updateRoleStatus(!roleData.active,roleData.id))
      {
        setRefresh(!refresh)
      }else{
        console.log("failed")
      }
    }
  }
  const handleupdate=async(roleData)=>{
    if(roleData.priority=="0"){
      alert(t("this role cannot be updated"))
    }else{
   
                    
      navigate("/management/access-edit/"+roleData.id)
    }
  }
  const isButtonDisabled = (moduleId) => {
    return !(
      !permission.module ||
      !permission.module[moduleId] ||
      permission.module[moduleId].accesstype === 2
    );
  };
  useEffect(() => {
    if (selecetdCustomer.id) {
      loadData().then(() => setLoading(false));
    }
  }, [refresh,selecetdCustomer,permission]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Loader loading={loading} />
      <MDBox pt={4} pb={3} className="xl-container">
        <Box>
          <Box>
            <MDBox className="page-heading">
              <MDTypography
                variant="h5"
                gutterBottom
                sx={{ pb: "8px", display: "flex" }}
              >
                {t("Access profiles")}
              </MDTypography>
            </MDBox>

            <Box>
              <Box
                sx={{
                  margin: "10px 0 0 0",
                }}
              >
                <MDBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                     <SearchComponent searchList = {rolebackup} onSearch={(e)=>setrolelist(e)} />
         
                  <Box className="filter-mobile">
                    <MDBox
                      color="text"
                      px={0}
                      sx={{ display: { xs: "block", sm: "flex" } }}
                    >
                      <Box
                        sx={{
                          display: { xs: "block", sm: "flex" },
                        }}
                      >
                      <Sortlist onSort={(e)=>setrolelist(e)} sortlist={rolebackup} />
                  
                      <Filter   onFilter={(e)=>setrolelist(e)} filterlist={rolebackup}  module = "accessManagement"/>
                      </Box>
                    </MDBox>
                  </Box>
                </MDBox>
              </Box>
              <MDBox
                sx={{
                  marginTop: "20px",
                }}
              >
                <List className="datatable">
                 {rolelist.map((data,index)=>(
                  <ListItem>
                    <Box className="list-content">
                      <Box className="left-content">
                        <label className="heading">{t(data.label)}</label>
                      </Box>
                     <Box className="blue-bg">
                        <Box>
                          <MDButton className="grid-btn"  onClick={(e)=>
                            navigate("/management/access/"+data.id)
                            // getRoleUser(data.id)
                          }>
                            {t("View page access")}
                          </MDButton>
                          <MDButton className="grid-btn" onClick={(e)=>
                            navigate("/management/access-profile/"+data.id)
                            // getRoleUser(data.id)
                          }
                            
                            >{t("View users")}</MDButton>
                        </Box>
                         <> <MDButton className="link-dot" 
                  disabled={isButtonDisabled("REST09")}
                         
                         onClick={() => handleupdate(data)} >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="18"
                            viewBox="0 0 17 15"
                            fill="none"
                          >
                            <path
                              d="M12.4649 1.08492C12.6572 0.899475 12.8855 0.752375 13.1367 0.652015C13.3879 0.551655 13.6572 0.5 13.9291 0.5C14.201 0.5 14.4703 0.551655 14.7215 0.652015C14.9727 0.752375 15.201 0.899475 15.3933 1.08492C15.5856 1.27036 15.7381 1.49051 15.8422 1.7328C15.9462 1.97509 15.9998 2.23478 15.9998 2.49703C15.9998 2.75928 15.9462 3.01897 15.8422 3.26126C15.7381 3.50355 15.5856 3.7237 15.3933 3.90915L5.50995 13.4409L1.4834 14.5L2.58155 10.6167L12.4649 1.08492Z"
                              stroke="#16273C"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </MDButton>
                        <Box>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                            }}
                            className={isButtonDisabled("REST09")? "disabled" :""}

                          >
                            <label className="active-text">{data.active?t("Active"):t("Inactive")}</label>
                            <Switch
                              // checked={followsMe}
                              checked = {data.active}
                              disabled={isButtonDisabled("REST09")}
                              onChange={() => handleStatus(data)}
                            />
                          </Box>
                        </Box></>
                      </Box>
                    </Box>
                  </ListItem>
                 )) }
                </List>
              </MDBox>
              <Box className="navigation-link">
               <MDButton
                  className="primary-btn btn xl"
                  disabled={isButtonDisabled("REST09")}

                  onClick={() => 
                    // temp_role()}
                    navigate("/management/access-detail")}
                            // onClick={()=>
                            //   add_roles()
                            // }
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="13"
                    viewBox="0 0 12 13"
                    fill="none"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M7.36739 1.86739C7.36739 1.1122 6.75519 0.5 6 0.5C5.24481 0.5 4.63261 1.1122 4.63261 1.86739V5.13261H1.36739C0.612202 5.13261 0 5.74481 0 6.5C0 7.25519 0.612202 7.86739 1.36739 7.86739H4.63261V11.1326C4.63261 11.8878 5.24481 12.5 6 12.5C6.75519 12.5 7.36739 11.8878 7.36739 11.1326V7.86739H10.6326C11.3878 7.86739 12 7.25519 12 6.5C12 5.74481 11.3878 5.13261 10.6326 5.13261H7.36739V1.86739Z"
                      fill="white"
                    />
                  </svg>
                  &nbsp;&nbsp; {t("Add profile")}
                </MDButton>
              </Box>
            </Box>
          </Box>
        </Box>
      </MDBox>

      {/* Modal mm-04 */}
   
    </DashboardLayout>
  );
}

export default Staff;
