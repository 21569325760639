import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import MDButton from "components/MDButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Filter from "../../components/Filter";
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
//modal import
import Modal from "@mui/material/Modal";
import { useTranslation } from "react-i18next";
import Loader from "components/Loader";

import { useNavigate } from "react-router-dom";

import { useMaterialUIController, setSelecetdCustomerrec } from "context";
import SearchComponent from "components/SearchInput";
import Sortlist from "components/Sortlist";
import { get_staff } from "backendservice/firebasefunctions,";
import { getContractType, getEmployeeType, getInitials, getRoleDescription, profilePictureStyle, updateStatus } from "./functions";
import Nodata from "components/Nodata";
import { get_staff_clinic } from "backendservice/firebasefunctions,";
import { get_employeeType } from "backendservice/firebasefunctions,";
import { get_contract } from "backendservice/firebasefunctions,";
import { get_roles } from "backendservice/firebasefunctions,";
import DeleteModal from "components/DeleteModal";
import axios from "axios";
function Staff() {
  const [controller, dispatch] = useMaterialUIController();
  const { selecetdCustomer, permission,selected_clinic } = controller;
  const [followsMe, setFollowsMe] = useState(true);
  const navigate = useNavigate();

  const { t } = useTranslation();
  const [stafflist, setStafflist] = useState([]);
  const [stafflistbackup, setstafflistbackup] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);
  const [customerID, setCustomer] = useState("");
  const [deleteModal, setdeletemodal] = useState({
    open:false,
    user:"",
    brand:"",
    clinic:"",
    startDate:""
  });
  const [employeeTypeList,setemployetypelist ] = useState([])
  const handleDeleteRecords = async(data,modal=false,resp)=>{
    console.log("hi")
    if(modal)
      {
        setdeletemodal({
          open:true,
          user:data.id,
          // brand:selecetdCustomer.id,
          // clinic:selected_clinic.id,
          // startDate:new Date()
        })
      }else{
        setLoading(true)
        if(resp)
          {
            let config = {
              method: 'post',
              maxBodyLength: Infinity,
             
              url: process.env.REACT_APP_REMOVEUSER,
              headers: { 
                'Content-Type': 'application/json'
              },
              data : JSON.stringify({"userId":deleteModal.user})
            };
            
        // Add staff using backend service
            
        try {
          const response = await axios.request(config);
         console.log("response ",response) 
          var res = response.data
          setdeletemodal({
            open:false,
            user:"",
            // brand:"",
            // clinic:"",
            // startDate:""
          })
          if(response.status==200 && res.status)
         { setRefresh(!refresh)}
          else{alert(t("some error occurred"))}
        }catch(error)
        {
          console.log(error)
          setLoading(false)
        }
       
          }else{
            setdeletemodal({
              open:false,
              user:"",
              // brand:"",
              // clinic:"",
              // startDate:""
            })
          }
          setLoading(false)
         
      }
  }
  const loadData = async () => {
 
    
    let customer = selecetdCustomer.id;
    let clinic = selected_clinic.id;
    // console.log("selected clinic",selected_clinic)
    if (customer !== "" && customer !== undefined && clinic !== "" && clinic !== undefined) {
      try {
        setLoading(true);
        // console.log("selected clinic",selected_clinic)
        var stafflist = await get_staff_clinic(customer,clinic);
        var employeetype  = await get_employeeType(customer)
            setemployetypelist(employeetype)   
        var contractList  = await get_contract(customer)
        console.log("contractList == ",contractList,stafflist)
         var roleList = await get_roles(customer)
        //  console.log(contractList,roleList)
        var data = stafflist.map((doc) => ({
          id: doc.id,
          data: doc.data,
          viewdetails: false,
          active: doc.active,
          contractType:doc.contractList!==undefined?getContractType(doc.contractList[doc.contractList.length-1]?.contractId,contractList):"",
          employeeType:getEmployeeType(doc.data.employeeTypeId,employeetype),
          roleDescription :getRoleDescription(doc.data.group,roleList)
        }));
        console.log("data ",data)
        setStafflist(data);
        setstafflistbackup(stafflist);

        setLoading(false);
      } catch (error) {
        setLoading(false);
        setStafflist([]);
        setstafflistbackup([]);
        console.log("error ==>", error);
      }
    }
    setLoading(false);
  };
  const handleActive = async (index) => {
    setLoading(true);
    const tempData = [...stafflist];
    tempData[index].active = !tempData[index].active; // Update local state immediately
    setStafflist(tempData); // Update local state

    try {
      await updateStatus(tempData[index].id, index, tempData); // Asynchronously update data in Firestore
      setLoading(false);
      setRefresh(!refresh)
      
    } catch (error) {
      console.log("Error updating status:", error);
      setLoading(false);
    }
  };
  const isButtonDisabled = (moduleId) => {
    return !(
      !permission.module ||
      !permission.module[moduleId] ||
      permission.module[moduleId].accesstype === 2
    );
  };
  useEffect(() => {
    if (selected_clinic.id && selecetdCustomer.id) {
      loadData().then(() => setLoading(false));
    }
  }, [refresh, selecetdCustomer,selected_clinic,permission]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Loader loading={loading} />
      <MDBox pt={4} pb={3} className="xl-container">
        <Box>
          <Box>
            <MDBox className="page-heading">
              <MDTypography
                variant="h5"
                gutterBottom
                sx={{ pb: "8px", display: "flex" }}
              >
                {t("Staffs")}
              </MDTypography>
            </MDBox>

            <Box>
              <Box
                sx={{
                  margin: "10px 0 0 0",
                }}
              >
                <MDBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <SearchComponent
                    searchList={stafflistbackup}
                    onSearch={(e) => setStafflist(e)}
                  />

                  <Box className="filter-mobile">
                    <MDBox
                      color="text"
                      px={0}
                      sx={{ display: { xs: "block", sm: "flex" } }}
                    >
                      <Box
                        sx={{
                          display: { xs: "block", sm: "flex" },
                        }}
                      >
                        <Sortlist
                          onSort={(e) => setStafflist(e)}
                          sortlist={stafflistbackup}
                        />

                        <Filter   onFilter={(e)=>{setStafflist(e)}} filterlist={stafflistbackup} brandId ={selecetdCustomer.id} module = "staff" employeeType={employeeTypeList} />
                      </Box>
                    </MDBox>
                  </Box>
                </MDBox>
              </Box>
              <MDBox
                sx={{
                  marginTop: "20px",
                }}
              >
                {stafflist.length == 0 ? (
                  <Nodata />
                ) : (
                  <List className="datatable">
                    {stafflist.map((data, index) => (
                      <ListItem>
                        <Box className="list-content" key={index}>
                          <Box className="left-content">
                            <label className="heading">
                              <span>
                                <div style={profilePictureStyle}>
                                  {" "}
                                  {getInitials(
                                    data.data.name,
                                    data.data.surname
                                  )}
                                </div>
                              </span>
                              {`${data.data.name} ${data.data.surname}`}
                            </label>
                          </Box>
                          <Box className="blue-bg">
                            <Box>
                              <MDButton
                                className="grid-btn"
                                onClick={(e) => {
                                  var temp = [...stafflist];
                                  temp[index].viewdetails =
                                    !temp[index].viewdetails;
                                  setStafflist(temp);
                                }}
                              >
                                {t("View details")}
                              </MDButton>
                            </Box>
                            
                                <MDButton
                                  className="link-dot"
                                  disabled={isButtonDisabled("REST05")}
                                  onClick={() =>
                                    navigate("/staff/edit/" + data.id)
                                  }
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="18"
                                    viewBox="0 0 17 15"
                                    fill="none"
                                  >
                                    <path
                                      d="M12.4649 1.08492C12.6572 0.899475 12.8855 0.752375 13.1367 0.652015C13.3879 0.551655 13.6572 0.5 13.9291 0.5C14.201 0.5 14.4703 0.551655 14.7215 0.652015C14.9727 0.752375 15.201 0.899475 15.3933 1.08492C15.5856 1.27036 15.7381 1.49051 15.8422 1.7328C15.9462 1.97509 15.9998 2.23478 15.9998 2.49703C15.9998 2.75928 15.9462 3.01897 15.8422 3.26126C15.7381 3.50355 15.5856 3.7237 15.3933 3.90915L5.50995 13.4409L1.4834 14.5L2.58155 10.6167L12.4649 1.08492Z"
                                      stroke="#16273C"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </svg>
                                </MDButton>
                                <MDButton
                  className="link-dot"
                  onClick = {(e)=> handleDeleteRecords(data,true)}
                  disabled={isButtonDisabled("REST05")}
                >
                 <svg xmlns="http://www.w3.org/2000/svg" width="17" height="19" viewBox="0 0 13 15" fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M0.151367 3.59384C0.151367 3.32412 0.370019 3.10547 0.639739 3.10547H12.3607C12.6304 3.10547 12.849 3.32412 12.849 3.59384C12.849 3.86356 12.6304 4.08221 12.3607 4.08221H0.639739C0.370019 4.08221 0.151367 3.86356 0.151367 3.59384Z" fill="#16273C"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M5.19829 1.47674C4.98241 1.47674 4.77538 1.5625 4.62274 1.71515C4.47009 1.86779 4.38433 2.07482 4.38433 2.2907V3.59302C4.38433 3.86274 4.16568 4.0814 3.89596 4.0814C3.62624 4.0814 3.40759 3.86274 3.40759 3.59302V2.2907C3.40759 1.81577 3.59625 1.3603 3.93207 1.02448C4.26789 0.688662 4.72337 0.5 5.19829 0.5H7.80294C8.27786 0.5 8.73333 0.688662 9.06915 1.02448C9.40497 1.3603 9.59364 1.81577 9.59364 2.2907V3.59302C9.59364 3.86274 9.37498 4.0814 9.10526 4.0814C8.83554 4.0814 8.61689 3.86274 8.61689 3.59302V2.2907C8.61689 2.07482 8.53114 1.86779 8.37849 1.71515C8.22584 1.5625 8.01881 1.47674 7.80294 1.47674H5.19829ZM1.94247 3.10465C2.21219 3.10465 2.43085 3.3233 2.43085 3.59302V12.7093C2.43085 12.9252 2.5166 13.1322 2.66925 13.2849C2.82189 13.4375 3.02893 13.5233 3.2448 13.5233H9.75643C9.9723 13.5233 10.1793 13.4375 10.332 13.2849C10.4846 13.1322 10.5704 12.9252 10.5704 12.7093V3.59302C10.5704 3.3233 10.789 3.10465 11.0588 3.10465C11.3285 3.10465 11.5471 3.3233 11.5471 3.59302V12.7093C11.5471 13.1842 11.3585 13.6397 11.0226 13.9755C10.6868 14.3113 10.2314 14.5 9.75643 14.5H3.2448C2.76988 14.5 2.31441 14.3113 1.97858 13.9755C1.64276 13.6397 1.4541 13.1842 1.4541 12.7093V3.59302C1.4541 3.3233 1.67275 3.10465 1.94247 3.10465Z" fill="#16273C"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M5.19833 6.35938C5.46805 6.35938 5.68671 6.57803 5.68671 6.84775V10.7547C5.68671 11.0244 5.46805 11.2431 5.19833 11.2431C4.92861 11.2431 4.70996 11.0244 4.70996 10.7547V6.84775C4.70996 6.57803 4.92861 6.35938 5.19833 6.35938Z" fill="#16273C"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M7.80283 6.35938C8.07255 6.35938 8.2912 6.57803 8.2912 6.84775V10.7547C8.2912 11.0244 8.07255 11.2431 7.80283 11.2431C7.5331 11.2431 7.31445 11.0244 7.31445 10.7547V6.84775C7.31445 6.57803 7.5331 6.35938 7.80283 6.35938Z" fill="#16273C"/>
                            </svg>
                
                </MDButton>
                                <Box>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
         className={isButtonDisabled("REST05")? "disabled" :""}

                                  >
                                    <label className="active-text">
                                      {data.active
                                        ? t("Active")
                                        : t("Inactive")}
                                    </label>
                                    <Switch
                                    disabled={isButtonDisabled("REST05")}
                                      checked={
                                        data.active ? data.active : false
                                      }
                                      onChange={() => handleActive(index)}
                                    />
                                  </Box>
                                </Box>
                              
                          </Box>
                        </Box>
                        {data.viewdetails ? (
                          <div className="flex">
                            <Box className="bottom-content">
                              <Box className="content">
                                <label>{t("Clinic")}:</label>
                                {/* <p>{data.data.defaultClinic}</p> */}
                                <p>{selected_clinic.label}</p>
                                
                              </Box>
                              <Box className="content">
                                <label>{t("Contract")}:</label>
                                <p>{data.contractType?.label}</p>
                              </Box>
                              <Box className="content">
                                <label>{t("Employment Type")}:</label>
                                <p>{data.employeeType?.label}</p>
                              </Box>
                              <Box className="content">
                                <label>{t("Profile")}:</label>
                                <p>{t(data.roleDescription?.label)}</p>
                              </Box>
                            </Box>
                          </div>
                        ) : (
                          ""
                        )}
                      </ListItem>
                    ))}
                  </List>
                )}
              </MDBox>
              <Box className="navigation-link">
               
                  <MDButton
                    className="primary-btn btn xl"
                    onClick={() => navigate("/staff/detail")}
                  disabled={isButtonDisabled("REST05")}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="13"
                      viewBox="0 0 12 13"
                      fill="none"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M7.36739 1.86739C7.36739 1.1122 6.75519 0.5 6 0.5C5.24481 0.5 4.63261 1.1122 4.63261 1.86739V5.13261H1.36739C0.612202 5.13261 0 5.74481 0 6.5C0 7.25519 0.612202 7.86739 1.36739 7.86739H4.63261V11.1326C4.63261 11.8878 5.24481 12.5 6 12.5C6.75519 12.5 7.36739 11.8878 7.36739 11.1326V7.86739H10.6326C11.3878 7.86739 12 7.25519 12 6.5C12 5.74481 11.3878 5.13261 10.6326 5.13261H7.36739V1.86739Z"
                        fill="white"
                      />
                    </svg>
                    &nbsp;&nbsp; {t("Add Staff")}
                  </MDButton>
                
              </Box>
            </Box>
          </Box>
        </Box>
      </MDBox>

    <DeleteModal open={deleteModal.open} message="Deleteing a user will delete all its record from the system including Leaves, Schedule, user records etc !" onChange={(e)=>handleDeleteRecords("",false,e)}/>
    </DashboardLayout>
  );
}

export default Staff;
