import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";

const Nodata = ()=>{
    const {t} = useTranslation()
    return(
        <Box className="no-data">
              <Box>
               <span>!</span>
              <h3>{t("Data not available!")}</h3>
              </Box>

             </Box>
    )
}
export default Nodata;