import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import MDButton from "components/MDButton";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Filter from "../../components/Filter";
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
//modal import
import { useTranslation } from "react-i18next";
import Loader from "components/Loader";
import { useNavigate, useParams } from "react-router-dom";
import { get_rolebased_users } from "backendservice/firebasefunctions,";
import {
  useMaterialUIController,
  setSelecetdCustomerrec
} from "context";
import SearchComponent from "components/SearchInput";
import Sortlist from "components/Sortlist";
import { profilePictureStyle } from "layouts/staff/functions";
import { getInitials } from "layouts/staff/functions";
import Nodata from "components/Nodata";
import { get_role } from "backendservice/firebasefunctions,";
function Userlist() {
  const [controller, dispatch] = useMaterialUIController();
  const {
    selecetdCustomer,

  } = controller;
  const navigate = useNavigate();

  const { t } = useTranslation();
  const [userlist, setuserlist] = useState([]);
  const [userlistbackup, setuserlistbackup] = useState([]);


  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const { id } = useParams()
  const loadData = async () => {
    let customer = selecetdCustomer.id
    if (customer !== "" && customer !== undefined) {
      setLoading(true)
      try {
        var userdata = await get_rolebased_users(id, customer)
        var data = await get_role(id, customer)
        setData(data)
        setuserlist(userdata)
        setuserlistbackup(userdata)
        setLoading(false)
      } catch (error) {
        setLoading(false)
        setuserlist([])
        setuserlistbackup([])
        // console.log("error ==>",error)
      }
    }
  }
  useEffect(() => {
    loadData()
  }, [refresh,selecetdCustomer]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Loader loading={loading} />
      <MDBox pt={4} pb={3} className="xl-container">
        <Box>
          <Box>
            <MDBox className="page-heading">
              <MDTypography
                variant="h5"
                gutterBottom
                sx={{ pb: "8px", display: "flex" }}
              >
                <Box
                  sx={{
                    pt: "2px",
                    width: "22px;",
                    display: { xs: "none", sm: "flex" },
                  }}
                >
                
                </Box>
                {t(data.label ? data.label : data.role)}
              </MDTypography>
            </MDBox>

            <Box>
              <Box
                sx={{
                  margin: "10px 0 0 0",
                }}
              >
                <MDBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <SearchComponent searchList={userlistbackup} onSearch={(e) => setuserlist(e)} />

                  <Box className="filter-mobile">
                    <MDBox
                      color="text"
                      px={0}
                      sx={{ display: { xs: "block", sm: "flex" } }}
                    >
                      <Sortlist onSort={(e) => setuserlist(e)} sortlist={userlistbackup} />

                      <Filter />
                    </MDBox>
                  </Box>
                </MDBox>
              </Box>
              <MDBox
                sx={{
                  marginTop: "20px",
                }}
              >
               {userlist.length==0?
               <Nodata/>:
               <List className="datatable">
                  {userlist.map((data, index) => (
                    <ListItem key = {index}>
                      <Box className="list-content">
                        <Box className="left-content">
                          <label className="heading">
                            <span>
                              {data.profile === "" ? <div style={profilePictureStyle} >  {getInitials(data.name,data.surname)}</div> : <img src={data.profile} style={profilePictureStyle} />}

                            </span>
                            {data.name}
                          </label>
                        </Box>
                        <Box className="blue-bg">
                          <MDButton className="link-dot" onClick={(e) => console.log(e)}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="17"
                              height="20"
                              viewBox="0 0 13 15"
                              fill="none"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M0 3.59286C0 3.32314 0.218652 3.10449 0.488372 3.10449H12.2093C12.479 3.10449 12.6977 3.32314 12.6977 3.59286C12.6977 3.86258 12.479 4.08124 12.2093 4.08124H0.488372C0.218652 4.08124 0 3.86258 0 3.59286Z"
                                fill="#16273C"
                              />
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M5.04692 1.47674C4.83105 1.47674 4.62401 1.5625 4.47137 1.71515C4.31872 1.86779 4.23297 2.07482 4.23297 2.2907V3.59302C4.23297 3.86274 4.01432 4.0814 3.74459 4.0814C3.47487 4.0814 3.25622 3.86274 3.25622 3.59302V2.2907C3.25622 1.81577 3.44489 1.3603 3.78071 1.02448C4.11653 0.688662 4.572 0.5 5.04692 0.5H7.65157C8.12649 0.5 8.58197 0.688662 8.91779 1.02448C9.25361 1.3603 9.44227 1.81577 9.44227 2.2907V3.59302C9.44227 3.86274 9.22362 4.0814 8.9539 4.0814C8.68418 4.0814 8.46553 3.86274 8.46553 3.59302V2.2907C8.46553 2.07482 8.37977 1.86779 8.22712 1.71515C8.07448 1.5625 7.86745 1.47674 7.65157 1.47674H5.04692ZM1.79111 3.10465C2.06083 3.10465 2.27948 3.3233 2.27948 3.59302V12.7093C2.27948 12.9252 2.36523 13.1322 2.51788 13.2849C2.67053 13.4375 2.87756 13.5233 3.09343 13.5233H9.60506C9.82093 13.5233 10.028 13.4375 10.1806 13.2849C10.3333 13.1322 10.419 12.9252 10.419 12.7093V3.59302C10.419 3.3233 10.6377 3.10465 10.9074 3.10465C11.1771 3.10465 11.3958 3.3233 11.3958 3.59302V12.7093C11.3958 13.1842 11.2071 13.6397 10.8713 13.9755C10.5355 14.3113 10.08 14.5 9.60506 14.5H3.09343C2.61851 14.5 2.16304 14.3113 1.82722 13.9755C1.4914 13.6397 1.30273 13.1842 1.30273 12.7093V3.59302C1.30273 3.3233 1.52139 3.10465 1.79111 3.10465Z"
                                fill="#16273C"
                              />
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M5.04697 6.36035C5.31669 6.36035 5.53534 6.579 5.53534 6.84872V10.7557C5.53534 11.0254 5.31669 11.2441 5.04697 11.2441C4.77725 11.2441 4.55859 11.0254 4.55859 10.7557V6.84872C4.55859 6.579 4.77725 6.36035 5.04697 6.36035Z"
                                fill="#16273C"
                              />
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M7.65146 6.36035C7.92118 6.36035 8.13983 6.579 8.13983 6.84872V10.7557C8.13983 11.0254 7.92118 11.2441 7.65146 11.2441C7.38174 11.2441 7.16309 11.0254 7.16309 10.7557V6.84872C7.16309 6.579 7.38174 6.36035 7.65146 6.36035Z"
                                fill="#16273C"
                              />
                            </svg>
                          </MDButton>
                        </Box>
                      </Box>
                    </ListItem>
                  ))}

                </List>}
              </MDBox>
              <Box className="navigation-link">
                <MDButton
                  className="primary-btn btn xl"
                  onClick={() => navigate(-1)}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none">
<path fill-rule="evenodd" clip-rule="evenodd" d="M0 6.49961C0 5.9524 0.443608 5.50879 0.990826 5.50879H11.0092C11.5564 5.50879 12 5.9524 12 6.49961C12 7.04683 11.5564 7.49044 11.0092 7.49044H0.990826C0.443608 7.49044 0 7.04683 0 6.49961Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M6.70062 0.790206C7.08756 1.17715 7.08756 1.8045 6.70062 2.19145L2.39206 6.5L6.70062 10.8086C7.08756 11.1955 7.08756 11.8229 6.70062 12.2098C6.31368 12.5967 5.68632 12.5967 5.29938 12.2098L0.290206 7.20062C-0.0967354 6.81368 -0.0967354 6.18632 0.290206 5.79938L5.29938 0.790206C5.68632 0.403265 6.31368 0.403265 6.70062 0.790206Z" fill="white"/>
</svg>
                  &nbsp; {t("Go Back")}
                </MDButton>
              </Box>
            </Box>
          </Box>
        </Box>
      </MDBox>

    </DashboardLayout>
  );
}

export default Userlist;
