import Loading from "../../assets/images/icons/heart2.gif";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
function  Loader({loading}){
    return (
        <Modal
        open={loading}
        aria-labelledby="modal-modal-title"
        className="cs-modal transparent-confirmation"
        aria-describedby="modal-modal-description"
      >
        <Box className="modal-dialog">
          <Box className="modal-content">
            <Box>
              <Box className="content-section">
                <img src={Loading} className="loading-image" style={{width:"250px",height:"auto"}} />
                {/* <MDTypography variant="h3" gutterBottom>
                  Redirecting to device management...
                </MDTypography> */}
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    )
}
export default Loader