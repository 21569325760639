import I18nextBrowserLanguageDetector from "i18next-browser-languagedetector";
// import React from "react";
// import { createRoot } from 'react-dom/client';
// import LanguageDetector from 'i18next-browser-languagedetector'
import {  initReactI18next } from "react-i18next";
import i18n from "i18next";
import en from "./en.json"
import fr from "./fr.json"
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(I18nextBrowserLanguageDetector)
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: {
      en: {
        translation: en
      },
      fr: {
        translation: fr
      },
  
    },
    lng: "fr", // if you're using a language detector, do not define the lng option
    fallbackLng: "fr",
    
    detection:{
        order: [ 'localStorage','cookie', 'sessionStorage', 'htmlTag'],
        caches:['localStorage']
    },

    interpolation: {
      escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    }
  });