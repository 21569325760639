import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import MDButton from "components/MDButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import Checkbox from "@mui/material/Checkbox";
import Filter from "../../components/Filter";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
//modal import
import Modal from "@mui/material/Modal";
import { useTranslation } from "react-i18next";
import Loader from "components/Loader";
import { useNavigate } from "react-router-dom";
import alasql from "alasql";
import SearchComponent from "components/SearchInput";

import {
  useMaterialUIController,
  setSelecetdCustomerrec
} from "context";
import { get_contract } from "backendservice/firebasefunctions,";
import { updateContract_status } from "backendservice/firebasefunctions,";
import Sortlist from "components/Sortlist";
import { add_contract } from "backendservice/firebasefunctions,";
import { nameProps } from "backendservice/validation";
import { edit_contract } from "backendservice/firebasefunctions,";
import { checkDeleteContract } from "./function";
import { deleteDocument } from "backendservice/firebasefunctions,";
function Contract() {
  const [controller, dispatch] = useMaterialUIController();
  const {
    selecetdCustomer,
    selected_clinic,
    permission
  } = controller;
  const [followsMe, setFollowsMe] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [modalopen, setmodalopen] = useState(false);
  const [contractlist, setContractList] = useState([]);
  const [contractListbackup, setContractListbackup] = useState([]);

  const [name, setName] = useState("");
  const [hours, setHours] = useState("");
  const [days, setDays] = useState("");
  const [others, setOthers] = useState("");
  const [comments, setComments] = useState("");


  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editId, setEditID] = useState("");
  const [modaltype, setmodaltype] = useState("");
  const [contractType,setcontractType] = useState({hours:false,days:false,others:false,extrahours:false})
  const handlemodalopen = (type,data) => {
    setmodaltype(type)
    if(type ==="add")
    {
      setName("")
      setHours("")
      setDays("")
      setOthers("")
      setComments("")
    
    setcontractType({hours:false,days:false,others:false,extrahours:false})
      setmodalopen(true);
    }else{
      console.log(data)
      setEditID(data.id)
      setName(data.contract)
      setHours(data.hours)
      setDays(data.days)
      setOthers(data.others)
      setComments(data.comments)
      setcontractType(data.contractType)
      setmodalopen(true);
    }
   
  };
  const handlemodalClose = () => {
    setName("")
    setHours("")
    setDays("")
    setOthers("")
    setComments("")
    setName("");
    setcontractType({hours:false,days:false,others:false,extrahours:false})
    setmodalopen(false);
  };
  const loadData=async()=>{
    let customer  = selecetdCustomer.id
    if(customer!=="" && customer!==undefined)
    {
        setLoading(true)
        console.log("data =====>",customer)
        try{
            var stafflist = await get_contract(customer,selected_clinic.id)
            setContractList(stafflist)
            setContractListbackup(stafflist)
     
            setLoading(false)
        }catch(error)
        {
            setLoading(false)
            setContractList([])
            setContractListbackup([])
            console.log("error ==>",error)
        }
    }
  }
const handleStatus = async(data)=>{
  setLoading(true);
          if(await updateContract_status(!data.active,data.id)){
  
              setRefresh(!refresh)
            }else{
            
              setLoading(false);
            }
}

const handleDelete = async(id)=>{
  setLoading(true)
  
  if(await checkDeleteContract(id))
    {
      await deleteDocument(process.env.REACT_APP_CONTRACT,id)
      alert(t("delete.success"))
    }else{
      alert(t("delete.restricted"))
    }
    setLoading(false)
  setRefresh(!refresh)
}
const addContract = async(e)=>{
  e.preventDefault();
  setLoading(true);
  var data = {
    contract:name,
    hours:hours,
    days:days,
    others:others,
    contractType:contractType,
    createdTime:new Date(),
    active:true,
    comments:comments,
    customer:selecetdCustomer.id,
    defaultClinicId:selected_clinic.id
  }
  if(modaltype =="add")
  {
    data.attachCounter = 0
    if(await add_contract(data)){
      handlemodalClose()
      setRefresh(!refresh)
    }else{
      alert(t("failed to add"))
      setLoading(false);
    }
  }else{
    if(await edit_contract(data,editId)){
      handlemodalClose()
      setRefresh(!refresh)
    }else{
      alert(t("failed to Update"))
      setLoading(false);
    }
  }
 
}
const isButtonDisabled = (moduleId) => {
  return !(
    !permission.module ||
    !permission.module[moduleId] ||
    permission.module[moduleId].accesstype === 2
  );
};
  useEffect(() => {
    if (selected_clinic.id && selecetdCustomer.id) {
      loadData().then(() => setLoading(false));
    }
  }, [refresh,selecetdCustomer,selected_clinic,permission]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Loader loading={loading} />
      <MDBox pt={4} pb={3} className="xl-container">
        <Box>
          <Box>
            <MDBox className="page-heading">
              <MDTypography
                variant="h5"
                gutterBottom
                sx={{ pb: "8px", display: "flex" }}
              >
               {t("Contracts")}
              </MDTypography>
            </MDBox>

            <Box>
              <Box
                sx={{
                  margin: "10px 0 0 0",
                }}
              >
                 <MDBox
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
               <SearchComponent searchList = {contractListbackup} onSearch={(e)=>setContractList(e)} />
            
                <Box className="filter-mobile">
                  <MDBox
                    color="text"
                    px={0}
                    sx={{ display: { xs: "block", sm: "flex" } }}
                  >
                    <Box
                      sx={{
                        display: { xs: "block", sm: "flex" },
                      }}
                    >
                        <Sortlist onSort={(e)=>setContractList(e)} sortlist={contractListbackup} />
            
                        <Filter   onFilter={(e)=>{setContractList(e)}} filterlist={contractListbackup}  module = "contract"/>
                
                    </Box>
                  </MDBox>
                </Box>
              </MDBox>
              </Box>
              <MDBox
                sx={{
                  marginTop: "20px",
                }}
              > {  contractlist.length==0 ? (<Box className="no-data">
              <Box>
               <span>!</span>
              <h3>{t("Data not available!")}</h3>
              </Box>

             </Box>):
                <List className="datatable">
                { contractlist.map((data,index)=>(
                  <ListItem key = {index}>
                    <Box className="list-content">
                      <Box className="left-content">
                        <label className="heading">{data.label}</label>
                      </Box>
                      <Box className="blue-bg">
                        <MDButton className="link-dot" onClick={(e)=>handlemodalopen("edit",data)}
                      disabled={isButtonDisabled("REST08")}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="18"
                            viewBox="0 0 17 15"
                            fill="none"
                          >
                            <path
                              d="M12.4649 1.08492C12.6572 0.899475 12.8855 0.752375 13.1367 0.652015C13.3879 0.551655 13.6572 0.5 13.9291 0.5C14.201 0.5 14.4703 0.551655 14.7215 0.652015C14.9727 0.752375 15.201 0.899475 15.3933 1.08492C15.5856 1.27036 15.7381 1.49051 15.8422 1.7328C15.9462 1.97509 15.9998 2.23478 15.9998 2.49703C15.9998 2.75928 15.9462 3.01897 15.8422 3.26126C15.7381 3.50355 15.5856 3.7237 15.3933 3.90915L5.50995 13.4409L1.4834 14.5L2.58155 10.6167L12.4649 1.08492Z"
                              stroke="#16273C"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </MDButton>
                        <MDButton
                  className="link-dot"
                  disabled={isButtonDisabled("REST08")}
                onClick={(e)=>handleDelete(data.id)}
                >
                 <svg xmlns="http://www.w3.org/2000/svg" width="17" height="19" viewBox="0 0 13 15" fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M0.151367 3.59384C0.151367 3.32412 0.370019 3.10547 0.639739 3.10547H12.3607C12.6304 3.10547 12.849 3.32412 12.849 3.59384C12.849 3.86356 12.6304 4.08221 12.3607 4.08221H0.639739C0.370019 4.08221 0.151367 3.86356 0.151367 3.59384Z" fill="#16273C"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M5.19829 1.47674C4.98241 1.47674 4.77538 1.5625 4.62274 1.71515C4.47009 1.86779 4.38433 2.07482 4.38433 2.2907V3.59302C4.38433 3.86274 4.16568 4.0814 3.89596 4.0814C3.62624 4.0814 3.40759 3.86274 3.40759 3.59302V2.2907C3.40759 1.81577 3.59625 1.3603 3.93207 1.02448C4.26789 0.688662 4.72337 0.5 5.19829 0.5H7.80294C8.27786 0.5 8.73333 0.688662 9.06915 1.02448C9.40497 1.3603 9.59364 1.81577 9.59364 2.2907V3.59302C9.59364 3.86274 9.37498 4.0814 9.10526 4.0814C8.83554 4.0814 8.61689 3.86274 8.61689 3.59302V2.2907C8.61689 2.07482 8.53114 1.86779 8.37849 1.71515C8.22584 1.5625 8.01881 1.47674 7.80294 1.47674H5.19829ZM1.94247 3.10465C2.21219 3.10465 2.43085 3.3233 2.43085 3.59302V12.7093C2.43085 12.9252 2.5166 13.1322 2.66925 13.2849C2.82189 13.4375 3.02893 13.5233 3.2448 13.5233H9.75643C9.9723 13.5233 10.1793 13.4375 10.332 13.2849C10.4846 13.1322 10.5704 12.9252 10.5704 12.7093V3.59302C10.5704 3.3233 10.789 3.10465 11.0588 3.10465C11.3285 3.10465 11.5471 3.3233 11.5471 3.59302V12.7093C11.5471 13.1842 11.3585 13.6397 11.0226 13.9755C10.6868 14.3113 10.2314 14.5 9.75643 14.5H3.2448C2.76988 14.5 2.31441 14.3113 1.97858 13.9755C1.64276 13.6397 1.4541 13.1842 1.4541 12.7093V3.59302C1.4541 3.3233 1.67275 3.10465 1.94247 3.10465Z" fill="#16273C"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M5.19833 6.35938C5.46805 6.35938 5.68671 6.57803 5.68671 6.84775V10.7547C5.68671 11.0244 5.46805 11.2431 5.19833 11.2431C4.92861 11.2431 4.70996 11.0244 4.70996 10.7547V6.84775C4.70996 6.57803 4.92861 6.35938 5.19833 6.35938Z" fill="#16273C"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M7.80283 6.35938C8.07255 6.35938 8.2912 6.57803 8.2912 6.84775V10.7547C8.2912 11.0244 8.07255 11.2431 7.80283 11.2431C7.5331 11.2431 7.31445 11.0244 7.31445 10.7547V6.84775C7.31445 6.57803 7.5331 6.35938 7.80283 6.35938Z" fill="#16273C"/>
                            </svg>
                
                </MDButton>
                        <Box>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                            }}
                            className={isButtonDisabled("REST08")? "disabled" :""}

                          >
                      <label className="active-text">{data.active?t("Active"):t("Inactive")}</label>
                            <Switch
                             checked={data.active}
                             disabled={isButtonDisabled("REST08")}
                             onChange={async() => 
                             
                         handleStatus(data)
                            }
                            />
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </ListItem>
                )) 
                }
              
                </List>
}
              </MDBox>
              <Box
                className="navigation-link"
              >
                 <MDButton
                  className="secondary-btn xl white"
                  onClick={() => navigate("/contract/report")}
                >
                  {t("View contract report")}
                </MDButton>
                <MDButton
                  className="primary-btn btn xl"
                  onClick={() => handlemodalopen("add",null)}
                  disabled={isButtonDisabled("REST08")}
                  // onClick={() => navigate("/employee")}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="13"
                    viewBox="0 0 12 13"
                    fill="none"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M7.36739 1.86739C7.36739 1.1122 6.75519 0.5 6 0.5C5.24481 0.5 4.63261 1.1122 4.63261 1.86739V5.13261H1.36739C0.612202 5.13261 0 5.74481 0 6.5C0 7.25519 0.612202 7.86739 1.36739 7.86739H4.63261V11.1326C4.63261 11.8878 5.24481 12.5 6 12.5C6.75519 12.5 7.36739 11.8878 7.36739 11.1326V7.86739H10.6326C11.3878 7.86739 12 7.25519 12 6.5C12 5.74481 11.3878 5.13261 10.6326 5.13261H7.36739V1.86739Z"
                      fill="white"
                    />
                  </svg>
                  &nbsp;&nbsp; {t("Add contract")}
                </MDButton>
              </Box>
            </Box>
          </Box>
        </Box>
      </MDBox>

      {/* Modal mm-04 */}
      <Modal
        open={modalopen}
        onClose={handlemodalClose}
        aria-labelledby="modal-modal-title"
        className="cs-modal lg"
        aria-describedby="modal-modal-description"
      >
        <Box className="modal-dialog">
          <Box className="modal-content" component="form" onSubmit = {(e)=>addContract(e)}>
            <Box className="modal-body">
              <FormGroup sx={{ mb: 2, mr: 2, width: "100%" }}>
                <InputLabel className="formlabel">{t("Contract name")}<strong style={{color:"red"}}> *</strong></InputLabel>
                <TextField
                  className="forminput"
                  placeholder={t("Enter contract name")}
                  variant="outlined"
                  required
                  value = {name}
                  InputProps={nameProps}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                />
              </FormGroup>
              <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <div style={{display:"flex"}}>
                <FormControlLabel
                        sx={{
                          display: "flex",
                          textAlign: "center",
                          marginRight:"4px"
                        }}
                        className="cs-checkbox"
                        label={t("Hours") }
                        checked = {contractType.hours}
                        onChange = {(e) => 
                          setcontractType(prevState => ({
                            ...prevState, // Spread the previous state to retain the unchanged properties
                            hours: !contractType.hours // Update the phone property with the new value
                          }))
                        }
                        control={<Checkbox />}
                      />
                      {/* <strong style={{color:"red"}}> *</strong> */}
                </div>
                   
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={12}>
                          <FormGroup sx={{ mb: 2 }}>
                          
                            <TextField
                              className="forminput"
                              placeholder={t("Enter hours")}
                              variant="outlined"
                              // required
                              value = {hours}
                              disabled = {!contractType.hours}
                              type="number"
                              onChange={(e) => {
                                setHours(e.target.value);
                              }}
                            />
                          </FormGroup>
                        </Grid>
                        
                      </Grid>
                   
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <div style={{display:"flex"}}>
                      <FormControlLabel
                        sx={{
                          display: "flex",
                          textAlign: "center",
                          marginRight:"4px"
                       
                        }}
                        className="cs-checkbox"
                        label={t("Days")}
                        checked = {contractType.days}
                        onChange = {(e) => 
                          setcontractType(prevState => ({
                            ...prevState, // Spread the previous state to retain the unchanged properties
                            days: !contractType.days // Update the phone property with the new value
                          }))
                        }
                        control={<Checkbox />}
                      />
                      {/* <strong style={{color:"red"}}> *</strong> */}
                      </div>
                      
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={12}>
                          <FormGroup sx={{ mb: 2 }}>
                          
                            <TextField
                              className="forminput"
                              placeholder={t("Enter days")}
                              variant="outlined"
                              // required
                              value = {days}
                              type="number"
                              disabled = {!contractType.days}
                              onChange={(e) => {
                                setDays(e.target.value);
                              }}
                            />
                          </FormGroup>
                        </Grid>
                        
                      </Grid>
                   
                    </Grid>

                    <Grid item xs={12} md={4}>
                    <div  style={{display:"flex"}}>
                    <FormControlLabel
                        sx={{
                          display: "flex",
                          textAlign: "center",
                          marginRight:"4px"
                         
                        }}
                        className="cs-checkbox"
                        label={t("Others")}
                        checked = {contractType.others}
                        onChange = {(e) => 
                          setcontractType(prevState => ({
                            ...prevState, // Spread the previous state to retain the unchanged properties
                            others: !contractType.others // Update the phone property with the new value
                          }))
                        }
                        control={<Checkbox />}
                      />
                      {/* <strong style={{color:"red"}}> *</strong> */}
                    </div>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={12}>
                          <FormGroup sx={{ mb: 2 }}>
                           
                            <TextField
                              className="forminput"
                              placeholder={t("Enter")}
                              variant="outlined"
                              // required
                              value={others}
                              type="number"
                              disabled = {!contractType.others}
                              onChange={(e) => {
                                setOthers(e.target.value);
                              }}
                            />
                          </FormGroup>
                        </Grid>
                        
                      </Grid>
                   
                    </Grid>
                  </Grid>
                  <FormControlLabel
                        sx={{
                          display: "flex",
                          textAlign: "center",
                          mb: 2,
                        }}
                        className="cs-checkbox"
                        label={t("Extra hours")}
                        checked = {contractType.extrahours}
                        onChange = {(e) => 
                          setcontractType(prevState => ({
                            ...prevState, // Spread the previous state to retain the unchanged properties
                            extrahours: !contractType.extrahours // Update the phone property with the new value
                          }))
                        }
                        control={<Checkbox />}
                      />
                       <FormGroup sx={{ mb: 2 }}>
                <InputLabel className="formlabel">
                  {t("Comment")}
                </InputLabel>
                <textarea
                  className="forminput"
                  rows="4"
                  placeholder={t("Enter comment")}
                  value = {comments}
                  // required
                  type="text"
                  onChange={(e) => {
                    setComments(e.target.value);
                  }}
                />
              </FormGroup>
            </Box>
            <Box className="modal-footer">
           
              <MDButton
                className="secondary-btn white"
                sx={{
                  padding: "11px 30px",
                  fontSize: "16px",
                  fontWeight: "normal",
                  minWidth: "160px",
                }}
                color="dark"
                onClick={() => handlemodalClose()}
              >
                {t("Cancel")}
              </MDButton>
              <MDButton
                className="secondary-btn"
                sx={{
                  padding: "11px 30px",
                  fontSize: "16px",
                  fontWeight: "normal",
                  minWidth: "160px",
                }}
                color="dark"
                type = "submit"
              >
                {modaltype=="add"?t("Save"):t("Update")}
              </MDButton>
            </Box>
          </Box>
        </Box>
      </Modal>
    </DashboardLayout>
  );
}

export default Contract;
