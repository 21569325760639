import { useState, useEffect } from "react";

// react-router components
import { useLocation, Link, useNavigate } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";

// Material Dashboard 2 React example components
import NotificationItem from "examples/Items/NotificationItem";
import SettingMenu from "examples/Items/SettingMenu";
import en from "../../../assets/images/en.svg";
import fr from "../../../assets/images/fr.svg";
import Resonante from "../../../assets/images/resonante/logo.png";

import i18next from "i18next";
// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarMobileMenu,
} from "examples/Navbars/DashboardNavbar/styles";

// Material Dashboard 2 React context
import {
  useMaterialUIController,
  setTransparentNavbar,
  setMiniSidenav,
  setOpenConfigurator,
  setCustomerdaata,
  setCustomerUpdate,
  setSelecetdCustomerrec,
  setClinicListContext,
  setSelectedClinicCOntext,
  setPermission
  
} from "context";
import { get_all_customer,get_role,get_clinic } from "backendservice/firebasefunctions,";
import { auth } from "backendservice/firebase";
import { useTranslation } from "react-i18next";

function DashboardNavbar({ absolute, light, isMini }) {
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useMaterialUIController();
  const {
      miniSidenav,
    transparentNavbar,
    fixedNavbar,
    openConfigurator,
    darkMode,
    customer,
    customerupdate,
    selecetdCustomer,
    selected_clinic,
    clinic_list,
    module,
    permission
  } = controller;
  const {t,i18n} = useTranslation()
  const [openMenu, setOpenMenu] = useState(false);
  const [openCustomer,setOpenCustomer]=useState(false);
  const [openClinic,setOpenClinic]=useState(false);
  const [openMenusetings, setOpenMenusetings] = useState(false);
  const route = useLocation().pathname.split("/").slice(1);
  const [username, setusername] = useState("");
  const [loading, setLoading] = useState(false);
const [brand,setBrand]=useState(customer)
const [selectedbrand,setSelectedBrand]=useState(selecetdCustomer)
const [clinicList,setClinicList]=useState(clinic_list)
const [selectedClinic,setselectedclinic]=useState(selected_clinic)
const [access,setaccess] = useState(false)
const languageOption  = [
  {label:"En",icon:en,value:"en",name:"English"},
  {label:"Fr",icon:fr,value:"fr",name:"French"}
]
const [language,setLanguagedisp] = useState({label:"En",icon:en})
  const navigate = useNavigate();
  var brandAdmin = false
  var userlocal = localStorage.getItem("user")
  const json = JSON.parse(userlocal)
  if (json.roleCode == "BA_00") {
    brandAdmin = true
  }
  const loadData=async()=>{
    setLoading(true)
    var data = localStorage.getItem("user")
  
  
      if (data == undefined || data == "") {
        navigate("/authentication/sign-in")
      } else {
        var jsonData = JSON.parse(data)
        if(jsonData.roleCode=="IA_00")
        {
          setaccess(true)
        }
       
      var custupdate =jsonData.customerupdate
       if(customerupdate!==custupdate)
      {
        setCustomerUpdate(dispatch,!customerupdate)
    try{
        // var resp =await  getCustomers()
        var roleId = jsonData.roleAccess.id
        var resp = await get_all_customer()
        var roles = await get_role(roleId)
        setPermission(dispatch,roles)
        jsonData.roleAccess = roles
        jsonData.roleCode = roles.roleCode
        jsonData.role = roles.role
        localStorage.setItem("user", JSON.stringify(jsonData))
        if(resp.length>0)
        {
 
                var brandData = []
                var brandinfo = []
        
                setusername(jsonData.name)
                var selecetd = {}
                resp.map((data,index)=>{
                  
                    var temps = {
                        id:data.id
                    }
                    brandData.push({...temps,...data.data})
                    brandinfo.push({label:data.data.company,id:data.id,image:data.data.logo,bgcolor:data.data.bgColor,categoryType:data.data.categoryType || ""})
                    if(jsonData.customer!=="")
                    {
                      // console.log(data.id,customersid)
                      if(data.id==jsonData.customer)
                      {
                        selecetd = {label:data.data.company,id:data.id,image:data.data.logo,bgcolor:data.data.bgColor,categoryType:data.data.categoryType || ""}
                      }
                    }
                })
                setSelecetdCustomerrec(dispatch,selecetd)
                setSelectedBrand(selecetd)
                loadClinic(selecetd.id)
            // setSelectedBrand({label:"BRAND",id:"",image:""})
            setCustomerdaata(dispatch,brandinfo)
            setBrand(brandinfo)
            // setCustomerdaata(dispatch,brandinfo)
    
        }else{
          setSelectedBrand({label:"err2",id:null,image:""})
          setCustomerdaata(dispatch,[])
          setBrand([])
            setLoading(false)
        }
    
    
        setLoading(false)
    }catch(error)
    {
      setSelectedBrand({label:"err1",id:null,image:""})
      setCustomerdaata(dispatch,[])
      setBrand([])
        setLoading(false)
    }
      }}
  }

  useEffect(() => {
    // Setting the navbar type
    var data = localStorage.getItem("i18nextLng");
    switch(data)
    {
      case "en":
        setLanguagedisp({label:"En",icon:en})
        break;
        case "fr":
          setLanguagedisp({label:"Fr",icon:fr})
          break;
          case "ar":
            setLanguagedisp({label:"Ar",icon:fr})
            break;
        default:
          setLanguagedisp({label:"En",icon:en})
    }

    loadData()
      setNavbarType("sticky");
 

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(
        dispatch,
        (fixedNavbar && window.scrollY === 0) || !fixedNavbar
      );
    }

    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  // const handleConfiguratorOpen = () =>
  //   setOpenConfigurator(dispatch, !openConfigurator);
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleOpenMenusettings = (event) =>
    setOpenMenusetings(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);
  const handleClosesettings = () => setOpenMenusetings(false);

  const handleOpenCustomer = (event) => setOpenCustomer(event.currentTarget);
  const handleCloseCustomer = () => setOpenCustomer(false);

  const handleOpenClinic = (event) => setOpenClinic(event.currentTarget);
  const handleCloseClinic = () => setOpenClinic(false);

  
  const changeLanguage=(lan)=>{
    
    i18next.changeLanguage(lan)
    switch(lan)
    {
      case "en":
        setLanguagedisp({label:"En",icon:en})
        break;
        case "fr":
          setLanguagedisp({label:"Fr",icon:fr})
          break;
          case "ar":
            setLanguagedisp({label:"Ar",icon:fr})
            break;
        default:
          setLanguagedisp({label:"En",icon:en})
    }
    handleClosesettings()
  }
  const handleSelectCustomer = async(data)=>{
    // console.log("customer selecetn",data)
    var local = localStorage.getItem("user")
    var resp = JSON.parse(local)

    resp.customer = data.id
    localStorage.setItem("user",JSON.stringify(resp))
    setSelecetdCustomerrec(dispatch,data)
    setSelectedBrand(data)
    loadClinic(data.id)
    handleCloseCustomer()
  }
  const handleSelectCLinic = async(data)=>{
    var local = localStorage.getItem("user")
    var resp = JSON.parse(local)

    resp.clinic = data.id
    localStorage.setItem("user",JSON.stringify(resp))
    setselectedclinic(data)
    setSelectedClinicCOntext(dispatch,data)

    handleCloseClinic()
  }
  const loadClinic = async(customer)=>{
    const clinicresp = await get_clinic(customer)
    if(clinicresp.length>0){

      var local = localStorage.getItem("user")
      var resp = JSON.parse(local)
  
     var clinicid =  resp?.clinic
     var selectedClinic = ""
      // localStorage.setItem("user",JSON.stringify(resp))
      if(clinicid!==undefined && clinicid!=="")
      {
        selectedClinic = clinicid
      }else{
        selectedClinic = clinicresp[0].id
      }
      var clinicSelected = clinicresp.find(obj => obj.id === selectedClinic);
      if(clinicSelected==undefined)
      {
        clinicSelected = clinicresp[0]
      }
      setClinicListContext(dispatch,clinicresp)
      setClinicList(clinicresp)
      // this need to be updaetd ------------------------------
      setSelectedClinicCOntext(dispatch,{label:clinicSelected.label,id:clinicSelected.id,data:clinicSelected.data})
      setselectedclinic({label:clinicSelected.label,id:clinicSelected.id,data:clinicresp[0].data})
           
    }else{
      setClinicListContext(dispatch,[])
      setClinicList([])
      // this need to be updaetd ------------------------------
      setSelectedClinicCOntext(dispatch,{label:"No Clinic",id:null,data:[]})
      setselectedclinic({label:"No Clinic",id:null})
  
    }
// console.log("response ===",brandData)

  }
  const renderCustomer = () => (
    <Menu
      anchorEl={openCustomer}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openCustomer)}
      onClose={handleCloseCustomer}
      sx={{ mt: 2 }}
    >
      {brand.map((data,index)=>(
        <NotificationItem icon={<Icon></Icon>} title={data.label} 
        onClick ={()=>{handleSelectCustomer(data)}}
        key = {index+"brand"}
     />
  
      ))}
       
    </Menu>
  );
  const renderClinic = () => (
    <Menu
      anchorEl={openClinic}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openClinic)}
      onClose={handleCloseClinic}
      sx={{ mt: 2 }}
    >
      {clinicList.map((data,index)=>(
        <NotificationItem icon={<Icon></Icon>} title={data.label} 
        key = {index+"notify"}
        onClick ={()=>{handleSelectCLinic(data)}}
      />
  
      ))}

    </Menu>
  );
  const logout = async()=>{
    navigate("/authentication/sign-in");
    localStorage.removeItem("user");
    localStorage.clear()
    sessionStorage.clear();
    await auth.signOut();
   
  }

  // Render the notifications menu
  const renderMenu = () => (
    <Menu
    className="account-menu"
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >
        <SettingMenu icon={<div></div>} title={t("Account settings")} onClick={()=>{
          
          navigate("/account-setting")
          ;}} />
         
   
   <SettingMenu icon={<div><svg xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 0 24 24" width="18"><path d="M0 0h24v24H0z" fill="none"/><path fill="#fff" d="M17 7l-1.41 1.41L18.17 11H8v2h10.17l-2.58 2.58L17 17l5-5zM4 5h8V3H4c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h8v-2H4V5z"/></svg></div>} title={t("Logout")} onClick={()=>{logout()}} />

    </Menu>
  );
  const rendersettings = () => (
    <Menu
      anchorEl={openMenusetings}
      anchorReference={null}
      className="language-menu"
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenusetings)}
      onClose={handleClosesettings}
      sx={{ mt: 2 }}
    >
      <NotificationItem
        icon={<img src={en} style={{ height: "15px" }} />}
        title="English"
        onClick={() =>changeLanguage("en")}
        selected={i18n.language === "fr"}
      /> 
      <NotificationItem
        icon={<img src={fr} style={{ height: "15px" }} alt=""/>}
        title="French"
        onClick={() =>changeLanguage("fr")}
        selected={i18n.language  === "en"}
      />
      {/* <NotificationItem
        title="Arabic"
        onClick={() => changeLanguage("ar")}
      /> */}
    </Menu>
  );

  // Styles for the navbar icons
  const iconsStyle = ({
    palette: { dark, white, text },
    functions: { rgba },
  }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });

  return (
    <AppBar
      // position={absolute ? "absolute" : navbarType}
      color="inherit"
      className="main-header"
      sx={(theme) =>
        navbar(theme, { transparentNavbar, absolute, light, darkMode })
      }
    >
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        <MDBox
          color="inherit"
          mb={{ xs: 0, md: 0 }}
          sx={(theme) => navbarRow(theme, { isMini })}
        >
          <Box className="brand">
            <img src={Resonante} alt="Resonante" />
          </Box>
       
        </MDBox>
        {isMini ? null : (
          <MDBox
            className="top-header"
            sx={(theme) => navbarRow(theme, { isMini })}
          >
            <MDBox className="hide-xs" pr={1}>
              <MDInput label="Search here" />
            </MDBox>
            <MDBox className="item-main" color={light ? "white" : "inherit"}>
              <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarMobileMenu}
                onClick={handleMiniSidenav}
                className="menu-mobile"
              >
                <Icon sx={iconsStyle} fontSize="medium">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="40"
                    viewBox="0 0 30 40"
                    fill="none"
                  >
                    <path
                      d="M26.25 16.6667H3.75"
                      stroke="#fff"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M26.25 10H3.75"
                      stroke="#fff"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M26.25 23.3333H3.75"
                      stroke="#fff"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </Icon>
              </IconButton>
              <Box sx={{ display: "flex" }}>
               {access &&  <IconButton
                  size="small"
                  disableRipple
                  className="header-item language customer-list"
                  color="inherit"
                  sx={navbarIconButton}
                  aria-controls="notification-menu"
                  aria-haspopup="true"
                  variant="contained"
                  onClick={handleOpenCustomer}
                >
                 <Box>
                    <img src={selectedbrand.image} alt="" /> <label>{selectedbrand.label}</label>
                    <Box className="arrow-right"></Box>
                  </Box>
                </IconButton>}
                <IconButton
                  size="small"
                  disableRipple
                  className="header-item clinic-select"
                  color="inherit"
                  sx={navbarIconButton}
                  aria-controls="notification-menu"
                  aria-haspopup="true"
                  variant="contained"
                  disabled={!(module=="staff" || module=="database" || module=="employee" || module=="contract" || module=="scheduler" || module=="report"  || module=="leave-management/ba")}
                  onClick={handleOpenClinic}
                >
                  <Box>
                   <label>{(selectedClinic.label)}</label>
                    <Box className="arrow-right"></Box>
                  </Box>
                </IconButton>
                <IconButton
                  size="small"
                  disableRipple
                  className="header-item language"
                  color="inherit"
                  sx={navbarIconButton}
                  aria-controls="notification-menu"
                  aria-haspopup="true"
                  variant="contained"
                  onClick={handleOpenMenusettings}
                >
                  <Box>
                    <img src={languageOption.find(({value})=>value==i18n.language).icon} alt="Language" /> <label>{languageOption.find(({value})=>value==i18n.language).label}</label>
                    <Box className="arrow-right"></Box>
                  </Box>
                </IconButton>
                <Link to="" className="web">
                  <IconButton
                    sx={navbarIconButton}
                    size="small"
                    className="header-item profile "
                    disableRipple
                    onClick={handleOpenMenu}
                  >
                    <Box >
                      {/* <img src={Profile} alt="Profile" />{" "}
                      <label>{username}</label> */}
                            <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="19"
                        height="26"
                        viewBox="0 0 15 22"
                        fill="none"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M7.5 10.167C10.2614 10.167 12.5 7.92842 12.5 5.16699C12.5 2.40557 10.2614 0.166992 7.5 0.166992C4.73858 0.166992 2.5 2.40557 2.5 5.16699C2.5 7.92842 4.73858 10.167 7.5 10.167ZM3 11.8335C1.34315 11.8335 0 13.1766 0 14.8335V18.8335C0 20.4904 1.34315 21.8335 3 21.8335H12C13.6569 21.8335 15 20.4904 15 18.8335V14.8335C15 13.1766 13.6569 11.8335 12 11.8335H3Z"
                          fill="white"
                        />
                      </svg>
                      <Box className="arrow-right"></Box>
                    </Box>
                  </IconButton>
                </Link>
            
                {/* <Link to="" className="mobile">
                  <IconButton
                    sx={navbarIconButton}
                    size="small"
                    className="header-item profile"
                    disableRipple
                    onClick={handleOpenMenu}
                  >
                    <Box>
                      <img src={Profile} alt="Profile" />{" "}
                      <label>{username}</label>
                      <Box className="arrow-right"></Box>
                    </Box>
                  </IconButton>
                </Link> */}

               
              </Box>
              {/* <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarIconButton}
                aria-controls="notification-menu"
                aria-haspopup="true"
                variant="contained"
                onClick={handleOpenMenu}
              >
                <Icon sx={iconsStyle}>notifications</Icon>
              </IconButton> */}
              {access && renderCustomer()}
              {renderMenu()}
              {rendersettings()}
              {renderClinic()}
            </MDBox>
          </MDBox>
        )}
      </Toolbar>
    </AppBar>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
