import moment from "moment";
import WeekNumber from './../../../../node_modules/react-calendar/dist/esm/MonthView/WeekNumber';
import { Userlist } from 'layouts/access-profile/access-profile';
import { get_shedule } from "backendservice/firebasefunctions,";
import { updateScheduleData } from "backendservice/firebasefunctions,";
import { get_clinic_id } from "backendservice/firebasefunctions,";
import { te } from "date-fns/locale";
import { updateWithoutMerge } from "backendservice/firebasefunctions,";
import { get_staff_leave } from "backendservice/firebasefunctions,";
import { get_customer } from "backendservice/firebasefunctions,";
import { get_leave_details } from "backendservice/firebasefunctions,";
import { Timestamp, deleteField, doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import { calculateTime } from "layouts/activities/helperFunctions/helper";
import { db } from "backendservice/firebase";
import { CounterUpdate } from "backendservice/firebasefunctions,";
import axios from "axios";

function getDateRangeArray(startDate, endDate) {
    const dates = [];
    let currentDate = moment(startDate);
    const end = moment(endDate);
  
    while (currentDate <= end) {
      dates.push(currentDate.format('YYYY-MM-DD'));
      currentDate = currentDate.clone().add(1, 'days');
    }
  
    return dates;
  }
  function getDatesForWeek(weekNumber,year = moment().year()) {
    // Create an array to store the dates
    const dates = [];

    // Get the start date of the week
    const startDate = moment().isoWeek(weekNumber).year(year).startOf('isoWeek');

    // Get the current date
    const currentDate = moment().startOf('day');

    // Loop through each day of the week and add it to the array
    for (let i = 0; i < 7; i++) {
        const date = startDate.clone().add(i, 'days').format('YYYY-MM-DD');
        const isActive = currentDate.isSame(date, 'day');
        dates.push({ date: date, active: isActive });
    }

    return dates;
}
function getweekdates(weekNumber)
{
    const dates = [];

    const startDate = moment().isoWeek(weekNumber).startOf('isoWeek');

    // Get the current date
    const currentDate = moment().startOf('day');

    // Loop through each day of the week and add it to the array
    for (let i = 0; i < 7; i++) {
        const date = startDate.clone().add(i, 'days').format('YYYY-MM-DD');
     
        dates.push(date);
    }

    return dates;
}
function generateWeekNumbersArray() {
  const weeksInYear = moment().isoWeeksInYear();
  const weekNumbersArray = [];

  for (let i = 1; i <= weeksInYear; i++) {
      const label = `Week ${i}`;
      const value = i;
      weekNumbersArray.push({ label, value });
  }

  return weekNumbersArray;
}
function generateMonthsArray() {
  const monthsArray = [];

  for (let i = 0; i < 12; i++) {
      const label = moment().month(i).format('MMMM');
      const value = i + 1; // Adding 1 to match the JavaScript month index (0-indexed to 1-indexed)
      monthsArray.push({ label:label, value:value });
  }

  return monthsArray;
}
function generateDatesOfMonth(month, year) {
  // Validate month number
  if (month < 1 || month > 12) {
    throw new Error('Invalid month number. Month number should be between 1 and 12.');
}
const dates = [];

// Get the start date of the week

// Get the current date
const currentDate = moment().startOf('day');
const daysInMonth = moment({ year, month: month - 1 }).daysInMonth(); // Note: Month is 0-indexed in Moment.js
const datesArray = [];

for (let day = 1; day <= daysInMonth; day++) {
    const dateString = moment({ year, month: month - 1, day }).format('YYYY-MM-DD');
   
    const isActive = currentDate.isSame(dateString, 'day');
    dates.push({ date: dateString, active: isActive });
    datesArray.push(dateString);
}

return {datesArray,dates};
}
function generateYeararray(){
  const currentYear = moment().year();
  const startYear = currentYear - 5;
  const endYear = startYear + 19;
  const years = Array.from(new Array(20), (val, index) => startYear + index);
 // console.log("year ",years);
return years
}
async function calculate_schedularview(userlist,datearray,activityList,selecetdCustomer,clinicList,customer){
  var record = [];
  console.log(userlist,"from calculate")
  userlist.map((data, index) => {
    const schedule ={}
   var  scheduleInfo = data
   console.log("schedule from data base",data)
   return
    datearray.forEach((d) => {
      var temp = {}
      if(scheduleInfo && scheduleInfo[datearray])
      {
        
         temp = scheduleInfo[datearray]


      }
    // data.leaveDates.map((data,index)=>{
    //   if(data==d)
    //   {
    //     temp ={
    //       bucket1: 5,
    //       bucket2: 5,
    //       bucketFree: 2,
    //       view:2,
    //       leave:data.leavetype[data],
    //       activityM: [
    //             ],
    //       activityAf: [
    //              ],
    //     };
    //   }
    // })

    //  var ddaa = {
    //     bucket1: 5,
    //     bucket2: 5,
    //     bucketFree: 2,
    //     view:1,
    //     activityM: [
    //       activityList[Math.floor(Math.random() * activityList.length)],
    //       activityList[Math.floor(Math.random() * activityList.length)],
    //     ],
    //     activityAf: [
    //       activityList[Math.floor(Math.random() * activityList.length)],
    //       activityList[Math.floor(Math.random() * activityList.length)],
    //     ],
    //   };


      schedule[d] = temp;
    });


    record.push({
      userid: data.id,
      name: data.name,
      schedule: schedule,
      createdTime: new Date(),
      updated: [],
      customer: selecetdCustomer.id,
      id: "random id",
      clinic: clinicList[1].id,
    });
    // record.userid= data.id,
    // record.name=data.name

    // record.schedule = schedule
  });
  console.log("final record ",record)
  return record
}
function getCurrentWeekNumber() {
  return moment().isoWeek();
}
function getDates(startDate,endDate)
{
  const dates = [];
  let currentDate = moment(startDate.toDate());
    const finalDate = moment(endDate.toDate());

 while (currentDate <= finalDate) {
      dates.push(currentDate.format('YYYY-MM-DD'));
      currentDate = currentDate.clone().add(1, 'days');
  }

  return dates;
}
function compareTimes(activity, morningtime, afternoonTime, scheduletime) {
  try{
  const actst =moment.unix(activity.duration.startingTime.seconds).format('HH:mm');
  const acten = moment.unix(activity.duration.closingTime.seconds).format('HH:mm');
  console.log(actst,acten)
  const actStart = moment(actst, 'HH:mm')
  const actEnd = moment(acten, 'HH:mm')
  
  let clinicStart, clinicEnd;

  if (scheduletime === 1) {
    clinicStart = moment(morningtime.opening_time, 'HH:mm');
    clinicEnd = moment(morningtime.closing_time, 'HH:mm');
  } else {
    clinicStart = moment(afternoonTime.opening_time, 'HH:mm');
    clinicEnd = moment(afternoonTime.closing_time, 'HH:mm');
  }

  if (!clinicStart.isValid() || !clinicEnd.isValid()) {
    return { status: false, code: 0, message: "Clinic hours are invalid" };
  }
  console.log(actStart,actEnd,clinicStart,clinicEnd,actStart.isBefore(clinicStart),actEnd.isAfter(clinicEnd))
  if (actStart.isBefore(clinicStart) || actEnd.isAfter(clinicEnd)) {
    return { status: false, code: 0, message: "L’activité est en dehors des heures d’ouvertureo " + clinicStart.format("HH:mm") + " - " + clinicEnd.format("HH:mm") };
  } else if ((actStart.isAfter(clinicStart) || actStart.isSame(clinicStart)) && (actEnd.isBefore(clinicEnd) || actEnd.isSame(clinicEnd))) {
    return { status: true, code: 2, message: "Activity can be assigned" };
  } else {
    return { status: false, code: 1, message: "Activity cannot be assigned" };
  }
}catch(error){
  console.log(error)
}
}
function checkClinicClosed(clinic,activity,scheduledtime,date)
{
    var clinicOpen = clinic.metadata.daysoperational
    const day = clinicOpen.find((d) => d.name === moment(date).format("dddd"));
  
      if(scheduledtime ==1)
        {
          return day.morning?day.morning:false
        }else{
          return day.afternoon?day.afternoon:false
        }
}
async function detectactivitySlot(clinicId,activity)
{
  var clinic = await get_clinic_id(clinicId);

  const actst =moment.unix(activity.duration.startingTime.seconds).format('HH:mm');
  const acten = moment.unix(activity.duration.closingTime.seconds).format('HH:mm');
  console.log(actst,acten)
  const actStart = moment(actst, 'HH:mm')
  const actEnd = moment(acten, 'HH:mm')
  var operationalDays = clinic.metadata.daysoperational.find((element)=>element.enabled ===true)

  var timmingM = clinic.time_slot_morning[operationalDays.name]
  var timmingAf = clinic.time_slot_afternoon[operationalDays.name]
  console.log("operational dauys ",timmingM,timmingAf )
    var clinicStartm = moment(timmingM.opening_time, 'HH:mm');
    var clinicEndm = moment(timmingM.closing_time, 'HH:mm');
  
    var clinicStartaf = moment(timmingAf.opening_time, 'HH:mm');
    var clinicEndaf = moment(timmingAf.closing_time, 'HH:mm');
    console.log(actStart.isBefore(clinicEndm),actStart.isAfter(clinicStartaf),)
    if (actStart.isBefore(clinicEndm)) {
      return 1; // Activity falls within morning time slot
    } else if (actStart.isSameOrAfter(clinicStartaf)) {
      return 2; // Activity falls within afternoon time slot
    } else {
      return 1; // Activity falls outside both morning and afternoon time slots
    }
}
function createFirebaseTimestamp(timeInput) {
  let date;

  if (typeof timeInput === 'string') {
    console.log(timeInput, "time string");
    const [hours, minutes] = timeInput.split(":").map(Number);

    // Create a new Date object with today's date and the specified time
    date = new Date();
    date.setHours(hours);
    date.setMinutes(minutes);
    date.setSeconds(0);
    date.setMilliseconds(0);
  } else if (timeInput instanceof Date) {
    console.log(timeInput, "Date object");
    date = timeInput;
  } else if (typeof timeInput === 'object' && typeof timeInput.seconds === 'number' && typeof timeInput.nanoseconds === 'number') {
    console.log(timeInput, "custom timestamp object");
    // Create a Date object from the custom timestamp
    date = new Date((timeInput.seconds * 1000) + Math.floor(timeInput.nanoseconds / 1000000));
  } else {
    throw new Error('Invalid input type. Expected a time string, a Date object, or a custom timestamp object.');
  }

  // Custom Timestamp format (can be adjusted to your needs)
  const timestamp = {
    seconds: Math.floor(date.getTime() / 1000),
    nanoseconds: (date.getTime() % 1000) * 1000000
  };

  console.log("timestamp", timestamp, moment.unix(timestamp.seconds).format('HH:mm'));
  return timestamp;
}

async function remove_schedule_date(brandId, clinicId, userId,date,clinic)
{
  var bucket = calculateBucket(clinic,date)

    var schedule =await  get_shedule(brandId, clinicId, userId,date,date)
    schedule[date].bucket1= bucket.bucket1
    schedule[date].bucket2= bucket.bucket2
    schedule[date].holiday = false
    schedule[date].leave= false
    schedule[date].activityAf = []
    schedule[date].activityM = []
    // CounterUpdate(process.env.REACT_APP_ACTIVITY,-1,selectedActivity.id)
    console.log("remove date",schedule[date])
    var resp =await updateScheduleData(brandId, clinicId, userId,schedule,date,date)

}
async function add_leave(brandId, clinicId, userId,startDate,endDate,leave,status)
{
  console.log("Triggered");
  let currentDate = moment.unix(startDate.seconds);
  const finalDate = moment.unix(endDate.seconds);
  var clinic = await get_clinic_id(clinicId);
  var dates = []
  while (currentDate <= finalDate) {
    dates.push(currentDate.format("YYYY-MM-DD"));
    currentDate = currentDate.clone().add(1, "days");
  }

    var schedule =await  get_shedule(brandId, clinicId, userId,dates[0],dates[dates.length-1])
    dates.map((date)=>{
      var bucket = calculateBucket(clinic, date);
      if(status =="approved")
      {
        
        if (
          typeof schedule[date] === "object" &&
          schedule[date] !== null
        ) {
        schedule[date].bucket1= schedule[date].bucket1initial
        schedule[date].bucket2= schedule[date].bucket2initial
        schedule[date].holiday = false
        schedule[date].leave= true
        schedule[date].leaveactivity = [leave]
        schedule[date].activityAf = []
        schedule[date].activityM = []
        }else{
          schedule[date] = {
            bucket1:bucket.bucket1,
            bucket2:bucket.bucket1,
            bucket1initial: bucket.bucket1,
            bucket2initial: bucket.bucket2,
            bucketFree: 0,
            holiday: false,
            leave: true,
            lunch: false,
            leaveactivity:[leave],
            specialActivity:false,
            activitySP:[],
            
            otherActivity:false,
            activityOT:[],
            activityM:[],
            activityAf:[],
            activitylunch: [],
          };
        }
      }else{
        // delete schedule[date] = {
        //   bucket1:bucket.bucket1,
        //   bucket2:bucket.bucket1,
        //   bucket1initial: bucket.bucket1,
        //   bucket2initial: bucket.bucket2,
        //   bucketFree: 0,
        //   holiday: false,
        //   leave: false,
        //   lunch: false,
        //   leaveactivity:[],
        //   activityM:[],
        //   activityAf:[],
        //   activitylunch: [],
        // };
        delete schedule[date]
        console.log("deleted date ",date)
      }
     
    })
console.log({schedule,brandId,clinicId,userId,startdate:moment.unix(startDate.seconds),endDate:moment.unix(endDate.seconds),leave,status},"from add_leave");

    var resp =await updateWithoutMerge(brandId, clinicId, userId,schedule,dates[0],dates[dates.length-1])

}
async function add_leave_schedule(brandId,clinicId, userId,leaveId,status)
{
//   let brandId  = "CwBYRPFgfR3rjaNvTIZa"
//  let  clinicId = "06306dec-ad39-4756-b4d9-dd6d732f1102"
//  let  userId = "S6uzN50e6UWNvxhkJbwY5tkjWof1"
//  let  leaveId  ="48bb1458-826b-418c-8a0c-7bb40fc613fc"
//  let  status = "approved"

  // console.log("leave details",)
  // console.log("Triggered",{brandId,clinicId, userId,leaveId,status});
 var leaveDetails =  await get_leave_details(leaveId)
  var dates = leaveDetails.dates
      var schedule =await  get_shedule(brandId, clinicId, userId,dates[0],dates[dates.length-1])
      var clinic = await get_clinic_id(clinicId);
  for (let i = 0; i < dates.length; i++) {
    const date = dates[i];
    var leaveType = leaveDetails.dateList.find((data)=>moment(data.date).isSame(moment(date)))
    console.log("leaveType",leaveType)
    var bucket = calculateBucket(clinic, date);
          if(status =="approved")
          {
            
            if (
              typeof schedule[date] === "object" &&
              schedule[date] !== null
            ) {
            schedule[date].bucket1= (leaveType.value==1 || leaveType.value == 0) ?schedule[date].bucket1initial:schedule[date].bucket1
            schedule[date].bucket2= (leaveType.value==2 || leaveType.value == 0) ?schedule[date].bucket2initial:schedule[date].bucket2
            schedule[date].holiday = false
            schedule[date].leave= true
            schedule[date].leaveValue= leaveType.value
            schedule[date].leaveactivity = [leaveType]
            schedule[date].activityAf = (leaveType.value==2 || leaveType.value == 0) ? []:schedule[date].activityAf
            schedule[date].activityM =(leaveType.value==1 || leaveType.value == 0) ? []:schedule[date].activityM
            }else{
              schedule[date] = {
                bucket1:bucket.bucket1,
                bucket2:bucket.bucket1,
                bucket1initial: bucket.bucket1,
                bucket2initial: bucket.bucket2,
                bucketFree: 0,
                holiday: false,
                leave: true,
                leaveValue:leaveType.value,
                lunch: false,
                leaveactivity:[leaveType],
                activityM:[],
                activityAf:[],
                activitylunch: [],
              };
            }
          }else{
            if (
              typeof schedule[date] === "object" &&
              schedule[date] !== null
            ) {
            schedule[date].holiday = false
            schedule[date].leave= false
            schedule[date].leaveValue= 0
            schedule[date].leaveactivity = []
            }
          }
    
  }

console.log({schedule,brandId,clinicId,userId,leaveId,status},"from add_leave");

    var resp =await updateScheduleData(brandId, clinicId, userId,schedule,dates[0],dates[dates.length-1])

}
 function calculateBucket( clinic,date)
{

   var morningtime = clinic.time_slot_morning[moment(date).format("dddd")]
   var afternoonTime = clinic.time_slot_afternoon[moment(date).format("dddd")]
   console.log("schedular -- ",morningtime,afternoonTime,moment(date).format("dddd"))
   var mrcloptime = morningtime.opening_time
   var mrclcltime = morningtime.closing_time
   var aftcloptime = afternoonTime.opening_time
   var aftclcltime = afternoonTime.closing_time

   const morningOpenTime = moment(mrcloptime, "HH:mm");
const morningCloseTime = moment(mrclcltime, "HH:mm");
const afternoonOpenTime = moment(aftcloptime, "HH:mm");
const afternoonCloseTime = moment(aftclcltime, "HH:mm");
var morningBucketDuration  = 0
var afternoonBucketDuration = 0
try{
  if(mrcloptime!=="")
  {  morningBucketDuration = moment.duration(morningCloseTime.diff(morningOpenTime)).hours();

   // Calculate duration for afternoon bucket
    afternoonBucketDuration = moment.duration(afternoonCloseTime.diff(afternoonOpenTime)).hours();
    // console.log("schedular time difference------",morningBucketDuration.hours(),afternoonBucketDuration.hours())
   }
   return {bucket1:morningBucketDuration,bucket2:afternoonBucketDuration}
}catch(error)
{
  console.log("schedular function-",error)
  return {bucket1:0,bucket2:0}

}

}
function checkactivityTime (act1st,act2st,act1cl,act2cl)
{
  act1st =moment(moment.unix(act1st.seconds).format('HH:mm'), 'HH:mm')
  act2st = moment(moment.unix(act2st.seconds).format('HH:mm'), 'HH:mm')
  act1cl =moment(moment.unix(act1cl.seconds).format('HH:mm'), 'HH:mm')
  act2cl = moment(moment.unix(act2cl.seconds).format('HH:mm'), 'HH:mm')

 
}
const checkcollision = (activity,oldlist)=>{
  try{
  var flag = false
  var collideactivity = []
 
  oldlist.map((data,index)=>{
    var newst =moment(moment.unix(activity.duration.startingTime.seconds).format('HH:mm'), 'HH:mm')
    var newet = moment(moment.unix(activity.duration.closingTime.seconds).format('HH:mm'), 'HH:mm')
    var extst =moment(moment.unix(data.duration.startingTime.seconds).format('HH:mm'), 'HH:mm')
    var extet = moment(moment.unix(data.duration.closingTime.seconds).format('HH:mm'), 'HH:mm')
    console.log(newst.isBefore(extet), newet.isAfter(extst),moment.unix(activity.duration.startingTime.seconds).format('HH:mm'),
    moment.unix(activity.duration.closingTime.seconds).format('HH:mm'),moment.unix(data.duration.startingTime.seconds).format('HH:mm'),
    moment(moment.unix(data.duration.closingTime.seconds).format('HH:mm')))
    if((newst.isBefore(extet) && newet.isAfter(extst)))
    {
      flag=true
      collideactivity.push(data)
    }

  })
  console.log("collided activity" ,collideactivity)

 return {status:!flag,collideActivity:collideactivity}

//  if( !flag &&oldlist.length<2)
//   {
//     return {status:false,collideActivity:[],message:"slot full"}
//   }else{
//   }
  }catch(error){
    console.log(error)
    return {status:false,collideActivity:[]}
  }

}
function activitycollision(activity, assignedActivity)
{
 // Parse start and end times of the selected activity
 var ac_st = moment(moment.unix(activity.duration.startingTime.seconds).format('HH:mm'), 'HH:mm');
 var ac_en = moment(moment.unix(activity.duration.closingTime.seconds).format('HH:mm'), 'HH:mm');

 // Initialize variables to track collision status, positions, and names of collided activities
 var collision = false;
 var pos = [];
 var count = 0;
 var names = "";

 // Iterate through each assigned activity
 assignedActivity.forEach((d, i) => {
   // console.log("activity timming ==",d.duration,activity.duration)
   // Parse start and end times of the current assigned activity
   var old_ac_st = moment(moment.unix(d.duration.startingTime.seconds).format('HH:mm'), 'HH:mm');
   var old_ac_en = moment(moment.unix(d.duration.closingTime.seconds).format('HH:mm'), 'HH:mm');
//     console.log(
//       "old_ac_st = ",old_ac_st.format("HH.mm"),
// "old_ac_en = ",old_ac_en.format("HH.mm"),
// "ac_st = ",ac_st.format("HH.mm"),
// "ac_en = ",ac_en.format("HH.mm"),
// old_ac_st.isBefore(ac_en) && old_ac_en.isAfter(ac_st)
//     )
   // Check if there is a collision
   if (old_ac_st.isBefore(ac_en) && old_ac_en.isAfter(ac_st)) {
     collision = true;
     count++;
     pos.push(d.id);
     names += d.activityName + " ";
     // console.log("inside ==",pos, d.activityName )
   }
   // console.log("outside",pos, d.activityName )
 });

 // Return the collision status, names of collided activities, count, and positions
 return { status: collision, collided: names.trim(), count: count, pos: pos };
}
function onCallActivityCollision(activity, assignedActivity,stDate) {
  // Parse start and end times of the selected activity

var date = moment(stDate,"YYYY-MM-DD"); 
console.log("activity start date " ,stDate,date.format("YYYY-MM-DD"))
  var endDate_moment = date.add(activity.duration.timeBreakup.length-1, 'days');
  var enddate = endDate_moment.format("YYYY-MM-DD")
  var ac_st = moment(stDate + " " + activity.start, 'YYYY-MM-DD HH:mm');
  var ac_en = moment(enddate + " " +activity.end, 'YYYY-MM-DD HH:mm');
console.log("length ==",activity.duration.timeBreakup.length-1)
  // Initialize variables to track collision status, positions, and names of collided activities
  var collision = false;
  var pos = [];
  var count = 0;
  var names = "";

  // Iterate through each assigned activity
  if(assignedActivity)
 { assignedActivity.forEach((d, i) => {
    
    var date = moment(d.startDate,"YYYY-MM-DD"); 
  var endDate_moment = date.add(d.duration.timeBreakup.length-1, 'days');
  console.log("length ==",d.duration.timeBreakup.length-1)

  var enddate = endDate_moment.format("YYYY-MM-DD")

   var old_ac_st = moment(d.startDate + " " + d.start, 'YYYY-MM-DD HH:mm');
    var old_ac_en = moment(enddate + " " +d.end, 'YYYY-MM-DD HH:mm');
  
    if (old_ac_st.isBefore(ac_en) && old_ac_en.isAfter(ac_st)) {
      collision = true;
      count++;
      pos.push(d.id);
      names += d.activityName + " ";
      console.log("inside ==",pos, d.activityName )
    }
    console.log("outside",pos, d.activityName )
  });}


  // Return the collision status, names of collided activities, count, and positions
  return { status: collision, collided: names.trim(), count: count, pos: pos };
}
const check_clinic_slot = (clinic, activity, date,holidayList) => {
  
  var activityDays = activity.duration.timeBreakup
if(activityDays?.length>1)
  {
    
    var noCollision = true
    let currentDate =moment(date);
    var day =currentDate.format("dddd");
    activityDays.map((ocDates,ind)=>{
    

      var afternoonTime = clinic.time_slot_afternoon[currentDate.format("dddd")];
      var morningTime = clinic.time_slot_morning[currentDate.format("dddd")];
    
      // Convert empty strings to null to represent closed times
      var convertTime = (time) => time === '' ? null : moment(time, 'HH:mm');
    
      var morningStart = convertTime(morningTime.opening_time);
      var morningEnd = convertTime(morningTime.closing_time);
      var afternoonStart = convertTime(afternoonTime.opening_time);
      var afternoonEnd = convertTime(afternoonTime.closing_time);
    
      var activityStart = moment(ocDates.startingTime, 'HH:mm');
      var activityEnd = moment(ocDates.closingTime, 'HH:mm');
    console.log("mor on call == ",ocDates)
      let isOutsideMorning = false;
      let isOutsideAfternoon = false;
      let fitsInBreak = false;
    
      if (!morningStart || !morningStart.isValid()) {
      
        isOutsideMorning = true;
      
      } else {
        isOutsideMorning = activityEnd.isSameOrBefore(morningStart) || activityStart.isSameOrAfter(morningEnd);
      
      }
    
      if (!afternoonStart || !afternoonStart.isValid()) {
        isOutsideAfternoon = true;
        // console.log("mor, aft ,date inside",isOutsideAfternoon,currentDate.format("dddd"))
      
      } else {
        isOutsideAfternoon = activityEnd.isSameOrBefore(afternoonStart) || activityStart.isSameOrAfter(afternoonEnd);
        console.log("mor, aft ,date inside",isOutsideAfternoon,currentDate.format("dddd"))
      
      }
    
      if (!afternoonStart && !morningStart) {
        fitsInBreak = true;
      } else if (morningEnd && afternoonStart) {
        fitsInBreak = activityStart.isSameOrAfter(morningEnd) && activityEnd.isSameOrBefore(afternoonStart);
      } 
      // console.log("mor, aft ,date outside",isOutsideMorning,isOutsideAfternoon,currentDate.format("dddd"),activityStart.format("HH:mm"),activityEnd.format("HH:mm"),afternoonStart.format("HH:mm"),afternoonEnd.format("HH:mm"))
        if(!holidayList.includes(currentDate.format("YYYY-MM-DD")))
     { if(!(isOutsideMorning && isOutsideAfternoon))
        {
          console.log("mor, aft ,date inside",isOutsideMorning,isOutsideAfternoon,currentDate.format("dddd"))
          noCollision =  false
        }}
      currentDate.add(1, 'day');
    })
    return noCollision
  }else
{  
  const day = moment(date).format("dddd");

  const afternoonTime = clinic.time_slot_afternoon[day];
  const morningTime = clinic.time_slot_morning[day];

  // Convert empty strings to null to represent closed times
  const convertTime = (time) => time === '' ? null : moment(time, 'HH:mm');

  const morningStart = convertTime(morningTime.opening_time);
  const morningEnd = convertTime(morningTime.closing_time);
  const afternoonStart = convertTime(afternoonTime.opening_time);
  const afternoonEnd = convertTime(afternoonTime.closing_time);

  const activityStart = moment(moment.unix(activity.duration.startingTime.seconds).format('HH:mm'), 'HH:mm');
  const activityEnd = moment(moment.unix(activity.duration.closingTime.seconds).format('HH:mm'), 'HH:mm');

  let isOutsideMorning = false;
  let isOutsideAfternoon = false;
  let fitsInBreak = false;

  if (!morningStart || !morningStart.isValid()) {
    isOutsideMorning = true;
  } else {
    isOutsideMorning = activityEnd.isSameOrBefore(morningStart) || activityStart.isSameOrAfter(morningEnd);
  }

  if (!afternoonStart || !afternoonStart.isValid()) {
    isOutsideAfternoon = true;
  } else {
    isOutsideAfternoon = activityEnd.isSameOrBefore(afternoonStart) || activityStart.isSameOrAfter(afternoonEnd);
  }
  if (!afternoonStart && !morningStart) {
    fitsInBreak = true;
  } else if (morningEnd && afternoonStart) {
    fitsInBreak = activityStart.isSameOrAfter(morningEnd) && activityEnd.isSameOrBefore(afternoonStart);
  }
  console.log("collision    ", isOutsideMorning, isOutsideAfternoon, fitsInBreak);
  return (isOutsideMorning && isOutsideAfternoon) || fitsInBreak;
}};

async function remove_schedule_task(brandId, clinicId, userId,activity,schedule,stdate,flag)
{

  try

  {
  var repeatid = activity.repeatId
  var repeat = activity.repeat
  var time = activity.repeatTime
  var mode = activity.repeatmode
  var dates = [];
  let currentDate = moment(stdate);
  // moment.unix(activity.duration.startingTime.seconds)
  const finalDate = moment.unix(time.endDate.seconds);
console.log(mode)
if(flag)
{
  switch(mode.code)
  {   
   case 0:

   
    while (currentDate <= finalDate) {
         dates.push(currentDate.format('YYYY-MM-DD'));
         currentDate = currentDate.clone().add(1, 'days');
     }
    console.log("afterloop",dates,finalDate)
     break;
     case 1:
  while (currentDate <= finalDate) {
       dates.push(currentDate.format('YYYY-MM-DD'));
       currentDate = currentDate.clone().add(7, 'days'); // Add 7 days for weekly repeat
   }
  
     break;
     case 2:
      while (currentDate <= finalDate) {
           dates.push(currentDate.format('YYYY-MM-DD'));
           currentDate = currentDate.clone().add(1, 'month'); // Add 1 month for monthly repeat
       }
    
     break;
     case 3:
      
            dates.push(currentDate.format('YYYY-MM-DD'));
             // Add 1 month for monthly repeat
       
      break;
   }
}else{
  dates.push(currentDate.format('YYYY-MM-DD'));
}
console.log("dates ",dates)
dates.map((data)=>{
  var temp = schedule[data]

  if(temp!==undefined && typeof temp=='object')
{  var indexToRemove = temp.activityM.findIndex(obj => obj.repeatId === repeatid);
  console.log("object removed ",indexToRemove,temp.activityM[indexToRemove])
  if (indexToRemove !== -1) {
    temp.activityM.splice(indexToRemove, 1);
    CounterUpdate(process.env.REACT_APP_ACTIVITY,-1,activity.id)
    temp.bucket1 =parseFloat(temp.bucket1)+parseFloat(activity.duration.durationHours )
  }

  var indexToRemove = temp.activityAf.findIndex(obj => obj.repeatId === repeatid);
  // console.log("object removed ",indexToRemove.temp.activityM[indexToRemove])
  if (indexToRemove !== -1) {
    temp.activityAf.splice(indexToRemove, 1)
    console.log(activity.duration.durationHours)
    temp.bucket2 =parseFloat( temp.bucket2)+parseFloat(activity.duration.durationHours) ;
  }
  var indexToRemove = temp.activitySP.findIndex(obj => obj.repeatId === repeatid);
  // console.log("object removed ",indexToRemove.temp.activityM[indexToRemove])
  if (indexToRemove !== -1) {
  
    var activity_rem = temp.activitySP[indexToRemove]
    var startDate  = activity_rem.startDate
    if(startDate!==undefined)
   { 
    let currentDate = moment(startDate,"YYYY-MM-DD");
    var activityDays = activity_rem.duration.timeBreakup?activity_rem.duration.timeBreakup:[{
      startingTime:moment.unix(activity_rem.duration.startingTime.seconds).format('HH:mm'),
      closingTime:moment.unix(activity_rem.duration.closingTime.seconds).format('HH:mm'),
      durationHours:calculateTime(moment(moment.unix(activity_rem.duration.startingTime.seconds).format('HH:mm'), 'HH:mm'),moment(moment.unix(activity_rem.duration.closingTime.seconds).format('HH:mm'), 'HH:mm'))
    }]

    activityDays.map((ocDates,ind)=>{
      var temp_oc = schedule[currentDate.format("YYYY-MM-DD")] 
      var indexToRemove = temp_oc.activitySP.findIndex(obj => obj.repeatId === repeatid);
      // console.log("object removed ",indexToRemove.temp.activityM[indexToRemove])
      if (indexToRemove !== -1) {
        temp_oc.activitySP.splice(indexToRemove, 1)
        temp_oc.specialActivity =temp_oc.activitySP.length>0?true:false ;
      }
      schedule[currentDate.format("YYYY-MM-DD")] = temp_oc;
      currentDate.add(1, 'day');
    })
   
      
  }else{
         temp.activitySP.splice(indexToRemove, 1)
    temp.specialActivity =temp.activitySP.length>0?true:false ;
 
    }
  }

  schedule[data] = temp
}else{
  console.log("i skip -----",data)
}

})
console.log("after remove ",schedule)
// function call to updte database 
// return false
var resp =await updateScheduleData(brandId, clinicId, userId,schedule,dates[0],
  dates[dates.length-1])
  

return true
}catch(error){
  console.log("remove error",error)
  return false
}
}

const removeOC_ACtivity = (id,schedule,temp)=>{
  var indexToRemove = temp.activitySP.findIndex(obj => obj.id === id);
  // console.log("object removed ",indexToRemove.temp.activityM[indexToRemove])
  if (indexToRemove !== -1) {
  
    var activity_rem = temp.activitySP[indexToRemove]
    var startDate  = activity_rem.startDate
    if(startDate!==undefined)
   { 
    let currentDate = moment(startDate,"YYYY-MM-DD");
    var activityDays = activity_rem.duration.timeBreakup?activity_rem.duration.timeBreakup:[{
      startingTime:moment.unix(activity_rem.duration.startingTime.seconds).format('HH:mm'),
      closingTime:moment.unix(activity_rem.duration.closingTime.seconds).format('HH:mm'),
      durationHours:calculateTime(moment(moment.unix(activity_rem.duration.startingTime.seconds).format('HH:mm'), 'HH:mm'),moment(moment.unix(activity_rem.duration.closingTime.seconds).format('HH:mm'), 'HH:mm'))
    }]

    activityDays.map((ocDates,ind)=>{
      var temp_oc = schedule[currentDate.format("YYYY-MM-DD")] 
      var indexToRemove = temp_oc.activitySP.findIndex(obj => obj.id === id);
      // console.log("object removed ",indexToRemove.temp.activityM[indexToRemove])
      if (indexToRemove !== -1) {
        var deltemp  =  temp_oc.activitySP.find(obj => obj.id === id);
        temp_oc.activitySP.splice(indexToRemove, 1)
        if(deltemp){
          CounterUpdate(process.env.REACT_APP_ACTIVITY,-1,deltemp.id)}
        temp_oc.specialActivity =temp_oc.activitySP.length>0?true:false ;
      }
      schedule[currentDate.format("YYYY-MM-DD")] = temp_oc;
      currentDate.add(1, 'day');
    })
   
      
  }else{
         temp.activitySP.splice(indexToRemove, 1)
    temp.specialActivity =temp.activitySP.length>0?true:false ;
 
    }

  }
}
async function checkDates(startdate, enddate, brandid, clinicid, userid, LeaveId) {
  try {
    let currentDate = moment(new Date(startdate?.$d));
    const finalDate = moment(new Date(enddate?.$d));
    let datelist = [];
    while (currentDate <= finalDate) {
      datelist.push(currentDate.format("YYYY-MM-DD"));
      currentDate = currentDate.clone().add(1, "days");
    }

    var branddetails = await get_customer(brandid);
    var clinicdetails = await get_clinic_id(clinicid);
    var holidaylist = branddetails.holidayList;
    var clinicmeta = clinicdetails.metadata.daysoperational;
    var get_leave = await get_staff_leave(userid, clinicid);
    var applied_leave = get_leave;

    var temp = [...datelist];
    let response = [];
    let removedDates = {
      ClinicClosed: [],
      BankHolidays: [],
      AppliedLeaves: [],
    };

    if (temp.length > 0) {
      temp.forEach((data, index) => {
        let day = moment(data);
        let clinicopen = clinicmeta.find(item => item.name == day.format("dddd"));
        let isHoliday = holidaylist.some(item => moment(item).isSame(day));
        let isAppliedLeave = applied_leave.some(
          leave => leave.dates.includes(day.format("YYYY-MM-DD")) && leave.id !== LeaveId && leave.status !== false
        );

        let obj = {
          date: data,
          isRemoved: false,
          removeReason: "",
          isMorningAvailable: true,
          isAfternoonAvailable: true,
        };

        if (!clinicopen.enabled) {
          obj.isRemoved = true;
          obj.removeReason = "Clinic is closed";
        } else if (isHoliday) {
          obj.isRemoved = true;
          obj.removeReason = "Holiday";
        } else if (isAppliedLeave) {
          obj.isRemoved = true;
          obj.removeReason = "Leave_Applied";
        } else {
          obj.isMorningAvailable = clinicopen.morning;
          obj.isAfternoonAvailable = clinicopen.afternoon;
        }

        response.push(obj);
      });
    } else {
      return [];
    }
    return response;
  } catch (error) {
    console.log(error);
    return [];
  }
}
function generateYears(startYear, endYear) {
  const years = [];
  for (let year = startYear; year <= endYear; year++) {
    years.push(year);
  }
  return years;
}
function createYearDropdown(startYear = 2020, endYear = 2040) {
  const years = generateYears(startYear, endYear);
  const dropdown = [];

  // Add an option for each year
  years.forEach((year) => {
    const option = document.createElement("option");
    option.value = year;
    option.label = year;
    dropdown.push(option);
  });

  return dropdown;
}
const delete_user_activities = async (brandId, clinicId, userId,fromdate,todate,deletetype="all")=>{

  try {
    var currentSchedule = await get_shedule(
      brandId,
      clinicId,
      userId,
      moment(fromdate).format("YYYY-MM-DD"),
      deletetype=="all"?moment(fromdate).format("YYYY-MM-DD"):moment(todate).format("YYYY-MM-DD")
    );
    const currentDate = moment(moment(fromdate).format("YYYY-MM-DD"));
if(deletetype=="all")
{const filteredRecords = Object.entries(currentSchedule)
    .filter(([date]) => moment(date).isBefore(currentDate))
    .reduce((acc, [date, data]) => {
        acc[date] = data;
        return acc;
    }, {});
    
    console.log("data base ",brandId,clinicId,userId,filteredRecords)

    var resp =await updateWithoutMerge(brandId, clinicId, userId,filteredRecords,moment(fromdate).format("YYYY-MM-DD"),moment(fromdate).format("YYYY-MM-DD"))
    let data = JSON.stringify({
      "brandId":brandId,
      "clinicId":clinicId,
      "userId": userId,
      "date": moment(fromdate).format("YYYY-MM-DD")
    });
    
    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url:[process.env.REACT_APP_DELETE_SCHEDULE],
      headers: { 
        'Content-Type': 'application/json'
      },
      data : data
    };
    
   var response = await axios.request(config)
    var res = response.data
    console.log("delete api =",response)
        if(res.status)
        {
          //delete succcesss 
        }else{
          // delete failed 
        }
}else{
  const endDate = moment(todate).format("YYYY-MM-DD")
  console.log("sche- ",currentSchedule,endDate,currentDate.format("YYYY-MM-DD"))
  const filteredRecords = Object.entries(currentSchedule)
  .filter(([date]) => (moment(date).isBefore(currentDate) ||moment(date).isAfter(endDate) ))
  .reduce((acc, [date, data]) => {
      acc[date] = data;
      return acc;
  }, {});
  var resp =await updateWithoutMerge(brandId, clinicId, userId,filteredRecords,moment(fromdate).format("YYYY-MM-DD"),moment(todate).format("YYYY-MM-DD"))
  
}


      return true
        //console.log(`Schedule data updated successfully for user ${userId} in clinic ${clinicId} of brand ${brandId}.`);

    } catch (error) {

        console.error("Error updating schedule data:", error);
return false
    }

}
const repeatDropdownOptions = [{
value:0,
label:"Daily",
code:0
},
{
value:1,
label:"Weekly",
code:1
},
{
value:2,
label:"Monthly",
code:2
},
{
  code:3,
  label:"No repeat",
  value:3
  },
  {
    code:4,
    label:"Custom",
    value:4
    },

]
const calenderMode = [{
  value:0,
  label:"Day",
  code:0
  },
  {
  value:1,
  label:"Weekly",
  code:1
  },
  {
  value:2,
  label:"Monthly",
  code:2
  }
  
  ]
  function ShowassignDate(date)
  {
    var text = ""
    try{

   
    if(date.length)
   { date.map((data)=>{
  text+=moment(data).format("YYYY-MM-DD")+","

  })
  }
  else
  {
    var text =moment(date).format("YYYY-MM-DD")}
    return text
  }catch(error){
    return ""
  }
    
  }
  const get_pending_leave_date = (leave)=>{
    var list = []
    try{

      leave.map((data,index)=>{
        list = [...list,...data.dates]
      })
      let uniquedates = new Set(list)
      return [...uniquedates]
    }catch(error)
    {
      console.log(error)
      return []
    }
   
  }
  export {getDateRangeArray,getDatesForWeek,getweekdates,repeatDropdownOptions,generateWeekNumbersArray,generateMonthsArray,
    calenderMode,generateDatesOfMonth,
    calculate_schedularview,getDates,compareTimes,
    add_leave_schedule,
    detectactivitySlot,
    createYearDropdown,
    createFirebaseTimestamp,
    remove_schedule_date,
    calculateBucket,
    ShowassignDate,
    checkcollision,
    remove_schedule_task,add_leave,
    checkDates,
    generateYeararray,
    get_pending_leave_date,
    onCallActivityCollision,
    removeOC_ACtivity,
    activitycollision,
    delete_user_activities,
    check_clinic_slot
  }