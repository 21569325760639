
import PropTypes from "prop-types";

// @mui material components
import Link from "@mui/material/Link";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React base styles
import typography from "assets/theme/base/typography";
import { useTranslation } from "react-i18next";
import policy from "../../assets/docs/legals.pdf"
function Footer() {
  const {t} = useTranslation()
  const  href = "https://phygitix.ai/";
  const { size } = typography;

  return (
    <MDBox
      width="100%"
      display="flex"
      className="footer-main"
      flexDirection={{ xs: "column", lg: "row" }}
      justifyContent="space-between"
      alignItems="center"
      px={1.5}
    >
      <MDBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexWrap="wrap"
        color="text"
        fontSize={size.sm}
        px={1.5}
      >
          <a href={policy}>
          {t("Legal")}
          </a>
          &nbsp;
        &copy; {new Date().getFullYear()}, {t("developed_by")}
        <Link href={href} target="_blank">
          <MDTypography variant="button" fontWeight="medium">
            &nbsp;Phygitix&nbsp;
          </MDTypography>
        </Link>
        {t("for_better")}
      </MDBox>
    
    </MDBox>
  );
}


export default Footer;
