import React from 'react';
import Box from '@mui/material/Box';
import MDButton from "components/MDButton";
import Modal from "@mui/material/Modal";
import { useTranslation } from "react-i18next";
export const DeleteModal = ({open,onChange,message}) => {
  const { t } = useTranslation();
  return (
    <Modal
    open={open}
    aria-labelledby="modal-modal-title"
    className="cs-modal xs info"
    aria-describedby="modal-modal-description"
  >
    <Box className="modal-dialog">
      <Box className="modal-content">
        <Box className="modal-body" mt={3}>
          <h2>{t("Are you sure?")}</h2>
          <p>{message?t(message):t("Do you really want to delete these record?")}</p>
          <Box mt={2}>
            <MDButton
              className="secondary-btn"
              onClick = {(e)=>onChange(true)}
              >
              {t("Delete")}
            </MDButton>
            <MDButton
              className="secondary-btn white"
              onClick = {(e)=>onChange(false)}
              >
              {t("Cancel")}
            </MDButton>
            
          </Box>
        </Box>
      </Box>
    </Box>
  </Modal>
  );
};

export default DeleteModal;
