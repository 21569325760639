import { useEffect, useState } from "react";

// react-router-dom components
import { Link, useLocation, useNavigate } from "react-router-dom";

// @mui material components
import Tailor from "../../assets/images/tailor.png";

// @mui icons

import Typography from "@material-ui/core/Typography";
// Material Dashboard 2 React components
import MDButton from "components/MDButton";

// Authentication layout component
import Modal from "@mui/material/Modal";

import { UseTranslation, useTranslation } from "react-i18next";

import dataPolicy from '../../assets/docs/privacy policy_v2.pdf'
import privacyPolicy from '../../assets/docs/privacy policy.pdf'

// Images
import {
  Alert,
  Box,
  IconButton,
  InputAdornment,
  Snackbar,
  TextField,
  createSvgIcon,
  useMediaQuery,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { sendPasswordResetEmail, signInWithEmailAndPassword  } from "firebase/auth";
import { auth } from "backendservice/firebase";
import { db } from "backendservice/firebase";
import {
  getDocs,
  collection,
  doc,
  getDoc,
  Timestamp,
  addDoc,
  setDoc,
  updateDoc
} from "firebase/firestore";
import { loginsessionref } from "backendservice/firebase";
import { userroles } from "backendservice/firebase";

// import { messaging } from "backendservice/firebase";
// import { getToken } from "firebase/messaging";

import { useMaterialUIController, setLayout } from "context";
import Loader from "components/Loader";
import axios from "axios";
import { get_user_details } from "backendservice/firebasefunctions,";
import { create_login_session } from "backendservice/firebasefunctions,";
import { add_norms, check_norms } from "./functions";
import { setSelecetdCustomerrec } from "context";
import { setCustomerUpdate,setPermission } from "context";
import { get_customer } from "backendservice/firebasefunctions,";
import { create_user } from "backendservice/firebasefunctions,";
import { get_role } from "backendservice/firebasefunctions,";
import { get_user } from "backendservice/firebasefunctions,";
// import tailorplanpolicy from "./docs/tailorplanpersonaldata.pdf"
function SignIn() {
  const {t} = useTranslation()
  const matches = useMediaQuery("(min-width:1024px)");
  const [loading, setLoading] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const [username, setusername] = useState("");
  const [passwords, setpassword] = useState("");
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [type, setType] = useState("success");
  const [country, setcountry] = useState("");
  const [state, setstate] = useState("");
  const [city, setcity] = useState("");
  const [modalopen, setmodalopen] = useState(false);
  const [modalopensecond, setModalopensecond] = useState(false)
  const [, dispatch] = useMaterialUIController();
  const { pathname } = useLocation();
  const [error, seterror] = useState();
  const [isOnline, setIsOnline] = useState(true);
  const [norms,setNorms] = useState({})
  const [userID,setUserID]= useState("")
  //modal open close functionn
  const handlemodalopengdpr = () => {
    setmodalopen(true);
  };
  const handlemodalopencookies = () => {
    console.log("akskdskdkadkjsbk")
    setModalopensecond(true);
  };
  const handlemodalClose = (data) => {
    console.log(data)
    if(data =="gdpr")
    {
    console.log("1")
      var temp = norms
      temp.gdpr=false
      // add_norms(temp)
      setNorms(prevState => ({
        ...prevState, // Spread the previous state to retain the unchanged properties
        gdpr:false,
        // Update the phone property with the new value
      }))
      setmodalopen(false)
      setMessage(
        t("Dear user , please accept the GDPR norms to login")    );
        setType("error");
        setOpen(true);
    }else if(data=="cookies"){
      console.log("2")
      setNorms(prevState => ({
        ...prevState, // Spread the previous state to retain the unchanged properties
        cookies:false,
        uid:userID
      }))
        var temp = norms
        temp.cookies=false
        add_norms(temp)
        handleLogin()
        setModalopensecond(false)
        // Update the phone property with the new value
   
    }else if(data=="gdprAccept"){
      // console.log("3")
      handleLogin()
      setmodalopen(false)
    }else{
      handleLogin()
      setModalopensecond(false)
    
    
    }
 };


  useEffect(() => {
    setLayout(dispatch, "page");
    getCountryName()
    const handleOnline = () => {
      setIsOnline(true);

      setOpen(true)
      setMessage("Online")
      setType("success")

      setTimeout(() => {
        getCountryName()
      }, 1000);
    };
    const handleOffline = () => {
      setIsOnline(false); console.log("offline"); setOpen(true)
      setMessage(t("Please check your connectivity"))
      setType("error")
    };

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };

  }, [pathname]);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);
  async function getCountryName() {
    try {
      const response = await fetch(process.env.REACT_APP_IP_API);
      const resData = await response.json();

      console.log(resData);
      setcountry(resData.country);
      setstate(resData.regionName);
      setcity(resData.city);
      seterror(false)
    } catch (error) {
      console.log("error ", error)
      if (error.code) {
        setOpen(true)
        setMessage(t("Network connection error"))
        setType("error")
        seterror(true)

      }
    }


    // console.log(country);
    return country;
  }
  //   async function gettokens(){

  //     try {
  //         // const messaging = firebase.messaging(); // Assuming you've set up Firebase and imported the necessary libraries
  //     // getToken
  //         const token = await getToken(messaging);
  //         console.log("token==>",token);
  //         // console.log(token);
  //       } catch (error) {
  //         console.error("Error getting token:", error);
  //       }
  //   }

  const navigate = useNavigate();

  const handleLogin = async () => {
  
    setLoading(true);
    var date = new Date();
    // console.log(Timestamp.fromDate(new Date()))
    // return true
    if (!isOnline) {
      setOpen(true)
      setMessage(t("SPlease check your connectivity"))
      setType("error")
    } else {
      if (
        passwords == undefined ||
        passwords == "" ||
        username == undefined ||
        username == ""
      ) {
        setMessage(t("Please enter your credentials !"));
        setType("error");
        setOpen(true);
      } else {
        //firebase call
        // console.log("usernam ",username,passwords)
        try {
          var response = await signInWithEmailAndPassword(
            auth,
            username,
            passwords
          );
          console.log("authentication response ==> ", response, response.user.auth.currentUser.uid)
          if (
            response.user.auth.currentUser.uid != null ||
            response.user.auth.currentUser.uid != undefined ||
            response.user.auth.currentUser.uid != ""
          ) {
      
            var userdata = await get_user_details(response.user.auth.currentUser.uid)
            console.log("userdata",userdata)
            setUserID(userdata.id)
            if (userdata.active == true) {
              // try {
                var terms_condition =await  check_norms(userdata.id)
                console.log("terms_condition",terms_condition)
                if(terms_condition.gdpr) 
           {   const logindata = {
                user: userdata.id,
                country: country,
                state: state,
                city: city,
                customer: userdata.customer,
                createdAt: Timestamp.fromDate(new Date()),
              };

              const loginsession = await create_login_session( logindata);

              var rolesdata = userdata["group"];
                var roles = await get_role( userdata["group"])
                setPermission(dispatch,roles)
                rolesdata = roles.role;

              if(roles.active)
             { var customerdata = get_customer(userdata["customer"])
              var  selecetd = {label:customerdata.company,id:userdata["customer"],image:customerdata.logo}
              setSelecetdCustomerrec(dispatch,selecetd)
              setCustomerUpdate(dispatch,false)
              
              let userObject = {
                role: rolesdata,
                customer: userdata["customer"],
                name: `${userdata["name"]} ${userdata["surname"]} `,
                roleAccess: roles,
                uid:userdata["id"],
                clinic:userdata["defaultClinicId"]==undefined?"":userdata["defaultClinicId"],
                // customerupdate:true,
                roleCode:roles.roleCode,
                userEmail:username
              };

              console.log(response)
              localStorage.setItem("user", JSON.stringify(userObject));
              sessionStorage.setItem("resFlg",response.user.accessToken);
              setMessage(`Login Was successfull, Welcome  ${userdata.fullname}`);
              setType("success");
              setOpen(true);
              setLoading(false);
              if(roles.roleCode=="IA_00")
              {
                navigate("/customer");
              }else{
                navigate("/scheduler");
              }
            }else{
              setMessage(t("your role has been revoked , please contact administrator"));
              setType("error");
              setOpen(true);
              setLoading(false);
              }
            
        
            }else{
              
              terms_condition.uid = userdata.id
              console.log(terms_condition)
                setNorms(terms_condition)
                handlemodalopengdpr()
                // if(!terms_condition.gdpr)
                // {
                //   handlemodalopengdpr()
                // }else if(!terms_condition.cookies){
                //   handlemodalopencookies()
                // }

                

              }
         
            } else {
              setLoading(false);
              setMessage(
                t(`your accont is inactive please contact your administrator`)
              );
              setType("error");
              setOpen(true);
            }
          }
        } catch (error) {
          console.log("error ==>", error);
          setMessage(t("Login Failed ! Invalid credentials"));
          setType("error");
          setLoading(false);
          setOpen(true);
          // setMessage(error)
          // setType("error")
          // setOpen(true)
        }
      }
    }

    setLoading(false);
    // navigate("/dashboard")
  };

  const handleResetPassword = async () => {
    try {
      const user = await get_user(username);
      if (user.length == 0) {
        setMessage(t("Email does not exist."));
        setType("error");
        setOpen(true);
        return;
      }
      await sendPasswordResetEmail(auth, username); // Assuming username is the user's email
      setMessage(t("Password reset email sent!"));
      setType("success");
      setOpen(true);
    } catch (error) {
      console.error("Error sending password reset email:", error.message);
      setMessage(t("Error sending password reset email."));
      setType("error");
      setOpen(true);
    }
  };
  

  const handlepassword = (e) => {
    e.preventDefault();
    setpassword(e.target.value);
  };
  return (
    <div className="login-main form-box ">
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        autoHideDuration={4000}
        onClose={() => setOpen(false)}
      >
        <Alert
          onClose={() => setOpen(false)}
          severity={type}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
      <div className="main-content">
        <Loader loading={loading} />

        <div className="main-section left">
          <div className="mid-section">
            <div className="content-section">
              <Box className="brand">
                <Box>
                  <img src={Tailor} />
                  <Box className="svg">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="124"
                      height="11"
                      viewBox="0 0 124 11"
                      fill="none"
                    >
                      <path
                        d="M123.549 2.9502V3.76436H118.72V6.46692H123.04V7.28108H118.72V10.0515H123.605V10.8656H117.827V2.9502H123.549Z"
                        fill="black"
                      />
                      <path
                        d="M111.568 3.77566V10.8656H110.663V3.77566H108.006V2.9502H114.225V3.77566H111.568Z"
                        fill="black"
                      />
                      <path
                        d="M103.54 9.29386V2.9502H104.411V10.8656H103.699L98.5875 4.37498V10.8656H97.7168V2.9502H98.5536L103.54 9.29386Z"
                        fill="black"
                      />
                      <path
                        d="M90.6266 2.89355L94.2338 10.8655H93.2726L92.3454 8.77361H88.0371L87.0986 10.8655H86.1826L89.7898 2.89355H90.6266ZM91.9948 7.97075L90.1969 3.93387L88.3876 7.97075H91.9948Z"
                        fill="black"
                      />
                      <path
                        d="M81.8274 9.29386V2.9502H82.6981V10.8656H81.9857L76.8746 4.37498V10.8656H76.0039V2.9502H76.8407L81.8274 9.29386Z"
                        fill="black"
                      />
                      <path
                        d="M72.1968 6.89656C72.1968 9.09028 70.5572 11.0013 68.1486 11.0013C65.7401 11.0013 64.123 9.11289 64.123 6.91918C64.123 4.72547 65.7627 2.81445 68.1712 2.81445C70.5798 2.81445 72.1968 4.70285 72.1968 6.89656ZM71.2696 6.91918C71.2696 5.10993 69.9579 3.63992 68.1486 3.63992C66.3394 3.63992 65.0503 5.08732 65.0503 6.89656C65.0503 8.70581 66.362 10.1758 68.1712 10.1758C69.9805 10.1758 71.2696 8.72843 71.2696 6.91918Z"
                        fill="black"
                      />
                      <path
                        d="M56.0097 4.92983C56.0097 5.68745 56.4168 6.10584 58.1582 6.479C59.9222 6.86346 60.7364 7.50801 60.7364 8.72925C60.7364 10.0975 59.6056 10.9795 58.0338 10.9795C56.7787 10.9795 55.7497 10.5611 54.7998 9.71303L55.3539 9.05718C56.1794 9.80349 56.9709 10.1766 58.0678 10.1766C59.1307 10.1766 59.8318 9.62256 59.8318 8.81971C59.8318 8.0734 59.436 7.65501 57.7738 7.30447C55.9532 6.90869 55.1164 6.32069 55.1164 5.00899C55.1164 3.74251 56.2133 2.83789 57.7172 2.83789C58.8706 2.83789 59.6961 3.16582 60.4989 3.81036L59.9788 4.50014C59.2438 3.90082 58.5088 3.64074 57.6946 3.64074C56.6656 3.64074 56.0097 4.20613 56.0097 4.92983Z"
                        fill="black"
                      />
                      <path
                        d="M51.544 2.95009V3.76425H46.7156V6.46681H51.0352V7.28097H46.7156V10.0514H51.6005V10.8655H45.8223V2.95009H51.544ZM48.7623 2.249H48.0612L49.1354 0.609375L50.0401 1.02776L48.7623 2.249Z"
                        fill="black"
                      />
                      <path
                        d="M42.2757 10.8656H41.1789L38.8834 7.78993H36.6218V10.8656H35.7285V2.9502H39.1322C40.8736 2.9502 41.993 3.88874 41.993 5.31352C41.993 6.63654 41.0884 7.40547 39.8446 7.63162L42.2757 10.8656ZM41.0884 5.34745C41.0884 4.36367 40.3421 3.77566 39.0643 3.77566H36.6218V6.98708H39.053C40.2403 6.98708 41.0884 6.37646 41.0884 5.34745Z"
                        fill="black"
                      />
                      <path
                        d="M26.8685 10.8656H25.7716L23.4762 7.78993H21.2146V10.8656H20.3213V2.9502H23.7249C25.4663 2.9502 26.5858 3.88874 26.5858 5.31352C26.5858 6.63654 25.6812 7.40547 24.4373 7.63162L26.8685 10.8656ZM25.6812 5.34745C25.6812 4.36367 24.9349 3.77566 23.6571 3.77566H21.2146V6.98708H23.6458C24.8331 6.98708 25.6812 6.37646 25.6812 5.34745Z"
                        fill="black"
                      />
                      <path
                        d="M13.2291 2.89355L16.8363 10.8655H15.8751L14.9479 8.77361H10.6396L9.70109 10.8655H8.78516L12.3923 2.89355H13.2291ZM14.5974 7.97075L12.7994 3.93387L10.9902 7.97075H14.5974Z"
                        fill="black"
                      />
                      <path
                        d="M6.71044 5.47183C6.71044 7.19062 5.28566 8.07262 3.6008 8.07262H1.67847V10.8656H0.785156V2.9502H3.7478C5.53443 2.9502 6.71044 3.90005 6.71044 5.47183ZM5.80581 5.50576C5.80581 4.36367 4.95773 3.77566 3.67995 3.77566H1.67847V7.25846H3.63472C4.94642 7.25846 5.80581 6.55738 5.80581 5.50576Z"
                        fill="black"
                      />
                    </svg>
                  </Box>
                </Box>
              </Box>
              <h4>
                Le Planning de votre équipe pensé et créé pour vous
              </h4>

              <div className="login-btn">
                <button className="btn-login" 
                
                // onClick={() => handlemodalopen()}
                >
                  
                    {t("Know more")} &nbsp; 
                   
                  
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="8"
                    height="13"
                    viewBox="0 0 8 13"
                    fill="none"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M0.855431 12.8536C0.660169 12.6583 0.660169 12.3417 0.855431 12.1464L6.50188 6.5L0.855431 0.853554C0.660169 0.658291 0.660169 0.341709 0.855431 0.146446C1.05069 -0.0488157 1.36728 -0.0488157 1.56254 0.146446L7.56254 6.14645C7.7578 6.34171 7.7578 6.65829 7.56254 6.85355L1.56254 12.8536C1.36728 13.0488 1.05069 13.0488 0.855431 12.8536Z"
                      fill="#16273C"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="main-section right">
          <div className="mid-section">
            <form className="content-section" onSubmit={(e) => {e.preventDefault();handleLogin()}}>
              <h3>{t("Welcome!")}</h3>
              <h2>{t("Log In.")}</h2>
              <p>
                {t("Log in with your data that you entered during your registration")}
              </p>
            <div className="login-form">
                <div>
                  <label>{t("Enter your email address")}</label>
                  <TextField
                    id="username"
                    // label="Username"
                    className="form-label"
                    // color="info"
                    value={username}
                    onChange={(e) => setusername(e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {/* <img src={userIcon} /> */}
                        </InputAdornment>
                      ),
                    }}
                    variant="standard"
                  ></TextField>
                </div>
                <div>
                  <label>{t("Enter your password")}</label>
                  <TextField
                    id="password"
                    // label="Password"
                    className="form-label"
                    // color="info"
                    defaultValue={passwords}
                    onChange={(e) => handlepassword(e)}
                    type={showPassword ? "text" : "password"}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {/* <img src={passicon} /> */}
                        </InputAdornment>
                      ),

                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                          // onMouseDown={handleMouseDownPassword}
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    variant="standard"
                  ></TextField>
                </div>
                <div className="forgot-pwd" style={{ cursor: 'pointer' }} onClick={handleResetPassword}>{t("Forgot your password?")}</div>
                <div className="login-btn">
                  <button className="btn-login" type = "submit">
                    {t("Login")}
                  </button>
                   {/* <button className="btn-login" onClick={() => createuser()}>
               register
                </button> */}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* Modal IA - LO - 2 */}
      <Modal
        open={modalopen}
        onClose={(e)=>handlemodalClose("gdpr")}
        aria-labelledby="modal-modal-title"
        className="cs-modal modal-main"
        aria-describedby="modal-modal-description"
      >
        <Box className="modal-dialog">
          <Box className="modal-content">

            <Box className="modal-heading">
              <Typography variant="h2" component="h2">
              {t("GDPR_Heading")}
              </Typography>
            </Box>
            <Box className="modal-body">
              <p>{t("GDPR1")}
              <a href={dataPolicy}>
              {t("termOfService")} 
              </a>
              {t("GDPR2")}  
              <a href={privacyPolicy}>
              {t("privacyPolicy")} 
              </a>
              {t("GDPR3")} 
              </p>
            </Box>
            <Box className="modal-footer">
              <MDButton
                className="secondary-btn2 light"
                sx={{
                  padding: "11px 30px",
                  fontSize: "16px",
                  fontWeight: "normal",
                  minWidth: "160px",
                }}
                color="dark"
                onClick={() => handlemodalClose("gdpr")}
              >
               {t("Learn More")}
              </MDButton>
              {/* <MDButton
                className="secondary-btn2 light"
                sx={{
                  padding: "11px 30px",
                  fontSize: "16px",
                  fontWeight: "normal",
                  minWidth: "160px",
                }}
                color="dark"
                onClick={() => handlemodalClose("gdpr")}
              >
                {t("Refuse")}
              </MDButton> */}
              <MDButton
                className="secondary-btn2"
                sx={{
                  padding: "11px 30px",
                  fontSize: "16px",
                  fontWeight: "normal",
                  minWidth: "160px",
                }}
                color="dark"
                onClick={() =>{ 
                  var temp = norms
                  temp.gdpr=true
                  
                
                  temp.cookies=true
                  setNorms(temp)
                  add_norms(temp)
                  // if(!norms.cookies)
                  // {
                    handlemodalClose("gdprAccept")
                    // handlemodalopencookies()
                  // handlemodalClose("gdprAccept")
                  // }else{
                  //   handlemodalClose("gdprAccept")
                  // }
                }}
              >
                {t("Accepter et Continuer")}
              </MDButton>
            </Box>
          </Box>
        </Box>
      </Modal>
      {/* Modal IA - LO - 3 */}
      {/* <Modal
        open={modalopensecond}
        onClose={(e)=>handlemodalClose("cookies")}
        aria-labelledby="modal-modal-title"
        className="cs-modal modal-main"
        aria-describedby="modal-modal-description"
      >
        <Box className="modal-dialog">
          <Box className="modal-content">

            <Box className="modal-heading">
              <Typography variant="h2" component="h2">
                {t("Our use of cookies")}
              </Typography>
            </Box>
            <Box className="modal-body">
              <p>{t("Cookies_msg")}</p>
            </Box>
            <Box className="modal-footer padding">
            

              <MDButton
                className="secondary-btn2 light"
                sx={{
                  padding: "11px 30px",
                  fontSize: "16px",
                  fontWeight: "normal",
                  minWidth: "160px",
                }}
                color="dark"
                onClick={(e) => handlemodalClose("cookies")}
              >
                {t("Reject all cookies")}
              </MDButton>
              
              <MDButton
                className="secondary-btn2 "
                sx={{
                  padding: "11px 30px",
                  fontSize: "16px",
                  fontWeight: "normal",
                  minWidth: "160px",
                }}
                color="dark"
                onClick={() =>{ 
                  var temp = norms
                  temp.cookies=true
                  add_norms(temp)
                  handlemodalClose("cookiesAccept")
                  // if(norms.cookies)
                  // {
                    
                  //   handlemodalopencookies()
                  // // handlemodalClose("gdprAccept")
                  // }else{
                  //   handlemodalClose("gdprAccept")
                  // }
                }}
              >
                {t("Accept all cookies")}
              </MDButton>
            </Box>
          </Box>
        </Box>
      </Modal> */}
    </div>
    // </MDBox>
  );
}

export default SignIn;
