import { user } from "backendservice/firebase";
import { db } from "backendservice/firebase";
import { doc, getDocs, query, setDoc,updateDoc, where } from "firebase/firestore";
import { v4 as uuidv4 } from "uuid";

const createEmployeetype = async (data) => { 
    var id =  uuidv4();
    data = {...data,raisedDate: new Date(),createdTime:new Date()}
    try{
        var res = await setDoc(doc(db, "employeeType", id), data);
        // console.log(res,"Employee type");
    }catch(error)
    {
        console.log(error)
    }
};
const updateStatus = async (id,index,data) => {
    try {
        const docref = await updateDoc(
            doc(db,"employeeType",id ),
            {active:data[index].data.active,updatedTime:new Date()}
          )
    } catch (error) {
        console.log(error);
    }
    
}
const updateEmployeeType = async (id,data,newValue) => {
    try {
        const docref = await updateDoc(
            doc(db,"employeeType",id ),
            {...data,employeeType:newValue,updatedTime:new Date()}
          )
    } catch (error) {
        console.log(error);
    }
}
const checkDelete = async(id)=>{

    const q = query(user, where("employeeTypeId", "==", id));

    // Execute the query and get the snapshot
    const snapshot = await getDocs(q);

    // Count the number of documents in the snapshot
    const count = snapshot.size;
    console.log(count);
    if(count==0)
        {
            return true
        }else{
            return false
        }
}
export {createEmployeetype,updateStatus,updateEmployeeType,checkDelete}