import { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MDButton from "components/MDButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Calerdar from "../../assets/images/resonante/calendar-icon.png";
import Switch from "@mui/material/Switch";
import MDTypography from "components/MDTypography";
import Checkbox from "@mui/material/Checkbox";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
//modal import
import Modal from "@mui/material/Modal";
import { nameValidation } from "backendservice/validation";
import { nameProps } from "backendservice/validation";
import { validateZipcode } from "backendservice/validation";
import { phoneProps } from "backendservice/validation";
import { validatePhoneNumber } from "backendservice/validation";
import Loader from "components/Loader";
import { isValidWebsite } from "backendservice/validation";
import { get_countryList } from "backendservice/firebasefunctions,";
import { filter_country_selection, filter_usedCountry, get_added_country } from "./functions";
import { ConstructionOutlined } from "@mui/icons-material";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { storage } from "backendservice/firebase";
import { v4 } from "uuid";
import { isValidEmail } from "backendservice/validation";
import { useAuthState } from 'react-firebase-hooks/auth'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import { auth } from "backendservice/firebase";
import { db } from "backendservice/firebase";
import { addDoc, collection, doc, getDoc, limit, orderBy, query, setDoc, updateDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { Alert, CircularProgress, Snackbar, Typography } from "@mui/material";
import { AuthCredential, EmailAuthProvider, reauthenticateWithCredential, updatePassword } from "firebase/auth";
import { CreditCard, SettingsPhone } from "@mui/icons-material";
import PhoneInput from "react-phone-number-input";
function Addcustomer() {
  const [name, setname] = useState("")
  const [surname, setSurname] = useState("")
  const [email, setEmail] = useState("")
  const [descriptions, setDescription] = useState("")
  const [password, setPassword] = useState("")
  const [active, setactive] = useState(false)
  const [user] = useAuthState(auth)
  const [role, setrole] = useState("")
  const [venue, setvenue] = useState("")
  const [snack,setsnack] = useState({message:"",open:false,type:"error",duration:3000})
  const [oldpass, setoldpass] = useState("")
  const [newpass, setnewpass] = useState("")
  const [cpass, setcpass] = useState("")

  const [errorpass, seterrorpass] = useState(false)
  const [errorpass1, seterrorpass1] = useState(false)
  const [loading, setloading] = useState(false)
  const [profilepic, setprofilepic] = useState(null);
  const [phone,setPhone]=useState("")
  const [progress, setProgresspercent] = useState(0);
  const [progressbar, setShowProgress] = useState(false);
  const [imgurl,setimageUrl]=useState("")
  const navigate = useNavigate()
  const handleClickImage = () => {
    imageref.current.click();
  };
  const imageref = useRef(null);
  // console.log("autherntication",user.uid)
  const loadData = async () => {
    if(user?.uid!=undefined && user?.uid != "" && user?.uid!= null)
    {
      setloading(true)
    try { 
      var query = doc(db, process.env.REACT_APP_USER, user.uid);
      var userdetails = await getDoc(query);
  
      //    console.log("useer data == ",userdetails.data())
      var userdata = userdetails.data();
      var roleref = await doc(db, process.env.REACT_APP_USERROLES, userdata["group"]);
      var roles = await getDoc(roleref);
      var userRole = roles.data()
      console.log(userRole,userdata)
      console.log("image",userdata.profile)
      setimageUrl(userdata.profile!==undefined?userdata.profile:"")
      setname(userdata.name)
      setSurname(userdata.surname)
      console.log("user", user)
      setPassword(user.reloadUserInfo.passwordHash)
      setEmail(user.email)
      setactive(userdata.active)
      setrole(userRole.role)
      setPhone(userdata.contact==undefined?"":userdata.contact)
      console.log("userd data ", userdata)
      setloading(false)
    }
      catch(error){
        setloading(false)
        console.log("error",error)
      }
    
    }else{
      navigate(-1)
    }

  }
  const [answersPost, setAnswersPost] = useState(false);
  const { t } = useTranslation();
  //modal open hook
  const [modalopen, setmodalopen] = useState(false);
  //modal open close functionn
  const handlemodalopen = () => {
    setmodalopen(true);
  };
  const handlemodalClose = () => {
    setoldpass("")
    setnewpass("")
    setcpass("")
    setmodalopen(false);
  };
  const handleClose = () =>{
    setsnack({message:"",open:false,type:"error",duration:3000})
  }
  
  const handleChangePawwsord = async (e) => {
    e.preventDefault();
    console.log("cpass");
    
    if (newpass === cpass) {
      console.log("cpass inside");
      seterrorpass(false);
      setloading(true);
      try {
        const user = auth.currentUser;
        console.log("user", user);
        const credential = EmailAuthProvider.credential(user.email, oldpass);

        reauthenticateWithCredential(user, credential).then(() => {
          console.log("reauthenticated", oldpass, newpass);
          if (oldpass !== newpass) { 
            updatePassword(user, newpass).then(() => {
              setloading(false);
              setsnack({ message: t("Password Updated"), open: true, type: "success", duration: 3000 });
              console.log("Password updated successfully!");
              handlemodalClose();
            }).catch((error) => {
              seterrorpass1(false);
              setsnack({ message: t("Update failed"), open: true, type: "error", duration: 3000 });
              setloading(false);
              console.log(error);
            });
          } else {
            setsnack({ message: t("Please use a new password"), open: true, type: "error", duration: 3000 });
            setloading(false);
          }
        }).catch((error) => {
          seterrorpass1(true);
          setsnack({ message: t("Password incorrect"), open: true, type: "error", duration: 3000 });
          console.log("auth error", error);
          setloading(false);
        });
      } catch (error) {
        setsnack({ message: t("error updating password"), open: true, type: "error", duration: 3000 });
        console.error("Error updating password:", error.message);
      }
    } else {
      setloading(false);
      seterrorpass(true);
      setsnack({ message: t("Password Does not match"), open: true, type: "error", duration: 3000 });
    }
  };

  const handleupdate = async (e) => {
    setloading(true);
    e.preventDefault();
    setloading(true);
    console.log("user uid", user.uid);
    var file = profilepic;
    if (file) {
      try {
        setShowProgress(true);
        const storageRef = ref(
          storage,
          `Profiles/${user.uid + (file.type === "image/png" ? ".png" : ".jpg")}`
        );
        const uploadTask = uploadBytesResumable(storageRef, file);
        console.log("Uploaded a blob or file!", uploadTask);
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const progresss = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
            console.log("percentage==>", progresss);
            setProgresspercent(progresss);
          },
          (error) => {
            alert(error);
            setloading(false);
            setShowProgress(false);
          },
          () => {
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
              setShowProgress(false);
              console.log("downloadurl==>", downloadURL);
              let data = {
                profile: downloadURL,
                contact: phone,
                name: name,
                surname: surname,
                fullname: name + " " + surname,
              };
              updateDoc(doc(db,process.env.REACT_APP_USER, user.uid), data);
              setimageUrl(downloadURL);
              setsnack({ message: t("Profile updated"), open: true, type: "success", duration: 3000 });
              setloading(false);
            });
          },
          [500]
        );
      } catch (error) {
        setloading(false);
        console.log("error", error);
      }
    } else {
      let data = {
        profile: imgurl,
        name: name,
        contact: phone,
        surname: surname,
        fullname: name + " " + surname,
        descriptions: descriptions,
      };
      await updateDoc(doc(db, process.env.REACT_APP_USER, user.uid), data);
      setsnack({ message: t("Profile updated"), open: true, type: "success", duration: 3000 });
      setloading(false);
    }
  };

  const handleUpload = async (event) => {
    const file = event.target.files[0];
    console.log("file type ==>", file);

    if (file !== null && file !== undefined && (file.type === "image/png" || file.type === "image/jpeg") && file.size < 2000000) {
      console.log("introsound ==>", file.name);
      try {
        setimageUrl(URL.createObjectURL(file));
        setprofilepic(file);
      } catch (error) {
        console.log("error upload ", error);
      }
    } else {
      setsnack({ message: t("File type not supported"), open: true, type: "error", duration: 3000 });
    }
  };

  useEffect(() => {
    if(user)
    {
      loadData()
    }
   
  }, [user])

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Loader loading={loading} />
      <Snackbar
        open={snack.open}
        autoHideDuration={snack.duration}
        onClose={handleClose}
        severity = {snack.type}
        // message={snack.message}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        // action={action}
      >
           <Alert elevation={6}  variant="filled" severity={snack.type}  sx={{ width: '100%' }}>
        {snack.message}
      </Alert>
      </Snackbar>
      <MDBox pt={4} pb={3} className="xl-container">
        <Box>
          <Box>
            <MDBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <MDBox className="page-heading">
                <MDTypography
                  variant="h5"
                  gutterBottom
                  sx={{ pb: "8px", display: "flex" }}
                >
                  <Box
                    sx={{
                      pt: "2px",
                      width: "22px;",
                      display: { xs: "none", sm: "flex" },
                    }}
                  >
                    <MDButton onClick={() => navigate(-1)}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M0 5.99961C0 5.4524 0.443608 5.00879 0.990826 5.00879H11.0092C11.5564 5.00879 12 5.4524 12 5.99961C12 6.54683 11.5564 6.99044 11.0092 6.99044H0.990826C0.443608 6.99044 0 6.54683 0 5.99961Z"
                          fill="#16273C"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M6.70062 0.290206C7.08756 0.677148 7.08756 1.3045 6.70062 1.69145L2.39206 6L6.70062 10.3086C7.08756 10.6955 7.08756 11.3229 6.70062 11.7098C6.31368 12.0967 5.68632 12.0967 5.29938 11.7098L0.290206 6.70062C-0.0967354 6.31368 -0.0967354 5.68632 0.290206 5.29938L5.29938 0.290206C5.68632 -0.0967354 6.31368 -0.0967354 6.70062 0.290206Z"
                          fill="#16273C"
                        />
                      </svg>
                    </MDButton>
                  </Box>
                  {t("Account settings")}
                </MDTypography>
              </MDBox>
            </MDBox>
            <MDBox className="card-layout" component="form" onSubmit={(e) => handleupdate(e)}>
              <Box className="form-main">
                <Box className="form-content">
                  <Box sx={{ width: { xs: "100%", sm: "100%" } }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={10}>
                      <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <FormGroup sx={{ mb: 2 }}>
                          <InputLabel className="formlabel">
                            {t("Name")}<strong style={{color:"red"}}> *</strong>
                          </InputLabel>
                          <TextField
                            className="forminput"
                            placeholder={t("Enter the name")}
                            variant="outlined"
                            value={name}
                            required
                          
                            onChange={(e) => setname(e.target.value)}
                           
                          />
                        </FormGroup>
                        </Grid>
                        <Grid item xs={12} md={6}>
                        <FormGroup sx={{ mb: 2 }}>
                          <InputLabel className="formlabel">
                            {t("Surname")}<strong style={{color:"red"}}> *</strong>
                          </InputLabel>
                          <TextField
                            className="forminput"
                            placeholder={t("Enter the name")}
                            variant="outlined"
                            value={surname}
                            required
                          
                            onChange={(e) => setSurname(e.target.value)}
                           
                          />
                        </FormGroup>
                        </Grid>
                        </Grid>

                        <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <FormGroup sx={{ mb: 2 }}>
                          <InputLabel className="formlabel">
                            {t("Contact number")}<strong style={{color:"red"}}> *</strong>
                          </InputLabel>
                          {/* <TextField
                            className="forminput"
                            placeholder={t("Enter the Contact number")}
                            variant="outlined"
                            value={phone}
                            required
                            onChange={(e) => setPhone(e.target.value)}
                            
                           
                          /> */}
                          <PhoneInput
                          className="forminput"
                          international
                          countryCallingCodeEditable={false}
                          defaultCountry="FR"
                          placeholder="Enter phone number"
                          value={phone}
                          required
                         
                          onChange={(value, country, e, formattedValue) => {
                            setPhone(value);
                            // console.log(value,"phone number"); // Log the phone number value
                          }}
                        />
                        </FormGroup>
                        </Grid>
                        <Grid item xs={12} md={6}>
                        <FormGroup sx={{ mb: 2 }}>
                          <InputLabel className="formlabel">
                            {t("Email ID")}<strong style={{color:"red"}}> *</strong>
                          </InputLabel>
                          <TextField
                            className="forminput"
                            placeholder={t("Enter the email iD")}
                            variant="outlined"
                            value={email}
                            required
                            onChange={(e) => setEmail(e.target.value)}
                            disabled
                           
                          />
                        </FormGroup>
                        </Grid>
                        </Grid>
                       
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <Box>
                          <FormGroup className="upload-image">
                            <InputLabel className="formlabel">{t("Profile")}</InputLabel>
                            <Box sx={{ marginBottom: { xs: "16px", sm: "0" } }}>
                            <MDButton className="upload-btn" 
                                   style={
                                    profilepic !== null
                                      ? {
                                          backgroundColor: "#B1B1B1",
                                          display: "inline-block",
                                          padding: "5px",
                                        }
                                      : { padding: "5px" }
                                  }
                                  onClick={() => handleClickImage()}
                              >
                                {progressbar ? (
                                    <CircularProgress
                                      variant="determinate"
                                      value={progress}
                                      style={{
                                        height: "inherit",
                                        width: "auto",
                                      }}
                                    />
                                  ) : (
                                    ""
                                  )}
                                  {!progressbar && profilepic == null && imgurl==""? (
                                    <div>
                                      <svg
                                        width="31"
                                        height="31"
                                        viewBox="0 0 31 31"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          fill-rule="evenodd"
                                          clip-rule="evenodd"
                                          d="M19.0324 3.53243C19.0324 1.58152 17.4509 0 15.5 0C13.5491 0 11.9676 1.58152 11.9676 3.53243V11.9676H3.53243C1.58152 11.9676 0 13.5491 0 15.5C0 17.4509 1.58152 19.0324 3.53243 19.0324H11.9676V27.4676C11.9676 29.4185 13.5491 31 15.5 31C17.4509 31 19.0324 29.4185 19.0324 27.4676V19.0324H27.4676C29.4185 19.0324 31 17.4509 31 15.5C31 13.5491 29.4185 11.9676 27.4676 11.9676H19.0324V3.53243Z"
                                          fill="#282B3B"
                                          fill-opacity="0.42"
                                        />
                                      </svg>

                                      <span>{t("Upload Profile")}</span>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                  {profilepic == null && imgurl==""? (
                                    ""
                                  ) : (
                                    <img
                                      src={imgurl}
                                      alt=""
                                      style={{
                                        height: "inherit",
                                        width: "inherit",
                                      }}
                                    />
                                  )}

                                  <input
                                    type="file"
                                    accept=".png, .jpg, .jpeg, .gif" 
                                    onChange={handleUpload}
                                    style={{ display: "none" }}
                                    ref={imageref}
                                  />
                              </MDButton>
                            </Box>
                          </FormGroup>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <FormGroup sx={{ mb: 2 }} className="input-icon">
                        <InputLabel className="formlabel">
                          {t("Password")}
                        </InputLabel>
                        <TextField
                          className="forminput"
                          type="password"
                          placeholder={t("Enter the password")}
                          variant="outlined"
                          value={password}
                          disabled
                        />
                   
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Box
                        sx={{
                        
                          marginTop: "28px",
                        }}
                      >
<MDButton
                      className="primary-btn btn xs" 
                      onClick={() => handlemodalopen()}
                  
                    >
                      {t("Change password")}
                    </MDButton>
                      </Box>
                    
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      {/* <FormGroup sx={{ mb: 2 }}>
                        <InputLabel className="formlabel">
                          {t("Employee type")}
                        </InputLabel>
                        <TextField
                          className="forminput"
                          placeholder={t("Receptionist")}
                          variant="outlined"
                         
                        />
                      </FormGroup> */}
                    </Grid>
                    <Grid item xs={12} md={6}>
                      {/* <Box sx={{ mb: 2 }}>
                        <InputLabel className="formlabel">
                          {t("Default clinic")}
                        </InputLabel>
                        <TextField
                          className="forminput"
                          placeholder={t("Marais district")}
                          variant="outlined"
                          
                        />
                    
                      </Box> */}
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Box sx={{ mb: 2 }}>
                        <InputLabel className="formlabel">
                          {t("Profile")}
                        </InputLabel>
                        <TextField
                          className="forminput"
                          placeholder={t("Brand Admin")}
                          variant="outlined"
                          value={role}
                      disabled
                        />
                      
                      </Box>
                    </Grid>
                  </Grid>

                  <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              marginTop:"10px"
                            }}
                          >
                            <label className="active-text">{t("Active")}</label>
                            <Switch
                              checked={active}
                              disabled
                              // onChange={() => setFollowsMe(!followsMe)}
                            />
                          </Box>
                 
                </Box>
              </Box>
              <Box
                pt={6}
                className="bottom-btn"
                sx={{
                  display: "flex",
                  justifyContent: "end",
                  marginTop: "18px",
                }}
              >
                <MDButton className="primary-btn btn xl"  type="submit">{t("Update")}</MDButton>
              </Box>
            </MDBox>
          </Box>
        </Box>
        <Modal
        open={modalopen}
        onClose={handlemodalClose}
        aria-labelledby="modal-modal-title"
        className="cs-modal slack-md"
        aria-describedby="modal-modal-description"
      >
        <Box className="modal-dialog">
          <Box className="modal-content" component="form" onSubmit={(e) => { handleChangePawwsord(e) }}>
            <Box className="modal-header">
              <MDButton
                className="modal-close"
                onClick={() => handlemodalClose()}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                >
                  <path
                    d="M13 1L1 13"
                    stroke="#282B3B"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M1 1L13 13"
                    stroke="#282B3B"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </MDButton>
            </Box>
            <Box className="modal-heading">
              <Typography variant="h2" component="h2">
                {t("Password change")}
              </Typography>
            </Box>
            <Box className="modal-body">
              <MDBox
                justifyContent="space-between"
                alignItems="center"
                sx={{ display: { xs: "block", sm: "flex" } }}
              >
                <FormGroup sx={{ mb: 2, width: "100%" }}>
                  <InputLabel className="formlabel">{t("Old Password")}<a style={{ color: 'red' }}>*</a></InputLabel>
                  <TextField
                    className="forminput"
                    placeholder={t("Enter old password")}
                    variant="outlined"
                    type="password"
                    required
                    value={oldpass}
                    InputProps={{
                      inputProps: {
                    
                        maxLength: 20,
                      },
                    }}
                    onChange={(e) => setoldpass(e.target.value)}
                    error={errorpass1}
                  />
                </FormGroup>

              </MDBox>
              <MDBox
                justifyContent="space-between"
                alignItems="center"
                sx={{ display: { xs: "block", sm: "flex" } }}
              >
                <FormGroup sx={{ mb: 2, width: "100%" }}>
                  <InputLabel className="formlabel">{t("New password")} <a style={{ color: 'red' }}>*</a></InputLabel>
                  <TextField
                    className="forminput"
                    placeholder={t("Enter the New Password")}
                    variant="outlined"
                    type="password"
                    required
                    value={newpass}
                    InputProps={{
                      inputProps: {
                    
                        maxLength: 20,
                      },
                    }}
                    onChange={(e) => setnewpass(e.target.value)}
                    error={errorpass}
                  />
                </FormGroup>

              </MDBox>
              <MDBox
                justifyContent="space-between"
                alignItems="center"
                sx={{ display: { xs: "block", sm: "flex" } }}
              >
                <FormGroup sx={{ mb: 2, width: "100%" }}>
                  <InputLabel className="formlabel">{t("Confirm Password")}<a style={{ color: 'red' }}>*</a></InputLabel>
                  <TextField
                    className="forminput"
                    placeholder={t("Enter the Password")}
                    variant="outlined"
                    type="password"
                    required
                    value={cpass}
                    InputProps={{
                      inputProps: {
                    
                        maxLength: 20,
                      },
                    }}
                    onChange={(e) => setcpass(e.target.value)}
                    error={errorpass}
                  />
                </FormGroup>

              </MDBox>


            </Box>
            <Box className="modal-footer">
              <MDButton
                className="secondary-btn"
                sx={{
                  padding: "11px 30px",
                  fontSize: "16px",
                  fontWeight: "normal",
                  minWidth: "160px",
                }}
                type="submit"
              >
                {t("Update")}
              </MDButton>
            </Box>
          </Box>
        </Box>
      </Modal>
    
      </MDBox>
    </DashboardLayout>
  );
}

export default Addcustomer;
