import { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MDButton from "components/MDButton";
import MenuItem from "@mui/material/MenuItem";
import FormGroup from "@mui/material/FormGroup";
import MDTypography from "components/MDTypography";
import { Chip, ClickAwayListener, FormControl, Modal, Typography } from "@mui/material";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useTranslation } from "react-i18next";
//modal import

import { useNavigate, useParams } from "react-router-dom";
import { get_customer } from "backendservice/firebasefunctions,";
import { get_countryList } from "backendservice/firebasefunctions,";
import { get_clinic } from "backendservice/firebasefunctions,";
import { get_contract } from "backendservice/firebasefunctions,";
import { get_employeeType } from "backendservice/firebasefunctions,";
import { get_roles } from "backendservice/firebasefunctions,";
import Loader from "components/Loader";
import { add_staff } from "backendservice/firebasefunctions,";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "backendservice/firebase";

import {
    useMaterialUIController,
    setSelecetdCustomerrec
  } from "context";
import { update_staff } from "backendservice/firebasefunctions,";
import PhoneInput from "react-phone-number-input";
import { isValidEmail } from "backendservice/validation";
import moment from "moment";
import ReactCalendar from "react-calendar";
function EditStaff() {
  const [controller, dispatch] = useMaterialUIController();
  const {
    selecetdCustomer,
    selected_clinic,
    permission
  } = controller;
  // Initialize necessary state variables

  const navigate = useNavigate();
  const formRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState({});
  const [formData, setFormData] = useState({
    regions: [],
    clinics: [],
    contractType: [],
    profile: [],
    selectedEmployeeType: "",
    employeeType: [],
    answersPost: false,
    modalopen: false,
    customerID: "",
    selectedRegion: "",
    selectedCountry: "",
    selectedSubRegion: "",
    subRegions: [],
    selectedClinic: [],
    selectedDefaultClinic: "",
    selectedContractType: "",
    contractList:[],
    selectedProfile: "",
    name: "",
    surname: "",
    email: "",
    contact: "",
    active:false
  });
  const [formSubmitted, setFormSubmitted] = useState(false); // Track form submission
  const [ validation,setValidation] = useState({phone:false,zip:false,website:false,email:false})
  const [contractModal,setContractModal]=useState({
    open:false,
    contractId:"",
    contractIndex:"",

  })
  // Import translation hook
  const { t,i18n } = useTranslation();
  var language = i18n.language;
  // id
  const { id } = useParams();
  const handleClinicSelection = async (event)=>{
    if(event.target.value!==undefined && event.target.value!="")
      {setLoading(true)
        console.log(event.target.value)
        var contractType = await get_contract(selecetdCustomer.id,event.target.value,true)
        var employeeType = await get_employeeType(selecetdCustomer.id,event.target.value)
        const activeContractTypes = contractType ? contractType.filter(({ active }) => active === true) : [];
        const activeEmployeeTypes = employeeType ? employeeType.filter(({ active }) => active === true) : [];
        setFormData((prevData) => ({
          ...prevData,
       
          contractType: activeContractTypes,
          employeeType: activeEmployeeTypes,
       
        }));
        setLoading(false)
      }
    setFormData((prevData) => ({
      ...prevData,
      selectedDefaultClinic: event.target.value,
    }))
  }
  // Function to load data from backend
  const loadData = async () => {
    setLoading(true);
    const datass = localStorage.getItem("user");
    const docRef = doc(db, "user", id);
    const staffRef = await getDoc(docRef);
    const staffData = staffRef.data();
    if (!staffData) {
      alert(t("Something went wrong!"));
      navigate(-1)
      return;
    }

    const json = JSON.parse(datass);
    const customer = selecetdCustomer.id;
    setUserData(json);
    setFormData((prevData) => ({ ...prevData, customerID: customer }));
    // Fetch necessary data from backend services in parallel
    const [region, clinic,
       contractType, employeeType,
        profile,country] = await Promise.all([
      get_customer(customer),
      get_clinic(customer),
      get_contract(customer,staffData.defaultClinicId,true),
      get_employeeType(customer,staffData.defaultClinicId),
      get_roles(customer,true),
      get_countryList()
    ]);

// filter active data
const activeClinics = clinic ? clinic.filter(({ active }) => active === true) : [];
const activeContractTypes = contractType ? contractType.filter(({ active }) => active === true) : [];
const activeEmployeeTypes = employeeType ? employeeType.filter(({ active }) => active === true) : [];
const activeProfile = profile ? profile.filter(({ active }) => active === true) : [];

    if (region && region.operatedRegion) {
      const allRegions = region.operatedRegion.map(({ region, country }) => ({ label: region, country }));
      setFormData((prevData) => ({ ...prevData, regions: allRegions }));
    }
    const selectedCountryData = country.find((country) => country.label === staffData.operatedRegion[0].country.data.Country);
    // Update sub-region state if available
    setFormData((prevData) => ({
      ...prevData,
      clinics: activeClinics,
      contractType: activeContractTypes,
      employeeType: activeEmployeeTypes,
      profile: activeProfile,
      contractList:staffData.contractList?staffData.contractList:[],
      country:country,
      subRegions: selectedCountryData.data["sub-region-data"] ? selectedCountryData.data["sub-region-data"] : "",
      selectedRegion: staffData.operatedRegion[0].region,
      selectedCountry: staffData.operatedRegion[0].country.data.Country,
      selectedSubRegion: staffData.operatedRegion[0].subRegion || "",
      selectedClinic: activeClinics.filter(clinic => staffData.clinic.includes(clinic.id)).map(clinic => clinic.label),
      selectedDefaultClinic: staffData.defaultClinicId,
      
      selectedContractType: staffData.contractTypeId,
      selectedProfile: staffData.group,
      selectedEmployeeType: staffData.employeeTypeId,
      name: staffData.name,
      surname: staffData.surname,
      email: staffData.email,
      contact: staffData.contact,
      active:staffData.active
    }));
 setLoading(false);
  };
  const deleteContract = (index)=>{
    var temp = [...formData.contractList]
    temp.splice(index,1)

    setFormData((prev)=>({
      ...prev,
      contractList:temp
    }))
  }

  const handleAddContract = ()=>{
    
    var rec = {
      contractId:"",
      contract:"",
      start:moment(new Date()).format("YYYY-MM-DD"),
      end:null,
      startcal:false,
      endCal:false,
      lock:false
    }
    var temp = [...formData.contractList]
    if(temp.length>0 && temp[temp.length-1].contractId=="" )
      {
        alert(t("fill_previous_contract"))
        return
      }
    if(temp.length>0 && temp[temp.length-1].end==null )
   { 
    alert(t("Please specify the end date for the previous contract"));
    return;

    setContractModal({
      open:true,
      contractId:temp[temp.length-1].contractId,
      contractIndex:temp.length-1,
    })

 
  }else{
    if(temp.length>0)
    {   temp[temp.length-1].lock = true}
    temp.push(rec)

    setFormData((prev)=>({
      ...prev,
      contractList:temp
    }))
    }

  }
  const handleContract = (event,index)=>{

    var contract = formData.contractType.find(({ id }) => id === event.target.value)?.label || "";
    var temp = [...formData.contractList]
   

      temp[index].contractId=event.target.value
      temp[index].contract=contract
      temp[index].start=moment(new Date()).format("YYYY-MM-DD")
      temp[index].end=null
      temp[index].startcal=false
      temp[index].endCal=false
      temp[index].lock=false
   
 
      setFormData((prev)=>({
        ...prev,
        selectedContractType:event.target.value,
        contractList:temp
      }))
  }

  const handleEndCOntract = (type)=>{
    if(type=="modal")
   { 
    var temp = [...formData.contractList]
    // temp[contractModal.contractIndex].end = moment(new Date).format("YYYY-MM-DD")
    temp[contractModal.contractIndex].lock = true
    setFormData((prev)=>({
      ...prev,
      contractList:temp
    }))
    setContractModal({
      open:false,
      contractId:"",
      contractIndex:"",
    })
    handleAddContract()

  }else{

    }

  }
  // Function to handle region change
  const handleRegionChange = (event) => {
    setFormData((prevData) => ({
      ...prevData,
      selectedRegion: event.target.value,
      selectedCountry: "",
    }));
  };
  // Function to handle country change
  const handleCountryChange = async (event) => {
    setLoading(true);
    const selectedCountry = event.target.value;
    setFormData((prevData) => ({ ...prevData, selectedCountry }));

    const selectedCountryData = formData.country.find((country) => country.label === selectedCountry);
    // Update sub-region state if available
    if (selectedCountryData && selectedCountryData.data && selectedCountryData.data["sub-region-data"]) {
      setFormData((prevData) => ({
        ...prevData,
        subRegions: selectedCountryData.data["sub-region-data"],
      }));
    } else {
      setFormData((prevData) => ({ ...prevData, subRegions: [] ,selectedSubRegion:""}));
    }

    setLoading(false);
  };
  // Function to handle form input change
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };
  // Function to save activity
  const saveActivity = async (e) => {
    e.preventDefault();
    setLoading(true);
    setFormSubmitted(true);
    const emailError = !isValidEmail(formData.email);
    if (emailError) {
      setLoading(false);
      return; // Stop form submission if there are validation errors
    }
    const temp = {
      group:formData.selectedProfile,
      employeeType: formData.employeeType.find(({ id }) => id === formData.selectedEmployeeType)?.label || "",
     
      employeeTypeId:  formData.selectedEmployeeType ,
      operatedRegion: [
        {
          region: formData.selectedRegion,
          country: formData.country ? formData.country.find(({ label }) => label === formData.selectedCountry) || false : false,
          subRegion: formData.selectedSubRegion ||  ""
        }
      ],
      clinic: formData.clinics.filter(clinic => formData.selectedClinic.includes(clinic.label)).map(clinic => clinic.id) || [],
      defaultClinicId:formData.selectedDefaultClinic ,
      defaultClinic:formData.clinics.find(clinic => clinic.id === formData.selectedDefaultClinic)?.label || "",
      contractType:formData.contractType.find(({ id }) => id === formData.selectedContractType)?.label || "",
      contractTypeId:formData.selectedContractType || "",
      contractList:formData.contractList?formData.contractList:[],
      roleDescription: formData.profile.find(({ id }) => id === formData.selectedProfile)?.label || "",
      name: formData.name.trim() || "",
      surname: formData.surname.trim() || "",
      fullName: `${formData.name} ${formData.surname}`,
      profile: "",
      email: formData.email.trim() || "",
      contact: formData.contact || "",
      customer: formData.customerID || "",
      active: formData.active,
      
    };
    // Add staff using backend service
    await update_staff(id, temp)
    alert(t("Staff updated successfully!"))
    // Reset form data and close modal
    setLoading(false);
    setFormData({
      regions: [],
      clinics: [],
      contractType: [],
      profile: [],
      selectedEmployeeType: "",
      employeeType: [],
      answersPost: false,
      modalopen: false,
      customerID: "",
      selectedRegion: "",
      selectedCountry: "",
      selectedSubRegion: "",
      subRegions: [],
      selectedClinic: [],
      selectedDefaultClinic: "",
      selectedContractType: "",
      selectedProfile: "",
      name: "",
      surname: "",
      email: "",
      contact: "",
      contractList:[]
    });
    navigate(-1);
   

   
  };
  // Effect hook to load data when customerID changes
  useEffect(() => {
    if(selecetdCustomer.id!=="" && selecetdCustomer.id !==undefined && selected_clinic.id!==undefined && selected_clinic.id!=="")
      {
        loadData();
      }
  }, [ selecetdCustomer,selected_clinic]);


  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Loader loading={loading} />
      <MDBox pt={4} pb={3} className="xl-container">
        <Box>
          <Box>
            <MDBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <MDBox className="page-heading">
                <MDTypography
                  variant="h5"
                  gutterBottom
                  sx={{ pb: "8px", display: "flex" }}
                >
                  <Box
                    sx={{
                      pt: "2px",
                      width: "22px;",
                      display: { xs: "none", sm: "flex" },
                    }}
                  >
                    <MDButton onClick={() => navigate("/staff")}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M0 5.99961C0 5.4524 0.443608 5.00879 0.990826 5.00879H11.0092C11.5564 5.00879 12 5.4524 12 5.99961C12 6.54683 11.5564 6.99044 11.0092 6.99044H0.990826C0.443608 6.99044 0 6.54683 0 5.99961Z"
                          fill="#16273C"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M6.70062 0.290206C7.08756 0.677148 7.08756 1.3045 6.70062 1.69145L2.39206 6L6.70062 10.3086C7.08756 10.6955 7.08756 11.3229 6.70062 11.7098C6.31368 12.0967 5.68632 12.0967 5.29938 11.7098L0.290206 6.70062C-0.0967354 6.31368 -0.0967354 5.68632 0.290206 5.29938L5.29938 0.290206C5.68632 -0.0967354 6.31368 -0.0967354 6.70062 0.290206Z"
                          fill="#16273C"
                        />
                      </svg>
                    </MDButton>
                  </Box>
                  {t("Staff details")}
                </MDTypography>
              </MDBox>
            </MDBox>
            <MDBox
              className="card-layout"
              component="form"
              onSubmit={(e) => saveActivity(e)}
              ref={formRef}
            >
              <Box className="form-main">
                <Box className="form-content">
                  <Box sx={{ width: { xs: "100%", sm: "100%" } }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <FormGroup sx={{ mb: 2 }}>
                          <InputLabel className="formlabel">
                          {t("First name")}<strong style={{color:"red"}}> *</strong>
                          </InputLabel>
                          <TextField
                            className="forminput"
                            placeholder={t("Enter first name")}
                            variant="outlined"
                            required
                            name="name"
                            inputProps={{ minLength: 3 }}
                            value={formData.name}
                            onChange={handleInputChange}
                          />
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <FormGroup sx={{ mb: 2 }} className="">
                          <InputLabel className="formlabel">
                            {t("Surname")}
                          </InputLabel>
                          <TextField
                            className="forminput"
                            placeholder={t("Enter surname")}
                            variant="outlined"
                            name="surname"

                            value={formData.surname}
                            onChange={handleInputChange}
                          />
                        </FormGroup>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <FormGroup sx={{ mb: 2 }}>
                          <InputLabel className="formlabel">
                            {t("E-mail id")}<strong style={{color:"red"}}> *</strong>
                          </InputLabel>
                          <TextField
                            className="forminput"
                            placeholder={t("Enter e-mail id")}
                            variant="outlined"
                            required
                            name="email"
                            value={formData.email}
                            onChange={(e) => {
                              handleInputChange(e)
                              setValidation(prevState => ({
                                ...prevState, // Spread the previous state to retain the unchanged properties
                                email: !isValidEmail(e.target.value) // Update the phone property with the new value
                              }));
                            }}
                            type="email"
                            disabled
                            error={validation.email && formSubmitted}
                            helperText={validation.email && formSubmitted ? t("Please enter a valid email address") : ""}
                          />
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <FormGroup sx={{ mb: 2 }}>
                          <InputLabel className="formlabel">
                            {t("Contact number")}
                          </InputLabel>
                          <PhoneInput
                            className="forminput"
                            placeholder={t("Enter contact number")}
                            name="contact"
                            international
                            countryCallingCodeEditable={false}
                            defaultCountry="FR"
                                    
                            value={formData.contact}
                            required
                            onChange={(e)=>handleInputChange({target:{name:"contact",value:e==undefined?"":e}})}
                          />
                        </FormGroup>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={4}>
                        <Box sx={{ mb: 2 }}>
                          <InputLabel className="formlabel">
                            {t("Region")}<strong style={{color:"red"}}> *</strong>
                          </InputLabel>
                          <Box className="cs-dropdown icon">
                            <Select
                              className="form-select"
                              id="languageSelector"
                              onChange={handleRegionChange}
                              value={formData.selectedRegion}
                              label="Country"
                              required
                            >
                              <MenuItem value="" disabled>
                                {t("Select an option")}
                              </MenuItem>
                              {formData.regions.map((region) => (
                                <MenuItem
                                  key={region.label}
                                  value={region.label}
                                >
                                  {region.label}
                                </MenuItem>
                              ))}
                            </Select>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Box sx={{ mb: 2 }}  className={`${!formData.selectedRegion ? 'disabled' : ''}`}>
                          <InputLabel className="formlabel">
                            {t("Country")}<strong style={{color:"red"}}> *</strong>
                          </InputLabel>
                          <Box className={`cs-dropdown icon`}>
                            <Select
                              className="form-select"
                              id="languageSelector"
                              label="Country"
                              value={formData.selectedCountry}
                              onChange={handleCountryChange}
                              disabled={!formData.selectedRegion}
                              required
                            >
                              <MenuItem value="" disabled>
                                {t("Select an option")}
                              </MenuItem>
                              {formData.regions
                                .find(
                                  (region) =>
                                    region.label === formData.selectedRegion
                                )
                                ?.country.map((country) => (
                                  <MenuItem
                                    key={country.label}
                                    value={country.label}
                                  >
                                    {country.label}
                                  </MenuItem>
                                ))}
                            </Select>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Box sx={{ mb: 2 }}  className={`${!formData.selectedCountry  ? 'disabled' : ''}`} >
                          <InputLabel className="formlabel">
                            {t("Sub-region")}{formData.subRegions.length > 0?<strong style={{color:"red"}}> *</strong> :""}
                          </InputLabel>
                          <Box className="cs-dropdown icon">
                            <Select
                              className="form-select"
                              id="subRegionSelector"
                              label="Sub-region"
                              value={formData.selectedSubRegion == false ? "" : formData.selectedSubRegion}
                              onChange={(event) =>
                                setFormData((prevData) => ({
                                  ...prevData,
                                  selectedSubRegion: event.target.value,
                                }))
                              }
                              disabled={!formData.selectedCountry  ||!formData.subRegions || formData.subRegions.length === 0}
                              required={formData.subRegions.length > 0}
                            >
                              <MenuItem value="" className="menu-item" disabled>
                                {t("Select an option")}
                              </MenuItem>
                              { formData.subRegions.length > 0  ? formData.subRegions.map((subRegion) => (
                                <MenuItem key={subRegion} value={subRegion}>
                                  {subRegion}
                                </MenuItem>
                              )) : ""}
                            </Select>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
      <Box>
        <InputLabel className="formlabel">
          {t("Clinic")}<strong style={{color:"red"}}> *</strong>
        </InputLabel>
        <Box className="cs-dropdown icon">
          <FormControl fullWidth>
            <Select
              className="form-select"
              id="clinicSelector"
              label="Clinic"
              multiple
              required
              value={formData.selectedClinic}
              onChange={(event) =>
                setFormData((prevData) => ({
                  ...prevData,
                  selectedClinic: event.target.value,
                }))}
              renderValue={(selected) => (
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  {selected.map((clinic) => (
                    <Chip key={clinic} label={clinic} />
                  ))}
                </div>
              )}
            >
              <MenuItem disabled value="">
                {formData.selectedClinic.length === 0 ? 'Select an option' : null}
              </MenuItem>
              {formData.clinics.map((clinic) => (
                <MenuItem key={clinic.id} value={clinic.label}>
                  {clinic.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Box>
    </Grid>
                      <Grid item xs={12} md={6}>
                        <Box>
                          <InputLabel className="formlabel">
                            {t("Default clinic")}<strong style={{color:"red"}}> *</strong>
                          </InputLabel>
                          <Box className="cs-dropdown icon">
                            <Select
                              className="form-select"
                              id="defaultClinicSelector"
                              label="Default Clinic"
                              value={formData.selectedDefaultClinic}
                              onChange={(event) =>
                                handleClinicSelection(event)
                             
                                
                              }
                              required
                            >
                              <MenuItem value="" disabled>
                                {t("Select an option")}
                              </MenuItem>
                              {formData.clinics.map((clinic) => (
                                <MenuItem
                                  key={clinic.id}
                                  value={clinic.id}
                                >
                                  {clinic.label}
                                </MenuItem>
                              ))}
                            </Select>
                          </Box>
                        </Box>
                      </Grid>
                    
                      <Grid item xs={12} md={6}>
                        <Box>
                          <InputLabel className="formlabel">
                            {t("Employee type")}<strong style={{color:"red"}}> *</strong>
                          </InputLabel>
                          <Box className="cs-dropdown icon">
                            <Select
                              className="form-select"
                              id="languageSelector"
                              label="Language"
                              value={formData.selectedEmployeeType}
                              required
                              onChange={(event) =>
                                setFormData((prevData) => ({
                                  ...prevData,
                                  selectedEmployeeType: event.target.value,
                                }))
                              }
                            >
                              <MenuItem className="menu-item" value="" disabled>
                                {t("Select an option")}
                              </MenuItem>
                              {formData.employeeType.map((employeeType) => (
                                <MenuItem
                                  key={employeeType.id}
                                  value={employeeType.id}
                                >
                                  {employeeType.label}
                                </MenuItem>
                              ))}
                            </Select>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Box sx={{ mb: 2 }}>
                          <InputLabel className="formlabel">
                            {t("Profile")}<strong style={{color:"red"}}> *</strong>
                          </InputLabel>
                          <Box className="cs-dropdown icon">
                            <Select
                              className="form-select"
                              id="languageSelector"
                              label="Language"
                              value={formData.selectedProfile}
                              onChange={(event) =>
                                setFormData((prevData) => ({
                                  ...prevData,
                                  selectedProfile: event.target.value,
                                }))
                              }
                              required
                            >
                              <MenuItem value="" className="menu-item" disabled>
                                {t("Select an option")}
                              </MenuItem>
                              {formData.profile.map((profile) => (
                                <MenuItem
                                  key={profile.id}
                                  value={profile.id}
                                >
                                  {t(profile.label)}
                                </MenuItem>
                              ))}
                            </Select>
                          </Box>
                        </Box>
                      </Grid>

                    





                      </Grid>
                      {console.log(formData,"formData")}

                      {  formData.contractList.map((d,i)=>(
                        <>
                       { !d.lock && <Grid container spacing={2}>
                          <Grid item xs={12} md={4}>
                        <Box pb={2}>
                          <InputLabel className="formlabel">
                            {t("Contract type")}<strong style={{color:"red"}}> *</strong>
                          </InputLabel>
                          <Box className="cs-dropdown icon">
                            <Select
                             disabled={d.lock}
                              className="form-select"
                              id="languageSelector"
                              label="Language"
                              required
                              value={d.contractId}
                              onChange={(event) =>
                                handleContract(event,i)
                                // console.log()
                              }
                            >
                              <MenuItem className="menu-item" value="" disabled>
                                {t("Select an option")}
                              </MenuItem>
                              {formData.contractType.map((contractType) => (
                                <MenuItem
                                  key={contractType.id}
                                  value={contractType.id}
                                >
                                  {contractType.contract}
                                </MenuItem>
                              ))}
                            </Select>
                          </Box>
                        </Box>
                      </Grid>
                      </Grid>}
                     
                    {d.contractId!=="" &&  <Grid container spacing={2}> <Grid item xs={12} md={12}> <Box className="edit-contract-section">
                    <Grid container spacing={2}>
                    <Grid item xs={12} md={3}>
                          <label className="label">{d.contract}</label>
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <FormGroup
                            mt={1}
                            className="input-icon calendar-view"
                           
                          >
                            <InputLabel className="formlabel">
                              {t("Starting date")}
                            </InputLabel>
                            <TextField
                             disabled={d.lock}
                              className="forminput"
                              placeholder={t("Select date")}
                              variant="outlined"
                              value = {d.start}
                              InputProps={{
                                readOnly: true, // Make the field read-only
                              }}
                              onClick={(e) => {
                                var temp = [...formData.contractList]
    
                                           temp[i].startcal = true
                                           console.log("temp ",temp)
                                          setFormData((prev)=>({
                                            ...prev,
                                            contractList:temp
                                          }))
                              }}
                            />
    {d.startcal && (
                             <ClickAwayListener
                          onClickAway={(e) => {
                            var temp = [...formData.contractList]
                            console.log("click aeaawajw ",e)
                                       temp[i].startcal = false
                                      setFormData((prev)=>({
                                        ...prev,
                                        contractList:temp
                                      }))
                          }}
                        >
                          <div>
                               <ReactCalendar
                                disabled={d.lock}
                                    // onChange={(e) => console.log(e)}
                                    className="forminput"
                                    value={new Date(d.start)}
                                    selectRange={false}
                                    // minDate={new Date()}
                                    // locale={locale}
                                    locale={language == "fr" ? "fr-FR" :"en-EN"}
                                    onChange={(e) =>
                                     { var temp = [...formData.contractList]
console.log("time",e, moment(e).format("YYYY-MM-DD"))
                                       temp[i].start = moment(e).format("YYYY-MM-DD")
                                      setFormData((prev)=>({
                                        ...prev,
                                        contractList:temp
                                      }))
                                      }
                                    }
                                  />
                                  </div>
                                  </ClickAwayListener>
                                  )
                                  }
                            <MDButton
                             disabled={d.lock}
                                      onClick={(e) => {
                                        var temp = [...formData.contractList]
            
                                                   temp[i].startcal = true
                                                  setFormData((prev)=>({
                                                    ...prev,
                                                    contractList:temp
                                                  }))
                                      }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="17"
                                height="18"
                                viewBox="0 0 15 16"
                                fill="none"
                              >
                                <path
                                  d="M12.4111 2.12109H2.12047C1.30857 2.12109 0.650391 2.77927 0.650391 3.59118V13.8818C0.650391 14.6937 1.30857 15.3518 2.12047 15.3518H12.4111C13.223 15.3518 13.8811 14.6937 13.8811 13.8818V3.59118C13.8811 2.77927 13.223 2.12109 12.4111 2.12109Z"
                                  fill="#16273C"
                                />
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M2.11966 2.76789C1.6665 2.76789 1.29914 3.13525 1.29914 3.58841V13.879C1.29914 14.3321 1.6665 14.6995 2.11966 14.6995H12.4102C12.8634 14.6995 13.2308 14.3322 13.2308 13.879V3.58841C13.2308 3.13525 12.8634 2.76789 12.4102 2.76789H2.11966ZM0 3.58841C0 2.41775 0.949002 1.46875 2.11966 1.46875H12.4102C13.5809 1.46875 14.5299 2.41775 14.5299 3.58841V13.879C14.5299 15.0496 13.5809 15.9986 12.4102 15.9986H2.11966C0.949003 15.9986 0 15.0496 0 13.879V3.58841Z"
                                  fill="white"
                                />
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M10.2062 0C10.565 0 10.8558 0.290823 10.8558 0.649572V3.58974C10.8558 3.94849 10.565 4.23931 10.2062 4.23931C9.84746 4.23931 9.55664 3.94849 9.55664 3.58974V0.649572C9.55664 0.290823 9.84746 0 10.2062 0Z"
                                  fill="white"
                                />
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M4.32438 0C4.68313 0 4.97395 0.290823 4.97395 0.649572V3.58974C4.97395 3.94849 4.68313 4.23931 4.32438 4.23931C3.96563 4.23931 3.6748 3.94849 3.6748 3.58974V0.649572C3.6748 0.290823 3.96563 0 4.32438 0Z"
                                  fill="white"
                                />
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M0 6.52848C0 6.16973 0.290823 5.87891 0.649572 5.87891H13.8803C14.2391 5.87891 14.5299 6.16973 14.5299 6.52848C14.5299 6.88723 14.2391 7.17805 13.8803 7.17805H0.649572C0.290823 7.17805 0 6.88723 0 6.52848Z"
                                  fill="white"
                                />
                              </svg>
                            </MDButton>
                            
                          </FormGroup>
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <FormGroup
                            mt={1}
                            className="input-icon calendar-view"
                           
                          >
                            <InputLabel className="formlabel">
                              {t("End date")}
                            </InputLabel>
                            <TextField
                              className="forminput"
                              disabled={d.lock}
                              placeholder={t("Select date")}
                              variant="outlined"
                              value = {d.end}
                              InputProps={{
                                readOnly: true, // Make the field read-only
                              }}
                              onClick={(e) => {
                                var temp = [...formData.contractList]
    
                                           temp[i].endcal = true
                                          setFormData((prev)=>({
                                            ...prev,
                                            contractList:temp
                                          }))
                              }}
                            />

                             {d.endcal && (
                            
                             <ClickAwayListener
                          onClickAway={(e) => {
                            var temp = [...formData.contractList]

                                       temp[i].endcal = false
                                      setFormData((prev)=>({
                                        ...prev,
                                        contractList:temp
                                      }))
                          }}
                        >
                          <div>
                               <ReactCalendar
                                    // onChange={(e) => console.log(e)}
                                    disabled={d.lock}
                                    className="forminput"
                                    value={new Date(d.end)}
                                    selectRange={false}
                                    minDate={new Date(d.start)}
                                    // locale={locale}
                                    locale={language == "fr" ? "fr-FR" :"en-EN"}
                                    onChange={(e) =>
                                     { var temp = [...formData.contractList]

                                       temp[i].end = moment(e).format("YYYY-MM-DD")
                                      setFormData((prev)=>({
                                        ...prev,
                                        contractList:temp
                                      }))
                                      }
                                    }
                                  />
                                  </div>
                                  </ClickAwayListener>
                                  )
                                  }
                            <MDButton
                             disabled={d.lock}
                                       onClick={(e) => {
                                        var temp = [...formData.contractList]
            
                                                   temp[i].endcal = true
                                                  setFormData((prev)=>({
                                                    ...prev,
                                                    contractList:temp
                                                  }))
                                      }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="17"
                                height="18"
                                viewBox="0 0 15 16"
                                fill="none"
                              >
                                <path
                                  d="M12.4111 2.12109H2.12047C1.30857 2.12109 0.650391 2.77927 0.650391 3.59118V13.8818C0.650391 14.6937 1.30857 15.3518 2.12047 15.3518H12.4111C13.223 15.3518 13.8811 14.6937 13.8811 13.8818V3.59118C13.8811 2.77927 13.223 2.12109 12.4111 2.12109Z"
                                  fill="#16273C"
                                />
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M2.11966 2.76789C1.6665 2.76789 1.29914 3.13525 1.29914 3.58841V13.879C1.29914 14.3321 1.6665 14.6995 2.11966 14.6995H12.4102C12.8634 14.6995 13.2308 14.3322 13.2308 13.879V3.58841C13.2308 3.13525 12.8634 2.76789 12.4102 2.76789H2.11966ZM0 3.58841C0 2.41775 0.949002 1.46875 2.11966 1.46875H12.4102C13.5809 1.46875 14.5299 2.41775 14.5299 3.58841V13.879C14.5299 15.0496 13.5809 15.9986 12.4102 15.9986H2.11966C0.949003 15.9986 0 15.0496 0 13.879V3.58841Z"
                                  fill="white"
                                />
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M10.2062 0C10.565 0 10.8558 0.290823 10.8558 0.649572V3.58974C10.8558 3.94849 10.565 4.23931 10.2062 4.23931C9.84746 4.23931 9.55664 3.94849 9.55664 3.58974V0.649572C9.55664 0.290823 9.84746 0 10.2062 0Z"
                                  fill="white"
                                />
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M4.32438 0C4.68313 0 4.97395 0.290823 4.97395 0.649572V3.58974C4.97395 3.94849 4.68313 4.23931 4.32438 4.23931C3.96563 4.23931 3.6748 3.94849 3.6748 3.58974V0.649572C3.6748 0.290823 3.96563 0 4.32438 0Z"
                                  fill="white"
                                />
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M0 6.52848C0 6.16973 0.290823 5.87891 0.649572 5.87891H13.8803C14.2391 5.87891 14.5299 6.16973 14.5299 6.52848C14.5299 6.88723 14.2391 7.17805 13.8803 7.17805H0.649572C0.290823 7.17805 0 6.88723 0 6.52848Z"
                                  fill="white"
                                />
                              </svg>
                            </MDButton>
                            
                          </FormGroup>
                        </Grid>
                        <Grid item xs={12} md={2}>
                          <MDButton className="delete-btn" 
                          disabled={d.lock}
                          onClick={(e)=>{
                            deleteContract(i)
                          }}
                          ><svg xmlns="http://www.w3.org/2000/svg" width="17" height="19" viewBox="0 0 13 15" fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M0.151367 3.59384C0.151367 3.32412 0.370019 3.10547 0.639739 3.10547H12.3607C12.6304 3.10547 12.849 3.32412 12.849 3.59384C12.849 3.86356 12.6304 4.08221 12.3607 4.08221H0.639739C0.370019 4.08221 0.151367 3.86356 0.151367 3.59384Z" fill="#16273C"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M5.19829 1.47674C4.98241 1.47674 4.77538 1.5625 4.62274 1.71515C4.47009 1.86779 4.38433 2.07482 4.38433 2.2907V3.59302C4.38433 3.86274 4.16568 4.0814 3.89596 4.0814C3.62624 4.0814 3.40759 3.86274 3.40759 3.59302V2.2907C3.40759 1.81577 3.59625 1.3603 3.93207 1.02448C4.26789 0.688662 4.72337 0.5 5.19829 0.5H7.80294C8.27786 0.5 8.73333 0.688662 9.06915 1.02448C9.40497 1.3603 9.59364 1.81577 9.59364 2.2907V3.59302C9.59364 3.86274 9.37498 4.0814 9.10526 4.0814C8.83554 4.0814 8.61689 3.86274 8.61689 3.59302V2.2907C8.61689 2.07482 8.53114 1.86779 8.37849 1.71515C8.22584 1.5625 8.01881 1.47674 7.80294 1.47674H5.19829ZM1.94247 3.10465C2.21219 3.10465 2.43085 3.3233 2.43085 3.59302V12.7093C2.43085 12.9252 2.5166 13.1322 2.66925 13.2849C2.82189 13.4375 3.02893 13.5233 3.2448 13.5233H9.75643C9.9723 13.5233 10.1793 13.4375 10.332 13.2849C10.4846 13.1322 10.5704 12.9252 10.5704 12.7093V3.59302C10.5704 3.3233 10.789 3.10465 11.0588 3.10465C11.3285 3.10465 11.5471 3.3233 11.5471 3.59302V12.7093C11.5471 13.1842 11.3585 13.6397 11.0226 13.9755C10.6868 14.3113 10.2314 14.5 9.75643 14.5H3.2448C2.76988 14.5 2.31441 14.3113 1.97858 13.9755C1.64276 13.6397 1.4541 13.1842 1.4541 12.7093V3.59302C1.4541 3.3233 1.67275 3.10465 1.94247 3.10465Z" fill="#16273C"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M5.19833 6.35938C5.46805 6.35938 5.68671 6.57803 5.68671 6.84775V10.7547C5.68671 11.0244 5.46805 11.2431 5.19833 11.2431C4.92861 11.2431 4.70996 11.0244 4.70996 10.7547V6.84775C4.70996 6.57803 4.92861 6.35938 5.19833 6.35938Z" fill="#16273C"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M7.80283 6.35938C8.07255 6.35938 8.2912 6.57803 8.2912 6.84775V10.7547C8.2912 11.0244 8.07255 11.2431 7.80283 11.2431C7.5331 11.2431 7.31445 11.0244 7.31445 10.7547V6.84775C7.31445 6.57803 7.5331 6.35938 7.80283 6.35938Z" fill="#16273C"/>
                            </svg></MDButton>
                        </Grid>
                      </Grid>

                      </Box>
                      </Grid>
                      </Grid>
                      }
                      
             
                      </>
                   )) }

              <Box className="navigation-link1">
                <MDButton className="primary-btn btn xl" onClick={(e)=>handleAddContract()}>{t("Add Contract")}</MDButton>
              </Box> 





                      
                   
                  </Box>
                </Box>
              </Box>
              <Box className="navigation-link">
                <MDButton type="submit" className="primary-btn btn xl">{t("Update")}</MDButton>
              </Box>
            </MDBox>
          </Box>
        </Box>
        <Modal
          open={contractModal.open}
          onClose={()=>setContractModal((prev)=>({
            ...prev,
          open:false,
          }))}
          aria-labelledby="modal-modal-title"
          className="cs-modal xs info"
          aria-describedby="modal-modal-description"
        >
          <Box className="modal-dialog">
            <Box className="modal-content" component="form">
              <Box className="modal-header">
                <MDButton
                  className="modal-close"
                  onClick={() =>  setContractModal({
                    open:false,
                    contractId:"",
                    contractIndex:"",
                  })}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M13.7071 0.292893C13.3166 -0.0976311 12.6834 -0.0976311 12.2929 0.292893L0.292893 12.2929C-0.0976315 12.6834 -0.0976315 13.3166 0.292893 13.7071C0.683416 14.0976 1.31658 14.0976 1.70711 13.7071L13.7071 1.70711C14.0976 1.31658 14.0976 0.683417 13.7071 0.292893Z"
                      fill="white"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M0.292893 0.292893C0.683417 -0.0976311 1.31658 -0.0976311 1.70711 0.292893L13.7071 12.2929C14.0976 12.6834 14.0976 13.3166 13.7071 13.7071C13.3166 14.0976 12.6834 14.0976 12.2929 13.7071L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683417 0.292893 0.292893Z"
                      fill="white"
                    />
                  </svg>
                </MDButton>
              </Box>
             
              <Box className="modal-body">
                  <h4>{t("alerts.activitycollision.Oops")}</h4>
                  <p>
                 {t("contract_end")}  </p>
                  <Box>
                  
                      <MDButton
                        className="secondary-btn"
                        onClick={(e) =>handleEndCOntract("modal")}
                      >
                        {t("End contract")}
                      </MDButton>
                    <MDButton
                      className="secondary-btn transparent"
                      onClick={(e) => setContractModal({
                        open:false,
                        contractId:"",
                        contractIndex:"",
                      })}
                    >
                      {t("Scheduler1.cancelbtn")}
                    </MDButton>
                  </Box>
                </Box>
            </Box>
          </Box>
        </Modal>
      </MDBox>
    </DashboardLayout>
  );
}

export default EditStaff;