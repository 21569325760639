import { useState, useMemo, useRef, useCallback, useEffect } from "react";
import {
  GoogleMap,
  useLoadScript,
  Marker,
  useJsApiLoader,
} from "@react-google-maps/api";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from "@reach/combobox";
import Typography from "@material-ui/core/Typography";
import "@reach/combobox/styles.css";
import { Button } from "@mui/material";
import { exceptioncountry } from "./validation";
import { useTranslation } from "react-i18next";

export default function Searchmap({ onClick, onClose, onCancel ,coordinate}) {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_KEY,
    libraries: ["places"]
  });

  if (!isLoaded) return <div>Loading...</div>;
  return <Map onClick={onClick} onClose={onClose} onCancel={onCancel}  coordinate={coordinate}/>;
}

function Map({ onClick, onClose, onCancel,coordinate }) {
  const center = useMemo(() => ({ lat: 43.45, lng: -80.49 }), []);
  const [selected, setSelected] = useState(null);
  const [alldetails, setAllDetails] = useState(null);
  const [map, setMap] = useState(null);
  const mapref = useRef();
 
  const onLoad = useCallback((map) => (mapref.current = map), []);
  const handleDetails = (e) => {
    //console.log("details===>", e);
    onClick(e);
  };

  const [location, setLocation] = useState(null);
  async function getlocationInfo(lat, lng) {
    const results = await getGeocode({ location: { lat: lat, lng: lng } });
    if (results.length > 0) {
      //console.log("Address:", results[0]);

      // Extract the country code (ISO 3166-1 alpha-2 code)
      var country = results[0]?.address_components?.find((component) =>
        component.types.includes("country")
      )?.short_name;
      var countrylong = results[0]?.address_components?.find((component) =>
      component.types.includes("country")
    )?.long_name;
      //console.log("Country Code:", country);
      const { lat, lng } = await getLatLng(results[0]);
      const address = results[0].formatted_address;
      //console.log("Address:", address); 

      // Extract city from address components
      const city = results[0].address_components.find((component) =>
        component.types.includes("locality")
      )?.long_name;
      const regionComponent = results[0].address_components.find(component =>
        component.types.includes('administrative_area_level_1')
      );
      console.log("exception checking == ",exceptioncountry.some((ele)=>(ele==countrylong || ele==country)))
      if(exceptioncountry.some((ele)=>(ele==countrylong || ele==country)))
        {
          country = "FR"
          countrylong = "France"
        }
      // return regionComponent ? regionComponent.long_name : null;
      const data = {
        countryName:countrylong,
        // result:results[0],
        address: address,
        country: country,
        coordinates: [getLatLng(results[0])],
        city: city,
        region:regionComponent ? regionComponent.long_name : ""
      };
      handleDetails(data);
      //console.log("current location data ", data);

      setSelected({ lat, lng });
      // You can use the country code as needed (e.g., "GB" for the United Kingdom)
    }
  }
  useEffect(() => {
    // Check if the browser supports Geolocation API
    console.log("coordinates",coordinate)
    if(coordinate)
    {
      const { lat, lng } =coordinate[0];
      getlocationInfo(lat, lng);
      //console.log({ latitude, longitude });
      mapref.current?.panTo({ lat: lat, lng: lng });
    }else{

  
    if (navigator.geolocation) {
      // Get the user's current location
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          // setLocation({ latitude, longitude });
          getlocationInfo(latitude, longitude);
          //console.log({ latitude, longitude });
          mapref.current?.panTo({ lat: latitude, lng: longitude });
        },
        (error) => {
          console.error("Error getting location:", error.message);
        }
      );
    } else {
      console.error("Geolocation is not supported by your browser");
    }
  }
  }, []);
  // const onUnmount = () => {
  //   setMap(null);
  // };
  return (
    <>
      
            <div className="places-container">
              <div className="search-result">
                <PlacesAutocomplete
                  handleDetails={handleDetails}
                  setSelected={(pos) => {
                    setSelected(pos);
                    mapref.current?.panTo(pos);
                  }}
                />
              </div>
            </div>
            <div className="google-map">
              <GoogleMap
                mapContainerStyle={{ height: "100%", width: "100%" }}
                center={center}
                zoom={14}
                onLoad={onLoad}
                // onUnmount={onUnmount}
              >
                {selected && <Marker position={selected} />}
              </GoogleMap>
           
            </div>
         
    </>
  );
}

const PlacesAutocomplete = ({ setSelected, handleDetails }) => {
  const {
    ready,
    value,
    setValue,
    suggestions: { status, data },
    clearSuggestions,
  } = usePlacesAutocomplete();
  // console.log(data);
  const {t} = useTranslation()
  const handleSelect = async (address) => {
    // console.log(address);
    setValue(address, false);
    clearSuggestions();


   
    const results = await getGeocode({ address });
    //console.log("geocode result==>", results);
    if (results.length > 0) {
      //console.log("Address:", results[0]);

      // Extract the country code (ISO 3166-1 alpha-2 code)
      var country = results[0]?.address_components?.find((component) =>
        component.types.includes("country")
      )?.short_name;
      var countrylong = results[0]?.address_components?.find((component) =>
      component.types.includes("country")
    )?.long_name;
      //console.log("Country Code:", country);
      const { lat, lng } = await getLatLng(results[0]);
      const address = results[0].formatted_address;
      //console.log("Address:", address);

      // Extract city from address components
      const city = results[0].address_components.find((component) =>
        component.types.includes("locality")
      )?.long_name;
      const regionComponent = results[0].address_components.find(component =>
        component.types.includes('administrative_area_level_1')
      );
      // console.log("exception checking == ",exceptioncountry.some((ele)=>(ele==countrylong || ele==country)),countrylong,country,exceptioncountry)
      if(exceptioncountry.some((ele)=>(ele==countrylong || ele==country)))
        {
          country = "FR"
          countrylong = "France"
          
        }
      // return regionComponent ? regionComponent.long_name : null;
      const data = {
        countryName:countrylong,
        address: address,
        country: country,
        coordinates: [getLatLng(results[0])],
        city: city,
        region:regionComponent ? regionComponent.long_name : ""
      };
      handleDetails(data);
      //console.log(lat, lng);

      setSelected({ lat, lng });
      // You can use the country code as needed (e.g., "GB" for the United Kingdom)
    }
  };

  return (
    // <>
    //   <Combobox onSelect={handleSelect}>
    //     <ComboboxInput
    //       value={value}
    //       onChange={(e) => setValue(e.target.value)}
    //       disabled={!ready}
    //       className="combobox-input"
    //       placeholder="Enter the location"
    //     />
    //     <ComboboxPopover>
    //       <ComboboxList>
    //       {status === "OK" &&
    //           data.map(({ place_id, description }) => (
    //             <ComboboxOption key={place_id} value={description} />
    //           ))}
    //       </ComboboxList>
    //     </ComboboxPopover>
    //   </Combobox>
     
    //   {status === "OK" &&
    //     data.map(({ place_id, description }) => (
    //       <div key={place_id} value={description}>
    //         {description}
    //       </div>
    //     ))}
    // </>
      <>
      <input
         value={value}
         onChange={(e) => {setValue(e.target.value)}}
         disabled={!ready}
         className="combobox-input"
         placeholder={t("Search a location")}
      ></input>
   
        {status === "OK" &&
              data.map(({ place_id, description }) => (
                <div key={place_id} value={description} onClick={()=>handleSelect(description)}>
                  {description}
                  </div>
              ))}
      </>
  );
};
