import React, { useState } from 'react';
import alasql from 'alasql';
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";

import FormGroup from "@mui/material/FormGroup";

import { useTranslation } from "react-i18next";
const SearchComponent = ({onSearch,searchList,specialChar,placeholder}) => {
  const [searchTerm, setSearchTerm] = useState('');

  const { t } = useTranslation();
  const handleSearch = (text) => {
    if(specialChar != true){

      text = text.replace(/[^a-zA-Z0-9 ]/g, '');
    }
    setSearchTerm(text)
    
    setSearchTerm(text)
    if (text.trim() === '') {
        onSearch(searchList)
      return;
    }
    const results = alasql(`SELECT * FROM ? WHERE label LIKE '%${text.trim()}%'`, [searchList]);

    onSearch(results)
    // setSearchResults(results);
  };

  return (

      <FormGroup className="search-input">
      <Box className="search-icon">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="21"
          viewBox="0 0 20 21"
          fill="none"
        >
          <path
            d="M9 17.5C13.4183 17.5 17 13.9183 17 9.5C17 5.08172 13.4183 1.5 9 1.5C4.58172 1.5 1 5.08172 1 9.5C1 13.9183 4.58172 17.5 9 17.5Z"
            stroke="#16273C"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M19.0004 19.5004L14.6504 15.1504"
            stroke="#16273C"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </Box>
      <TextField
        className="forminput"
        placeholder={placeholder ? t(`Search ${placeholder}`) : t("Search")}
        variant="outlined"
        value={searchTerm}
        onChange={(e) => handleSearch(e.target.value)}
        
      />
    </FormGroup>
  );
};

export default SearchComponent;
