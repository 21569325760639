import { user } from "backendservice/firebase";
import { db } from "backendservice/firebase";
import { doc, getDocs, query, where } from "firebase/firestore";

const checkDeleteContract = async(id)=>{

    const q = query(user, where("contractTypeId", "==", id));

    // Execute the query and get the snapshot
    const snapshot = await getDocs(q);

    // Count the number of documents in the snapshot
    const count = snapshot.size;
    console.log(count);
    if(count==0)
        {
            return true
        }else{
            return false
        }
}
export {checkDeleteContract}