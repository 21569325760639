import { Box } from "@mui/material";
import moment from "moment";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
function convert(decimalHours) {
  // Get the whole number of hours
  console.log('decimal == ',decimalHours)
  decimalHours = Number(decimalHours)
  if(isNumber(decimalHours))
{  const hours = Math.floor(decimalHours);

  // Get the fractional part and convert it to minutes
  const minutes = Math.round((decimalHours - hours) * 60);

  // Format hours and minutes to always have two digits
  const formattedHours = String(hours).padStart(2, '0');
  const formattedMinutes = String(minutes).padStart(2, '0');

  // Return the formatted string as "HH:mm"
  return `${formattedHours}:${formattedMinutes}`;}
  else{
    return "-"
  }
}
function isNumber(value) {
  return typeof value === 'number' && !isNaN(value);
}

const Tableelement = ({user,activity,report,mode}) => {
 
var record = []
var total = 0
var totalnoofdays = 0
// console.log("inside activity == ",user,activity,report)
activity.map((d,i)=>{
  var userinfo = report.report[user]
  // console.log(userinfo)
  var rec = "-"
  // console.log("number_of_days ==",userinfo.activityNoOfDays[d.activityName.trim()],userinfo.activityNoOfDays)
  totalnoofdays += Number.isFinite(userinfo.activityNoOfDays[d.activityName.trim()])
  ? userinfo.activityNoOfDays[d.activityName.trim()]
  : 0;
   if(mode==0)
  {
    rec = userinfo.activity[d.activityName.trim()]
    total += parseFloat(rec)
 
    
    // activityDays
  }else if(mode==1)
  {
    rec = userinfo.activityDays[d.activityName.trim()]
    total += parseFloat(rec)
  }
  else if(mode==2)
  { 
    rec = "-"
    total += parseFloat(rec)
  }
  
  record.push(rec)

  // record.push(userinfo[activity.id])
})

if (Number.isNaN(total)) {
  total = "-"
} else{
  if(mode==0)
    {
      total = convert(total)
    }else{
      total = total.toFixed(2)
    }
  // total = convert(total)
}
  return (
    <Box className="event-main">
                              <ul>
                               {record.map((d,i)=>(
 <li>{mode==0?convert(d):d}</li>
                               ))}
                              <li>{total}</li>
                              <li>{totalnoofdays}</li>
                              </ul>
                            </Box>
  );
};
const Tableelementoncall = ({user,activity,report,mode}) => {
 
  var record = []
  var total = 0
  var totalquantity = 0
  // console.log("acticity ==",activity)
  activity.map((d,i)=>{
    var userinfo = report.report[user]
    // console.log(userinfo)
    var rec = "-"
    if(mode==0)
    {
      rec = userinfo.oncall[d.id]
      total += parseFloat(rec)
      if(userinfo.oncall[d.id]>0)totalquantity++
      // activityDays
   
    }else if(mode==1)
    {
      rec = userinfo.oncallDays[d.id]
      total += parseFloat(rec)
      if(userinfo.oncall[d.id]>0)totalquantity++
    }
    else if(mode==2)
    { 
      rec = "-"
      if(userinfo.oncall[d.id]>0)totalquantity++
      // total += rec
    }
    record.push(rec)
    // record.push(userinfo[activity.id])
})
  // console.log("type of total == ",typeof total,total)
  if (Number.isNaN(total)) {
    total = "-"
  } else{
    if(mode==0)
    {
      total = convert(total)
    }else{
      total = total.toFixed(2)
    }
     
  }
  if (Number.isNaN(totalquantity)) {
    totalquantity = "-"
  } 
    return (
      <Box className="event-main">
                                <ul>
                                 {record.map((d,i)=>(
   <li>{mode==0?convert(d):d}</li>
                                 ))}
                                 <li>{total}</li>
                                 <li>{totalquantity}</li>
                                </ul>
                              </Box>
    );
  };
  const Leaveelement = ({user,report,mode}) => {
 
    // var record = []

   
        var userinfo = report.report[user]
        var rec = 0 
        if(userinfo.leavecount) rec = userinfo.leavecount
        
       
        // record.push(rec)
        // record.push(userinfo[activity.id])
  
      return (
        <Box className="event-main">
                                  <ul>
                                  
                                   <li>{rec}</li>
                                  </ul>
                                </Box>
      );
    };
    const Staffdata = ({user,report,mode}) => {
 
      // var record = []
  
  var userinfo = report.report[user]
          var rec = userinfo.contract_duration || 0
          var startdate = userinfo.contract_startdate
          var endDate = userinfo.contract_endDate
          var contactName = userinfo.contract_contactName
         
          // if(userinfo.leavecount) rec = userinfo.contracttime
          
         
          // record.push(rec)
          // record.push(userinfo[activity.id])
    
        return (
          <Box className="event-main">
                                    <ul>
                                    <li>{startdate}</li>
                                    <li>{endDate}</li>
                                    <li>{contactName}</li>
                                     {/* <li>{rec}</li> */}
                                    </ul>
                                  </Box>
        );
      };
// Main App component using the above components

export { Tableelement,Tableelementoncall,Leaveelement,Staffdata};
