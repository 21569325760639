import { useEffect, useRef, useState } from "react";
import moment from 'moment';
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MDButton from "components/MDButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Calerdar from "../../assets/images/resonante/calendar-icon.png";
import Switch from "@mui/material/Switch";
import MDTypography from "components/MDTypography";
import { Tooltip, Typography } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
//modal import
import Modal from "@mui/material/Modal";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useNavigate ,useParams} from "react-router-dom";
import { useMaterialUIController } from "context";
import { useAuthState } from "react-firebase-hooks/auth";
import {auth} from "backendservice/firebase";
import { add_leave } from "layouts/scheduler/functions";
import Loader from "components/Loader";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "backendservice/firebase";
import { update_leave } from "backendservice/firebasefunctions,";
import dayjs from "dayjs";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Explanation from "components/Explanation";
import { checkDates } from "layouts/scheduler/functions";
import Loading from "../../assets/images/icons/heart2.gif";
import { add_leave_schedule } from "layouts/scheduler/functions";

function Profile() {
  const navigate = useNavigate();
  const formRef = useRef(null);
  const [isDataLoaded, setIsDataLoaded] = useState(false);

  const [answersPost, setAnswersPost] = useState(false);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [controller, dispatch] = useMaterialUIController();
  const {selecetdCustomer,selected_clinic} = controller;
  const [modalopen, setmodalopen] = useState(false);
  const [user] =useAuthState(auth)
  const [halfDay,setHalfDay] = useState(false)
  const [days,setDays] = useState([])
  const [formaldates,setformaldates] =useState([])
  var selectedLanguage = localStorage.getItem("i18nextLng");
  const [openToDate, setOpenToDate] = useState(false); // State to control 'toDate' DatePicker
  const handleOpenToDate = () => {
    setOpenToDate(true); // Open 'toDate' DatePicker
  };

  const handleCloseToDate = () => {
    setOpenToDate(false); // Close 'toDate' DatePicker
  };
  const [inputData, setInputData] = useState(
    {
    staff: "",
    leaveType: "Select Type",
    fromDate: "",
    toDate: "",
    reason: ""
    }
  )
  const [oldData, setOldData] = useState(
    {
    staff: "",
    leaveType: "Select Type",
    fromDate: "",
    toDate: "",
    reason: ""
    }
  )
  const [oldData2, setOldData2] = useState(
    {
    fromDate: "",
    toDate: "",
    }
  )

  //loadData 
  const { id } = useParams();

  const handleSelectMorning = (date) => {
    const newData = days.map((item) =>
      item.date === date ? { ...item, isSelectedMorning: !item.isSelectedMorning } : item
    );
    setDays(newData);
  };

  const handleSelectAfternoon = (date) => {
    const newData = days.map((item) =>
      item.date === date ? { ...item, isSelectedAfternoon: !item.isSelectedAfternoon } : item
    );
    setDays(newData);
  };

  
  const loadData = async () => {
    setLoading(true)
    try {
      // setLoading(true);
      var datass = localStorage.getItem("user");
      const docRef = doc(db, "leave", id);
      const staffRef = await getDoc(docRef);
      const leaveData = staffRef.data();
      if (!leaveData) {
        alert(t("Something went wrong!"));
        navigate(-1)
        return;
      }

      const json = JSON.parse(datass);
      const customer = json.customer;
      // setUserData(json);
      setInputData((prevData) => ({ ...prevData , createdTime: leaveData.createdTime, staff:leaveData.staff,leaveType:leaveData.leaveType,fromDate:dayjs(moment(new Date(leaveData.fromDate.seconds * 1000))),toDate:dayjs(moment(new Date(leaveData.toDate.seconds * 1000))),reason:leaveData.reason ,days:leaveData.days,userId:leaveData.userId}));
      setOldData2((prevData) => ({ ...prevData , fromDate:dayjs(moment(new Date(leaveData.fromDate.seconds * 1000))),toDate:dayjs(moment(new Date(leaveData.toDate.seconds * 1000)))}));
      setOldData((prevData) => ({ ...prevData , createdTime: leaveData.createdTime, staff:leaveData.staff,leaveType:leaveData.leaveType,fromDate:leaveData.fromDate,toDate:leaveData.toDate,reason:leaveData.reason ,days:leaveData.days,defaultClinicId:leaveData.defaultClinicId}));
      setHalfDay(leaveData.halfDay)
      setformaldates(leaveData.dates)
      const sortedDates = leaveData.dateList
      .map((item) => item.date)
      .sort((a, b) => new Date(a) - new Date(b));
    
    // Set days state value
    const daysStateValue = sortedDates.map((date) => {
      const correspondingItem = leaveData.dateList.find((item) => item.date === date);
      return {
        date,
        isSelectedMorning: correspondingItem.slot === "morning" || correspondingItem.slot === "fullday",
        isSelectedAfternoon: correspondingItem.slot === "afternoon" || correspondingItem.slot === "fullday",
      };
    });
    
      // Now, set the days state using the daysStateValue
      setDays(daysStateValue);

      setLoading(false)
      setIsDataLoaded(true); // Set data loaded state to true

    } catch (error) {
      setLoading(false)

    }
  }
  const staticLeaveTypes = [
    { value: "Paid Leave", label: t("Paid Leave") },
    { value: "Unpaid Leave", label: t("Unpaid Leave") },
    { value: "Paternity Leave", label: t("Paternity Leave") },
    { value: "Maternity Leave", label: t("Maternity Leave") },
    { value: "Sick Leave", label: t("Sick Leave") },
    { value: "Day Off", label: t("Day Off") },
    { value: "Compensatory Time Off", label: t("Compensatory Time Off") },
    { value: "Training", label: t("Training") },
    { value: "JNT (Ex RTT)", label: t("JNT (Ex RTT)") },

  ];
  //modal open close functionn
  const handlemodalopen = () => {
    setmodalopen(true);
  };
  const handlemodalClose = () => {
    setmodalopen(false);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setInputData((prevFormData) => ({ ...prevFormData, [name]: value }));
   
  };
  const isDisabled = () => {
    if(user?.uid !== inputData.userId){
      return true
    }else{
      return false
    }
  }
  
  const calculateDaysDifference = () => {
    if (inputData.fromDate !== "" && inputData.toDate !== "") {
      const fromDate = moment(new Date(inputData.fromDate.$d));
      const toDate = moment(new Date(inputData.toDate.$d));
      const today = moment();
  
      // if (!fromDate.isSameOrAfter(today, "day") || toDate.diff(fromDate, "days") + 1 <= 0) {
      //   if (!inputData.invalidDateAlertShown) {
      //     alert(t("Please select a valid Date."));
      //     setInputData((prevFormData) => ({
      //       ...prevFormData,
      //       invalidDateAlertShown: true,
      //       fromDate: "",
      //       toDate: "",
      //     }));
      //   }
      //   return; // Exit the function early
      // }
  
      // Reset the flag if the date is valid
      setInputData((prevFormData) => ({ ...prevFormData, invalidDateAlertShown: false }));
    } else {
      // Reset the flag when the dates are empty
      setInputData((prevFormData) => ({ ...prevFormData, invalidDateAlertShown: false }));
    }
  };
  

  const saveActivity = async (e) => {
    e.preventDefault();
    setLoading(true)

   
    const result = [];
    const datesToAlert = []; // Array to store dates that need to be alerted
    if(formaldates.length === 0){
      setLoading(false)
      alert(t("Sorry, no available dates for the selected leave period. Please choose different dates."))
      return
    }
    if (halfDay) {
      days.filter((item) => !item.isRemoved).forEach((item) => {
        const date = moment(item.date);
        const formattedDate = date.format("YYYY/MM/DD");
  
        if (!item.isSelectedMorning && !item.isSelectedAfternoon) {
          datesToAlert.push(formattedDate); // Collect dates that need to be alerted
        }
  
        if (item.isSelectedMorning && item.isSelectedAfternoon) {
          result.push({
            slot: "fullday",
            value: 0,
            date: formattedDate,
            leaveType: inputData.leaveType,
          });
        } else if (item.isSelectedMorning) {
          result.push({
            slot: "morning",
            value: 1,
            date: formattedDate,
            leaveType: inputData.leaveType,
          });
        } else if (item.isSelectedAfternoon) {
          result.push({
            slot: "afternoon",
            value: 2,
            date: formattedDate,
            leaveType: inputData.leaveType,
          });
        }
      });
  
      if (datesToAlert.length > 0) {
        alert(`${t("Please select slot for the following dates:")} ${datesToAlert.join(", ")}`);
        setLoading(false); // Stop loading
        return;
      }
    } else {
      days.filter((item) => !item.isRemoved).forEach((item) => {
        const date = moment(item.date);
        const formattedDate = date.format("YYYY/MM/DD");
  
        if (item.isAfternoonAvailable && item.isMorningAvailable) {
          result.push({
            slot: "fullday",
            value: 0,
            date: formattedDate,
            leaveType: inputData.leaveType,
          });
        } else if (item.isMorningAvailable) {
          result.push({
            slot: "morning",
            value: 1,
            date: formattedDate,
            leaveType: inputData.leaveType,
          });
        } else if (item.isAfternoonAvailable) {
          result.push({
            slot: "afternoon",
            value: 2,
            date: formattedDate,
            leaveType: inputData.leaveType,
          });
        }
      });
    }
    let temp = {
      ...inputData,
      userId:user?.uid,
      customer:selecetdCustomer.id   ,
      fromDate:new Date(inputData.fromDate.$d),
      toDate:new Date(inputData.toDate.$d),
      status:null,
      dateList: result,
      halfDay: halfDay,
      dates:formaldates,
      days:formaldates.length
     }
      await update_leave(id ,temp)
      await add_leave_schedule(
        temp.customer,
        oldData.defaultClinicId,
        temp.userId,
        id,
        "updated"
      );
    // await add_leave(temp.customer,oldData.defaultClinicId,temp.userId,oldData.fromDate,oldData.toDate,{leaveType:temp.leaveType,reason:temp.reason},"updated")
      alert(t("Leave Updated successfully!"))
      navigate(-1)
      setLoading(false)

  }
  
  const generateDateList = async (fromDate, toDate) => {

  // Check if oldData and defaultClinicId are defined

    let oldfrom = moment.unix(oldData.fromDate.seconds)
    let oldto = moment.unix(oldData.toDate.seconds)
    let newfrom = moment(new Date(fromDate.$d))
    let newto = moment(new Date(toDate.$d))
    setLoading(true)
    if (!oldfrom.isSame(newfrom) || !oldto.isSame(newto)) {

      const dates = [];
    let response = await checkDates(fromDate,toDate,selecetdCustomer.id,selected_clinic.id,user?.uid,id)

   for (let i = 0; i < response.length; i++) {
    const item = response[i];
    let obj = {
      date: moment(item.date),
      isSelectedAfternoon: false,
      isSelectedMorning: false,
      isRemoved: item.isRemoved,
      removeReason: item.removeReason,
      isMorningAvailable: item.isMorningAvailable,
      isAfternoonAvailable: item.isAfternoonAvailable,
    };
      dates.push(obj)
   }
   const formalDates = response
   .filter((item) => !item.isRemoved)
   .map((item) => moment(item.date).format("YYYY-MM-DD"));

     setformaldates(formalDates)
    setDays(dates);
    setLoading(false)

    }
  };
  
  useEffect(() => {
    calculateDaysDifference();
    generateDateList(inputData.fromDate,inputData.toDate)
  }, [inputData.fromDate, inputData.toDate]);
  
  useEffect(() => {
    if (isDataLoaded) {
      generateDateList(inputData.fromDate, inputData.toDate);
    }
  }, [isDataLoaded, inputData.fromDate, inputData.toDate]);
  
  useEffect(() => {
    loadData()
    generateDateList(inputData.fromDate,inputData.toDate)
  },[selecetdCustomer])
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Loader loading={loading} />
      <MDBox pt={4} pb={3} className="xl-container leave-management-form">
        <Box>
          <Box>
            <MDBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <MDBox className="page-heading">
                <MDTypography
                  variant="h5"
                  gutterBottom
                  sx={{ pb: "8px", display: "flex" }}
                >
                  <Box
                    sx={{
                      pt: "2px",
                      width: "22px;",
                      display: { xs: "none", sm: "flex" },
                    }}
                  >
                    <MDButton onClick={() => navigate(-1)}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M0 5.99961C0 5.4524 0.443608 5.00879 0.990826 5.00879H11.0092C11.5564 5.00879 12 5.4524 12 5.99961C12 6.54683 11.5564 6.99044 11.0092 6.99044H0.990826C0.443608 6.99044 0 6.54683 0 5.99961Z"
                          fill="#16273C"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M6.70062 0.290206C7.08756 0.677148 7.08756 1.3045 6.70062 1.69145L2.39206 6L6.70062 10.3086C7.08756 10.6955 7.08756 11.3229 6.70062 11.7098C6.31368 12.0967 5.68632 12.0967 5.29938 11.7098L0.290206 6.70062C-0.0967354 6.31368 -0.0967354 5.68632 0.290206 5.29938L5.29938 0.290206C5.68632 -0.0967354 6.31368 -0.0967354 6.70062 0.290206Z"
                          fill="#16273C"
                        />
                      </svg>
                    </MDButton>
                  </Box>
                  {t("Leave application")}
                </MDTypography>
              </MDBox>
            </MDBox>
            <MDBox className="card-layout"
              component="form"
              onSubmit={(e) => saveActivity(e)}
              ref={formRef}>
              <Box className="form-main">
                <Box className="form-content">
                  <Box sx={{ width: { xs: "100%", sm: "100%" } }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <Box sx={{ mb: 2 }}>
                          <InputLabel className="formlabel">
                            {t("Employee name")}<strong style={{color:"red"}}> *</strong> 
                          </InputLabel>
                          {/* <Box className="cs-dropdown icon">
                            <Select
                              className="form-select"
                              id="languageSelector"
                              label="Country"
                            >
                              <MenuItem value="" select>
                                Select an option
                              </MenuItem>
                            </Select>
                          </Box> */}
                          <TextField
                            className="forminput"
                            placeholder={t("Staff Name")}
                            variant="outlined"
                            name="staff"
                            value={inputData.staff}
                            onChange={handleInputChange}
                            required
                            disabled
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Box sx={{ mb: 2 }}>
                          <InputLabel className="formlabel">
                            {t("Leave type")}<strong style={{color:"red"}}> *</strong>
                          </InputLabel>
                          <Box className="cs-dropdown icon">
                            <Select
                              className="form-select"
                              id="languageSelector"
                              label="Leave Type"
                              value={inputData.leaveType}
                              onChange={handleInputChange}
                              name="leaveType"
                              placeholder="Select Leave type"
                              required
                              disabled={isDisabled()}
                            >
                              <MenuItem value="" disabled>
                                {t("Select an option")}
                              </MenuItem>
                              {staticLeaveTypes.map((leaveType) => (
                              <MenuItem key={leaveType.value} value={leaveType.value}>
                                {leaveType.label}
                              </MenuItem>
                            ))}
                            </Select>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={4}>
                        <FormGroup sx={{ mb: 2 }} className="input-icon">
                          <InputLabel className="formlabel">
                            {t("From (including)")}<strong style={{color:"red"}}> *</strong>
                          </InputLabel>
                          <LocalizationProvider className="date-main" 
                          adapterLocale={selectedLanguage == "fr" ? "fr" : "en"}
                          dateAdapter={AdapterDayjs}>
                          <DatePicker
                            label={t('leaveModuleDateFormet')}
                            disabled={true}
                           value={inputData.fromDate || null} // Bind value to state
                           onChange={(newDate) => handleInputChange({ target: { name: 'fromDate', value: newDate } })}
                           renderInput={(params) => <TextField {...params} />} 
                           slotProps={{
                            textField: {
                              placeholder: selectedLanguage === "fr" ? "JJ/MM/AAAA" : "MM/DD/YYYY",
                            },
                          }}
                          />
                      </LocalizationProvider>
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <FormGroup sx={{ mb: 2 }} className="input-icon">
                          <InputLabel className="formlabel">
                            {t("Up to (including)")}<strong style={{color:"red"}}> *</strong>
                          </InputLabel>
                           <LocalizationProvider className="date-main" dateAdapter={AdapterDayjs}
                          adapterLocale={selectedLanguage == "fr" ? "fr" : "en"}
                          >
                          <DatePicker 
                            label={t('leaveModuleDateFormet')}
                            minDate={inputData.fromDate || null}
                           value={inputData.toDate || null} // Bind value to state
                           onChange={(newDate) => handleInputChange({ target: { name: 'toDate', value: newDate } })}
                           renderInput={(params) => <TextField {...params} />} 
                           open={openToDate} // Control opening of 'toDate' DatePicker
                           onOpen={handleOpenToDate} // Handle opening
                           onClose={handleCloseToDate} // Handle closing
                           slotProps={{
                             textField: {
                               placeholder: selectedLanguage === 'fr' ? 'JJ/MM/AAAA' : 'MM/DD/YYYY',
                               onClick: handleOpenToDate, // Open calendar when clicking on the field
                             },
                           }}
                          />
                      </LocalizationProvider>
                        </FormGroup>
                      </Grid>
                    </Grid>

                    <Box className="left-content" sx={{ display: "flex" }}>
                      <FormControlLabel
                        sx={{
                          display: "flex",
                          textAlign: "center",
                          paddingBottom: "15px",
                          marginRight: "0",
                        }}
                        className="cs-checkbox"
                        label={t("Half working days")}
                        checked={halfDay}
                        onClick={() => setHalfDay(!halfDay)}
                        // disabled = {data.lock}
                        control={<Checkbox />}
                      />
                      <Explanation
                        toggle="left" 
                        contentLine1={t("If there are half working days to be included within the applied dates, the check box can be selected to proceed with selecting morning slot or afternoon slot for the preferred dates.")}
                      />
                    </Box>

                    {halfDay &&  inputData.fromDate && inputData.toDate && (
                      <List className="datatable">
                        {/* Display the "Select All" column */}
                        <ListItem>
                          <Box className="list-content">
                            <Box className="left-content">
                              <label className="heading bold">
                                {t("Select All")}
                              </label>
                              <Explanation
                                toggle="right"
                                contentLine1={t("Please note that selecting the 'Morning' option will reserve morning slots for all specified dates, while selecting 'Afternoon' will reserve afternoon slots accordingly. To request a full-day leave, kindly ensure that both the 'Morning' and 'Afternoon' checkboxes are selected for the preferred date(s).")}
                              />
                            </Box>
                            <Box className="blue-bg">
                              <FormGroup
                                sx={{
                                  marginTop: "8px",
                                  display: "flex",
                                  flexDirection: "row",
                                }}
                              >
                                <FormControlLabel
                                 
                                  className="formlabel cs-checkbox"
                                  control={
                                    <Checkbox
                                    onChange={() => setDays(days.map(item => ({ ...item, isSelectedMorning: !days.every(item => item.isSelectedMorning) })))}
                                    checked={days.every((item) => item.isSelectedMorning)}
                                    />
                                  }
                                  label={t("Morning")}
                                />
                                <FormControlLabel
                                  className="formlabel cs-checkbox"
                                  control={
                                    <Checkbox
                                    onChange={() => setDays(days.map(item => ({ ...item, isSelectedAfternoon: !days.every(item => item.isSelectedAfternoon) })))}
                                    checked={days.every((item) => item.isSelectedAfternoon)}
                                    />
                                  }
                                  label={t("Afternoon")}
                                />
                                {/* <FormControlLabel
          className="formlabel cs-checkbox"
          control={
            <Checkbox
              checked={inputData.selectAll === "FullDay"}
              onChange={(e) => handleSelectAll("FullDay", e.target.checked)}
            />
          }
          label={t("Full Day")}
        /> */}
                              </FormGroup>
                            </Box>
                          </Box>
                        </ListItem>

                        {/* Display individual date rows */}
                        {days.length > 0 ? days.map((date, index) => (
  <ListItem key={index}>
    <Box className="list-content">
      <Box className="left-content">
        <label className="heading"> 
          {moment(date.date).format("DD")} {t(moment(date.date).format("MMMM"))}, {moment(date.date).format("YYYY")}
        </label>
      </Box>
      <Box className="blue-bg">
        {date.isRemoved ? (
          <Tooltip title={t(date.removeReason)}>
            <div className="no-leave"><label>{t("Unavailable")}</label></div>
          </Tooltip>
        ) : (
          <FormGroup
            sx={{
              mt: 2,
              display: "flex",
              flexDirection: "row",
            }}
          >
            {date.isMorningAvailable ? (
              <FormControlLabel
                className="formlabel cs-checkbox"
                control={
                  <Checkbox
                    checked={date.isSelectedMorning}
                    onChange={() => handleSelectMorning(date.date)}
                  />
                }
                label={t("Morning")}
              />
            ) : (
              <Tooltip title={t("Morning Unavailable")}>
                <div className="no-leave"><label>{t("Unavailable")}</label></div>
              </Tooltip>
            )}
            {date.isAfternoonAvailable ? (
              <FormControlLabel
                className="formlabel cs-checkbox"
                control={
                  <Checkbox
                    checked={date.isSelectedAfternoon}
                    onChange={() => handleSelectAfternoon(date.date)}
                  />
                }
                label={t("Afternoon")}
              />
            ) : (
              <Tooltip title={t("Afternoon Unavailable")}>
                <div className="no-leave"><label>{t("Unavailable")}</label></div>
              </Tooltip>
            )}
          </FormGroup>
        )}
      </Box>
    </Box>
  </ListItem>
)) : (
  <Box className="loading-section">
    <img src={Loading} className="loading-image" style={{ width: "250px", height: "auto" }} />
  </Box>
)}

                      </List>
                    )}



                    <Box sx={{ paddingBottom: "20px" }}>
                      <InputLabel className="formlabel">
                        {t("Reason")}
                      </InputLabel>
                      <textarea
                        className="forminput"
                        rows="6"
                        placeholder={t("Enter reason")}
                        name="reason"
                        value={inputData.reason}
                        onChange={handleInputChange}
                        disabled={isDisabled()}
                        // required
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box className="navigation-link">
                <MDButton type="submit" className="primary-btn btn xl">{t("Update")}</MDButton>
              </Box>
            </MDBox>
          </Box>
        </Box>
      </MDBox>
    </DashboardLayout>
  );
}

export default Profile;
