import { createFirebaseTimestamp } from "."

const assign_OC_activity = (activity,dates)=>{
    console.log("activity creation==",activity)
    var data = {
        "id":activity.id,
        "activityName": activity.activityName,
        "subtotalCountdown":activity.subtotalCountdown ,
        "color": activity.color,
        "duration": {
            "numberOfDays": activity.duration.numberOfDays,
            "durationHours":activity.duration.durationHours,
            "timeBreakup": activity.duration.timeBreakup,
            "hours": activity.duration.hours,
            "closingTime":  createFirebaseTimestamp(dates.closingTime),
            "startingTime": createFirebaseTimestamp(dates.startingTime),
            "days": dates.durationHours
        },
        "durationString": activity.durationString,
        "rate":activity.rate,
        "repeat": activity.repeat,
        "repeatId":activity.repeatId,
        "start":activity.start,
        "end":activity.end,
        "repeatTime": activity.repeatTime,
        "repeatmode":activity.repeatmode,
        "startDate":activity.startDate,
        "ocId":activity.ocId
    }
 

    return data
}
export {assign_OC_activity}