import React from 'react';
import Box from '@mui/material/Box';
import Iicon from "../../assets/images/icons/i-icon.png";

export const Explanation = ({
  heading,
  toggle,
  contentLine1,
  contentLine2
}) => {
  return (
    <Box display='flex'>
      <Box>{heading} &nbsp;</Box>
      <Box className={"hover-dropdown "+ toggle} ><div className="explanation-icon"><svg width="14" height="14" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="Group 785">
<path id="Vector" d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z" fill="#16273C"/>
<path id="Vector (Stroke)" fill-rule="evenodd" clip-rule="evenodd" d="M9.99902 5C10.5513 5 10.999 5.44772 10.999 6V10C10.999 10.5523 10.5513 11 9.99902 11C9.44674 11 8.99902 10.5523 8.99902 10V6C8.99902 5.44772 9.44674 5 9.99902 5Z" fill="white"/>
<path id="Vector (Stroke)_2" fill-rule="evenodd" clip-rule="evenodd" d="M8.99902 13.998C8.99902 13.4458 9.44674 12.998 9.99902 12.998H10.009C10.5613 12.998 11.009 13.4458 11.009 13.998C11.009 14.5503 10.5613 14.998 10.009 14.998H9.99902C9.44674 14.998 8.99902 14.5503 8.99902 13.998Z" fill="white"/>
</g>
</svg></div>

        <Box className="content-area">
        <span class="arrow-up"></span>
          <div>
            <p>{contentLine1}</p>
            <p>{contentLine2}</p>
          </div>
        </Box>
      </Box>
    </Box>
  );
};

export default Explanation;
