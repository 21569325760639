import React from 'react';
import Box from '@mui/material/Box';
import MDButton from "components/MDButton";
import Modal from "@mui/material/Modal";
import { useTranslation } from "react-i18next";
import { Grid, InputLabel, Typography } from '@mui/material';

const Collisionmodal = ({modalinfo,handleModalClose})=>{
const {t} = useTranslation()
  return (
    <Modal
    open={modalinfo.open}
    aria-labelledby="modal-modal-title"
    className="cs-modal xs info"
    aria-describedby="modal-modal-description"
    onClose={()=>handleModalClose()}
  >
    <Box className="modal-dialog">
      <Box className="modal-content">
        <Box className="modal-body" mt={3}>
        <Box className="scroll-bar">
          <h2>{t("clinicColide_header")}</h2>
          <h4>{t("clinic_modal_message")}
          </h4>
          <p><b>Dates:</b> {modalinfo.dates}</p>
          <p><b>Personnel:</b>  {modalinfo.users}</p>
          </Box>
          <Box mt={2}>
            <MDButton
              className="secondary-btn"
              onClick = {(e)=>handleModalClose()}
              >
              {t("Ok")}
            </MDButton>
            {/* <MDButton
              className="secondary-btn white"
              onClick = {(e)=>onChange(false)}
              >
              {t("Cancel")}
            </MDButton> */}
            
          </Box>
        </Box>
      </Box>
    </Box>
  </Modal>
  )

}
export {Collisionmodal};