import { useEffect, useRef, useState } from "react";
import moment from "moment";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MDButton from "components/MDButton";
import MenuItem from "@mui/material/MenuItem";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import MDTypography from "components/MDTypography";
import Loading from "../../assets/images/icons/heart2.gif";
import Checkbox from "@mui/material/Checkbox";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useTranslation } from "react-i18next";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useNavigate } from "react-router-dom";
import { useMaterialUIController } from "context";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "backendservice/firebase";
import { add_leave } from "backendservice/firebasefunctions,";
import Loader from "components/Loader";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Explanation from "components/Explanation";
import { checkDates } from "layouts/scheduler/functions";
import { Tooltip } from "@mui/material";
import "dayjs/locale/fr";
import axios from "axios";
import dayjs from "dayjs";
function Profile() {
  const navigate = useNavigate();
  const formRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const { t,i18n } = useTranslation();
  const [controller, dispatch] = useMaterialUIController();
  const { selecetdCustomer, selected_clinic } = controller;
  const [modalopen, setmodalopen] = useState(false);
  const [user] =useAuthState(auth)
  const [halfDay,setHalfDay] = useState(false)
  const [days,setDays] = useState([])
  const [formaldates,setformaldates] =useState([])
  var selectedLanguage =i18n.language ;
  const [inputData, setInputData] = useState({
    staff: "",
    leaveType: "Select Type",
    fromDate: "",
    toDate: "",
    reason: ""
    });
    const [openFromDate, setOpenFromDate] = useState(false); // State to control 'fromDate' DatePicker
  const [openToDate, setOpenToDate] = useState(false); // State to control 'toDate' DatePicker

  const handleOpenFromDate = () => {
    setOpenFromDate(true); // Open 'fromDate' DatePicker
  };

  const handleCloseFromDate = () => {
    setOpenFromDate(false); // Close 'fromDate' DatePicker
  };

  const handleOpenToDate = () => {
    setOpenToDate(true); // Open 'toDate' DatePicker
  };

  const handleCloseToDate = () => {
    setOpenToDate(false); // Close 'toDate' DatePicker
  };
  //  const handleFocus = () => {      
  //   setInputData(prevData => (
  //    { ...prevData,
  //     fromDate: dayjs()}
  //   ))
  // };  
 
  

    const handleSelectMorning = (date) => {
      const newData = days.map((item) =>
        item.date === date ? { ...item, isSelectedMorning: !item.isSelectedMorning } : item
      );
      setDays(newData);
    };
  
    const handleSelectAfternoon = (date) => {
      const newData = days.map((item) =>
        item.date === date ? { ...item, isSelectedAfternoon: !item.isSelectedAfternoon } : item
      );
      setDays(newData);
    };

  
  
// Update handleInputChange to handle radio button and dropdown changes
const handleInputChange = (event) => {
  const { name, value, type } = event.target;
 
    setInputData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  //loadData

  const loadData = async () => {
    setLoading(true);
    try {
      // setLoading(true);
      var datass = localStorage.getItem("user");
      var customer = "";
      if (datass == undefined || datass == "") {
        alert(t("user not found"));
        setLoading(false);
      } else {
        var json = JSON.parse(datass);
        setInputData((prevData) => ({
          ...prevData,
          staff: json.name,
          userEmail:json.userEmail
        }));
        // setuserData(json)
        setLoading(false);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  const staticLeaveTypes = [
    { value: "Paid Leave", label: t("Paid Leave") },
    { value: "Unpaid Leave", label: t("Unpaid Leave") },
    { value: "Paternity Leave", label: t("Paternity Leave") },
    { value: "Maternity Leave", label: t("Maternity Leave") },
    { value: "Sick Leave", label: t("Sick Leave") },
    { value: "Day Off", label: t("Day Off") },
    { value: "Compensatory Time Off", label: t("Compensatory Time Off") },
    { value: "Training", label: t("Training") },
    { value: "JNT (Ex RTT)", label: t("JNT (Ex RTT)") },

  ];

  //modal open close functionn
  const handlemodalopen = () => {
    setmodalopen(true);
  };
  const handlemodalClose = () => {
    setmodalopen(false);
  };
  const calculateDaysDifference = () => {
    if (inputData.fromDate !== "" && inputData.toDate !== "") {
      const fromDate = moment(new Date(inputData.fromDate.$d));
      const toDate = moment(new Date(inputData.toDate.$d));
      const today = moment();
  
      if (!fromDate.isSameOrAfter(today, "day") || toDate.diff(fromDate, "days") + 1 <= 0) {
        if (!inputData.invalidDateAlertShown) {
          alert(t("Please select a valid Date."));
          setInputData((prevFormData) => ({
            ...prevFormData,
            invalidDateAlertShown: true,
            fromDate: "",
            toDate: "",
          }));
        }
        return; // Exit the function early
      }
  
      // Reset the flag if the date is valid
      setInputData((prevFormData) => ({ ...prevFormData, invalidDateAlertShown: false }));
    } else {
      // Reset the flag when the dates are empty
      setInputData((prevFormData) => ({ ...prevFormData, invalidDateAlertShown: false }));
    }
  };
  

  const saveActivity = async (e) => {
    e.preventDefault();
    setLoading(true);  
     // Check if all days are removed
  const allDaysRemoved = days.every((item) => item.isRemoved);
  if (allDaysRemoved) {
    setLoading(false);
    alert(t("All selected days are unavailable. Please choose different dates."));
    return;
  }
    const result = [];
    const datesToAlert = []; // Array to store dates that need to be alerted
  if(formaldates.length === 0){
    setLoading(false)
    alert(t("Sorry, no available dates for the selected leave period. Please choose different dates."))
    return
  }
    if (halfDay) {
      days.filter((item) => !item.isRemoved).forEach((item) => {
        const date = moment(item.date);
        const formattedDate = date.format("YYYY/MM/DD");
  
        if (!item.isSelectedMorning && !item.isSelectedAfternoon) {
          datesToAlert.push(formattedDate); // Collect dates that need to be alerted
        }
  
        if (item.isSelectedMorning && item.isSelectedAfternoon) {
          result.push({
            slot: "fullday",
            value: 0,
            date: formattedDate,
            leaveType: inputData.leaveType,
          });
        } else if (item.isSelectedMorning) {
          result.push({
            slot: "morning",
            value: 1,
            date: formattedDate,
            leaveType: inputData.leaveType,
          });
        } else if (item.isSelectedAfternoon) {
          result.push({
            slot: "afternoon",
            value: 2,
            date: formattedDate,
            leaveType: inputData.leaveType,
          });
        }
      });
  
      if (datesToAlert.length > 0) {
        alert(`${t("Please select slot for the following dates:")} ${datesToAlert.join(", ")}`);
        setLoading(false); // Stop loading
        return;
      }
    } else {
      days.filter((item) => !item.isRemoved).forEach((item) => {
        const date = moment(item.date);
        const formattedDate = date.format("YYYY/MM/DD");
  
        if (item.isAfternoonAvailable && item.isMorningAvailable) {
          result.push({
            slot: "fullday",
            value: 0,
            date: formattedDate,
            leaveType: inputData.leaveType,
          });
        } else if (item.isMorningAvailable) {
          result.push({
            slot: "morning",
            value: 1,
            date: formattedDate,
            leaveType: inputData.leaveType,
          });
        } else if (item.isAfternoonAvailable) {
          result.push({
            slot: "afternoon",
            value: 2,
            date: formattedDate,
            leaveType: inputData.leaveType,
          });
        }
      });
    }
  
    let temp = {
      ...inputData,
      userId: user.uid,
      fromDate: new Date(inputData.fromDate.$d),
      toDate: new Date(inputData.toDate.$d),
      customer: selecetdCustomer.id,
      defaultClinicId: selected_clinic.id,
      status: null,
      dateList: result,
      halfDay: halfDay,
      dates:formaldates,
      days:formaldates.length,
      userEmail:inputData.userEmail
    };
    
    try {
      const emailResponse = await axios.post(process.env.REACT_APP_LEAVE_CREATED, {
        userName: inputData.staff,
        startDate: moment(inputData.fromDate).format("YYYY-MM-DD"),
        endDate: moment(inputData.toDate).format("YYYY-MM-DD"),
        reason: inputData.reason || "Motif non fourni.",
        receiverEmail: 'support@tailorplan.fr', // Replace with the actual receiver's email
      }, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
      console.log('Absence request email sent successfully:', emailResponse.data);
      await add_leave(temp);
      alert(t("Leave Application Successful!"));
      navigate(-1);
    } catch (error) {
      console.error('Error sending absence request email:', error);
      alert(t("Leave Application Failed. Please try again."));
    } finally {
      setLoading(false); // Stop loading regardless of success or failure
    }
  };
  
  const generateDateList = async (fromDate, toDate) => {
    if (fromDate !== "" && toDate !== "") {
      setLoading(true)
      const dates = [];
      let response = await checkDates(fromDate, toDate, selecetdCustomer.id, selected_clinic.id, user?.uid);
      for (var i = 0; i < response.length; i++) {
        const item = response[i];
        let obj = {
          date: moment(item.date),
          isSelectedAfternoon: false,
          isSelectedMorning: false,
          isRemoved: item.isRemoved,
          removeReason: item.removeReason,
          isMorningAvailable: item.isMorningAvailable,
          isAfternoonAvailable: item.isAfternoonAvailable,
        };
        dates.push(obj);
      }
      const formalDates = response.filter((item) => !item.isRemoved).map((item) => moment(item.date).format("YYYY-MM-DD"));
      setformaldates(formalDates);
      setDays(dates);
      setLoading(false)
    }
  };
  
  
  useEffect( async ()  =>   {
    calculateDaysDifference();
    generateDateList(inputData.fromDate,inputData.toDate)
  }, [inputData.fromDate, inputData.toDate]);

  useEffect(() => {
    loadData();
  }, [selecetdCustomer, selected_clinic]);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Loader loading={loading} />
      <MDBox pt={4} pb={3} className="xl-container leave-management-form">
        <Box>
          <Box>
            <MDBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <MDBox className="page-heading">
                <MDTypography
                  variant="h5"
                  gutterBottom
                  sx={{ pb: "8px", display: "flex" }}
                >
                  <Box
                    sx={{
                      pt: "2px",
                      width: "22px;",
                      display: { xs: "none", sm: "flex" },
                    }}
                  >
                    <MDButton onClick={() => navigate(-1)}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M0 5.99961C0 5.4524 0.443608 5.00879 0.990826 5.00879H11.0092C11.5564 5.00879 12 5.4524 12 5.99961C12 6.54683 11.5564 6.99044 11.0092 6.99044H0.990826C0.443608 6.99044 0 6.54683 0 5.99961Z"
                          fill="#16273C"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M6.70062 0.290206C7.08756 0.677148 7.08756 1.3045 6.70062 1.69145L2.39206 6L6.70062 10.3086C7.08756 10.6955 7.08756 11.3229 6.70062 11.7098C6.31368 12.0967 5.68632 12.0967 5.29938 11.7098L0.290206 6.70062C-0.0967354 6.31368 -0.0967354 5.68632 0.290206 5.29938L5.29938 0.290206C5.68632 -0.0967354 6.31368 -0.0967354 6.70062 0.290206Z"
                          fill="#16273C"
                        />
                      </svg>
                    </MDButton>
                  </Box>
                  {t("Leave application")}
                </MDTypography>
              </MDBox>
            </MDBox>
            <MDBox
              className="card-layout"
              component="form"
              onSubmit={(e) => saveActivity(e)}
              ref={formRef}
            >
              <Box className="form-main">
                <Box className="form-content">
                  <Box sx={{ width: { xs: "100%", sm: "100%" } }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <Box sx={{ mb: 2 }}>
                          <InputLabel className="formlabel">
                            {t("Employee name")}<strong style={{color:"red"}}> *</strong>
                          </InputLabel>
                          <TextField
                            className="forminput"
                            placeholder={t("Staff Name")}
                            variant="outlined"
                            name="staff"
                            value={inputData.staff}
                            onChange={handleInputChange}
                            disabled
                            required
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Box sx={{ mb: 2 }}>
                          <InputLabel className="formlabel">
                            {t("Leave type")}<strong style={{color:"red"}}> *</strong>
                          </InputLabel>
                          <Box className="cs-dropdown icon">
                            <Select
                              className="form-select"
                              id="languageSelector"
                              label="Leave Type"
                              value={inputData.leaveType}
                              onChange={handleInputChange}
                              name="leaveType"
                              placeholder="Select Leave type"
                              required
                            >
                              <MenuItem value="" disabled>
                                {t("Select an option")}
                              </MenuItem>
                              {staticLeaveTypes.map((leaveType) => (
                                <MenuItem
                                  key={leaveType.value}
                                  value={leaveType.value}
                                >
                                  {leaveType.label}
                                </MenuItem>
                              ))}
                            </Select>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid container spacing={4}>
                      <Grid item xs={12} md={3}>
                        <FormGroup
                          sx={{ mb: 2 }}
                          className="input-icon datepicker"
                        >
                          <InputLabel className="formlabel">
                            {t("From (including)")}<strong style={{color:"red"}}> *</strong>
                          </InputLabel>
                          <LocalizationProvider
                          adapterLocale={selectedLanguage == "fr" ? "fr" : "en"}
                            dateAdapter={AdapterDayjs}
                            className="date-main"
                          >
                             <DatePicker
                                label={t('leaveModuleDateFormet')}
                                value={ inputData.fromDate || null} // Bind value to state
                                minDate={dayjs()}
                                onChange={(newDate) =>
                                  handleInputChange({
                                    target: { name: 'fromDate', value: newDate || null },
                                  })
                                }
                                open={openFromDate} // Control opening of 'fromDate' DatePicker
                                onOpen={handleOpenFromDate} // Handle opening
                                onClose={handleCloseFromDate} // Handle closing
                                slotProps={{  
                                  textField: {  
                                    placeholder: selectedLanguage === 'fr' ? 'JJ/MM/AAAA' : 'MM/DD/YYYY',  
                                    onClick: handleOpenFromDate, // Open calendar when clicking on the field  
                                    // onFocus: handleFocus, // Trigger event on focus  
                                  },  
                                }}  
                                renderInput={(params) => <TextField  {...params} />}
                                />
                          </LocalizationProvider>
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <FormGroup
                          sx={{ mb: 2 }}
                          className="input-icon datepicker"
                        >
                          <InputLabel className="formlabel">
                            {t("Up to (including)")}<strong style={{color:"red"}}> *</strong>
                          </InputLabel>
                          <LocalizationProvider
                            className="date-main"
                            dateAdapter={AdapterDayjs}
                            adapterLocale={selectedLanguage == "fr" ? "fr" : "en"}



                          >
                            <DatePicker
              label={t('leaveModuleDateFormet')}
              minDate={inputData.fromDate || null}
              value={inputData.toDate || null} // Bind value to state
              onChange={(newDate) =>
                handleInputChange({
                  target: { name: 'toDate', value: newDate },
                })
              }
              open={openToDate} // Control opening of 'toDate' DatePicker
              onOpen={handleOpenToDate} // Handle opening
              onClose={handleCloseToDate} // Handle closing
              slotProps={{
                textField: {
                  placeholder: selectedLanguage === 'fr' ? 'JJ/MM/AAAA' : 'MM/DD/YYYY',
                  onClick: handleOpenToDate, // Open calendar when clicking on the field
                },
              }}
              renderInput={(params) => <TextField {...params} />}
            />
                          </LocalizationProvider>
                        </FormGroup>
                      </Grid>
                    </Grid>

                    <Box className="left-content" sx={{ display: "flex" }}>
                      <FormControlLabel
                        sx={{
                          display: "flex",
                          textAlign: "center",
                          paddingBottom: "15px",
                          marginRight: "0",
                        }}
                        className="cs-checkbox"
                        label={t("Half working days")}
                        checked={halfDay}
                        onClick={() => setHalfDay(!halfDay)}
                        // disabled = {data.lock}
                        control={<Checkbox />}
                      />
                      <Explanation
                        toggle="left"
                        contentLine1={t("If there are half working days to be included within the applied dates, the check box can be selected to proceed with selecting morning slot or afternoon slot for the preferred dates.")}
                      />
                    </Box>

                    {halfDay &&  inputData.fromDate && inputData.toDate && (
                      <List className="datatable">
                        {/* Display the "Select All" column */}
                        <ListItem>
                          <Box className="list-content">
                            <Box className="left-content">
                              <label className="heading bold">
                                {t("Select All")}
                              </label>
                              <Explanation
                                toggle="right"
                                contentLine1={t("Please note that selecting the 'Morning' option will reserve morning slots for all specified dates, while selecting 'Afternoon' will reserve afternoon slots accordingly. To request a full-day leave, kindly ensure that both the 'Morning' and 'Afternoon' checkboxes are selected for the preferred date(s).")}
                              />
                            </Box>
                            <Box className="blue-bg">
                              
                              <FormGroup
                                sx={{
                                  marginTop: "8px",
                                  display: "flex",
                                  flexDirection: "row",
                                }}
                              >
                                <FormControlLabel
                                
                                  className="formlabel cs-checkbox"
                                  control={
                                    <Checkbox
                                    onChange={() => setDays(days.map(item => ({ ...item, isSelectedMorning: !days.every(item => item.isSelectedMorning) })))}
                                    checked={days.every((item) => item.isSelectedMorning)}
                                    />
                                  }
                                  label={t("Morning")}
                                />
                                <FormControlLabel
                                  className="formlabel cs-checkbox"
                                  control={
                                    <Checkbox
                                    onChange={() => setDays(days.map(item => ({ ...item, isSelectedAfternoon: !days.every(item => item.isSelectedAfternoon) })))}
                                    checked={days.every((item) => item.isSelectedAfternoon)}
                                    />
                                  }
                                  label={t("Afternoon")}
                                />
                               
                              </FormGroup>
                            </Box>
                          </Box>
                        </ListItem>



                        {days.length > 0 ? days.map((date, index) => (
  <ListItem key={index}>
    <Box className="list-content">
      <Box className="left-content">
        <label className="heading"> 
          {moment(date.date).format("DD")} {t(moment(date.date).format("MMMM"))}, {moment(date.date).format("YYYY")}
        </label>
      </Box>
      <Box className="blue-bg">
        {date.isRemoved ? (
          <Tooltip title={t(date.removeReason)}>
            <div className="no-leave"><label>{t("Unavailable")}</label></div>
          </Tooltip>
        ) : (
          <FormGroup
            sx={{
              mt: 2,
              display: "flex",
              flexDirection: "row",
            }}
          >
            {date.isMorningAvailable ? (
              <FormControlLabel
                className="formlabel cs-checkbox"
                control={
                  <Checkbox
                    checked={date.isSelectedMorning}
                    onChange={() => handleSelectMorning(date.date)}
                  />
                }
                label={t("Morning")}
              />
            ) : (
              <Tooltip title={t("Morning Unavailable")}>
                <div className="no-leave"><label>{t("Unavailable")}</label></div>
              </Tooltip>
            )}
            {date.isAfternoonAvailable ? (
              <FormControlLabel
                className="formlabel cs-checkbox"
                control={
                  <Checkbox
                    checked={date.isSelectedAfternoon}
                    onChange={() => handleSelectAfternoon(date.date)}
                  />
                }
                label={t("Afternoon")}
              />
            ) : (
              <Tooltip title={t("Afternoon Unavailable")}>
                <div className="no-leave"><label>{t("Unavailable")}</label></div>
              </Tooltip>
            )}
          </FormGroup>
        )}
      </Box>
    </Box>
  </ListItem>
)) : (
  <Box className="loading-section">
    <img src={Loading} className="loading-image" style={{ width: "250px", height: "auto" }} />
  </Box>
)}


                      </List>
                    )}

                    <Box sx={{ paddingBottom: "20px" }}>
                      <InputLabel className="formlabel">
                        {t("Reason")} 
                      </InputLabel>
                      <textarea
                        className="forminput"
                        rows="6"
                        placeholder={t("Enter reason")}
                        name="reason"
                        value={inputData.reason}
                        onChange={handleInputChange}
                        // required
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box className="navigation-link">
                <MDButton type="submit" className="primary-btn btn xl">
                  {t("submit")}
                </MDButton>
              </Box>
            </MDBox>
          </Box>
        </Box>
      </MDBox>
    </DashboardLayout>
  );
}

export default Profile;

