import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import MDButton from "components/MDButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import FormGroup from "@mui/material/FormGroup";
import Help01 from "../../assets/images/icons/help-icon01.png";
import Help02 from "../../assets/images/icons/help-icon02.png";
import Help03 from "../../assets/images/icons/help-icon03.png";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Typography from "@material-ui/core/Typography";
import Sort from "../../assets/images/icons/sort.png";
import SortWhite from "../../assets/images/icons/sortwhite.png";
import Filter from "../../components/Filter";
import Select from "@mui/material/Select";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
//modal import
import Modal from "@mui/material/Modal";
import { useTranslation } from "react-i18next";
import Loader from "components/Loader";
import { auth } from "backendservice/firebase";
import { tickets } from "backendservice/firebase";
import moment from "moment";
import { useAuthState } from "react-firebase-hooks/auth";
import { v4 as uuidv4 } from "uuid";
import { useNavigate } from "react-router-dom";
import {
  doc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";

import { db } from "backendservice/firebase";
import alasql from "alasql";
import { createSupportTicket, get_status, severityMenu, ticketstatusmenue } from "./helperFunctions/helper";
import { useMaterialUIController } from "context";
import Nodata from "components/Nodata";
import SearchComponent from "components/SearchInput";
import Sortlist from "components/Sortlist";
import axios from "axios";




function Clinics() {
  const [followsMe, setFollowsMe] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const open = Boolean(anchorEl);
  const [subject, setSubject] = useState("");
  const [userquery, setuserQuery] = useState([]);
  const [severity, setSeverity] = useState("");

  const [user] =useAuthState(auth)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const { t } = useTranslation();
  const [answersPost, setAnswersPost] = useState(false);
  //modal open hook
  const [modalopen, setmodalopen] = useState(false);
  //modal open close functionn
  //   const handlemodalopen = () => {
  //     setmodalopen(true);
  //   };
  //   const handlemodalClose = () => {
  //     setmodalopen(false);
  //   };
  const [categoryOptions, setcategoryOption] = useState([]);
  const [categoryOptions2, setcategoryOption2] = useState([]);

  const [name, setName] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);
  const [customerID, setCustomer] = useState("");
  const [userData,setuserData] = useState({})
  const [ticketdata, setticketdata] = useState([]);
  const [ticketdataBackup,SetTicketdataBackup] = useState([])
  const [controller, dispatch] = useMaterialUIController();
  const {
    selecetdCustomer,
    selected_clinic,
    permission
  } = controller;
  const getAllCategory = async () => {
    try {
      setLoading(true);
      var datass = localStorage.getItem("user");
      // console.log("userdata == ",data)
      var customer = "";
      if (datass == undefined || datass == "") {
        // setusername("User")
      } else {
        var json = JSON.parse(datass);
        //   setcustomer(json.customer)
        customer = json.customer;
        //console.log(json.customer);
      setuserData(json)

      }
      setCustomer(customer);
 
      //   console.log("service response ==>",MessageResp)
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  const handlemodalopen = () => {
    setmodalopen(true);
  };
  const handlemodalClose = () => {
    setName("");

    setmodalopen(false);
  };

  const handleSubmit = async (e) => {
    // console.log(webhookref)

    setLoading(true);
    var id = uuidv4();

    var data = {
      name: name,
      customer: customerID,
      status: true,
    };
    // console.log("submit data", data)
    try {
      const docref = await setDoc(
        doc(db, process.env.REACT_APP_CATEGORYTYPE, id),
        data
      );
      handlemodalClose();
    } catch (error) {
      console.log(error);
      handlemodalClose();
    }
    handlemodalClose();
    setRefresh(!refresh);
    // setLoading(false)
  };

  const handleSearch = (e) => {
    var res = alasql(
      "SELECT * FROM ? WHERE label LIKE '%" + e.target.value + "%'",
      [categoryOptions]
    );
    //console.log("sort = ", res);
    setcategoryOption2(res);
  };
  const handleSort = (e, type) => {
    if (type == "name") {
      var res = alasql("SELECT * FROM ? ORDER BY label", [categoryOptions2]);
      //console.log("sort = ", res);
      setcategoryOption2(res);
    }
    handleClose();
  };
  const loadData = async()=>{
    setLoading(true)
    try{

      var snapshot = await getDocs(
        query(tickets, where("user", "==", user.uid), where("defaultClinicId", "==", selected_clinic.id))
      );
      var ticketrecord = snapshot.docs.map((doc) => ({
        id: doc.id,
        data: doc.data(),
        label:doc.data().ticketNumber,
        createdTime:doc.data().raiseTime,
        level:doc.data().level,
        viewdetails:false
      }));
      console.log("tickets ",ticketrecord)
      setticketdata(ticketrecord)
      SetTicketdataBackup(ticketdata)
      setLoading(false)
    }catch(error)
    {
      setLoading(false)
      console.log(error)
    }
  
  }
  const createTicket = async (e) => {
    e.preventDefault();
    setLoading(true);
  
    var temp = {
      "module": "HELP AND SUPPORT",
      "page":"help and support",
      "customer":customerID,
      "customerName":selecetdCustomer,
      "user":user.uid,
      "severity": severity,
      "level":severityMenu.find((item) => item.label === severity).value,
      "subject":subject,
      "query": userquery,
      "status": "Open",
      "statusCode":0,
      "unread":0,
      "defaultClinicId":selected_clinic.id
      // "auth":auth
  }
  
    console.log(temp, "my temp");
  
    try {
      // Create the support ticket
      await createSupportTicket(temp);
      setRefresh(!refresh);
  
      // Prepare data to send to the mail API
      const mailData = {
        title: subject,
        severity: severity.label,
        description: userquery,
        senderEmail: JSON.parse(localStorage.getItem("user")).userEmail, // Use the email of the user raising the ticket
        receiverEmail: "support@tailorplan.fr", // Replace with the intended receiver's email or leave blank for default
      };
  
      // Make the API request to trigger the email using axios
      const response = await axios.post(
        process.env.REACT_APP_CREATE_TICKET,
        mailData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
  
      // Check if the response is successful
      if (response.status === 200) {
        console.log("Email triggered successfully:", response.data);
      } else {
        alert("Failed to send email")
        throw new Error(response.data.message || "Failed to send email");
      }
    } catch (error) {
      console.error("Error creating support ticket or sending email:", error);
      // Optionally, handle the error with a user notification or set an error state
    } finally {
      setLoading(false);
      setSubject("")
      setuserQuery([])
      setSeverity("")
      handlemodalClose();
    }
  };
  const isButtonDisabled = (moduleId) => {
    return !(
      !permission.module ||
      !permission.module[moduleId] ||
      permission.module[moduleId].accesstype === 2 ||
      permission.module[moduleId].accesstype === 0
    );
  };
  useEffect(() => {

    if (selected_clinic.id && selecetdCustomer.id) {
      getAllCategory();
      loadData(customerID).then(() => setLoading(false));
    }
  }, [selecetdCustomer,refresh,customerID,selected_clinic,permission,user]);

  

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Loader loading={loading} />
      <MDBox pt={4} pb={3} className="xl-container1 activity">
        <Box>
          <Box>
            <MDBox className="page-heading">
              <MDTypography
                variant="h5"
                gutterBottom
                sx={{ pb: "8px", display: "flex" }}
              >
                {t("Queries")}
              </MDTypography>
            </MDBox>

            <Box>
              <Box
                sx={{
                  margin: "10px 0 0 0",
                }}
              >
                <MDBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
              <SearchComponent searchList = {ticketdataBackup} onSearch= {(e)=>setticketdata(e)} specialChar={true} placeholder={"by ticket ID"} />

                  <Box className="filter-mobile">
                    <MDBox
                      color="text"
                      px={0}
                      sx={{ display: { xs: "block", sm: "flex" } }}
                    >
                      <Box
                        sx={{
                          display: { xs: "block", sm: "flex" },
                        }}
                      >
                        <Sortlist onSort={(e=>setticketdata(e))} sortlist={ticketdataBackup}  placeholder={"by severity"}/>
                       
                        <Filter/>
                      </Box>
                    </MDBox>
                  </Box>
                </MDBox>
              </Box>
             
              <MDBox
                sx={{
                  marginTop: "20px",
                }}
              >
            <Box className="help-box">
              {ticketdata.length == 0 ? <Nodata /> :
              <List className="query-list" sx={{ width: "100%" }}>
              {ticketdata.map((data,index) => (
                <ListItem>
                <Box className="top-content">
                  <label>
                  <Box className="cs-dropdown icon" mt={0} pb={1}>
                    <Box component="span" sx={{ width: '27px', alignItems:'center',display:'flex' }}>
                      <img src={ticketstatusmenue[data.data.statusCode].logo}/> 
                    </Box>
               
                </Box>
                    <strong>{t("Ticket ID")}:</strong>&nbsp;{data.data.ticketNumber}
                  </label>
                  <label className="left-line">
                    <strong>{t("Severity")} :</strong>&nbsp;{t(data.data.severity)}
                  </label>
                  <label className="left-line">
                    <strong>{t("Date")}:</strong>&nbsp;{ moment(new Date(data.data.raiseTime.seconds*1000)).format('DD/MM/YYYY')}
                  </label>
                </Box>
                <Box className="content">
                  <MDBox
                    sx={{
                      display: { xs: "block", sm: "flex" },
                      justifyContent: "space-between",
                    }}
                  >
                    <p>
                      <strong>{t("Subject")} :</strong> {data.data.subject}
                    </p>
                    <Box className="link">
                     <Box> <MDButton className="link-btn btn"  onClick ={(e)=>{var temp = [...ticketdata];
                      temp[index].viewdetails = !temp[index].viewdetails;
                   
                      setticketdata(temp)
                      
                      }}>
                        {t("View details")}
                      </MDButton></Box>
                      <Box
                        sx={{
                          marginLeft: "20px",
                        }}
                      >
                        <MDButton
                          className="primary-btn btn"
                          onClick={() => navigate("/help-support/chat/"+data.id)}
                        >
                          {t("Chat box")}
                        </MDButton>
                      </Box>
                    </Box>
                  </MDBox>
                </Box>
                { data.viewdetails ? (<Box className="bottom-content">
                  <label>{t("Query")}:</label>
                  <p>
                  {data.data.query}
                  </p>
                </Box>):""}
                
              </ListItem>
              ))}
             
            </List>}
            </Box>
              </MDBox>
               <Box className="navigation-link">
                <MDButton
                  className="primary-btn btn xl"
                  onClick={() => handlemodalopen()}
                  disabled={isButtonDisabled("REST12")}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="13"
                    viewBox="0 0 12 13"
                    fill="none"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M7.36739 1.86739C7.36739 1.1122 6.75519 0.5 6 0.5C5.24481 0.5 4.63261 1.1122 4.63261 1.86739V5.13261H1.36739C0.612202 5.13261 0 5.74481 0 6.5C0 7.25519 0.612202 7.86739 1.36739 7.86739H4.63261V11.1326C4.63261 11.8878 5.24481 12.5 6 12.5C6.75519 12.5 7.36739 11.8878 7.36739 11.1326V7.86739H10.6326C11.3878 7.86739 12 7.25519 12 6.5C12 5.74481 11.3878 5.13261 10.6326 5.13261H7.36739V1.86739Z"
                      fill="white"
                    />
                  </svg>
                  &nbsp;&nbsp;{t("Add query")}
                </MDButton>
              </Box>
            </Box>
          </Box>
        </Box>
      </MDBox>

      {/* Modal mm-04 */}
      <Modal
        open={modalopen}
        onClose={handlemodalClose}
        aria-labelledby="modal-modal-title"
        className="cs-modal slack-md"
        aria-describedby="modal-modal-description"
      >
        <Box className="modal-dialog">
          <Box className="modal-content">
            <Box className="modal-header">
              <MDButton
                className="modal-close"
                onClick={() => handlemodalClose()}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                >
                  <path
                    d="M13 1L1 13"
                    stroke="#282B3B"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M1 1L13 13"
                    stroke="#282B3B"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </MDButton>
            </Box>
            <Box className="modal-heading">
              <Typography variant="h2" component="h2">
                Category
              </Typography>
            </Box>
            <Box className="modal-body">
              <FormGroup sx={{ mb: 2, mr: 2, width: "100%" }}>
                <InputLabel className="formlabel">{t("Name")}</InputLabel>
                <TextField
                  className="forminput"
                  placeholder={t("Enter name")}
                  variant="outlined"
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                />
              </FormGroup>
            </Box>
            <Box className="modal-footer">
              <MDButton
                className="secondary-btn"
                sx={{
                  padding: "11px 30px",
                  fontSize: "16px",
                  fontWeight: "normal",
                  minWidth: "160px",
                }}
                color="dark"
                onClick={(e) => handleSubmit(e)}
              >
                Submit
              </MDButton>
            </Box>
          </Box>
        </Box>
      </Modal>

              <Modal
        open={modalopen}
        onClose={handlemodalClose}
        aria-labelledby="modal-modal-title"
        className="cs-modal slack-md"
        aria-describedby="modal-modal-description"
      >
        <Box className="modal-dialog">
          <Box className="modal-content"  component="form"
           onSubmit={(e)=>createTicket(e)}
           >
            <Box className="modal-header">
              <MDButton
                className="modal-close"
                onClick={() => handlemodalClose()}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                >
                  <path
                    d="M13 1L1 13"
                    stroke="#fff"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M1 1L13 13"
                    stroke="#fff"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </MDButton>
            </Box>
            <Box className="modal-heading">
              <Typography variant="h2" component="h2">
              {t("Create a support ticket")}
              </Typography>
            </Box>
            <Box className="modal-body">
              <MDBox
                justifyContent="space-between"
                alignItems="center"
                sx={{ display: { xs: "block", sm: "flex" } }}
              >
                <FormGroup sx={{ mb: 2, width: "100%" }}>
                  <InputLabel className="formlabel">{t("Subject")}<strong style={{color:"red"}}> *</strong></InputLabel>
                  <TextField
                    className="forminput"
                    placeholder={t("Enter the subject")}
                    required
                    value={subject}
                    variant="outlined"
                    onChange={(e)=>setSubject(e.target.value)}
                  />
                </FormGroup>
               
              </MDBox>
              {/* {console.log(severity ?  severityMenu.find((item) => item.label === severity).value :"" ,"mmy")} */}
              <Box className="survey-dropdown" sx={{ mb: 2, width: "100%" }}>
                <InputLabel className="formlabel">{t("Severity")}<strong style={{color:"red"}}> *</strong></InputLabel>
                <Box className="cs-dropdown icon" mt={0} pb={1}>
                  <Select className="form-select" label="Select Severity"    required onChange = {(e)=>setSeverity(e.target.value)}>
                    {severityMenu.map((data,index)=>(
                          <MenuItem value={data.label }>
                          {t(data.label)}
                        </MenuItem>
                    ))}
                 
                  </Select>
                </Box>
              </Box>
              <FormGroup sx={{ mb: 2, width: "100%" }}>
                  <InputLabel className="formlabel">{t("Query")}<strong style={{color:"red"}}> *</strong></InputLabel>
                  <textarea
                                className="forminput"
                                rows="6"
                                required
                                value={userquery}
                                onChange={(e)=>setuserQuery(e.target.value)}
                                placeholder={t(
                                  "Enter the query"
                                )}
                              />
                </FormGroup>
            </Box>
            <Box className="modal-footer">
              <MDButton
             className="secondary-btn"
                sx={{
                  padding: "11px 30px",
                  fontSize: "16px",
                  fontWeight: "normal",
                  minWidth: "160px",
                }}
                type = "submit"
              >
                {t("Submit")}
              </MDButton>
            </Box>
          </Box>
        </Box>
      </Modal>
    </DashboardLayout>
  );
}

export default Clinics;
