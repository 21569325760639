import { db } from "backendservice/firebase";
import { doc, setDoc,updateDoc } from "firebase/firestore";
import { v4 as uuidv4 } from "uuid";

function getInitials(name,surname) {
  name = name.trim()
  surname = surname.trim()
    let firstChar =""
    let lastChar = ""
    if(name[0])
   { firstChar = name[0].toUpperCase()
    
  }
  if(surname[0]){
   lastChar = surname[0].toUpperCase()}
    return `${firstChar}${lastChar}`
  }
  
const profilePictureStyle = {
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    backgroundColor: '#ADD8E6', // Dark background color
    color: '#191970', // Text color
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '16px',
    // fontWeight: 'bold',
  };

  const updateStatus = async (id,index,data) => {
    try {
        const docref = await updateDoc(
            doc(db,"user",id ),
            {active:data[index].active}
          )
    } catch (error) {
        console.log(error);
    }
    
}
const getEmployeeType=(user,employeelist)=>{
var temp = employeelist.filter((d) => d.id === user);

return temp[0]
}
const getContractType=(user,contractList)=>{
    var temp = contractList.filter((d) => d.id === user);

return temp[0]
  }
  const getRoleDescription=(user,contractList)=>{
    
    var temp = contractList.filter((d) => d.id === user);

return temp[0]
    }
  
  export {getInitials,profilePictureStyle, updateStatus,getEmployeeType,getContractType,getRoleDescription}