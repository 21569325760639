
import { createContext, useContext, useReducer, useMemo } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Material Dashboard 2 React main context
const MaterialUI = createContext();

// Setting custom name for the context which is visible on react dev tools
MaterialUI.displayName = "MaterialUIContext";

// Material Dashboard 2 React reducer
function reducer(state, action) {
  switch (action.type) {
    case "MINI_SIDENAV": {
      return { ...state, miniSidenav: action.value };
    }
    case "TRANSPARENT_SIDENAV": {
      return { ...state, transparentSidenav: action.value };
    }
    case "WHITE_SIDENAV": {
      return { ...state, whiteSidenav: action.value };
    }
    case "SIDENAV_COLOR": {
      return { ...state, sidenavColor: action.value };
    }
    case "TRANSPARENT_NAVBAR": {
      return { ...state, transparentNavbar: action.value };
    }
    case "FIXED_NAVBAR": {
      return { ...state, fixedNavbar: action.value };
    }
    case "OPEN_CONFIGURATOR": {
      return { ...state, openConfigurator: action.value };
    }
    case "DIRECTION": {
      return { ...state, direction: action.value };
    }
    case "LAYOUT": {
      return { ...state, layout: action.value };
    }
    case "DARKMODE": {
      return { ...state, darkMode: action.value };
    }
    case "CHANGESERVICE": {
      //console.log("context ==>",action.value )
      localStorage.setItem("nav",action.value )
      return { ...state, serviceState: action.value };
    }
    case "CUSTOMER": {
      //console.log("context ==>",action.value )
      return { ...state, customer: action.value };
    }
    case "CUSTOMERUPDATE": {
      //console.log("context ==>",action.value )
      return { ...state, customerupdate: action.value };
    }
    case "SELECTEDCUSTOMER": {
      //console.log("context ==>",action.value )
      return { ...state, selecetdCustomer: action.value };
    }
    case "PROJECTNAME": {
      //console.log("context ==>",action.value )
      return { ...state, projectName: action.value };
    }
    case "PERMISSIONS": {
      //console.log("context ==>",action.value )
      return { ...state, permission: action.value };
    }
    case "MODULE": {
      //console.log("context ==>",action.value )
      return { ...state, module: action.value };
    }
    case "CLINIC": {
      //console.log("context ==>",action.value )
      return { ...state, clinic_list: action.value };
    }
    case "SELECTEDCLINIC": {
      //console.log("context ==>",action.value )
      return { ...state, selected_clinic: action.value };
    }
    case "TICKET_UPDATE": {
      return { ...state, ticketUpdate: !state.ticketUpdate };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

// Material Dashboard 2 React context provider
function MaterialUIControllerProvider({ children }) {
  const initialState = {
    miniSidenav: false,
    transparentSidenav: false,
    whiteSidenav: false,
    sidenavColor: "info",
    transparentNavbar: true,
    fixedNavbar: true,
    openConfigurator: false,
    direction: "ltr",
    layout: "sign-in",
    darkMode: false,
    serviceState:false,
    customer:[],
    customerupdate:false,
    selecetdCustomer:{label:"BRAND",id:null,image:""},
    selected_clinic:{label:"Clinic",id:null,data:[]},
    clinic_list:[],
    projectName:"Resonante",
    permission:{},
    module:"",
    ticketUpdate: false,
  };

  const [controller, dispatch] = useReducer(reducer, initialState);

  const value = useMemo(() => [controller, dispatch], [controller, dispatch]);

  return <MaterialUI.Provider value={value}>{children}</MaterialUI.Provider>;
}

// Material Dashboard 2 React custom hook for using context
function useMaterialUIController() {
  const context = useContext(MaterialUI);

  if (!context) {
    throw new Error(
      "useMaterialUIController should be used inside the MaterialUIControllerProvider."
    );
  }

  return context;
}

// Typechecking props for the MaterialUIControllerProvider
MaterialUIControllerProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

// Context module functions
const setMiniSidenav = (dispatch, value) => dispatch({ type: "MINI_SIDENAV", value });
const setTransparentSidenav = (dispatch, value) => dispatch({ type: "TRANSPARENT_SIDENAV", value });
const setWhiteSidenav = (dispatch, value) => dispatch({ type: "WHITE_SIDENAV", value });
const setSidenavColor = (dispatch, value) => dispatch({ type: "SIDENAV_COLOR", value });
const setTransparentNavbar = (dispatch, value) => dispatch({ type: "TRANSPARENT_NAVBAR", value });
const setFixedNavbar = (dispatch, value) => dispatch({ type: "FIXED_NAVBAR", value });
const setOpenConfigurator = (dispatch, value) => dispatch({ type: "OPEN_CONFIGURATOR", value });
const setDirection = (dispatch, value) => dispatch({ type: "DIRECTION", value });
const setLayout = (dispatch, value) => dispatch({ type: "LAYOUT", value });
const setDarkMode = (dispatch, value) => dispatch({ type: "DARKMODE", value });
const setChangeService = (dispatch, value) => dispatch({ type: "CHANGESERVICE", value });
const setCustomerUpdate = (dispatch, value) => dispatch({ type: "CUSTOMERUPDATE", value });
const setCustomerdaata = (dispatch, value) => dispatch({ type: "CUSTOMER", value });
const setSelecetdCustomerrec = (dispatch, value) => dispatch({ type: "SELECTEDCUSTOMER", value });
const setProjectName = (dispatch, value) => dispatch({ type: "PROJECTNAME", value });
const setPermission = (dispatch, value) => dispatch({ type: "PERMISSIONS", value });
const setModule = (dispatch, value) => dispatch({ type: "MODULE", value });
const setClinicListContext = (dispatch, value) => dispatch({ type: "CLINIC", value });
const triggerTicketUpdate = (dispatch) => dispatch({ type: "TICKET_UPDATE" });
const setSelectedClinicCOntext = (dispatch, value) => dispatch({ type: "SELECTEDCLINIC", value });
export {
  MaterialUIControllerProvider,
  useMaterialUIController,
  setMiniSidenav,
  setTransparentSidenav,
  setWhiteSidenav,
  setSidenavColor,
  setTransparentNavbar,
  setFixedNavbar,
  setOpenConfigurator,
  setDirection,
  setLayout,
  setDarkMode,
  setChangeService,
  setCustomerdaata,
  setCustomerUpdate,
  setSelecetdCustomerrec, 
  setProjectName,
  setPermission,
  setModule,
  setClinicListContext,
  setSelectedClinicCOntext,
  triggerTicketUpdate,
};
