import { useEffect, useState } from "react";
import alasql from "alasql";
import MDButton from "components/MDButton";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Box from "@mui/material/Box";
import MDTypography from "components/MDTypography";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import FilterIcon from "../../assets/images/icons/filter-icon.png";
import { useTranslation } from "react-i18next";
import {
  generateRegions,
  generate_department,
  generate_list,
  generate_uniqueField_device,
} from "./functions";
// import { get_clinic } from "backendservice/firebasefunctions,";
// import { get_employeeType } from "backendservice/firebasefunctions,";

function Filter({ filterlist, onFilter, module, brandId, employeeType }) {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(false);
  const [openFilter, setopenFilter] = useState(false);
  const [filteroptions, setFilteroptions] = useState([]);
  const [accourdians, setaccourdians] = useState([]);
  const [selectAll, setselectall] = useState(true);
  const [clearALL,setClearAll] = useState(false)
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const handleSelectAll = (pos1) => {
    if (filteroptions && filteroptions[accourdians[pos1]]) {
      filteroptions[accourdians[pos1]].forEach((data, pos2) => {
        if (!data.checked) {
          handlecheck(pos1, pos2);
        }
      });
    }
  };
  
  const handlecheck = (pos1, pos2) => {
    if (
      module !== undefined &&
      module !== null &&
      module !== "" &&
      filterlist.length > 0
    ) {
      switch (module) {
        case "service_alerts":
          var res = [];
          var temp = filteroptions;
          var test = false;
          switch (accourdians[pos1]) {
            case accourdians[0]:
              temp[accourdians[pos1]][pos2].checked =
                !temp[accourdians[pos1]][pos2].checked;
              temp[accourdians[pos1]].map((data, index) => {
                if (data.checked === true) {
                  var sql = alasql(
                    "SELECT * FROM ? WHERE status = " + data.value + "",
                    [filterlist]
                  );
                  res = [...res, ...sql];
                  test = true;
                }
              });
     
              break;

            case accourdians[1]:
              var res = [];

              temp[accourdians[pos1]][pos2].checked =
                !temp[accourdians[pos1]][pos2].checked;
              temp[accourdians[pos1]].map((data, index) => {
                if (data.checked === true) {
                  var sql = alasql(
                    "SELECT * FROM ? WHERE type = '" + data.value + "'",
                    [filterlist]
                  );
                  res = [...res, ...sql];
                  test = true;
                }
              });

              break;
          }
          if (test) {
            onFilter(res);
          } else {
            onFilter(filterlist);
          }

          setFilteroptions(temp);

          //   setServicesOption2(res);
          break;
        case "survey_list":
          var res = [];
          var temp = filteroptions;
          var test = false;
          temp[accourdians[pos1]][pos2].checked =
            !temp[accourdians[pos1]][pos2].checked;

          temp[accourdians[pos1]].map((data, index) => {
            if (data.checked === true) {
              var sql = alasql(
                "SELECT * FROM ? WHERE surveyType = " + data.value + "",
                [filterlist]
              );
              res = [...res, ...sql];
              test = true;
            }
          });
          if (test) {
            onFilter(res);
          } else {
            onFilter(filterlist);
          }
          setFilteroptions(temp);
 
        case "byName":
          var res = [];
          var temp = filteroptions;
          var test = false;
          temp[accourdians[pos1]][pos2].checked =
            !temp[accourdians[pos1]][pos2].checked;

          var count = 0;
          temp[accourdians[pos1]].map((data, index) => {
            if (data.checked === true) {
              var sql = alasql(
                "SELECT * FROM ? WHERE LOWER(state) = LOWER(?)",
                [filterlist, data.value]
              );
              count++;
              res = [...res, ...sql];
              test = true;
            }
          });
          if (count === temp[accourdians[pos1]].length) {
            setselectall(true);
          } else {
            setselectall(false);
          }
          if (test) {
            onFilter(res);
          } else {
            onFilter([]);
          }
          setFilteroptions(temp);
          break;

        case "Help_support":
          var res = [];
          var temp = filteroptions;
          var test = false;
          switch (accourdians[pos1]) {
     

            case accourdians[1]:
              var res = [];

              temp[accourdians[pos1]][pos2].checked =
                !temp[accourdians[pos1]][pos2].checked;
              temp[accourdians[pos1]].map((data, index) => {
                if (data.checked === true) {
                  // console.log( "SELECT * FROM ? WHERE statusCode = " + data.value + "",accourdians[1],temp[accourdians[pos1]])

                  var sql = alasql(
                    "SELECT * FROM ? WHERE statusCode = " + data.value + "",
                    [filterlist]
                  );
                  res = [...res, ...sql];
                  test = true;
                }
              });
              break;
            case accourdians[0]:
              var res = [];

              temp[accourdians[pos1]][pos2].checked =
                !temp[accourdians[pos1]][pos2].checked;

              temp[accourdians[pos1]].map((data, index) => {
                if (data.checked === true) {
                  var sql = alasql(
                    "SELECT * FROM ? WHERE level = " + data.value + "",
                    [filterlist]
                  );
                  res = [...res, ...sql];
                  test = true;
                }
              });

              break;
          }
          if (test) {
            onFilter(res);
          } else {
            onFilter([]);
          }

          setFilteroptions(temp);

          //   setServicesOption2(res);
          break;
        case "clinic":
          var res = [];
          var temp = filteroptions;
          var test = false;
          switch (accourdians[pos1]) {
            // case accourdians[0]:
            //   temp[accourdians[pos1]][pos2].checked =
            //     !temp[accourdians[pos1]][pos2].checked;
            //   temp[accourdians[pos1]].map((data, index) => {
            //     if (data.checked === true) {
            //       var sql = alasql(
            //         "SELECT * FROM ? WHERE id = '" + data.value + "'",
            //         [filterlist]
            //       );
            //       res = [...res, ...sql];
            //       test = true;
            //     }
            //   });

            //   //   console.log("sort = ", res);
            //   break;

            case accourdians[0]:
              var res = [];

              temp[accourdians[pos1]][pos2].checked =
                !temp[accourdians[pos1]][pos2].checked;
              temp[accourdians[pos1]].map((data, index) => {
                if (data.checked === true) {
                  console.log(
                    "SELECT * FROM ? WHERE region = " + data.value + "",
                    accourdians[1],
                    temp[accourdians[pos1]]
                  );

                  var sql = alasql(
                    "SELECT * FROM ? WHERE region = '" + data.value + "'",
                    [filterlist]
                  );
                  res = [...res, ...sql];
                  test = true;
                }
              });
              break;
            case accourdians[1]:
              var res = [];

              temp[accourdians[pos1]][pos2].checked =
                !temp[accourdians[pos1]][pos2].checked;
              temp[accourdians[pos1]].map((data, index) => {
                if (data.checked === true) {
                  // console.log( "SELECT * FROM ? WHERE statusCode = " + data.value + "",accourdians[1],temp[accourdians[pos1]])

                  var sql = alasql(
                    "SELECT * FROM ? WHERE active = " + data.value + "",
                    [filterlist]
                  );
                  res = [...res, ...sql];
                  test = true;
                }
              });
              break;
          }
          if (test) {
            onFilter(res);
          } else {
            onFilter([]);
          }

          setFilteroptions(temp);

          //   setServicesOption2(res);
          break;
        case "Device":
          var res = [];
          var temp = filteroptions;
          var test = false;
          switch (accourdians[pos1]) {
            case accourdians[0]:
              temp[accourdians[pos1]][pos2].checked =
                !temp[accourdians[pos1]][pos2].checked;
              temp[accourdians[pos1]].map((data, index) => {
                if (data.checked === true) {
                  var sql = alasql(
                    "SELECT * FROM ? WHERE checked = " + data.value + "",
                    [filterlist]
                  );
                  res = [...res, ...sql];
                  test = true;
                }
              });

              //   console.log("sort = ", res);
              break;

            case accourdians[1]:
              var res = [];

              temp[accourdians[pos1]][pos2].checked =
                !temp[accourdians[pos1]][pos2].checked;
              temp[accourdians[pos1]].map((data, index) => {
                if (data.checked === true) {
                  // console.log( "SELECT * FROM ? WHERE statusCode = " + data.value + "",accourdians[1],temp[accourdians[pos1]])

                  var sql = alasql(
                    "SELECT * FROM ? WHERE venue ='" + data.value + "'",
                    [filterlist]
                  );
                  res = [...res, ...sql];
                  test = true;
                }
              });
              break;
        
          }
          if (test) {
            onFilter(res);
          } else {
            onFilter([]);
          }

          setFilteroptions(temp);

          //   setServicesOption2(res);
          break;
        case "staff":
          var res = [];
          var temp = filteroptions;
          var test = false;
          switch (accourdians[pos1]) {
            case accourdians[0]:
              temp[accourdians[pos1]][pos2].checked =
                !temp[accourdians[pos1]][pos2].checked;
              temp[accourdians[pos1]].map((data, index) => {
                if (data.checked === true) {
                  var sql = alasql(
                    "SELECT * FROM ? WHERE active = " + data.value + "",
                    [filterlist]
                  );
                  res = [...res, ...sql];
                  test = true;
                }
              });

              //   console.log("sort = ", res);
              break;
            case accourdians[1]:
              temp[accourdians[pos1]][pos2].checked =
                !temp[accourdians[pos1]][pos2].checked;
              temp[accourdians[pos1]].map((data, index) => {
                if (data.checked === true) {
                  // console.log("filter ==",filterlist)
                  var sql = alasql("SELECT * FROM ? WHERE employeeTypeId= ?", [
                    filterlist,
                    data.value,
                  ]);

                  res = [...res, ...sql];
                  test = true;
                }
              });

              //   console.log("sort = ", res);
              break;
          }
          if (test) {
            onFilter(res);
          } else {
            onFilter([]);
          }

          setFilteroptions(temp);

          //   setServicesOption2(res);
          break;
        case "employeeType":
          var res = [];
          var temp = filteroptions;
          var test = false;
          switch (accourdians[pos1]) {
            case accourdians[0]:
              temp[accourdians[pos1]][pos2].checked =
                !temp[accourdians[pos1]][pos2].checked;
              temp[accourdians[pos1]].map((data, index) => {
                if (data.checked === true) {
                  var sql = alasql(
                    "SELECT * FROM ? WHERE active = " + data.value + "",
                    [filterlist]
                  );
                  res = [...res, ...sql];
                  test = true;
                }
              });
              break;
          }
          if (test) {
            onFilter(res);
          } else {
            onFilter([]);
          }

          setFilteroptions(temp);

          //   setServicesOption2(res);
          break;
          case "activityDatabase":
            var res = [];
            var temp = filteroptions;
            var test = false;
            
            temp[accourdians[pos1]][pos2].checked =
            !temp[accourdians[pos1]][pos2].checked;
          var rec = {}
            Object.keys(temp).forEach(key => {
             
                temp[key].map((d,i)=>{
                    rec[d.name] = d.checked
                })
            });
     

                switch (accourdians[pos1]) {
              case accourdians[0]:
              
                temp[accourdians[pos1]].map((data, index) => {
                  if (data.checked === true) {
                         var  sql = filterlist.filter(item => (
                 
                    item.data.data.subtotalCountdown[data.name] === true
                  ));
                    res = [...res, ...sql];
                    test = true;
                  }
                });
                break;
                case accourdians[1]:
              
                temp[accourdians[pos1]].map((data, index) => {
                  if (data.checked === true) {
                    var  sql  = filterlist.filter(item => (
                 
                    item.data.data.rate[data.name] === true
                  ));
                    res = [...res, ...sql];
                    test = true;
                  }
                });
                break;
                case accourdians[2]:
              
                temp[accourdians[pos1]].map((data, index) => {
                  if (data.checked === true) {
                    var  sql  = filterlist.filter(item => (
                 
                    item.data.data.category === data.value
                  ));
                    res = [...res, ...sql];
                    test = true;
                  }
                });
                break;
            }
            console.log("filter ",res)
         
            if (res!==undefined) {
              onFilter(res);
            } else {
              onFilter([]);
            }
  
            setFilteroptions(temp);
  
            //   setServicesOption2(res);
            break;
        case "accessManagement":
          var res = [];
          var temp = filteroptions;
          var test = false;
          switch (accourdians[pos1]) {
            case accourdians[0]:
              temp[accourdians[pos1]][pos2].checked =
                !temp[accourdians[pos1]][pos2].checked;
              temp[accourdians[pos1]].map((data, index) => {
                if (data.checked === true) {
                  var sql = alasql(
                    "SELECT * FROM ? WHERE active = " + data.value + "",
                    [filterlist]
                  );
                  res = [...res, ...sql];
                  test = true;
                }
              });
              break;
          }
          if (test) {
            onFilter(res);
          } else {
            onFilter([]);
          }

          setFilteroptions(temp);

          //   setServicesOption2(res);
          break;
          case "contract":
            var res = [];
            var temp = filteroptions;
            var test = false;
            switch (accourdians[pos1]) {
              case accourdians[0]:
                temp[accourdians[pos1]][pos2].checked =
                  !temp[accourdians[pos1]][pos2].checked;
                temp[accourdians[pos1]].map((data, index) => {
                  if (data.checked === true) {
                    var sql = alasql(
                      "SELECT * FROM ? WHERE active = " + data.value + "",
                      [filterlist]
                    );
                    res = [...res, ...sql];
                    test = true;
                  }
                });
                break;
              }
              if (test) {
                onFilter(res);
              } else {
                onFilter([]);
              }
    
              setFilteroptions(temp);
          break;
          default:
            var temp = filteroptions;
            setFilteroptions(temp);
            break;
        }
    }
  };
 const loadData = async () => {
    if (!module || filterlist.length === 0) return;
  
    let temp = [];
    setClearAll(false);
  
    const setAccourdiansAndOptions = (accourdiansList, optionsList) => {
      setaccourdians(accourdiansList);
      setFilteroptions(optionsList);
    };
  
    const createOptionsList = (labels, values) => {
      return labels.map((label, index) => ({
        label: t(label),
        checked: false,
        value: values[index],
      }));
    };
  
    const createCheckedOptionsList = (labels, names, values) => {
      return labels.map((label, index) => ({
        label: t(label),
        name: names[index],
        checked: false,
        value: values[index],
      }));
    };
  
    switch (module) {
      case "service_alerts":
        setAccourdiansAndOptions(
          [t("filter.alertsfilter.statuslabel"), t("filter.alertsfilter.typelabel")],
          {
            [t("filter.alertsfilter.statuslabel")]: createOptionsList(
              ["filter.alertsfilter.statusActivelabel", "filter.alertsfilter.statusInactivelabel"],
              [true, false]
            ),
            [t("filter.alertsfilter.typelabel")]: createOptionsList(
              ["filter.alertsfilter.typeActionlabel", "filter.alertsfilter.typeAlertlabel"],
              ["action", "alert"]
            ),
          }
        );
        break;
  
      case "survey_list":
        setAccourdiansAndOptions(
          [t("filter.surveyfilter.labelSurveyType")],
          {
            [t("filter.surveyfilter.labelSurveyType")]: createOptionsList(
              ["filter.surveyfilter.branched", "filter.surveyfilter.normal"],
              [true, false]
            ),
          }
        );
        break;
  
      case "byName":
        setAccourdiansAndOptions(
          [t("By Department")],
          {
            "Active": createOptionsList(
              ["filter.alertsfilter.statusActivelabel", "filter.alertsfilter.statusInactivelabel"],
              [true, false]
            ),
            [t("By Department")]: generate_department(filterlist),
          }
        );
        break;
  
      case "Help_support":
        setAccourdiansAndOptions(
          [t("filter.help_support.Ticket_Severity"), t("filter.help_support.Ticket_Status")],
          {
            [t("filter.help_support.Ticket_Status")]: generate_list(filterlist),
            [t("filter.help_support.Ticket_Severity")]: createOptionsList(
              ["filter.help_support.Major", "filter.help_support.Medium", "filter.help_support.Low"],
              [0, 1, 2]
            ).map(option => ({ ...option, checked: false })),
            [t("filter.help_support.Ticket_Status")]: createOptionsList(
              ["filter.help_support.Open", "filter.help_support.Progress", "filter.help_support.Resolved"],
              [0, 1, 2]
            ).map(option => ({ ...option, checked: false })),
          }
        );
        break;
  
      case "clinic":
        setAccourdiansAndOptions(
          [t("filter.clinicFilter.region"), t("filter.clinicFilter.active")],
          {
            [t("filter.clinicFilter.active")]: createOptionsList(["Active", "Inactive"], [true, false]).map(option => ({ ...option, checked: false })),
            [t("filter.clinicFilter.region")]: generateRegions(filterlist)
          }
        );
        break;
  
      case "Device":
        const resp = generate_uniqueField_device(filterlist);
        setAccourdiansAndOptions(
          [t("filter.alertsfilter.statusActivelabel"), t("filter.Device.Store")],
          {
            [t("filter.alertsfilter.statusActivelabel")]: createOptionsList(
              ["filter.alertsfilter.statusActivelabel", "filter.alertsfilter.statusInactivelabel"],
              [true, false]
            ).map(option => ({ ...option, checked: true })),
            [t("filter.Device.Store")]: resp.store,
            "Zone": resp.zone,
            "Device List": generate_list(filterlist),
          }
        );
        break;
  
      case "staff":
        setAccourdiansAndOptions(
          [t("filter.alertsfilter.statusActivelabel"), t("Employee type")],
          {
            [t("filter.alertsfilter.statusActivelabel")]: createOptionsList(
              ["filter.alertsfilter.statusActivelabel", "filter.alertsfilter.statusInactivelabel"],
              [true, false]
            ).map(option => ({ ...option, checked: false })),
            [t("Employee type")]: employeeType.map(d => ({
              label: t(d.label),
              checked: false,
              value: d.id,
            })),
          }
        );
        break;
  
      case "employeeType":
        setAccourdiansAndOptions(
          [t("filter.alertsfilter.statusActivelabel")],
          {
            [t("filter.alertsfilter.statusActivelabel")]: createOptionsList(
              ["filter.alertsfilter.statusActivelabel", "filter.alertsfilter.statusInactivelabel"],
              [true, false]
            ).map(option => ({ ...option, checked: false })),
          }
        );
        break;
  
      case "accessManagement":
        setAccourdiansAndOptions(
          [t("filter.alertsfilter.statusActivelabel")],
          {
            [t("filter.alertsfilter.statusActivelabel")]: createOptionsList(
              ["filter.alertsfilter.statusActivelabel", "filter.alertsfilter.statusInactivelabel"],
              [true, false]
            ).map(option => ({ ...option, checked: false })),
          }
        );
        break;
  
      case "activityDatabase":
        setClearAll(true);
        setAccourdiansAndOptions(
          [t("Subtotal countdown"), t("Rate"),t("Type")],
          {
            [t("Subtotal countdown")]: createCheckedOptionsList(
              ["Hours", "Days", "Quantity"],
              ["hours", "days", "numbers"],
              [true, true, true]
            ),
            [t("Rate")]: createCheckedOptionsList(
              ["On Duty", "On call"],
              ["onDuty", "onCall"],
              [true, true]
            ),
            [t("Type")]: createCheckedOptionsList(
              ["ASV", "Vétérinaire","Others"],
              ["asv", "vet","oth"],
              ["asv", "vet","oth"]
            ),
          }
        );
        break;
        case "contract":
          setAccourdiansAndOptions(
            [t("filter.alertsfilter.statusActivelabel")],
            {
              [t("filter.alertsfilter.statusActivelabel")]: createOptionsList(
                ["filter.alertsfilter.statusActivelabel", "filter.alertsfilter.statusInactivelabel"],
                [true, false]
              ).map(option => ({ ...option, checked: false }))
            
            }
          );
        break;
        default:
        setAccourdiansAndOptions(
          [],
         []
        );
        break
    }
  };
  
  const handleClearall = ()=>{
    let temp = [];
    loadData()
    onFilter(filterlist);
  
  }
  useEffect(() => {
    // console.log("filter list",filterlist)
    loadData();
  }, [filterlist, t]);
  return (
    <>
      <MDButton
        className="btn-second white"
        sx={{
          padding: "11px 30px",
          fontSize: "16px",
          textTransform: "none",
          fontWeight: "normal",
          color: "#000",
          ":hover": { bgcolor: "#fff" },
        }}
        onClick={(e) => {
          setopenFilter(!openFilter);
        }}
        color="white"
      >
        <img src={FilterIcon} alt="icon" />
        &nbsp;{t("filter.filter")}
      </MDButton>
      <Box className={openFilter ? "right-bar open" : "right-bar"}>
        <Box
          className="top-section"
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          <MDTypography
            variant="h6"
            sx={{ fontWeight: "normal", fontSize: "20px" }}
            color="black"
          >
            {t("filter.filter")}
          </MDTypography>
          <Box sx={{ display: "flex" }}>
         <MDButton
              variant="gradient"
              className="clear-btn"
              sx={{
                padding: "0",
                fontSize: "16px",
                textTransform: "none",
                textDecoration: "underline",
                fontWeight: "normal",
                color: "#000",
              }}
              onClick = {(e)=>handleClearall()}
            >
              {t("Clear Filter")}
            </MDButton>
            <MDButton
              variant="gradient"
              sx={{
                padding: "0",
                textTransform: "none",
                fontWeight: "normal",
              }}
              onClick={(e) => {
                setopenFilter(false);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="13"
                viewBox="0 0 14 13"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M13.5543 0.413871C14.1486 0.965699 14.1486 1.86039 13.5543 2.41222L2.59777 12.5861C2.0035 13.138 1.03998 13.138 0.445707 12.5861C-0.148569 12.0343 -0.148569 11.1396 0.445707 10.5878L11.4022 0.413871C11.9965 -0.137957 12.96 -0.137957 13.5543 0.413871Z"
                  fill="#282B3B"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M0.445707 0.413871C1.03998 -0.137957 2.0035 -0.137957 2.59777 0.413871L13.5543 10.5878C14.1486 11.1396 14.1486 12.0343 13.5543 12.5861C12.96 13.138 11.9965 13.138 11.4022 12.5861L0.445707 2.41222C-0.148569 1.86039 -0.148569 0.965699 0.445707 0.413871Z"
                  fill="#282B3B"
                />
              </svg>
            </MDButton>
          </Box>
        </Box>
        <Box className="sidebar-accordion">
          {accourdians.map((data, index) => (
            <Accordion
              expanded={expanded === index}
              onChange={handleChange(index)}
            >
              <AccordionSummary
                className="accordion-panel"
                aria-controls="panel1bh-content"
                id="panel1bh-header2"
              >
                <Typography className="heading">{data}</Typography>
              </AccordionSummary>
              <AccordionDetails className="accordion-detail">
                {filteroptions[data] && (
                  <>
                    <Box className="checkbox-section">
                      <FormControlLabel
                        sx={{
                          display: "grid",
                          textAlign: "center",
                          marginRight: "0",
                        }}
                        className="cs-checkbox"
                        label={t("Select all")}
                        control={
                          <Checkbox
                          checked={filteroptions[data].every(option => option.checked)}
                          onClick={() => handleSelectAll(index)}
                          />
                        }
                      />
                    </Box>

                    {filteroptions[data].map((data2, index2) => (
                      <Box className="checkbox-section">
                        <FormControlLabel
                          sx={{
                            display: "grid",
                            textAlign: "center",
                            marginRight: "0",
                          }}
                          className="cs-checkbox"
                          label={t(data2.label)}
                          control={
                            <Checkbox
                              checked={data2.checked}
                              onClick={(e) => {
                                handlecheck(index, index2);
                              }}
                            />
                          }
                        />
                      </Box>
                    ))}
                  </>
                )}
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
      </Box>
    </>
  );
}

export default Filter;
