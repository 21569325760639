import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import MDButton from "components/MDButton";
import Filter from "../../components/Filter";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
//modal import
import { useTranslation } from "react-i18next";
import Loader from "components/Loader";

import { useNavigate,useParams } from "react-router-dom";

import { get_role } from "backendservice/firebasefunctions,";
import SearchComponent from "components/SearchInput";
import Sortlist from "components/Sortlist";
import { useMaterialUIController } from "context";
function Staff() {
  const [controller, dispatch] = useMaterialUIController();
  const {
    selecetdCustomer,

  } = controller;
  const navigate = useNavigate();

  const { t } = useTranslation();
  const [list, setlist] = useState([]);
  const [listbackup, setlistbackup] = useState([]);


  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);
  const [customerID, setCustomer] = useState("");

  const { id } = useParams()
  const loadData = async () => {
    let customer = selecetdCustomer.id
    if (customer !== "" && customer !== undefined) {
      setLoading(true)
      try {
        var data = await get_role(id, customer)
        setlist(data)
        setlistbackup(data)
        setLoading(false)
      } catch (error) {
        setLoading(false)
        setlist([])
        setlistbackup([])
        // console.log("error ==>",error)
      }
    }
  }
  useEffect(() => {
    loadData()
  }, [refresh,selecetdCustomer]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Loader loading={loading} />
      <MDBox pt={4} pb={3} className="xl-container">
        <Box>
          <Box>
            <MDBox className="page-heading">
              <MDTypography
                variant="h5"
                gutterBottom
                sx={{ pb: "8px", display: "flex" }}
              >
                <Box
                    sx={{
                      pt: "2px",
                      width: "22px;",
                      display: { xs: "none", sm: "flex" },
                    }}
                  >
                   
                  </Box>
                {t(list.label ? list.label : list.role)}
              </MDTypography>
            </MDBox>

            <Box>
              <Box
                sx={{
                  margin: "10px 0 0 0",
                }}
              >
                <MDBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                    {/* <SearchComponent searchList={listbackup} onSearch={(e) => setlist(e)} /> */}

                  <Box className="filter-mobile">
                    <MDBox
                      color="text"
                      px={0}
                      sx={{ display: { xs: "block", sm: "flex" } }}
                    >
                      <Box
                        sx={{
                          display: { xs: "block", sm: "flex" },
                        }}
                      >
                           {/* <Sortlist onSort={(e) => setlist(e)} sortlist={list} />

                            <Filter /> */}
                      </Box>
                    </MDBox>
                  </Box>
                </MDBox>
              </Box>
              <MDBox
                sx={{
                  marginTop: "20px",
                }}
              >
                <List className="datatable">
                  {list.module &&  Object.entries(list.module)
        .sort(([, a], [, b]) => a.pos - b.pos)
        .map(([key, data], index) =>
          data.view &&  (
                    data.active &&

                    <ListItem>
                      <Box className="list-content">
                        <Box className="left-content">
                          <label className="heading">{t(data.name)}</label>
                        </Box>
                        <Box className="blue-bg">
                     
                          {(data.accesstype == 2 && data.id!=="REST10") && <Box className="info-tag pink">
                            <span>{t("Read & Write")}</span>

                          </Box>}
                          {data.accesstype == 1  && <Box className="info-tag yellow">
                            <span>{t("Read")}</span>

                          </Box>}
                          {(data.admin && data.accesstype == 0)  && <Box className="info-tag blue">
                            <span>{t("Admin")}</span>

                          </Box>}
                        </Box>
                      </Box>
                    </ListItem>

                  ))}
              
                
                </List>
              </MDBox>
              <Box className="navigation-link">
                <MDButton
                  className="primary-btn btn xl"
                  onClick={() => navigate(-1)}
                >
                 <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none">
<path fill-rule="evenodd" clip-rule="evenodd" d="M0 6.49961C0 5.9524 0.443608 5.50879 0.990826 5.50879H11.0092C11.5564 5.50879 12 5.9524 12 6.49961C12 7.04683 11.5564 7.49044 11.0092 7.49044H0.990826C0.443608 7.49044 0 7.04683 0 6.49961Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M6.70062 0.790206C7.08756 1.17715 7.08756 1.8045 6.70062 2.19145L2.39206 6.5L6.70062 10.8086C7.08756 11.1955 7.08756 11.8229 6.70062 12.2098C6.31368 12.5967 5.68632 12.5967 5.29938 12.2098L0.290206 7.20062C-0.0967354 6.81368 -0.0967354 6.18632 0.290206 5.79938L5.29938 0.790206C5.68632 0.403265 6.31368 0.403265 6.70062 0.790206Z" fill="white"/>
</svg>
                  &nbsp; {t("Go Back")}
                </MDButton>
              </Box>
            </Box>
          </Box>
        </Box>
      </MDBox>
    </DashboardLayout>
  );
}

export default Staff;
