import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { get_staff_clinic } from "backendservice/firebasefunctions,";
import { get_clinic_id } from "backendservice/firebasefunctions,";
import { get_customer } from "backendservice/firebasefunctions,";
import { get_activities } from "backendservice/firebasefunctions,";
import { get_contract } from "backendservice/firebasefunctions,";
import { get_clinic } from "backendservice/firebasefunctions,";
import moment from "moment";
import { getMonthsBetweenDates } from "backendservice/firebasefunctions,";
import { get_staff_leave_pending } from "backendservice/firebasefunctions,";
import { get_pending_leave_date } from "layouts/scheduler/functions";
import { get_shedule } from "backendservice/firebasefunctions,";
import { get_employeeType } from "backendservice/firebasefunctions,";
import { getContractType } from "layouts/staff/functions";
import { v4 } from "uuid";
import { useTranslation } from "react-i18next";

const handleDownload = async (
  customer,
  clinics,
  startDate,
  endDate,
  category,
  mode,t
) => {
  // const {t} = useTranslation()
  var response = await reportcalculation(
    customer,
    clinics,
    startDate,
    endDate,
    category,
    mode[0]
  );

  var data = [];
  // Create sample data
  // console.log("response", response);
 mode.map((m,i)=>{
  Object.entries(response).map(([key, report]) => {
    var activities = [];
    var intrecord  = []
    report.activitylist.map((rec, index) => {
      activities.push({ activityName: rec.activityName.trim() });
    });
    activities.push({ activityName:t("Total hours")  })
    activities.push({ activityName:t("Total days")  })
    // <li>{t("Total hours")}</li>
    // <li>{t("Total days")}</li>
    report.oncallList.map((rec, index) => {
      activities.push({ activityName: rec.activityName.trim() });
    });
    activities.push({ activityName:t("Total hours") })
    activities.push({ activityName:t("Total quantity")})
    activities.push({ activityName:t("Leave") })
    activities.push({ activityName:t("Contract Start") })
    activities.push({ activityName:t("Contract End") })
    activities.push({ activityName:t("Type of contract") })
    // activities.push({ activityName:"No of days in contract" })
    var users = [];
 
    {Object.entries(report.report).map(([k, d]) => (
      users.push({id:d.mapid, label:d.username,userid:d.userdetails.id })
    ))}
    // Creating the header row with activity names
    var header = [
      t("Clinic"),
      t("Staff"),
      ...activities.map((activity) => activity.activityName.trim()),
    ];
    // console.log("header == ", header);
    // Creating data rows for each user
    var type = [
      m==0?t("Hours contract"):m==1?t("Days of Contract"):t("Collabs")
    ]
    intrecord= [[],[type], header];
  
    users.forEach((user) => {
      var record = [];
      var activitytotal = 0
      var oncalltotal = 0
      var oncalltotalquantity = 0
      var totalnoofdays = 0
      report.activitylist.map((d, i) => {
        var userinfo = report.report[user.id];
        var rec = "-";
    
        if(m==0)
          {
            rec = userinfo.activity[d.activityName.trim()]
            // total += parseFloat(rec)
         
            
            // activityDays
          }else if(m==1)
          {
            rec = userinfo.activityDays[d.activityName.trim()]
            // total += parseFloat(rec)
          }
          else if(m==2)
          { 
            rec = "-"
            // total += parseFloat(rec)
          }
        totalnoofdays += Number.isFinite(userinfo.activityNoOfDays[d.activityName.trim()])
        ? userinfo.activityNoOfDays[d.activityName.trim()]
        : 0;
        activitytotal += parseFloat(rec)
        record.push(rec);
      });
      record.push(Number.isNaN(activitytotal)?"-":activitytotal)
      record.push(Number.isNaN(totalnoofdays)?"-":totalnoofdays)
      
      report.oncallList.map((d, i) => {
        var userinfo = report.report[user.id];
        if(userinfo.oncall[d.id]>0)oncalltotalquantity++
        // console.log(userinfo)
        var rec = "-";
        if(m==0)
          {
            rec = userinfo.oncall[d.id]
            if(userinfo.oncall[d.id]>0)oncalltotal += parseFloat(rec)
            // activityDays
         
          }else if(m==1)
          {
            rec = userinfo.oncallDays[d.id]
            if(userinfo.oncall[d.id]>0)oncalltotal += parseFloat(rec)
        
          }
          else if(m==2)
          { 
            rec = "-"
           
            // total += rec
          }
        // if(mode==0)
        // {
        // rec = userinfo.oncall[d.id];
        // activityDays
        // }else if(mode==1)
        // {
        //   rec = userinfo.oncallDays[d.id]
        // }
        // else if(mode==2)
        // {
        //   rec = "-"
        // }
       
        record.push(rec);

        // record.push(userinfo[activity.id])
      });
    
      
      record.push(Number.isNaN(oncalltotal)?"-":oncalltotal)
      record.push(Number.isNaN(oncalltotalquantity)?"-":oncalltotalquantity)
      
      var userinfo = report.report[user.id];
      var rec = 0 
      if(userinfo.leavecount) rec = userinfo.leavecount
      record.push(rec)
      var rec = userinfo.contract_duration || 0
      var startdate = userinfo.contract_startdate
      var endDate = userinfo.contract_endDate
      var contactName = userinfo.contract_contactName

      record.push(startdate)
      record.push(endDate)
      record.push(contactName)
      // record.push(rec)

      var row = [
        report.clinicdetails.label,
        user.label,
        //  ...Array(activities.length).fill("")
        ...record,
      ]; // Filling columns for activities with empty values
      intrecord.push(row);
     
    });
    data = [...data, ...intrecord];
  });
 })


  // Create a new workbook
  const ws = XLSX.utils.aoa_to_sheet(data);
  const wb = XLSX.utils.book_new();

  // some formating -------------
  // ws["!merges"] = [
  //   { s: { r: 0, c: 0 }, e: { r: 0, c: 4 } }, // Merging cells A1 to E1
  // ];
  // if (!ws["A1"]) ws["A1"] = {}; // Initialize the cell if it doesn't exist

  // ws["A1"].v = "Activities"; // Set the text
  //   ws["A1"].s = {
  //     alignment: {
  //       vertical: "center", // Vertical alignment
  //       horizontal: "center", // Horizontal alignment
  //     },
  //     fill: {
  //       fgColor: { rgb: "FFFF00" }, // Background color (yellow)
  //     },
  //     font: {
  //       bold: true,
  //       color: { rgb: "000000" }, // Font color (black)
  //     },
  //   };

  // Add worksheet to the workbook
  XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

  // Generate Excel file
  const wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });

  // Convert binary string to Blob
  const blob = new Blob([s2ab(wbout)], { type: "application/octet-stream" });

  // Trigger download
  saveAs(blob, moment().format("YYYY_DDMM") + v4() + ".xlsx");
};

const s2ab = (s) => {
  const buf = new ArrayBuffer(s.length);
  const view = new Uint8Array(buf);
  for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
  return buf;
};

const get_option = async (customer, clinic) => {
  var resp = await loadOptions(customer, [clinic]);
  var cliniclist = await get_clinic(customer);
  resp.cliniclist = cliniclist;
  console.log("response == re", resp);
  return resp;
};

async function loadOptions(customer, clinic) {
  var staffdetails = [];
  var clinicdetails = [];
  var activities = [];
  var contract = [];
  var allOptions = {};
  const promises = clinic.map(async (data, index) => {
    var staff = await get_staff_clinic(customer, data);
    if (staff) {
      staffdetails = [...staffdetails, ...staff];
    }

    var clinic = await get_clinic_id(data);
    if (clinic) {
      clinicdetails = [...clinicdetails, clinic];
    }
    // var customerDetils = await get_customer(customer);
    var activitydatabase = await get_activities(customer, data);
    if (activitydatabase) {
      activities = [...activities, ...activitydatabase];
    }
    var contractlist = await get_contract(customer, data, true);
    if (contractlist) {
      contract = [...contract, ...contractlist];
    }
    allOptions[data] = {
      staff: staff,
      clinic: clinic,
      activitydatabase: activitydatabase,
      contractlist: contractlist,
    };
  });

  const optionDetails = await Promise.all(promises);
  return { staffdetails, clinicdetails, activities, contract };
  // console.log("alloption == ", allOptions);
  return true;
}

async function reportcalculation(
  customer,
  clinics,
  startDate,
  endDate,
  category,
  mode
) {
  var start = moment(startDate).format("YYYY-MM-DD");
  var end = moment(endDate).format("YYYY-MM-DD");
  var months = getMonthsBetweenDates(start, end);
  var reports = [{}];
  var employeeTypes = await get_employeeType(customer);
  var contractList = await get_contract(customer);

  reports = await Promise.all(
    clinics.map(async (clinicdata) => {
      var users = await get_staff_clinic(customer, clinicdata.id);
      users = await Promise.all(
        users.map(async (data) => {
          const employeeType =
            employeeTypes.find((obj) => obj.id === data.employeeTypeId)
              ?.label || "";
          delete data.data; // remove this line if any indepth data is equired
          // console.log("user data == ",data)
          return {
            ...data,
            employeeType,
            contractType:
              data.contractList !== undefined
                ? getContractType(
                    data.contractList[data.contractList.length - 1]?.contractId,
                    contractList
                  )
                : "",
                
            employee: employeeTypes.find(
              (obj) => obj.id === data.employeeTypeId
            ),
          };
        })
      );
      var activitydatabase = await get_activities(customer, clinicdata.id);

      users = users.filter((staff) => {
        {
          if (staff.employee?.category) {
            return  category.includes(staff.employee.category);
          } else {
            return false;
          }
        }
      });
      activitydatabase = activitydatabase.filter((data) =>
        data.category ? category.includes(data.category): false
      );
      const clinicleveldata = await users.reduce(async (acc, data) => {
        const accumulator = await acc;
        var allrec = {}
        var scheduledetails_all = await get_shedule(
          customer,
          clinicdata.id,
          data.id,
          startDate,
          endDate
        );
        for (let i = 0; i < data.contractList.length; i++) {
          // console.log(`Element at index ${i} is ${myArray[i]}`);
          var contract_startdate =data.contractList[i].start
          var contract_endDate =data.contractList[i].end==null?endDate:data.contractList[i].end
          var c_start = moment(contract_startdate,"YYYY-MM-DD")
          var c_end = moment(contract_endDate,"YYYY-MM-DD")
          var contractdetails = contractList.find((d) => d.id === data.contractList[i].contractId);
          // console.log("contract details ",contractdetails)
        var scheduledetails = Object.entries(scheduledetails_all)
          .filter(
            ([date]) =>
              moment(date).isSameOrAfter(c_start) &&
              moment(date).isSameOrBefore(c_end)
          )
          .reduce((acc, [date, data]) => {
            acc[date] = data;
            return acc;
          }, {});

        var activitiAssigned = {};
        var activitynoofday = {}
        var onCallactivity = {};
        var Actdays = {};
        var OncDays = {};
        var leave = 0
        for (let key in scheduledetails) {
          var activities = [];
          var spActivity = [];
          
          if (scheduledetails.hasOwnProperty(key)) {
            if (
              scheduledetails[key] &&
              scheduledetails[key].activityM &&
              scheduledetails[key].activitySP &&
              scheduledetails[key].activityAf
            ) {
              activities = [
                ...scheduledetails[key].activityM,

                ...scheduledetails[key].activityAf,
                ...scheduledetails[key].activitySP,
              ];
              // spActivity = [...scheduledetails[key].activitySP];
            }
          }
          // console.log("conytract type ==", data.contractType.contractType);
          if(scheduledetails[key].leave)
          {
            
            leave =leave+(scheduledetails[key].leaveValue==0 ?1:0.5)
          }
          // console.log("contract inside ==",contractdetails?.contractType)
          // activities.forEach((d) => {
          //   if (contractdetails?.contractType.hours) {
          //     const duration = calculate_diff(
          //       d.duration.startingTime.seconds,
          //       d.duration.closingTime.seconds,
          //       "hours"
          //     );
          //     activitiAssigned[d.id] = activitiAssigned[d.id]
          //       ? activitiAssigned[d.id] + duration
          //       : duration;
          //   }
          //   // if(d.duration.days)
          //   if (contractdetails?.contractType.days) {
          //     const duration = calculate_diff(
          //       d.duration.startingTime.seconds,
          //       d.duration.closingTime.seconds,
          //       "days"
          //     );
          //     console.log("calculated days ==",duration)
          //     // console.log(duration)
          //     Actdays[d.id] = Actdays[d.id]
          //       ? Actdays[d.id] + duration
          //       : duration;
          //   }

         

          // });

          activities.forEach((d) => {
            if (contractdetails?.contractType.hours) {
              var duration = calculate_diff(
                d.duration.startingTime.seconds,
                d.duration.closingTime.seconds,
                "hours"
              );
              if (d.rate.onCall || d.rate.onCallIn || d.rate.onDuty) {
                // activitiAssigned[d.id] = activitiAssigned[d.id]
                // ? activitiAssigned[d.id] + duration
                // : duration;


                if(d.duration.timeBreakup.length==1)
            {  activitiAssigned[d.id] = activitiAssigned[d.id]
                ? activitiAssigned[d.id] + duration
                : duration;
              }else{
              
                 const moment1 = moment.unix(d.duration.startingTime.seconds);
                const moment2 = moment.unix(d.duration.closingTime.seconds);

                if (moment2.format("HH:mm") === "23:59") {
                 
                  // Calculate the difference in hours and add 1 minute to round up to 24 hours.
                  const differenceInHours = moment2.diff(moment1, "hours", true) + 1/60;
                  // console.log("difference is 1 min ",differenceInHours,duration)
                  duration = Math.round(differenceInHours * 100) / 100;
                } else {
                  // Regular calculation for other times.
                  // console.log("difference is 1 min ",moment2.format("HH:mm"))
                  const differenceInHours = moment2.diff(moment1, "hours", true);
                  duration = Math.round(differenceInHours * 100) / 100;
                }
                // Calculate the difference in hours
                
                activitiAssigned[d.id] = activitiAssigned[d.id]
                ? activitiAssigned[d.id] + duration
                : duration;

                }
              }else{
                activitiAssigned[d.activityName.trim()] = activitiAssigned[d.activityName.trim()]
                ? activitiAssigned[d.activityName.trim()] + duration
                : duration;

              }
            }
            // if(d.duration.days)console.log()
            
            if (d.duration.days) {
              var duration = calculate_diff(
                d.duration.startingTime.seconds,
                d.duration.closingTime.seconds,
                "days"
              );
             
              console.log("activity name  == ",d.activityName.trim(),duration,Actdays[d.activityName.trim()])
              // console.log(duration)
              if (d.rate.onCall || d.rate.onCallIn || d.rate.onDuty) {
                Actdays[d.id] = Actdays[d.id]
                ? Actdays[d.id] + duration
                : duration;
              //   if(d.duration.timeBreakup.length==1)
              //     {  
              //       Actdays[d.id] = Actdays[d.id]
              //   ? Actdays[d.id] + duration
              //   : duration;
              //       }else{
                    
              //          const moment1 = moment.unix(d.duration.startingTime.seconds);
              //         const moment2 = moment.unix(d.duration.closingTime.seconds);
      
              //         if (moment2.format("HH:mm") === "23:59") {
              //           // Calculate the difference in days and add 1 minute as a fraction of a day to round up to a full day.
              //           const differenceInDays = moment2.diff(moment1, "days", true) + 1 / 1440; // 1 minute = 1/1440 of a day
              //           duration = Math.round(differenceInDays * 100) / 100;
              //         } else {
              //           // Regular calculation for other times.
              //           const differenceInDays = moment2.diff(moment1, "days", true);
              //           duration = Math.round(differenceInDays * 100) / 100;
              //         }

              // Actdays[d.id] = Actdays[d.id]
              //   ? Actdays[d.id] + duration
              //   : duration;
              // }
            }
          else{

            Actdays[d.activityName.trim()] = Actdays[d.activityName.trim()]
            ? Actdays[d.activityName.trim()] + duration
            : duration;
          }

          if (d.rate.onCall || d.rate.onCallIn || d.rate.onDuty) {

          
        }else{
          if(d.duration.days)
            {  
              activitynoofday[d.activityName.trim()] = activitynoofday[d.activityName.trim()]
                ? activitynoofday[d.activityName.trim()] + d.duration.numberOfDays!==""? parseFloat(d.duration.numberOfDays):0
                : d.duration.numberOfDays!==""? parseFloat(d.duration.numberOfDays):0;
              }
        }
         

        }});



        }
        console.log("calculated days ==",Actdays)
        // console.log("activity days  == ", Actdays);
        var activitylist = {};
        var noOfDays = {};
        var oncalllist = {};
        var activityDays = {};
        var oncallDays = {};
        // var leave = {};
        var leaveDays = {};
        // console.log("user data  =",data)
        // console.log("contract inside ==",contractdetails?.contractType)


        // activitydatabase.forEach((d) => {
        //   // console.log("contract inside ==",contractdetails?.contractType)
        //   if (d.rate.onCall || d.rate.onCallIn || d.rate.onDuty) {
        //     oncalllist[d.id] = activitiAssigned[d.id]
        //       ? activitiAssigned[d.id].toFixed(2)
        //       :contractdetails?.contractType.hours
        //       ? "0"
        //       : "-";

        //     oncallDays[d.id] = Actdays[d.id]
        //       ? Actdays[d.id].toFixed(2)
        //       : 
        //       contractdetails?.contractType.days
        //       ? "0"
        //       : "-";
        //   } else {
        //     activitylist[d.id] = activitiAssigned[d.id]
        //       ? activitiAssigned[d.id].toFixed(2)
        //       : contractdetails?.contractType.hours
        //       ? "0"
        //       : "-";


              
        //     activityDays[d.id] = Actdays[d.id]
        //       ? Actdays[d.id].toFixed(2)
        //       :
        //        contractdetails?.contractType.days
        //       ? "0"
        //       : "-";
        //   }
        // });
        console.log('activitydays ===',activitiAssigned,activitydatabase,Actdays)

        activitydatabase.forEach((d) => {
          console.log("name of the on day -- ",parseFloat(activitynoofday[d.activityName.trim()]))
          noOfDays[d.activityName.trim()] = activitynoofday[d.activityName.trim()]
          ? parseFloat(activitynoofday[d.activityName.trim()])
          :  "0";
          // console.log("contract inside ==",contractdetails?.contractType)
          if (d.rate.onCall || d.rate.onCallIn || d.rate.onDuty) {
            oncalllist[d.id] = activitiAssigned[d.id]
              ? activitiAssigned[d.id].toFixed(2)
              :contractdetails?.contractType.hours
              ? "0"
              : "-";

            oncallDays[d.id] = Actdays[d.id]
              ? Actdays[d.id].toFixed(2)
              : 
              contractdetails?.contractType.days
              ? "0"
              : "-";
          } else {
            activitylist[d.activityName.trim()] = activitiAssigned[d.activityName.trim()]
              ? activitiAssigned[d.activityName.trim()].toFixed(2)
              : contractdetails?.contractType.hours
              ? "0"
              : "-";


              console.log("activitydays ",d.activityName.trim(),Actdays[d.activityName.trim()])
            activityDays[d.activityName.trim()] = Actdays[d.activityName.trim()]
              ? Actdays[d.activityName.trim()].toFixed(2)
              :
               contractdetails?.contractType.days
              ? "0"
              : "-";
          }
        });


        console.log('activity list === ',activitylist)
        allrec[data.id+i] = {
          mapid:data.id+i,
          username:data.label,
          userdetails:data,
          
          activity: activitylist,
          oncall: oncalllist,
          activityDays: activityDays,
          activityNoOfDays:noOfDays,
          oncallDays: oncallDays,
          leavecount: leave,
          contract_startdate:data.contractList[i].start,
          contract_endDate:data.contractList[i].end==null?"-":data.contractList[i].end,
          contract_contactName:data.contractList[i].contract,
          contract:data.contractList[i].contractId,
          contract_duration:calculate_days(data.contractList[i].start,data.contractList[i].end==null?end:data.contractList[i].end)


        }
    

      }
      return {
        ...accumulator,
       ...allrec
      };

      }, Promise.resolve({}));

      return {
        [clinicdata.id]: {
          clinicid: clinicdata.id,
          clinicdetails: clinicdata,
          userlist: users,
          activitylist: activitydatabase.filter(
            (item) =>
              !item.rate.onCall && !item.rate.onCallIn && !item.rate.onDuty
          ).filter((item, index, self) => 
            index === self.findIndex((i) => i.activityName.trim() === item.activityName.trim())
          ),
          oncallList: activitydatabase.filter(
            (item) => item.rate.onCall || item.rate.onCallIn || item.rate.onDuty
          ),
          report: clinicleveldata,
        },
      };
    })
  );

  // Convert the array of objects into a single object
  const reportsObject = Object.assign({}, ...reports);

  return reportsObject;
}
const validateDate = (startDate, endDate) => {
  var start = moment(startDate);
  var end = moment(endDate);
  return start.isSameOrBefore(end);
};
function addYearAndMonth(dateStr) {
  const date = new Date(dateStr); // Create a date object from the input string
  date.setFullYear(date.getFullYear() + 1); // Add one year
  date.setMonth(date.getMonth() + 1); // Add one month

  // Return the new date in YYYY-MM-DD format
  return date;
}

function calculate_diff(timestamp1, timestamp2, type = "hours") {
  // Convert Firebase timestamps to JavaScript Date objects
  // const date1 = timestamp1.toDate();
  // const date2 = timestamp2.toDate();

  // Create moment objects from the dates
  const moment1 = moment.unix(timestamp1);
  const moment2 = moment.unix(timestamp2);

  // Calculate the difference in hours
  const differenceInHours = moment2.diff(moment1, type, true); // 'true' to get fractional hours
  var diff = Math.round(differenceInHours * 100) /100  
  // console.log("calculated diff ",diff)
  return diff;
}
function calculate_days(date1, date2, type = "days") {
  // Create moment objects from the date strings
  const moment1 = moment(date1, "YYYY-MM-DD");
  const moment2 = moment(date2, "YYYY-MM-DD");

  // Calculate the difference in days
  const differenceInDays = moment2.diff(moment1, type, true); // 'true' to get fractional days
  // console.log("dayss",differenceInDays)
  var diff = Math.round(differenceInDays * 100) /100  
  // console.log("calculated diff ",diff)
  return diff // Round to 2 decimal places// Round to 2 decimal places
}
const checkReportDisplay = (report) => {
  if (report.userlist.length == 0 || report.activitylist.length == 0) {
    return false;
  } else {
    return true;
  }
};
export {
  handleDownload,
  loadOptions,
  get_option,
  validateDate,
  addYearAndMonth,
  reportcalculation,
  checkReportDisplay,
};
