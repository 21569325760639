// import { Navigate } from 'react-router-dom';
// import ComingSoon from "layouts/coming_soon";
import Customer from "layouts/customer";
import Clinic from "layouts/clinic";
import Scheduler from "layouts/scheduler/static";
import Staff from "layouts/staff";
import StaffDetail from "layouts/staff/staff-details";
import Activities from "layouts/activities";
import ActivitiesDetail from "layouts/activities/activities-details";
import Employee from "layouts/employee";
import ContractReport from "layouts/contracts/contract-report";
import AccessProfiles from "layouts/access-profile";
import Access from "layouts/access-profile/access";
import Report from "layouts/report";
import Help from "layouts/help";
import Privacy from "layouts/privacy";
import AccountSetting from "layouts/account-setting";
import LeaveForm from "layouts/leave-management/leave-form";
import Chat from "layouts/help/chat";
import AccessDetail from "layouts/access-profile/access-profile-detail";
import Termsofsale from "layouts/termsofsale";

// @mui icons
import SignIn from "layouts/authentication";
import Addcustomer from "layouts/customer/addcustomer";
import Addclinic from "layouts/clinic/addclinic";
import Contract from "layouts/contracts";
import Userlist from "layouts/access-profile/access-profile";
import EditStaff from "layouts/staff/edit-staff";
import EditLeave from "layouts/leave-management/Edit-Leave"

import Index from 'layouts/leave-management';
import Policyv2 from "layouts/privacy/policyv2";
var datass = localStorage.getItem("user")
var admin = false
var internalAdmin = false
var brandAdmin = false 
if (datass == undefined || datass == "") {
} else {
  const json = JSON.parse(datass)
  if (json.roleCode == "IA_00") {
    internalAdmin = true
  }
  if (json.roleCode == "BA_00") {
    brandAdmin = true
  }
  if (json.roleCode == "IA_00" || json.roleCode == "BA_00") {
    admin = true
  }

}

const routes = [
  {
    id:"REST01",
    type: "collapse",
    name: "Customer list",
    key: "customer",
    icon: "customer",
    route: "/customer",
    component: <Customer />,
  },
  {
    // id:"REST01",
    type: "hidden",
    name: "Customer Detail",
    key: "customer",
    icon: "customer",
    route: "/customer/add",
    component: <Addcustomer />,
  },
  {
    type: "hidden",
    name: "Customer Detail",
    key: "customer",
    icon: "customer",
    route: "/customer/edit/:id",
    component: <Addcustomer />,
  },
  {
    type: "hidden",
    name: "Clinic add",
    key: "clinic",
    icon: "clinic",
    route: "/clinic/add",
    component: <Addclinic />,
  },
  {
    type: "hidden",
    name: "Edit Clinic",
    key: "clinic",
    icon: "clinic",
    route: "/clinic/edit/:id",
    component: <Addclinic />,
  },
  {
    type: "hidden",
    name: "Account Setting",
    key: "account",
    icon: "account",
    route: "/account-setting",
    component: <AccountSetting />,
  },
  {
    id:"REST02",
    type: "dropdown",
    name: "Configuration",
    key: "configuration",
    icon: "configuration",
    collapse: [
      {
        id:"REST0101",
        name: "Customer Details",
        key: "details",
        route: "/customer/details",
        component: <Addcustomer type="brandDetails"/>,
      },
      {
        id:"REST03",
        name: "Clinic management",
        key: "clinic",
        route: "/clinic",
        component: <Clinic />,
      },
      {
        id:"REST09",
        name: "Access management",
        key: "access",
        route: "/management",
        component: <AccessProfiles />,
      },
      {
        id:"REST07",
        name: "Employee type",
        key: "employee",
        route: "/employee",
        component: <Employee />,
      },
      {
        id:"REST08",
        name: "Contract type",
        key: "contract",
        route: "/contract",
        component: <Contract />,
      },
      {
        id:"REST05",
        name: "Staff database",
        key: "staff",
        route: "/staff",
        component: <Staff />,
      },
      {
        id:"REST06",
        name: "Activities database",
        key: "database",
        route: "/database",
        component: <Activities />,
      },
     
     
   
    ],
  },
  {
    name:"Edit activities",
    key:"database",
    route:"/database/edit/:id",
    component:<ActivitiesDetail />
  },
  {
    type: "hidden",
    name: "Staff Detail",
    key: "help",
    icon: "help",
    route: "/staff/detail",
    component: <StaffDetail />,
  },
  {
    type: "hidden",
    name: "edit staff",
    key: "help",
    icon: "help",
    route: "/staff/edit/:id",
    component: <EditStaff />,
  },
  {
    type: "hidden",
    name: "Activities Detail",
    key: "help",
    icon: "help",
    route: "/database/detail",
    component: <ActivitiesDetail />,
  },
  {
    type: "hidden",
    name: "Access Profile",
    key: "access",
    icon: "help",
    route: "/management/access/:id",
    component: <Access />,
  },
  {
    type: "hidden",
    name: "Access Profile",
    key: "access",
    icon: "help",
    route: "/management/access-profile/:id",
    component: <Userlist />,
  },
  {
    type: "hidden",
    name: "Access Profile Detail",
    key: "access",
    icon: "help",
    route: "/management/access-detail",
    component: <AccessDetail />,
  },
  {
    type: "hidden",
    name: "Access Profile Detail",
    key: "access",
    icon: "help",
    route: "/management/access-edit/:id",
    component: <AccessDetail />,
  },
  
  {
    type: "hidden",
    name: "Contract Report",
    key: "help",
    icon: "help",
    route: "/contract/report",
    component: <ContractReport />,
  },
  
  {
    id:"REST10",
    type: "collapse",
    name: "Scheduler",
    key: "schedule",
    icon: "schedule",
    route: "/scheduler",
    component: <Scheduler />,
  },
  {
    id:"REST13",
    type: "collapse",
    name: "Leave Management",
    key: "leave",
    icon: "leave",
    route: "/leave-management",
    component:<Index module ="REST13"/>,
  },
  // {
  //   id:"REST10",
  //   type: "collapse",
  //   name: "Scheduler new",
  //   key: "new",
  //   icon: "schedule",
  //   route: "/scheduler/new",
  //   component: <Schedularv2 />,
  // },
  {
    id:"REST11",
    type: "collapse",
    name: "Report",
    key: "report",
    icon: "report",
    route: "/report",
    component: <Report />,
  },
  // {
  //   id:"REST12",
  //   type: "collapse",
  //   name: "Help & support",
  //   key: "help",
  //   icon: "help",
  //   route: "/help-support",
  //   component: brandAdmin ? <BrandAdmin /> : internalAdmin ? <Admin /> : <Help />,
  // },
  // {
  //   id:"REST12",
  //   type: "collapse",
  //   authorization:"OT_00",
  //   name: "Help & support",
  //   key: "help",
  //   icon: "help",
  //   route: "/help-support/ot",
  //   component:  <Help />,
  // },
  // {
  //   id:"REST12",
  //   type: "collapse",
  //   authorization:"IA_00",
  //   name: "Help & support",
  //   key: "help",
  //   icon: "help",
  //   route: "/help-support/ia",
  //   component:  <Admin />,
  // },
  {
    id:"REST12",
    // authorization:"BA_00",
    type: "collapse",
    name: "Help & support",
    key: "help",
    icon: "help",
    route: "/help-support",
    component: <Help module="REST12" /> ,
  },
  {

    type: "hidden",
    name: "Help & support",
    key: "help",
    icon: "help",
    route: "/help-support/chat/:id",
    component: <Chat />,
  },

  
  {
    type: "hidden",
    name: "Leave Management",
    key: "leave",
    icon: "leave",
    route: "/leave-management/apply",
    component: <LeaveForm />,
  },
  {
    type: "hidden",
    name: "Leave Management",
    key: "leave",
    icon: "leave",
    route: "/leave-management/edit/:id",
    component: <EditLeave />,
  },
  {
    id:"REST14",
    type: "collapse",
    name: "Terms of sale",
    key: "termsofsale",
    icon: "privacy",
    route: "/sale/doc",
    component: <Termsofsale />,
  },
  {
    id:"REST15",
    type: "collapse",
    name: "Privacy policy",
    key: "privacy",
    icon: "privacy",
    route: "/privacy-policy",
    component: <Privacy />,
  },
  {
    id:"REST16",
    type: "collapse",
    name: "General conditions of use",
    key: "policyv2",
    icon: "privacy",
    route: "/privacy/policy/v2",
    component: <Policyv2 />,
  },
  {
    type: "hidden",
    name: "Sign In",
    key: "sign-in",
    icon: "table",
    route: "/authentication/sign-in",
    component: <SignIn />,
  },
];

export default routes;
