const workingDays = {
    Monday: {
      opening_time: "",
      closing_time:  "",
    },
    Tuesday: {
      opening_time: "",
      closing_time:  "",
    },
    Wednesday: {
      opening_time:  "",
      closing_time:  "",
    },
    Thursday: {
      opening_time:  "",
      closing_time:  "",
    },
    Friday: {
      opening_time:  "",
      closing_time:  "",
    },
    Saturday: {
      opening_time:  "",
      closing_time:  "",
    },
    Sunday: {
      opening_time:  "",
      closing_time:  "",
    },
  }

  function calculateHours(startTime, endTime) {
    // Split the start and end time into hours and minutes
    if(startTime!=="" && endTime!== "")
    {
        const [startHour, startMinute] = startTime.split(':').map(Number);
        const [endHour, endMinute] = endTime.split(':').map(Number);
    
        // Convert the start and end time to minutes
        const startTimeInMinutes = startHour * 60 + startMinute;
        const endTimeInMinutes = endHour * 60 + endMinute;
    
        // Calculate the difference in minutes
        const differenceInMinutes = endTimeInMinutes - startTimeInMinutes;
    
        // Calculate the difference in hours
        const differenceInHours = (differenceInMinutes / 60);
    
        return differenceInHours.toFixed(2);
    }else{
        return 0
    }
  
}
  const timeoptioncreator = () => {
    const timeoption = [];
    const min = [];
    const hrs = [];
    for (let hour = 0; hour < 24; hour++) {
      const formattedHour = hour.toString().padStart(2, "0");
      for (let minute = 0; minute < 60; minute += 30) {
        const formattedMinute = minute.toString().padStart(2, "0");
        const time = `${formattedHour}:${formattedMinute}`;

        timeoption.push({ value: time, label: time });
      }
      hrs.push({ value: formattedHour, label: formattedHour });
    }
    for (let minute = 0; minute < 60; minute++) {
      const formattedMinute = minute.toString().padStart(2, "0");
      min.push({ value: formattedMinute, label: formattedMinute });
    }
    console.log("time ,", {timeoption,hrs,min})
return {timeoption,hrs,min}
    // //console.log(timeOptions)
    // setTimeOption(timeOptions);
    // setHrs(hrsop);
    // setMin(minop);
  };
  const calculate_break  =(morning,afternoon)=>{
    var offtime = {}
    console.log(morning,afternoon)
    
    Object.entries(workingDays).map((data,index)=>{
      console.log(data[0])
         offtime[data[0]]  = {break_time_start :morning[data[0]].closing_time,break_time_end:afternoon[data[0]].opening_time,break_hours:calculateHours(morning[data[0]].closing_time,afternoon[data[0]].opening_time)}
    })
    return offtime
  }
  const checkClinicTimming = (daysoperational,mtimming,aftimming)=>
  {
    return false
  }
  export{workingDays,timeoptioncreator,calculate_break,checkClinicTimming}