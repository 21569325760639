const validatePhoneNumber = (phoneNumber) => {
  // Regular expression to validate phone number format
if(phoneNumber!=="") {
   // Regular expression to validate phone number with a maximum length of 15 characters
   const phoneRegex = /^\+(?:[0-9] ?){9,14}[0-9]$/;
   // Check for characters other than digits and plus sign
   if (!/^[0-9+ ]+$/.test(phoneNumber)) {
       return false;
   }
   // Check for multiple plus signs ("++")
   if (phoneNumber.includes("++")) {
       return false;
   }
   return phoneRegex.test(phoneNumber);

}else{
    return true
  }
};
function isValidWebsite(url) {
  // Regular expression to validate website address
  if(url.trim() !== "") {
    const urlRegex = /^(?:(?:https?|ftp):\/\/)?(www\.)?((?!www)[a-zA-Z0-9-])+\.[a-z]{2,}((?:\.[a-z]{2,})*)?((?:\/[a-zA-Z0-9]+)*)?(?:\/[^\s/?#]*)?$/i;
    return urlRegex.test(url);
} else {
    return true; // If the URL is empty, consider it valid
}
}
function validateName(name) {
  // Regular expression to validate website address
  if(name.trim()!=="")
  {
    const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;

    // return urlRegex.test(name);
    return false
  }else{
    return true
  }

}
function isValidEmail(email) {
  if(email.trim()!=="")
  {
    const emailRegex = /^(?!.*\.\.)[a-zA-Z0-9._%+-]+@(?!.*\.\.)[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(?:\.[a-zA-Z]{2,})?$/;
    return emailRegex.test(email);
  }else{
    return true
  }

}
const nameValidation  = (value)=>{

  if (! /[^\p{L}0-9\s]/gui.test(value) && value.length <= 20) {
  //   setInputValue(value);
  //   setError('');
  return false
  } else {
      return true
  }
}
const validateZipcode = (inputZipCode)=>{
  const zipCodeRegex = /^\d{4,7}(-\d{4})?$/;
  const isValidZipCode = zipCodeRegex.test(inputZipCode);
  return(!isValidZipCode)
}
const nameProps = {
  inputProps: {
    maxLength: 80,
  },
}
const shortnameProps = {
  inputProps: {
    maxLength: 20,
  },
}
const phoneProps = {
  inputProps: {
    // maxLength: 10, // Limit the maximum length of the input
    pattern: '[0-9]*', // Only allow numeric characters
    inputMode: 'numeric',
}}
  const restrictSpecial =(text)=>{

    const result = text.replace(/[^\w\s\d\-àâäçéèêëîïôœùûüÿÀÂÄÇÉÈÊËÎÏÔŒÙÛÜŸ]/gi, '');
return result;
  }
var exceptioncountry = [
  "Réunion",
  "Seychelles",
  "RE"
]
export {validatePhoneNumber,isValidWebsite,nameProps,phoneProps,exceptioncountry,nameValidation,validateZipcode,isValidEmail,restrictSpecial,shortnameProps}