import React, { useEffect, useState } from 'react';
import alasql from 'alasql';
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MDButton from "components/MDButton";
import Sort from "../../assets/images/icons/sort.png";
import SortWhite from "../../assets/images/icons/sortwhite.png";
import { useTranslation } from "react-i18next";

const Sortlist = ({ onSort, sortlist, placeholder,module }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [sortOrder, setSortOrder] = useState(true); // true for ascending, false for descending
  const open = Boolean(anchorEl);
  const { t } = useTranslation();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
const [sortOption,setSortOption] = useState([{
  label:"By name",
  value:"name"
},
{
  label:"By date of adding",
  value:"date"
}


])
useEffect(()=>{
if(module!==undefined)
{
  console.log('all records ',sortlist)
  switch(module)
  {
    case "activityDatabase":
      setSortOption(
        [{
          label:"By Activity",
          value:"act_name"
        },
        {
          label:"By Activity shortname",
          value:"act_shName"
        },
        {
          label:"By date of adding",
          value:"date"
        }
        
        
        ]
      )
      break;
      default:

  }
}
},[sortlist])
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleFilter = (type) => {
    let res;
    const order = sortOrder ? "ASC" : "DESC";
    switch (type) {
      case "name":
        res = alasql(`SELECT * FROM ? ORDER BY LOWER(label) ${order}`, [sortlist]);

        break;
      case "level":
        res = alasql(`SELECT * FROM ? ORDER BY level ${order}`, [sortlist]);
        break;
        case "act_shName":
          res = JSON.parse(JSON.stringify(sortlist))
           res.sort((a, b) => {
            var tempnamea = a.data.data.activityName
            var tempnameb = b.data.data.activityName
           { if (tempnamea< tempnameb) {
              return order=="ASC"? -1:1;
            }
            if (tempnamea > tempnameb) {
              return order=="ASC"? 1:-1;
            }}
            return 0;
          });
         
          break;
          case "act_name":
            res = JSON.parse(JSON.stringify(sortlist))
             res.sort((a, b) => {
              var tempnamea = a.data.data.fullname
              var tempnameb = b.data.data.fullname
             { if (tempnamea< tempnameb) {
                return order=="ASC"? -1:1;
              }
              if (tempnamea > tempnameb) {
                return order=="ASC"? 1:-1;
              }}
              return 0;
            });
           
            break;
      default:
        res = alasql(`SELECT * FROM ? ORDER BY createdTime ${order}`, [sortlist]);
        break;
    }
    onSort(res);
    setSortOrder(!sortOrder); // Toggle sort order for next click
    handleClose();
  };

  return (
    <Box
      className="cs-dropdown-menu"
      sx={{
        marginLeft: "20px",
        marginRight: "10px",
      }}
    >
      <MDButton
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        className="btn-second icon-toggle"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        sx={{
          padding: "11px 30px",
          fontSize: "16px",
          textTransform: "none",
          fontWeight: "normal",
          color: "#000",
          ":hover": { bgcolor: "#000", color: "#fff" },
        }}
        color="white"
      >
        <img src={Sort} className="black" alt="icon" />
        <img src={SortWhite} className="white" alt="icon" />
        &nbsp;{t("Sort")}
      </MDButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        className="menu-main"
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {/* {placeholder ?
          <MenuItem onClick={() => handleFilter("level")}>
            {t(placeholder)}
          </MenuItem>
          :
          <MenuItem onClick={() => handleFilter("name")}>
            {t("By name")}
          </MenuItem>
        }
        <MenuItem onClick={() => handleFilter("date")}>
          {t("By date of adding")}
        </MenuItem> */}
        {sortOption.map((d,i)=>(
          <MenuItem onClick={() => handleFilter(d.value)}>
          {t(d.label)}
        </MenuItem>
        ))}
        
      </Menu>
    </Box>
  );
};

export default Sortlist;
