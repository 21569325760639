
import PropTypes from "prop-types";

// @mui material components
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import Box from "@mui/material/Box";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Custom styles for the SidenavCollapse
import {
  collapseItem,
  collapseIconBox,
  collapseIcon,
  collapseText,
} from "examples/Sidenav/styles/sidenavCollapse";

// Material Dashboard 2 React context
import { useMaterialUIController } from "context";
import { useTranslation } from "react-i18next";
import { Badge } from "@mui/material";

function SidenavCollapse({ leave, help,icon, key, name, active, ...rest  }) {
  const [controller] = useMaterialUIController();
  const { t } = useTranslation();
  const { selecetdCustomer, permission, selected_clinic } = controller;

  const isButtonDisabled = (moduleId) => {
    const roleCode = permission.roleCode;
    const module = permission.module?.[moduleId];
    
    if (roleCode === "OT_00") {
      if (module) {
        return module.accesstype === 0; 
      }
      return false;
    }
  
    return !module || !module.accesstype || module.accesstype === 2 || module.accesstype === 0;
  };
  
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode, sidenavColor } = controller;
  return (
    <ListItem component="li">
      <MDBox
        {...rest}
        className="side-list-item"
        sx={(theme) =>
          collapseItem(theme, {
            active,
            transparentSidenav,
            whiteSidenav,
            darkMode,
            sidenavColor,
          })
        }
      >
         <Box className={icon} sx={(theme) => collapseIcon(theme, { active })}>
        <ListItemIcon
          sx={(theme) =>
            collapseIconBox(theme, { transparentSidenav, whiteSidenav, darkMode, active })
          }
        >
          {typeof icon === "string" ? (
           <Icon sx={(theme) => collapseIcon(theme, { active })}>{icon}</Icon>
          ) : (
            icon
          )}
        </ListItemIcon>
        </Box>

        <ListItemText
          primary={name}
          sx={(theme) =>
            collapseText(theme, {
              miniSidenav,
              transparentSidenav,
              whiteSidenav,
              active,
            })
          }
        />
        {name == t("Leave Management") && leave?.length > 0 && isButtonDisabled("REST13")  && (
          <Badge badgeContent={leave.length} color="primary" sx={{ marginLeft: 2 }}>
      </Badge>
    )}
     {name == t("Help & support")  && (
          <Badge badgeContent={help?.length} color="primary" sx={{ marginLeft: 2 }}>
      </Badge>
    )}
      </MDBox>
    </ListItem>
  );
}

// Setting default values for the props of SidenavCollapse
SidenavCollapse.defaultProps = {
  active: false,
};

// Typechecking props for the SidenavCollapse
SidenavCollapse.propTypes = {
  icon: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  active: PropTypes.bool,
};

export default SidenavCollapse;
