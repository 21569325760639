import { db } from "backendservice/firebase";
import { doc, setDoc,updateDoc } from "firebase/firestore";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";

const createActivity = async (data) => { 
    var id =  uuidv4();
    try{
        var res = await setDoc(doc(db, "activities", id), data);
      
    }catch(error)
    {
        console.log(error)
    }
};
function getHourGap(formData) {
     if (formData.duration.startingTime && formData.duration.closingTime) {
        const startingTime = formData.duration.startingTime.format("HH:mm");
        const closingTime = formData.duration.closingTime.format("HH:mm");

      
        const moment1 = moment(startingTime, 'HH:mm');
        const moment2 = moment(closingTime, 'HH:mm');
      
        // Calculate the difference in minutes
      
        if (moment1.isAfter(moment2)) {
            moment2.add(1, 'day');
          }
        
          // Calculate the difference in minutes
          const diffInMinutes = Math.abs(moment1.diff(moment2, 'minutes'));
        
          // Convert the difference to hours and return
          if (diffInMinutes == "0.00") {
          
            return false
          }
         const hours = Math.floor(diffInMinutes / 60);

          // Extract the fractional part and convert to minutes
          const minutes = Math.round((diffInMinutes / 60 - hours) * 60);
        
          // return `${hours} Hr and ${minutes} Min`;
          return `${hours}:${minutes}`
          //   return diffInMinutes / 60;
       
       
      }else{
        return false
      }
    // Parse the input times using Moment.js
   
  }
  function calculateTime (start,end){
    if (end === "24:00") {
      end = moment("00:00", "HH:mm").add(1, 'day');
  }
    const moment1 = moment(start, "HH:mm");
const moment2 = moment(end, "HH:mm");
const diffInMinutes = Math.abs(moment1.diff(moment2, 'minutes'));
const hours = Math.floor(diffInMinutes / 60);

// Extract the fractional part and convert to minutes
const minutes = Math.round((diffInMinutes / 60 - hours) * 60);

// return `${hours} Hr and ${minutes} Min`;
return `${hours}:${minutes}`
// Calculate the difference in hours
// const diffInHours = Math.abs(moment1.diff(moment2, 'hours', true));
// return diffInHours
  }
  function generateRandomColor() {
    // Generating random values for RGB (red, green, blue)
    const r = Math.floor(Math.random() * 256); // Random number between 0 and 255
    const g = Math.floor(Math.random() * 256); // Random number between 0 and 255
    const b = Math.floor(Math.random() * 256); // Random number between 0 and 255
  
    // Constructing a color in hexadecimal format
    const color = `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b.toString(16).padStart(2, '0')}`;
  
    return color;
  }
  function calculateInterval(formData,days)
  {
   var adddedhours = days*24
  if (formData.duration.startingTime && formData.duration.closingTime) {
        const startingTime = formData.duration.startingTime.format("HH:mm");
        const closingTime = formData.duration.closingTime.format("HH:mm");

      
        const moment1 = moment(startingTime, 'HH:mm');
        const moment2 = moment(closingTime, 'HH:mm');
      
        // Calculate the difference in minutes
      
        if (moment1.isAfter(moment2)) {
            moment2.add(1+days, 'day');
          }else if(days>0)
          {
            moment2.add(days, 'day')
          }
        
          // Calculate the difference in minutes
          const diffInMinutes = Math.abs(moment1.diff(moment2, 'minutes'));
        
          // Convert the difference to hours and return
          if (diffInMinutes == "0.00") {
          
            return false
          }
         const hours = Math.floor(diffInMinutes / 60);

          // Extract the fractional part and convert to minutes
          const minutes = Math.round((diffInMinutes / 60 - hours) * 60);
        console.log("hours ",hours,minutes)
          // return `${hours} Hr and ${minutes} Min`;
          return `${hours}:${minutes}`
          //   return diffInMinutes / 60;
        }else{return false}

  }
export {createActivity,getHourGap,generateRandomColor,calculateTime,calculateInterval}