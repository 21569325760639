
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Document, Page,pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc =`//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;
function Pdfviewer({file}) {
  const [language,setLanguage] = useState(null)

  const { t,i18n } = useTranslation();
  const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    // Callback function to set the total number of pages
    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    };
  useEffect(() => {
    setLanguage(i18n.language);

    // Update the language state whenever the language changes
    const handleLanguageChange = (lng) => {
      setLanguage(lng);
    };

    i18n.on('languageChanged', handleLanguageChange);

    // Cleanup the event listener on component unmount
    return () => {
      i18n.off('languageChanged', handleLanguageChange);
    };

 },[i18n])
  return (
  
            <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
        {Array.from(new Array(numPages), (el, index) => (
          <Page key={`page_${index + 1}`} scale={2}  pageNumber={index + 1} />
        ))}
      </Document>
           
        
  );
}

export default Pdfviewer;
