import { write_terms } from "backendservice/firebasefunctions,"

const { get_user_terms } = require("backendservice/firebasefunctions,")

const check_norms =async (uid)=>{
    var resp =await get_user_terms(uid)
    

    if(resp.length>0)
    {
        console.log("response function",resp[0].data)
        return {gdpr:resp[0].data.gdpr,cookies:resp[0].data.cookies,uid:resp[0].data.uid,id:resp.id}
    }else{
        return {gdpr:false,cookies:false,uid:null,id:"000"}
    }

}
const add_norms =async(data)=>{

   
    console.log("write user ",data)
    write_terms(data,data.id)
}
export {check_norms,add_norms}