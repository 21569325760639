
import Box from "@mui/material/Box";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import PrivacyPolicyEN from '../../assets/docs/termofsale.pdf'
import PrivacyPolicyFR from '../../assets/docs/termofsale.pdf'
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Pdfviewer from "components/PdfViewer";


function Termsofsale() {
  const [language,setLanguage] = useState(null)

  const { t,i18n } = useTranslation();

  useEffect(() => {
    setLanguage(i18n.language);

    // Update the language state whenever the language changes
    const handleLanguageChange = (lng) => {
      setLanguage(lng);
    };

    i18n.on('languageChanged', handleLanguageChange);

    // Cleanup the event listener on component unmount
    return () => {
      i18n.off('languageChanged', handleLanguageChange);
    };

 },[i18n])
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={4} pb={3} className="xl-container privacy-page">
        <Box>
          <Box>
          <MDBox className="page-heading">
              <MDTypography
                variant="h5"
                gutterBottom
                sx={{ pb: "8px", display: "flex" }}
              >
                {t("Terms of sale")}
              </MDTypography>
            </MDBox>
            <Box className="scrollbar">
         
      <Pdfviewer file={PrivacyPolicyEN} />
           

            </Box>
        
           <Box pt={3} sx={{ display: "flex", justifyContent:"end" }}>
           <a 
           href={language == "fr" ? PrivacyPolicyFR : PrivacyPolicyEN} 
           
           download="Privacy-policy.pdf" className="primary-btn btn" >
              {t("Download Pdf")}
            </a>
           </Box>
          </Box>
        </Box>
      </MDBox>

    </DashboardLayout>
  );
}

export default Termsofsale;
