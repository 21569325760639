const generate_list = (list) => {
  var resp = [];
  
  try {
    list.map((data, index) => {
      resp.push({ value: data.id, label: data.label, checked: false });
    });
    return resp;
  } catch (error) {
    console.log(error);
    return [];
  }
};
const generate_department = (list) => {
  const resp = new Set();
  try {
    list.forEach((data) => {
      const stateName = data.state && data.state.trim() !== "" ? data.state : "Département non spécifié";
      const value = data.state && data.state.trim() !== "" ? data.state : "";
      resp.add(JSON.stringify({ value: value, label: stateName, checked: false }));
    });
    return [...resp].map(item => JSON.parse(item));
  } catch (error) {
    console.log(error);
    return [];
  }
};


const generateRegions = (list)=>{
  var resp = [];
  const uniqueRegion = new Set();
  list.map((data,index)=>{
    console.log("each clinic ",data.data.operatedRegion)
  
      uniqueRegion.add(data.region);
   
   
  })
  const uniqureRegionarray = Array.from(uniqueRegion);
  uniqureRegionarray.map((data,index)=>{
    resp.push({value:data,label:data,checked:false})
  })
  return resp

}
const generate_uniqueField_device = (list)=>{
  const uniqueZones = new Set();
const uniqueStoreNames = new Set();

// Iterate over the array of JSON objects
list.forEach(obj => {
    // Add zone value to the set
    // uniqueZones.add(obj.zonedetails.zoneName);
    // // Add storeName value to the set
    // uniqueStoreNames.add(obj.storedetails.storeName);
        uniqueZones.add(obj.zone);
    // Add storeName value to the set
    uniqueStoreNames.add(obj.venue);
});

// Convert sets to arrays if needed
var zone = []
var store = []
var accourdian = ["Active","Store","Zone"]
const uniqueZonesArray = Array.from(uniqueZones);
const uniqueStoreNamesArray = Array.from(uniqueStoreNames);
uniqueZonesArray.map((data,index)=>{
  zone.push({value:data,label:data,checked:true})
})
uniqueStoreNamesArray.map((data,index)=>{
  store.push({value:data,label:data,checked:true})
})


return{store:store,zone:zone}

}
export { generate_list,generate_uniqueField_device,generateRegions,generate_department };
