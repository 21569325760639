import { useEffect, useMemo, useRef, useState } from "react";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MDButton from "components/MDButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Calerdar from "../../assets/images/resonante/calendar-icon.png";
import Switch from "@mui/material/Switch";
import MDTypography from "components/MDTypography";
import { Button, Typography } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
//modal import
import Modal from "@mui/material/Modal";

import { useNavigate, useParams } from "react-router-dom";
import { calculate_break, timeoptioncreator, workingDays } from "./functions";
import Loader from "components/Loader";
import { useMaterialUIController, setSelecetdCustomerrec } from "context";
import { get_customer } from "backendservice/firebasefunctions,";
import {
  nameValidation,
  nameProps,
  shortnameProps,
} from "backendservice/validation";
import Googlenmaps from "backendservice/googlemaps";
import { add_clinic } from "backendservice/firebasefunctions,";
import { get_countryList } from "backendservice/firebasefunctions,";
import { get_clinic_id } from "backendservice/firebasefunctions,";
import { update_clinic } from "backendservice/firebasefunctions,";
import Explanation from "components/Explanation";
function Addclinic() {
  const navigate = useNavigate();
  const [controller, dispatch] = useMaterialUIController();
  const { selecetdCustomer } = controller;
  const [answersPost, setAnswersPost] = useState(false);
  const { t } = useTranslation();
  //modal open hook
  const [modalopen, setmodalopen] = useState(false);
  //modal open close functionn
  const [openMaps, setOpenmaps] = useState(false);
  const [venueLocation, setVenueLocation] = useState("");
  //modal open close functionn
  const [name, setName] = useState("");
  const [fullName, setFullname] = useState("");
  const [timeOff, setTimeoff] = useState([]);
  const [daysoperational, setDaysoperational] = useState([
    { name: "Monday", enabled: false, morning: false, afternoon: false },
    { name: "Tuesday", enabled: false, morning: false, afternoon: false },
    { name: "Wednesday", enabled: false, morning: false, afternoon: false },
    { name: "Thursday", enabled: false, morning: false, afternoon: false },
    { name: "Friday", enabled: false, morning: false, afternoon: false },
    { name: "Saturday", enabled: false, morning: false, afternoon: false },
    { name: "Sunday", enabled: false, morning: false, afternoon: false },
  ]);
  const [mapdata, setMapdata] = useState({});
  const [customerId, setCustomerid] = useState("");

  const { timeoption, hrs, min } = useMemo(() => timeoptioncreator(), []);
  const [slelectedDays, setSeelecetdDays] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [operatedRegion, setOperatedRegion] = useState({});
  const open = Boolean(anchorEl);
  const [timming, settimming] = useState(workingDays);
  const [afttimming, setafttimming] = useState(workingDays);
  const [selectedDates, setSelectedDates] = useState([]);
  const [dateString, setdateString] = useState("");
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [brandData, setBrandData] = useState({});
  const [modalmapopen, setmodalmapopen] = useState(false);
  const [modalerror, seterrormodal] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false); // Track form submission

  const [formData, setFormData] = useState({
    regions: [],
    country: [],
    subRegions: [],
    selectedRegion: "",
    selectedCountry: "",
    selectedSubRegion: "",
  });
  const CustomDatePickerInput = ({ value, onClick }) => (
    <button className="custom-input" onClick={onClick}>
      {value || "Select a date"}
    </button>
  );
  const customStyles = {
    // Define your custom styles for highlighted dates
    day: (date) => {
      const isSelected = selectedDates.some((selectedDate) =>
        selectedDate.isSame(date, "day")
      );

      return {
        ...(isSelected && { backgroundColor: "#36a2eb", color: "#fff" }), // Highlight color
      };
    },
  };

  const handleerrormodalopen = () => {
    seterrormodal(true);
  };
  const handleerrorclose = () => {
    seterrormodal(false);
  };

  const onClose = (e) => {
    setOpenmaps(false);
  };

  const handleSavemap = async (e) => {
    //console.log(venueLocation);
    if (venueLocation != "" && Object.keys(mapdata).length > 0) {
      setOpenmaps(false);
    } else {
      alert(t("Please select a proper location"));
    }
  };
  const handleMaps = (e) => {
    // //console.log("aadd venuews  ==",e)

    var country = e.countryName;

    var operatedRegion = "";
    brandData.operatedRegion.map((data1, index1) => {
      data1.country.map((data, index) => {
        console.log(data.data.Country, country, "google map -----");
        if (data.data.Country == country && formData.selectedCountry ==country) {
          console.log("inside ", data1.region);
          operatedRegion = data1;
          return;
        }
      });
    });
    if (Object.keys(operatedRegion).length > 0) {
      setOperatedRegion(operatedRegion);
      setVenueLocation(e.address);
      setMapdata(e);
    } else {
      setMapdata({});
      setVenueLocation("");
      handleerrormodalopen();
      setOperatedRegion({});
    }

    // setOpenmaps(false);
  };
  const onCancel = (e) => {
    setMapdata({});
    setVenueLocation("");
    handleerrormodalopen();
    setOperatedRegion({});
    setOpenmaps(false);
  };
  const handlemodalMapopen = () => {
    setmodalmapopen(true);
    setOpenmaps(true);
  };
  const handlemodalMapClose = () => {
    setmodalmapopen(false);
    setVenueLocation("");
    setOpenmaps(false);
  };
  const handleCheckbox = async (days) => {
    const updatedDaysOperational = daysoperational.map((d) =>
      d.name === days ? { ...d, enabled: !d.enabled } : d
    );
    const day = updatedDaysOperational.find((d) => d.name === days);
    if (!day.enabled) {
      var tempm = { ...timming };
      tempm[days].opening_time = "";
      tempm[days].closing_time = "";
      var tempaft = { ...afttimming };
      tempaft[days].opening_time = "";
      tempaft[days].closing_time = "";
      settimming(tempm);
      setafttimming(tempaft);
    }
    setDaysoperational(updatedDaysOperational);
  };
  const handleCheckboxhalfday = async (days, e) => {
    const { name, checked } = e.target;
    const updatedDaysOperational = daysoperational.map((d) =>
      d.name === days ? { ...d, [name]: checked } : d
    );
    const day = updatedDaysOperational.find((d) => d.name === days);

    var tempm = { ...timming };
    var tempaft = { ...afttimming };
    if (!day.morning) {
      tempm[days].opening_time = "";
      tempm[days].closing_time = "";
    } else if (!day.afternoon) {
      tempaft[days].opening_time = "";
      tempaft[days].closing_time = "";
    }
    settimming(tempm);
    setafttimming(tempaft);
    console.log("operation days ", updatedDaysOperational);
    setDaysoperational(updatedDaysOperational);
  };
  const handlemodalClose = () => {
    setmodalopen(false);
  };

  const handleWorkingdays = (day, opening_time, closing_time, type) => {
    if (type == "Morning") {
      settimming((prevTimming) => ({
        ...prevTimming,
        [day]: {
          opening_time: opening_time,
          closing_time: closing_time,
        },
      }));
    }
    if (type == "Afternoon") {
      setafttimming((prevTimming) => ({
        ...prevTimming,
        [day]: {
          opening_time: opening_time,
          closing_time: closing_time,
        },
      }));
    }
  };
  const copyMondayTimings = () => {
    const mondayMorningOpening = timming["Monday"]?.opening_time || "";
    const mondayMorningClosing = timming["Monday"]?.closing_time || "";
    const mondayAfternoonOpening = afttimming["Monday"]?.opening_time || "";
    const mondayAfternoonClosing = afttimming["Monday"]?.closing_time || "";

    daysoperational.forEach((day) => {
      if (day.enabled && day.name !== "Monday") {
        day.morning &&
          handleWorkingdays(
            day.name,
            mondayMorningOpening,
            mondayMorningClosing,
            "Morning"
          );
        day.afternoon &&
          handleWorkingdays(
            day.name,
            mondayAfternoonOpening,
            mondayAfternoonClosing,
            "Afternoon"
          );
      }
    });
  };

  const handleWorkingdaysaft = (day, opening_time, closing_time, type) => {
    // var temp =timming
    //console.log(day, opening_time, closing_time);

    setafttimming((prevTimming) => ({
      ...prevTimming,
      [day]: {
        opening_time: opening_time,
        closing_time: closing_time,
      },
    }));

    // temp[day][type]=time
    // //console.log(temp)
    // settimming(temp)
  };
  const loadData = async (customer) => {
    // console.log("data =====>", snapshot.data());
    setLoading(true);
    // var response = await get_customer(customer)
    const [response, country] = await Promise.all([
      get_customer(customer),
      get_countryList(),
    ]);
    if (response && response.operatedRegion) {
      const allRegions = response.operatedRegion.map(({ region, country }) => ({
        label: region,
        country,
      }));
      setFormData((prevData) => ({ ...prevData, regions: allRegions }));
    }
    setFormData((prevData) => ({
      ...prevData,
      country: country,
    }));

    console.log("customer info", response, customer);
    setBrandData(response);
    setLoading(false);
  };
  const loadOldData = async () => {
    setLoading(true);

    const [response, country] = await Promise.all([
      get_customer(selecetdCustomer.id),
      get_countryList(),
    ]);
    if (response && response.operatedRegion) {
      const allRegions = response.operatedRegion.map(({ region, country }) => ({
        label: region,
        country,
      }));
      setFormData((prevData) => ({ ...prevData, regions: allRegions }));
    }
    setFormData((prevData) => ({
      ...prevData,
      country: country,
    }));
    var storeData = await get_clinic_id(id);
    setName(storeData.storeName);
    setFullname(
      storeData.fullName == undefined ? storeData.storeName : storeData.fullName
    );
    setMapdata({
      address: storeData.address,
      country: storeData.country,
      coordinates: storeData.coordinates,
      city: storeData.city,
      region: "",
    });
    // setcoordinate(storeData.coordinates)
    setOperatedRegion(storeData.operatedRegion);
    setVenueLocation(storeData.address);
    setDaysoperational(
      storeData.metadata?.daysoperational !== undefined
        ? storeData.metadata.daysoperational
        : []
    );
    console.log("clinic - timeslot", storeData.time_slot_morning);
    settimming(storeData.time_slot_morning);
    setafttimming(storeData.time_slot_afternoon);
    setFormData((prevData) => ({
      ...prevData,
      selectedRegion: storeData.clinicRegion[0].region,
    }));
    const selectedCountry = storeData.clinicRegion[0].country.label;

    setFormData((prevData) => ({
      ...prevData,
      selectedCountry: storeData.clinicRegion[0].country.label,
    }));
    setFormData((prevData) => ({
      ...prevData,
      selectedSubRegion: storeData.clinicRegion[0].subRegion,
    }));
    const selectedCountryData = country.find(
      (country) => country.label === selectedCountry
    );
    // Update sub-region state if available
    if (
      selectedCountryData &&
      selectedCountryData.data &&
      selectedCountryData.data["sub-region-data"]
    ) {
      var state = new Set(selectedCountryData.data["sub-region-data"]);

      setFormData((prevData) => ({
        ...prevData,
        subRegions: [...state],
      }));
    } else {
      setFormData((prevData) => ({ ...prevData, subRegions: [] }));
    }

    setBrandData(response);
    setLoading(false);
  };
  const handleRegionChange = (event) => {
    setFormData((prevData) => ({
      ...prevData,
      selectedRegion: event.target.value,
      selectedCountry: "",
    }));
  };
  // Function to handle country change
  const handleCountryChange = async (event) => {
    setLoading(true);
    const selectedCountry = event.target.value;
    setFormData((prevData) => ({ ...prevData, selectedCountry }));

    const selectedCountryData = formData.country.find(
      (country) => country.label === selectedCountry
    );
    // Update sub-region state if available
    if (
      selectedCountryData &&
      selectedCountryData.data &&
      selectedCountryData.data["sub-region-data"]
    ) {
      var state = new Set(selectedCountryData.data["sub-region-data"]);

      setFormData((prevData) => ({
        ...prevData,
        subRegions: [...state],
      }));
    } else {
      setFormData((prevData) => ({ ...prevData, subRegions: [] }));
    }

    setLoading(false);
  };

  const saveClinic = async (e) => {
    e.preventDefault();
    setLoading(true);
    setFormSubmitted(true);
    const nameError = nameValidation(name);
    if (nameError) {
      setLoading(false);
      return; // Stop form submission if there are validation errors
    }
    try {
      const isAnyDayEnabled = daysoperational.some(
        (day) => day.enabled === true
      );
      if (!isAnyDayEnabled) {
        alert(t("Please enable at least one day of operation"));
        setLoading(false);
        return; // Stop execution if no day is enabled
      }
      const isshiftenable = daysoperational.some(
        (day) =>
          day.enabled === true &&
          day.morning === false &&
          day.afternoon === false
      );
      if (isshiftenable) {
        alert(t("Please select atleat one shift to enter record"));
        setLoading(false);
        return; // Stop execution if no day is enabled
      }

      if (id !== undefined && id !== "") {
        var data = mapdata;
        data.storeName = name;
        data.fullName = fullName;
        // data.timeOff = timeOff;
        data.time_slot_morning = timming;
        data.time_slot_afternoon = afttimming;
        data.users = [];
        data.operatedRegion = operatedRegion;
        data.metadata = { daysoperational: daysoperational };
        data.customer = customerId;
        data.break_period = calculate_break(timming, afttimming);

        data.updatedTime = new Date();
        data.clinicRegion = [
          {
            region: formData.selectedRegion,
            country: formData.country
              ? formData.country.find(
                  ({ label }) => label === formData.selectedCountry
                ) || false
              : false,
            subRegion: formData.selectedSubRegion || false,
          },
        ];
        // console.log(data,"from add clinic");

        if (Object.values(data).some((value) => value === undefined)) {
          alert(t("Please select a city name in the location"));
        }
        // console.log("zone ",zone)
        if (await update_clinic(id, data)) {
          alert(t("Clinic data updated successfully"));
          navigate(-1);
        } else {
          alert(t("some error occured"));
        }

        //console.log("Finaldata", data);
        setLoading(false);
      } else {
        var data = mapdata;
        data.customer = customerId;

        data.gdpr = "";
        data.logo = "";
        data.active = true;
        data.storeName = name;
        data.fullName = fullName;
        // data.timeOff = timeOff;
        data.time_slot_morning = timming;
        data.time_slot_afternoon = afttimming;
        data.users = [];
        data.operatedRegion = operatedRegion;
        data.metadata = { daysoperational: daysoperational };
        data.customer = customerId;
        data.break_period = calculate_break(timming, afttimming);
        data.active = true;
        data.createdTime = new Date();
        data.attachCounter = 0
        data.clinicRegion = [
          {
            region: formData.selectedRegion,
            country: formData.country
              ? formData.country.find(
                  ({ label }) => label === formData.selectedCountry
                ) || false
              : false,
            subRegion: formData.selectedSubRegion || false,
          },
        ];

        if (Object.values(data).some((value) => value === undefined)) {
          alert(t("Please select a city name in the location"));
        }
        // console.log("zone ",zone)
        if (await add_clinic(data)) {
          alert(t("new clinic added"));
          navigate(-1);
        } else {
          alert(t("some error occured"));
        }

        //console.log("Finaldata", data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  const { id } = useParams();
  useEffect(() => {
    setCustomerid(selecetdCustomer.id);
    if (id == undefined || id == "") {
      loadData(selecetdCustomer.id);
    } else {
      loadOldData();
    }
  }, [selecetdCustomer]);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Loader loading={loading} />
      <MDBox pt={4} pb={3} className="xl-container">
        <Box>
          <Box>
            <MDBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <MDBox className="page-heading">
                <MDTypography
                  variant="h5"
                  gutterBottom
                  sx={{ pb: "8px", display: "flex" }}
                >
                  <Box
                    sx={{
                      pt: "2px",
                      width: "22px;",
                      display: { xs: "none", sm: "flex" },
                    }}
                  >
                    <MDButton onClick={() => navigate("/clinic")}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M0 5.99961C0 5.4524 0.443608 5.00879 0.990826 5.00879H11.0092C11.5564 5.00879 12 5.4524 12 5.99961C12 6.54683 11.5564 6.99044 11.0092 6.99044H0.990826C0.443608 6.99044 0 6.54683 0 5.99961Z"
                          fill="#16273C"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M6.70062 0.290206C7.08756 0.677148 7.08756 1.3045 6.70062 1.69145L2.39206 6L6.70062 10.3086C7.08756 10.6955 7.08756 11.3229 6.70062 11.7098C6.31368 12.0967 5.68632 12.0967 5.29938 11.7098L0.290206 6.70062C-0.0967354 6.31368 -0.0967354 5.68632 0.290206 5.29938L5.29938 0.290206C5.68632 -0.0967354 6.31368 -0.0967354 6.70062 0.290206Z"
                          fill="#16273C"
                        />
                      </svg>
                    </MDButton>
                  </Box>
                  {t("Return to clinic list")}
                </MDTypography>
                <MDTypography
                  variant="h4"
                  gutterBottom
                  sx={{ pb: "8px", display: "flex" }}
                >
                  {t("Clinic details")}
                </MDTypography>
              </MDBox>
            </MDBox>
            <MDBox
              className="card-layout"
              component="form"
              onSubmit={(e) => saveClinic(e)}
            >
              <Box className="form-main">
                <Box className="form-content">
                  <Box sx={{ width: { xs: "100%", sm: "100%" } }}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={4}>
                        <FormGroup sx={{ mb: 2 }}>
                          <InputLabel className="formlabel">
                            {t("Enter the full name")}
                            <strong style={{ color: "red" }}> *</strong>
                          </InputLabel>
                          <TextField
                            className="forminput"
                            placeholder={t("Enter the full name")}
                            variant="outlined"
                            required
                            value={fullName}
                            onChange={(e) => setFullname(e.target.value)}
                            InputProps={{ nameProps }}
                          />
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <FormGroup sx={{ mb: 2 }}>
                          <InputLabel className="formlabel">
                            {t("Enter the short name")}
                            <strong style={{ color: "red" }}> *</strong>
                          </InputLabel>
                          <TextField
                            className="forminput"
                            placeholder={t("Enter the short name")}
                            variant="outlined"
                            required
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            InputProps={{ shortnameProps }}
                            error={nameValidation(name) && formSubmitted}
                            helperText={
                              nameValidation(name) &&
                              formSubmitted &&
                              name !== ""
                                ? t(
                                    "Invalid short name. Use letters, numbers, and spaces (max 20 characters)"
                                  )
                                : ""
                            }
                          />
                        </FormGroup>
                      </Grid>

                      <Grid item xs={12} md={4}>
                        <Box sx={{ mb: 2 }}>
                          <InputLabel className="formlabel">
                            {t("Region")}
                            <strong style={{ color: "red" }}> *</strong>
                          </InputLabel>
                          <Box className="cs-dropdown icon">
                            <Select
                              className="form-select"
                              id="languageSelector"
                              onChange={handleRegionChange}
                              value={formData.selectedRegion}
                              label="Country"
                              required
                            >
                              <MenuItem value="" disabled>
                                {t("Select an option")}
                              </MenuItem>
                              {formData.regions.map((region) => (
                                <MenuItem
                                  key={region.label}
                                  value={region.label}
                                >
                                  {region.label}
                                </MenuItem>
                              ))}
                            </Select>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box sx={{ width: { xs: "100%", sm: "100%" } }}>
                    <Grid container spacing={2}>
                      
                      <Grid item xs={12} md={4}>
                        <Box
                          sx={{ mb: 2 }}
                          className={`${
                            !formData.selectedRegion ? "disabled" : ""
                          }`}
                        >
                          <InputLabel className="formlabel">
                            {t("Country")}
                            <strong style={{ color: "red" }}> *</strong>
                          </InputLabel>
                          <Box className={`cs-dropdown icon`}>
                            <Select
                              className="form-select"
                              id="languageSelector"
                              label="Country"
                              value={formData.selectedCountry}
                              onChange={handleCountryChange}
                              disabled={!formData.selectedRegion}
                              required
                            >
                              <MenuItem value="" disabled>
                                {t("Select an option")}
                              </MenuItem>
                              {formData.regions
                                .find(
                                  (region) =>
                                    region.label === formData.selectedRegion
                                )
                                ?.country.map((country) => (
                                  <MenuItem
                                    key={country.label}
                                    value={country.label}
                                  >
                                    {country.label}
                                  </MenuItem>
                                ))}
                            </Select>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <FormGroup sx={{ mb: 2 }}    
                        // className="input-icon"    // this was the old classs i changed it with the disable one 
                        className={`input-icon ${
                            !formData.selectedCountry ? "disabled" : ""
                          }`}
                          
                          >
                          <InputLabel className="formlabel">
                            {t("Location")}
                            <strong style={{ color: "red" }}> *</strong>
                          </InputLabel>
                          <TextField
                            className="forminput"
                            placeholder={t("Enter location")}
                            variant="outlined"
                            readOnly
                            value={venueLocation}
                            required
                            InputProps={{
                              readOnly:true
                            }}
                          />
                          <MDButton onClick={() => handlemodalMapopen()}
                            disabled ={ !formData.selectedCountry }
                            >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="22"
                              viewBox="0 0 16 18"
                              fill="none"
                            >
                              <path
                                d="M14.0909 7.54545C14.0909 12.6364 7.54545 17 7.54545 17C7.54545 17 1 12.6364 1 7.54545C1 5.80949 1.68961 4.14463 2.91712 2.91712C4.14463 1.68961 5.80949 1 7.54545 1C9.28142 1 10.9463 1.68961 12.1738 2.91712C13.4013 4.14463 14.0909 5.80949 14.0909 7.54545Z"
                                fill="#16273C"
                                stroke="white"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M7.54608 9.72692C8.75106 9.72692 9.72789 8.75008 9.72789 7.5451C9.72789 6.34011 8.75106 5.36328 7.54608 5.36328C6.34109 5.36328 5.36426 6.34011 5.36426 7.5451C5.36426 8.75008 6.34109 9.72692 7.54608 9.72692Z"
                                fill="white"
                                stroke="white"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </MDButton>
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Box
                          sx={{ mb: 2 }}
                          className={`${
                            !formData.selectedCountry ? "disabled" : ""
                          }`}
                        >
                          <InputLabel className="formlabel">
                            {t("Sub-region")}
                            {formData.subRegions.length > 0 ? (
                              <strong style={{ color: "red" }}> *</strong>
                            ) : (
                              ""
                            )}
                          </InputLabel>
                          <Box className="cs-dropdown icon">
                            <Select
                              className="form-select"
                              id="subRegionSelector"
                              label="Sub-region"
                              value={formData.selectedSubRegion}
                              onChange={(event) =>
                                setFormData((prevData) => ({
                                  ...prevData,
                                  selectedSubRegion: event.target.value,
                                }))
                              }
                              disabled={
                                !formData.selectedCountry ||
                                formData.subRegions.length === 0
                              }
                              required={formData.subRegions.length > 0}
                            >
                              <MenuItem value="" className="menu-item" disabled>
                                {t("Select an option")}
                              </MenuItem>
                              {formData.subRegions.map((subRegion) => (
                                <MenuItem key={subRegion} value={subRegion}>
                                  {subRegion}
                                </MenuItem>
                              ))}
                            </Select>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
            
                  <Box className="scroll-view">
                    <Box className="content-view">
                      <Grid container spacing={0}>
                        <Grid item xs={12} md={12}>
                          <Grid container spacing={4}>
                            <Grid item xs={12} md={6}>
                              <Box className="venue-heading morning">
                                <label className="label-name heading">
                                  <strong>{t("Working days")}</strong>
                                </label>

                                <Box className="heading-group">
                                  <div className="input-group">
                                    <label>
                                      <strong>{t("morning")}</strong>
                                    </label>
                                  </div>
                                  <div className="input-group">
                                    <label>{t("Opening time")}</label>
                                  </div>
                                  <div className="input-group closing">
                                    <label className="closing-time">
                                      {t("Closing time")}
                                    </label>
                                  </div>
                                </Box>
                              </Box>
                              {daysoperational.map((data, index) => (
                                <Box
                                  className={`venue-calc`}
                                  key={index}
                                >
                                  <Box className="label-name">
                                    <FormControlLabel
                                      sx={{
                                        display: "flex",
                                        textAlign: "center",
                                      }}
                                      checked={
                                        data.enabled
                                      }
                                      onChange={(e) => handleCheckbox(data.name)}
                                      // disabled = {!data.enabled}
                                      className="cs-checkbox"
                                      label={t(data.name)}
                                      control={<Checkbox />}
                                    />
                                  </Box>
                                  <Box  className="checkbox-group">
                                    <FormControlLabel
                                      sx={{
                                        display: "flex",
                                        textAlign: "center",
                                      }}
                                      className="cs-checkbox"
                                      name="morning"
                                      disabled={!data.enabled}
                                      label={t("")}
                                      checked={
                                        daysoperational.find(
                                          (day) => day.name === data.name
                                        )?.morning || false
                                      }
                                      onChange={(e) =>
                                        handleCheckboxhalfday(data.name, e)
                                      }
                                      control={<Checkbox />}
                                    />
                                  </Box>
                                  <FormGroup className="input-group">
                                    <Box className="input-align">
                                      <Select
                                        sx={{ mb: 2 }}
                                        className="forminput icon"
                                        required={data.enabled && data.morning}
                                        value={
                                          timming[data.name].opening_time !== ""
                                            ? timming[
                                                data.name
                                              ].opening_time.split(":")[0]
                                            : timming[data.name].opening_time
                                        }
                                        disabled={
                                          !(data.enabled && data.morning)
                                        }
                                        onChange={(e) => {
                                          if (data.enabled) {
                                            var closingTime =
                                              timming[data.name].closing_time;
                                            var opening_time =
                                              timming[data.name].opening_time;
                                            handleWorkingdays(
                                              data.name,
                                              opening_time !== ""
                                                ? e.target.value +
                                                    ":" +
                                                    opening_time.split(":")[1]
                                                : e.target.value + ":" + "00",
                                              closingTime,
                                              "Morning"
                                            );
                                          }
                                        }}
                                      >
                                        {hrs.map(({ label }) => (
                                          <MenuItem key={label} value={label}>
                                            {label}
                                          </MenuItem>
                                        ))}
                                      </Select>
                                    </Box>
                                    <Box className="input-align">
                                      <Select
                                        className="forminput icon"
                                        required={data.enabled && data.morning}
                                        value={
                                          timming[data.name].opening_time !== ""
                                            ? timming[
                                                data.name
                                              ].opening_time.split(":")[1]
                                            : timming[data.name].opening_time
                                        }
                                        disabled={
                                          !(data.enabled && data.morning)
                                        }
                                        onChange={(e) => {
                                          if (data.enabled) {
                                            var closingTime =
                                              timming[data.name].closing_time;
                                            var opening_time =
                                              timming[data.name].opening_time;
                                            handleWorkingdays(
                                              data.name,
                                              opening_time !== ""
                                                ? opening_time.split(":")[0] +
                                                    ":" +
                                                    e.target.value
                                                : "00" + ":" + e.target.value,
                                              closingTime,
                                              "Morning"
                                            );
                                          }
                                        }}
                                      >
                                        {min.map(({ label }) => (
                                          <MenuItem key={label} value={label}>
                                            {label}
                                          </MenuItem>
                                        ))}
                                      </Select>
                                    </Box>
                                  </FormGroup>

                                  <FormGroup className="input-group ">
                                    <Box className="input-align">
                                      <Select
                                        sx={{ mb: 2 }}
                                        required={data.enabled && data.morning}
                                        className="forminput icon"
                                        disabled={
                                          !(data.enabled && data.morning)
                                        }
                                        value={
                                          timming[data.name].closing_time !== ""
                                            ? timming[
                                                data.name
                                              ].closing_time.split(":")[0]
                                            : timming[data.name].closing_time
                                        }
                                        onChange={(e) => {
                                          if (data.enabled) {
                                            var closingTime =
                                              timming[data.name].closing_time;
                                            var opening_time =
                                              timming[data.name].opening_time;
                                            handleWorkingdays(
                                              data.name,
                                              opening_time,
                                              closingTime !== ""
                                                ? e.target.value +
                                                    ":" +
                                                    closingTime.split(":")[1]
                                                : e.target.value + ":" + "00",
                                              "Morning"
                                            );
                                          }
                                        }}
                                      >
                                        {hrs.map(({ label }) => (
                                          <MenuItem key={label} value={label}>
                                            {label}
                                          </MenuItem>
                                        ))}
                                      </Select>
                                    </Box>

                                    <Box className="input-align">
                                      <Select
                                        required={data.enabled && data.morning}
                                        className="forminput icon"
                                        value={
                                          timming[data.name].closing_time !== ""
                                            ? timming[
                                                data.name
                                              ].closing_time.split(":")[1]
                                            : timming[data.name].closing_time
                                        }
                                        disabled={
                                          !(data.enabled && data.morning)
                                        }
                                        onChange={(e) => {
                                          if (data.enabled) {
                                            var closingTime =
                                              timming[data.name].closing_time;
                                            var opening_time =
                                              timming[data.name].opening_time;
                                            handleWorkingdays(
                                              data.name,
                                              opening_time,
                                              closingTime !== ""
                                                ? closingTime.split(":")[0] +
                                                    ":" +
                                                    e.target.value
                                                : "00" + ":" + e.target.value,
                                              "Morning"
                                            );
                                          }
                                        }}
                                      >
                                        {min.map(({ label }) => (
                                          <MenuItem key={label} value={label}>
                                            {label}
                                          </MenuItem>
                                        ))}
                                      </Select>
                                    </Box>
                                  </FormGroup>
                                </Box>
                              ))}
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <Box className="venue-heading afternoon">
                              <Box className="label-name afternoon-label"></Box>
                                <Box className="heading-group">
                                  
                                  <div className="input-group">
                                    <label>
                                      <strong>{t("afternoon")}</strong>
                                    </label>
                                  </div>
                                  <div className="input-group">
                                    <label className="open-time">
                                      {t("Opening time")}
                                    </label>
                                  </div>
                                  <div className="input-group closing">
                                    <label className="closing-time option">
                                      {t("Closing time")}
                                    </label>
                                  </div>
                                </Box>
                              </Box>
                              {daysoperational.map((data, index) => (
                                <Box
                                  className={`venue-calc`}
                                  key={index}
                                >
                                  <Box className="label-name afternoon-label"></Box>
                                  <Box  className="checkbox-group">
                                    <FormControlLabel
                                      sx={{
                                        display: "flex",
                                        textAlign: "center",
                                      }}
                                      className="cs-checkbox"
                                      disabled={!data.enabled}
                                      label={t("")}
                                      name="afternoon"
                                      checked={
                                        daysoperational.find(
                                          (day) => day.name === data.name
                                        )?.afternoon || false
                                      }
                                      onChange={(e) =>
                                        handleCheckboxhalfday(data.name, e)
                                      }
                                      control={<Checkbox />}
                                    />
                                  </Box>
                                  <FormGroup className="input-group">
                                    <Box className="input-align">
                                      <Select
                                        sx={{ mb: 2 }}
                                        className="forminput icon"
                                        required={
                                          data.enabled && data.afternoon
                                        }
                                        value={
                                          afttimming[data.name].opening_time !==
                                          ""
                                            ? afttimming[
                                                data.name
                                              ].opening_time.split(":")[0]
                                            : afttimming[data.name].opening_time
                                        }
                                        disabled={
                                          !(data.enabled && data.afternoon)
                                        }
                                        onChange={(e) => {
                                          if (data.enabled) {
                                            var closingTime =
                                              afttimming[data.name]
                                                .closing_time;
                                            var opening_time =
                                              afttimming[data.name]
                                                .opening_time;
                                            handleWorkingdays(
                                              data.name,
                                              opening_time !== ""
                                                ? e.target.value +
                                                    ":" +
                                                    opening_time.split(":")[1]
                                                : e.target.value + ":" + "00",
                                              closingTime,
                                              "Afternoon"
                                            );
                                          }
                                        }}
                                      >
                                        {hrs.map(({ label }) => (
                                          <MenuItem key={label} value={label}>
                                            {label}
                                          </MenuItem>
                                        ))}
                                      </Select>
                                    </Box>
                                    <Box className="input-align">
                                      <Select
                                       sx={{ mb: 2 }}
                                        required={
                                          data.enabled && data.afternoon
                                        }
                                        className="forminput icon"
                                        value={
                                          afttimming[data.name].opening_time !==
                                          ""
                                            ? afttimming[
                                                data.name
                                              ].opening_time.split(":")[1]
                                            : afttimming[data.name].opening_time
                                        }
                                        disabled={
                                          !(data.enabled && data.afternoon)
                                        }
                                        onChange={(e) => {
                                          if (data.enabled) {
                                            var closingTime =
                                              afttimming[data.name]
                                                .closing_time;
                                            var opening_time =
                                              afttimming[data.name]
                                                .opening_time;
                                            handleWorkingdays(
                                              data.name,
                                              opening_time !== ""
                                                ? opening_time.split(":")[0] +
                                                    ":" +
                                                    e.target.value
                                                : "00" + ":" + e.target.value,
                                              closingTime,
                                              "Afternoon"
                                            );
                                          }
                                        }}
                                      >
                                        {min.map(({ label }) => (
                                          <MenuItem key={label} value={label}>
                                            {label}
                                          </MenuItem>
                                        ))}
                                      </Select>
                                    </Box>
                                  </FormGroup>
                                  <FormGroup className="input-group ">
                                    <Box className="input-align">
                                      <Select
                                      sx={{ mb: 2 }}
                                        required={
                                          data.enabled && data.afternoon
                                        }
                                        className="forminput icon"
                                        disabled={
                                          !(data.enabled && data.afternoon)
                                        }
                                        value={
                                          afttimming[data.name].closing_time !==
                                          ""
                                            ? afttimming[
                                                data.name
                                              ].closing_time.split(":")[0]
                                            : afttimming[data.name].closing_time
                                        }
                                        onChange={(e) => {
                                          if (data.enabled) {
                                            var closingTime =
                                              afttimming[data.name]
                                                .closing_time;
                                            var opening_time =
                                              afttimming[data.name]
                                                .opening_time;
                                            handleWorkingdays(
                                              data.name,
                                              opening_time,
                                              closingTime !== ""
                                                ? e.target.value +
                                                    ":" +
                                                    closingTime.split(":")[1]
                                                : e.target.value + ":" + "00",
                                              "Afternoon"
                                            );
                                          }
                                        }}
                                      >
                                        {hrs.map(({ label }) => (
                                          <MenuItem key={label} value={label}>
                                            {label}
                                          </MenuItem>
                                        ))}
                                      </Select>
                                    </Box>
                                    <Box className="input-align">
                                      <Select
                                        required={
                                          data.enabled && data.afternoon
                                        }
                                        className="forminput icon"
                                        value={
                                          afttimming[data.name].closing_time !==
                                          ""
                                            ? afttimming[
                                                data.name
                                              ].closing_time.split(":")[1]
                                            : afttimming[data.name].closing_time
                                        }
                                        disabled={
                                          !(data.enabled && data.afternoon)
                                        }
                                        onChange={(e) => {
                                          if (data.enabled) {
                                            var closingTime =
                                              afttimming[data.name]
                                                .closing_time;
                                            var opening_time =
                                              afttimming[data.name]
                                                .opening_time;
                                            handleWorkingdays(
                                              data.name,
                                              opening_time,
                                              closingTime !== ""
                                                ? closingTime.split(":")[0] +
                                                    ":" +
                                                    e.target.value
                                                : "00" + ":" + e.target.value,
                                              "Afternoon"
                                            );
                                          }
                                        }}
                                      >
                                        {min.map(({ label }) => (
                                          <MenuItem key={label} value={label}>
                                            {label}
                                          </MenuItem>
                                        ))}
                                      </Select>
                                    </Box>
                                  </FormGroup>
                                {index==0 ? <Box class="link-copy"> <Box className="copy-box">
                                
                                <Button
                                   onClick={copyMondayTimings}
                                   className="link-btn"
                                 >
                                   <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 15 15" fill="none">
<path fill-rule="evenodd" clip-rule="evenodd" d="M6.33965 5.46306C5.85336 5.46306 5.45915 5.85727 5.45915 6.34355V12.9473C5.45915 13.4336 5.85336 13.8278 6.33965 13.8278H12.9434C13.4296 13.8278 13.8239 13.4336 13.8239 12.9473V6.34355C13.8239 5.85727 13.4296 5.46306 12.9434 5.46306H6.33965ZM4.28516 6.34355C4.28516 5.20889 5.20498 4.28906 6.33965 4.28906H12.9434C14.078 4.28906 14.9979 5.20889 14.9979 6.34355V12.9473C14.9979 14.0819 14.078 15.0018 12.9434 15.0018H6.33965C5.20498 15.0018 4.28516 14.0819 4.28516 12.9473V6.34355Z" fill="#16273C"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M2.05644 1.17399C1.82292 1.17399 1.59896 1.26676 1.43384 1.43189C1.26871 1.59701 1.17595 1.82097 1.17595 2.05449V8.65821C1.17595 8.89173 1.26871 9.11569 1.43384 9.28081C1.59896 9.44594 1.82292 9.5387 2.05644 9.5387H2.79019C3.11438 9.5387 3.37719 9.80151 3.37719 10.1257C3.37719 10.4499 3.11438 10.7127 2.79019 10.7127H2.05644C1.51156 10.7127 0.988991 10.4962 0.603699 10.111C0.218408 9.72566 0.00195312 9.20309 0.00195312 8.65821V2.05449C0.00195312 1.50961 0.218408 0.987038 0.603699 0.601746C0.988991 0.216454 1.51156 0 2.05644 0H8.66016C9.20505 0 9.72761 0.216455 10.1129 0.601746C10.4982 0.987037 10.7147 1.50961 10.7147 2.05449V2.78824C10.7147 3.11243 10.4518 3.37523 10.1277 3.37523C9.80347 3.37523 9.54066 3.11243 9.54066 2.78824V2.05449C9.54066 1.82097 9.44789 1.59701 9.28277 1.43189C9.11764 1.26676 8.89368 1.17399 8.66016 1.17399H2.05644Z" fill="#16273C"/>
</svg>
                                 </Button>
                                 <Explanation toggle="left"  contentLine1={t("copy_clinic_info")}/>
                                 </Box></Box>:
                                 <Box class="link-copy"> <Box className="copy-box">
                                
                                
                                       </Box></Box>
                                  }
                                
                                </Box>
                              ))}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                  {/* <Button onClick={copyMondayTimings}>{t("Copy to All")}</Button> */}
                </Box>
              </Box>
              <Box className="navigation-link">
                <MDButton className="primary-btn btn xl" type="submit">
                  {id !== undefined && id !== "" ? t("Update") : t("Save")}
                </MDButton>
              </Box>
            </MDBox>
          </Box>
        </Box>
        <Modal
          open={openMaps}
          onClose={handlemodalMapClose}
          aria-labelledby="modal-modal-title"
          className="cs-modal map-modal"
          aria-describedby="modal-modal-description"
        >
          <Box className="modal-dialog">
            <Box className="modal-content">
              <Box className="modal-header">
                <MDButton
                  className="modal-close"
                  onClick={() => handlemodalMapClose()}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                  >
                    <path
                      d="M13 1L1 13"
                      stroke="#fff"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M1 1L13 13"
                      stroke="#fff"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </MDButton>
              </Box>
              <Box className="modal-heading">
                <Typography variant="h2" component="h2">
                  {t("Search a location")}
                </Typography>
              </Box>
              <Box className="modal-body">
                {openMaps && (
                  <Googlenmaps
                    onClick={handleMaps}
                    onClose={""}
                    onCancel={onCancel}
                  />
                )}
              </Box>
              <Box className="modal-footer">
                <Box>
                  <MDButton
                    className="dark-btn"
                    sx={{
                      padding: "11px 30px",
                      fontSize: "16px",
                      fontWeight: "normal",
                      minWidth: "160px",
                    }}
                    color="dark"
                    onClick={() => handleSavemap()}
                  >
                    {t("Save Location")}
                  </MDButton>
                </Box>
              </Box>
            </Box>
          </Box>
        </Modal>
        <Modal
          open={modalerror}
          onClose={handlemodalClose}
          aria-labelledby="modal-modal-title"
          className="cs-modal info-modal"
          aria-describedby="modal-modal-description"
        >
          <Box className="modal-dialog">
            <Box className="modal-content">
              <Box className="modal-header">
                <MDButton
                  className="modal-close"
                  onClick={() => handleerrorclose()}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                  >
                    <path
                      d="M13 1L1 13"
                      stroke="#282B3B"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M1 1L13 13"
                      stroke="#282B3B"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </MDButton>
              </Box>
              <Box className="modal-heading">
                <Box
                  className="icon"
                  sx={{
                    fontSize: "46px",
                    color: "rgba(0, 158, 224, 0.18)",
                    textAlign: "center",
                  }}
                >
                  !
                </Box>
                <MDTypography variant="h2" component="h2">
                  {t(
                    "The selected location doesn’t fall under the countries of this brand"
                  )}
                </MDTypography>
              </Box>
              <Box
                className="modal-btn"
                sx={{
                  marginTop: "20px",
                  marginBottom: "20px",
                  display: "flex",
                  flex: "1 ",
                }}
              >
                <Box>
                  <MDButton
                    className="secondary-btn white"
                    sx={{
                      padding: "11px 30px",
                      fontSize: "16px",
                      fontWeight: "normal",
                      minWidth: "200px",
                    }}
                    color="dark"
                    onClick={() => {
                      handlemodalMapClose();
                      handleerrorclose();
                    }}
                  >
                    {t("Cancel")}
                  </MDButton>
                </Box>
                <Box>
                  <MDButton
                    className="secondary-btn"
                    sx={{
                      padding: "11px 30px",
                      fontSize: "16px",
                      fontWeight: "normal",
                      minWidth: "200px",
                    }}
                    color="dark"
                    onClick={() => handleerrorclose()}
                  >
                    {t("Select another location")}
                  </MDButton>
                </Box>
              </Box>
            </Box>
          </Box>
        </Modal>
      </MDBox>
    </DashboardLayout>
  );
}

export default Addclinic;
