import { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MDButton from "components/MDButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Calerdar from "../../assets/images/resonante/calendar-icon.png";
import Switch from "@mui/material/Switch";
import MDTypography from "components/MDTypography";
import { Typography } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
//modal import
import Modal from "@mui/material/Modal";
import { useAuthState} from 'react-firebase-hooks/auth'
import { auth } from "backendservice/firebase";

import { useNavigate,useParams } from "react-router-dom";
import { Chatlog } from "backendservice/firebase";
import { useCollectionData} from 'react-firebase-hooks/firestore'
import { addDoc, collection, doc, getDocs, limit, orderBy, query, setDoc, updateDoc, where } from "firebase/firestore";
import { db } from "backendservice/firebase";
import moment from "moment";
import { tickets } from "backendservice/firebase";
import Loader from "components/Loader";
import { useMaterialUIController } from "context";

function Profile() {
  const navigate = useNavigate();

  const [answersPost, setAnswersPost] = useState(false);
  const { t } = useTranslation();
  const {id} = useParams()
  const [user] =useAuthState(auth)
  const [text,setText]= useState("")
  const [userData,setuserData] = useState({})
  const [customer, setCustomer] = useState(false);
  const [ticketData,setticketdata] = useState({})
  const [loading, setLoading] = useState(false);
  const [controller, dispatch] = useMaterialUIController();
  const {selecetdCustomer,permission} = controller;
  const ref = useRef(null);

  const que = query(
    Chatlog,
    where('ticketId', '==', id),
    orderBy('createdTime'),
    limit(25)
  );
  const [message] = useCollectionData(que);
  const addMessage = async (e) => {
    e.preventDefault()
    if (text.trim() == "") {
      alert(t("Pleasewrite some text"))
    } else {


      var datass = localStorage.getItem("user");
      var json = JSON.parse(datass);
      var customersid = json.customer;
      var name = json.name;
      var sender = false
      if (json.roleCode == "IA_00") {
        name = "admin";
        customersid = customersid
        sender = true
      }

      try {

        var data = {
          "ticketId": id,
          "uname": name,
          "to": customersid,
          "from": customersid,
          "uid": user.uid,
          "fromUsername": userData.name,
          "message": text,
          "sender": sender,
          "createdTime": new Date()
        }
        const docref = await addDoc(
          collection(db, "Chatlog"), data
        );

        const lastChildElement = ref.current?.lastElementChild;
        lastChildElement?.scrollIntoView({ behavior: 'smooth' });
        setText("")
      } catch (error) {
        console.log(error)
      }
    }

  }
  
const loadTicketData = async () => {
  setLoading(true)
  var snapshot = await getDocs(
    query(tickets, where("id", "==", id))
  );
  var ticketrecord = snapshot.docs.map((doc) => ({
    id: doc.id,
    data: doc.data(),
    viewdetails:false
  }));
    setticketdata(ticketrecord[0])
    setLoading(false)
}
useEffect(() => {
loadTicketData()
},[id])
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const isButtonDisabled = (moduleId) => {
    return !(
      !permission.module ||
      !permission.module[moduleId] ||
      permission.module[moduleId].accesstype === 2
    );
  };

  useEffect( ()=>{
    var datass = localStorage.getItem("user");
    var customerid = "";
    if (datass == undefined || datass == "") {
      // setusername("User")
    } else {
      var json = JSON.parse(datass);
      //   setcustomer(json.customer)
      customerid = json.customer;
  
      setuserData(json)
  
    }
    setCustomer(customerid);
    const lastChildElement = ref.current?.lastElementChild;
    lastChildElement?.scrollIntoView({ behavior: 'smooth' });
  },[])

  //modal open hook
  const [modalopen, setmodalopen] = useState(false);
  //modal open close functionn
  const handlemodalopen = () => {
    setmodalopen(true);
  };
  const handlemodalClose = () => {
    setmodalopen(false);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Loader loading={loading} />
      <MDBox pt={4} pb={3} className="xl-container help-page">
        <Box>
          <Box>
            <MDBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <MDBox className="page-heading">
                <MDTypography
                  variant="h5"
                  gutterBottom
                  sx={{ pb: "8px", display: "flex" }}
                >
                  <Box
                    sx={{
                      pt: "2px",
                      width: "22px;",
                      display: { xs: "none", sm: "flex" },
                    }}
                  >
                    <MDButton onClick={() => navigate(-1)}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M0 5.99961C0 5.4524 0.443608 5.00879 0.990826 5.00879H11.0092C11.5564 5.00879 12 5.4524 12 5.99961C12 6.54683 11.5564 6.99044 11.0092 6.99044H0.990826C0.443608 6.99044 0 6.54683 0 5.99961Z"
                          fill="#16273C"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M6.70062 0.290206C7.08756 0.677148 7.08756 1.3045 6.70062 1.69145L2.39206 6L6.70062 10.3086C7.08756 10.6955 7.08756 11.3229 6.70062 11.7098C6.31368 12.0967 5.68632 12.0967 5.29938 11.7098L0.290206 6.70062C-0.0967354 6.31368 -0.0967354 5.68632 0.290206 5.29938L5.29938 0.290206C5.68632 -0.0967354 6.31368 -0.0967354 6.70062 0.290206Z"
                          fill="#16273C"
                        />
                      </svg>
                    </MDButton>
                  </Box>
                  {t("Chat Box")}
                </MDTypography>
              </MDBox>
            </MDBox>
            <Box className="chat-page" syt>
              <Box className="chat-section" ref={ref} >
              <Box className="right">
                  <MDBox className="right-chat" style={{width:"100%"}}>
                    <Box className="content">
                      <Box className="list">
                        <label>{t("Ticket id")} :</label> <p>{ticketData?.data?.ticketNumber}</p>
                      </Box>
                      <Box className="list">
                        <label>{t("Status")} :</label> <p>{t(ticketData?.data?.status)}</p>
                      </Box>
                      <Box className="list">
                        <label>{t("Severity")} :</label> <p>{t(ticketData?.data?.severity)}</p>
                      </Box>
                      <Box className="list">
                        <label>{t("Subject")} :</label>{" "}
                        <p>{ticketData?.data?.subject}</p>
                      </Box>
                      <Box className="list">
                        <label>{t("Query")} : </label>{" "}
                        <p>
                          {ticketData?.data?.query}
                        </p>
                      </Box>
                      <Box className="date">
                        <p>{moment(new Date(ticketData.data?.raiseTime?.seconds*1000)).format("ddd DD/MM/yyyy")}</p>
                      </Box>
                    </Box>
                    <Box className="user">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="30"
                        height="30"
                        viewBox="0 0 30 30"
                        fill="none"
                      >
                        <circle
                          cx="14.9997"
                          cy="15.0003"
                          r="14.9997"
                          fill="#16273C"
                        />
                        <circle
                          cx="14.9996"
                          cy="11.5389"
                          r="6.92294"
                          fill="white"
                        />
                        <mask
                          id="mask0_186_7710"
                     
                          maskUnits="userSpaceOnUse"
                          x="0"
                          y="0"
                          width="31"
                          height="30"
                        >
                          <circle
                            cx="15.0007"
                            cy="15.0003"
                            r="14.9997"
                            fill="#EDEDED"
                          />
                        </mask>
                        <g mask="url(#mask0_186_7710)">
                          <rect
                            x="4.6167"
                            y="20.7697"
                            width="20.7688"
                            height="13.8459"
                            rx="3"
                            fill="white"
                          />
                        </g>
                      </svg>
                    </Box>
                  </MDBox>
                </Box>
                {message?.map((data,index) => (
                (userData.access == "IA_00"?data.sender:!data.sender)?
                <Box className="right">
                <MDBox className="right-chat">
                  <Box className="content">
                    <Box className="list">
                      <p>
                       {data.message}
                      </p>
                    </Box>
                    <Box className="date">
                      <p>{moment(new Date(data.createdTime.seconds*1000)).format("ddd DD/MM/yyyy")}</p> 
                    </Box>
                    <Box className="username">{data.uname}</Box>
                  </Box>
                  <Box className="user">
                  <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      height="30"
                      viewBox="0 0 30 30"
                      fill="none"
                    >
                      <circle
                        cx="14.9997"
                        cy="15.0003"
                        r="14.9997"
                        fill="#16273C"
                      />
                      <circle
                        cx="14.9996"
                        cy="11.5389"
                        r="6.92294"
                        fill="white"
                      />
                      <mask
                        id="mask0_186_7710"
                   
                        maskUnits="userSpaceOnUse"
                        x="0"
                        y="0"
                        width="31"
                        height="30"
                      >
                        <circle
                          cx="15.0007"
                          cy="15.0003"
                          r="14.9997"
                          fill="#EDEDED"
                        />
                      </mask>
                      <g mask="url(#mask0_186_7710)">
                        <rect
                          x="4.6167"
                          y="20.7697"
                          width="20.7688"
                          height="13.8459"
                          rx="3"
                          fill="white"
                        />
                      </g>
                    </svg>
                  </Box>
                </MDBox>
              </Box>
                :
                <Box className="left">
                <MDBox className="left-chat">
                  <Box className="user">
                  <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      height="30"
                      viewBox="0 0 30 30"
                      fill="none"
                    >
                      <circle
                        cx="14.9997"
                        cy="15.0003"
                        r="14.9997"
                        fill="#FEFEFE"
                      />
                      <circle
                        cx="15.0001"
                        cy="11.5389"
                        r="6.92294"
                        fill="#16273C"
                      />
                      <mask
                        id="mask0_186_7718"
                       
                        maskUnits="userSpaceOnUse"
                        x="0"
                        y="0"
                        width="30"
                        height="30"
                      >
                        <circle
                          cx="15.0007"
                          cy="15.0003"
                          r="14.9997"
                          fill="#EDEDED"
                        />
                      </mask>
                      <g mask="url(#mask0_186_7718)">
                        <rect
                          x="4.61621"
                          y="20.7697"
                          width="20.7688"
                          height="13.8459"
                          rx="3"
                          fill="#16273C"
                        />
                      </g>
                    </svg>
                  
                    
                  </Box>
                  <Box className="content">
                    <Box className="list">
                      <p>
                      {data.message}
                      </p>
                    </Box>
                    <Box className="date">
                      <p>{moment(new Date(data.createdTime.seconds*1000)).format("ddd DD/MM/yyyy")}</p> 
                    </Box>
                    <Box className="username">Admin</Box>
                  </Box>
                </MDBox>
              </Box>

                ))}
              </Box>
              <Box className="chat-message"  component = "form" onSubmit={(e)=>addMessage(e)}>
                <textarea
                  className="forminput"
                  rows="6"
                  placeholder={t("Type your messege")}
                  value={text}
                onChange={(e)=>setText(e.target.value)}
                required
                disabled={isButtonDisabled("REST12")}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && !e.shiftKey) {
                    e.preventDefault(); // Prevent default behavior (adding new line)
                    
                    addMessage(e); // Call your form submission function
                    setText("")
                  }
                }}
                />
                 {/* <Box
                 sx={{
                  display: "flex",
                  justifyContent: "end",
                }}
                ><MDButton
                  className="dark-btn"
                  sx={{
                    padding: "11px 30px",
                    fontSize: "16px",
                    fontWeight: "normal",
                    minWidth: "160px",
                  }}
                  type="submit"
                  // onClick={() => handlemodalClose1()}
                 
                >
                  Send Message
                </MDButton></Box> */}
                {/* <MDButton className="link-btn">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="22"
                    height="23"
                    viewBox="0 0 22 23"
                    fill="none"
                  >
                    <path
                      d="M20.4383 10.6622L11.2483 19.8522C10.1225 20.9781 8.59552 21.6106 7.00334 21.6106C5.41115 21.6106 3.88418 20.9781 2.75834 19.8522C1.63249 18.7264 1 17.1994 1 15.6072C1 14.015 1.63249 12.4881 2.75834 11.3622L11.9483 2.17222C12.6989 1.42166 13.7169 1 14.7783 1C15.8398 1 16.8578 1.42166 17.6083 2.17222C18.3589 2.92279 18.7806 3.94077 18.7806 5.00222C18.7806 6.06368 18.3589 7.08166 17.6083 7.83222L8.40834 17.0222C8.03306 17.3975 7.52406 17.6083 6.99334 17.6083C6.46261 17.6083 5.95362 17.3975 5.57834 17.0222C5.20306 16.6469 4.99222 16.138 4.99222 15.6072C4.99222 15.0765 5.20306 14.5675 5.57834 14.1922L14.0683 5.71222"
                      stroke="black"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </MDButton> */}
              </Box>
            </Box>
          </Box>
        </Box>
        <Modal
          open={modalopen}
          onClose={handlemodalClose}
          aria-labelledby="modal-modal-title"
          className="cs-modal customer-modal"
          aria-describedby="modal-modal-description"
        >
          <Box className="modal-dialog">
            <Box className="modal-content" component="form">
              <Box className="modal-header">
                <MDButton
                  className="modal-close"
                  onClick={() => handlemodalClose()}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M13.7071 0.292893C13.3166 -0.0976311 12.6834 -0.0976311 12.2929 0.292893L0.292893 12.2929C-0.0976315 12.6834 -0.0976315 13.3166 0.292893 13.7071C0.683416 14.0976 1.31658 14.0976 1.70711 13.7071L13.7071 1.70711C14.0976 1.31658 14.0976 0.683417 13.7071 0.292893Z"
                      fill="white"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M0.292893 0.292893C0.683417 -0.0976311 1.31658 -0.0976311 1.70711 0.292893L13.7071 12.2929C14.0976 12.6834 14.0976 13.3166 13.7071 13.7071C13.3166 14.0976 12.6834 14.0976 12.2929 13.7071L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683417 0.292893 0.292893Z"
                      fill="white"
                    />
                  </svg>
                </MDButton>
              </Box>
              <Box className="modal-heading">
                <Typography variant="h2" component="h2">
                  Add region
                </Typography>
              </Box>
              <Box className="modal-body">
                <FormGroup sx={{ mb: 2, width: "100%" }}>
                  <InputLabel className="formlabel">
                    {t("Region name")}
                  </InputLabel>
                  <TextField
                    className="forminput"
                    placeholder={t("Enter old password")}
                    variant="outlined"
                    type="password"
                  />
                </FormGroup>
                <Box sx={{ mb: 2 }}>
                  <InputLabel className="formlabel">
                    {t("Operating country / countries")}
                  </InputLabel>
                  <Box className="cs-dropdown icon">
                    <Select
                      className="form-select"
                      id="languageSelector"
                      label="Language"
                    >
                      <MenuItem value="" className="menu-item" select>
                        Select an option
                      </MenuItem>
                      <MenuItem className="menu-item" value="">
                        Select an option
                      </MenuItem>
                    </Select>
                  </Box>
                </Box>
                <Box className="region-list">
                  <ul>
                    <li>
                      <Box className="list-item">
                        <label>France</label>
                        <MDButton className="calendar">
                          <img src={Calerdar} />
                        </MDButton>

                        <MDButton className="plus-btn">
                          Select sub-regions &nbsp;
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="15"
                            height="15"
                            viewBox="0 0 15 15"
                            fill="none"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M9.20924 1.70924C9.20924 0.765253 8.44399 0 7.5 0C6.55601 0 5.79076 0.765253 5.79076 1.70924V5.79076H1.70924C0.765253 5.79076 0 6.55601 0 7.5C0 8.44399 0.765253 9.20924 1.70924 9.20924H5.79076V13.2908C5.79076 14.2347 6.55601 15 7.5 15C8.44399 15 9.20924 14.2347 9.20924 13.2908V9.20924H13.2908C14.2347 9.20924 15 8.44399 15 7.5C15 6.55601 14.2347 5.79076 13.2908 5.79076H9.20924V1.70924Z"
                              fill="#16273C"
                            />
                          </svg>
                        </MDButton>
                      </Box>
                      <Box className="list-detail">
                        <ul>
                          <li>
                            <Box className="checkbox-section">
                              <FormControlLabel
                                sx={{
                                  display: "grid",
                                  textAlign: "center",
                                  marginRight: "0",
                                }}
                                className=" cs-checkbox"
                                label="Auvergne"
                                control={<Checkbox />}
                              />
                            </Box>
                          </li>
                          <li>
                            <Box className="checkbox-section">
                              <FormControlLabel
                                sx={{
                                  display: "grid",
                                  textAlign: "center",
                                  marginRight: "0",
                                }}
                                className=" cs-checkbox"
                                label="Brittany"
                                control={<Checkbox />}
                              />
                            </Box>
                          </li>
                          <li>
                            <Box className="checkbox-section">
                              <FormControlLabel
                                sx={{
                                  display: "grid",
                                  textAlign: "center",
                                  marginRight: "0",
                                }}
                                className=" cs-checkbox"
                                label="Herault"
                                control={<Checkbox />}
                              />
                            </Box>
                          </li>
                        </ul>
                      </Box>
                    </li>
                  </ul>
                </Box>
              </Box>
              <Box className="modal-footer">
                <MDButton className="primary-btn btn xl">Save</MDButton>
              </Box>
            </Box>
          </Box>
        </Modal>
      </MDBox>
    </DashboardLayout>
  );
}

export default Profile;
