import { db } from "backendservice/firebase";
import { doc, setDoc } from "firebase/firestore";
import { v4 as uuidv4 } from "uuid";
import Help01 from "../../../assets/images/icons/help-icon01.png";
import Help02 from "../../../assets/images/icons/help-icon02.png";
import Help03 from "../../../assets/images/icons/help-icon03.png";



const  get_status= (status)=>{
  switch(status)
  {
    case 0 :
      return (    <span className="red">
      !<div className="tag">OPEN</div>
    </span>)
    break;
    case 1:
      return (  <span className="orange">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
      >
        <g clip-path="url(#clip0_6_3346)">
          <path
            d="M7 1.1665V3.49984"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M7 10.5V12.8333"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M2.87598 2.87598L4.52681 4.52681"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M9.47363 9.47314L11.1245 11.124"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M1.16699 7H3.50033"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M10.5 7H12.8333"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M2.87598 11.124L4.52681 9.47314"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M9.47363 4.52681L11.1245 2.87598"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_6_3346">
            <rect width="14" height="14" fill="white" />
          </clipPath>
        </defs>
      </svg>
      <div className="tag">IN PROGRESS</div>
    </span>)
      break
    case 2:
      return (
    <span className="green">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <circle cx="10" cy="10" r="10" fill="#1C9690" />
      <path
        d="M15 7L8.8125 13.1875L6 10.375"
        fill="#1C9690"
      />
      <path
        d="M15 7L8.8125 13.1875L6 10.375"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
    <div className="tag">RESOLVED</div>
  </span>)
    break;
     default:
      return (   <span className="red">
      !<div className="tag">OPEN</div>
    </span>)
    break


  }
}
const createSupportTicket = async (data) => {

  var id = uuidv4();
  data.id = id
  data.ticketNumber = generateUniqueTicketNumber()
  data.raiseTime = new Date();
  data.requestInfo = {}

  try {
    var docref = await setDoc(doc(db, "tickets", data.id), data);
  } catch (error) {
    console.log(error)
  }
};
async function getCountryName() {
    const response = await fetch(process.env.REACT_APP_IP_API);
    const resData = await response.json();

    // console.log(resData

    // console.log(country);
    return resData;
  }
function generateUniqueTicketNumber() {
    const prefix = "RST";
    const timestamp = Date.now(); // Use current timestamp as a unique identifier
    const randomSuffix = Math.floor(Math.random() * 1000); // Add a random number for additional uniqueness
  
    const ticketNumber = `${prefix}${timestamp}-${randomSuffix}`;
    return ticketNumber;
  }
const severityMenu = [
  {label:"Major",name:"Major",value:0},
  {label:"Medium",name:"Medium",value:1},
  {label:"Low",name:"Low",value:2}
]
const ticketstatusmenue = [
  {label:"Open",name:"Open",value:0,logo:Help01},
  {label:"In Progress",name:"In Progress",value:1,logo:Help02},
  {label:"Resolved",name:"Resolved",value:2,logo:Help03}
]
const getClassForStatusCode = (statusCode) => {
  switch (statusCode) {
    case 0:
      return 'red';
    case 1:
      return 'orange';
    case 2:
      return 'green';
    default:
      return '';
  }
};


export  {createSupportTicket,get_status,severityMenu,ticketstatusmenue , getClassForStatusCode}