import { useEffect, useState } from "react";
import { useMaterialUIController } from "context";

import Admin from "./Admin";
import Nonadmin from "./nonadmin";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

function Index({module}) {
    const [page, setPage] = useState(1);
    const [controller, dispatch] = useMaterialUIController();
    const {
        permission,
      selecetdCustomer
    } = controller;
useEffect(()=>{
  
    if(permission?.module && permission?.module[module])
        {
            var pageaccess = permission?.module[module]
            if(pageaccess.admin)
                {
                    setPage(2)
                }else{
                    setPage(1)
                }
            // console.log("module ",pageaccess)
        }
    
    // if()
},[permission,selecetdCustomer])
  return (
    <DashboardLayout>
    <DashboardNavbar />
    {page === 1 && <Nonadmin  />}
    {page === 2 && <Admin />} 

     </DashboardLayout>
  )
}

export default Index;
