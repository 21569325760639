import React, { useState, useEffect } from "react";
import {useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import MDButton from "components/MDButton";
import MenuItem from "@mui/material/MenuItem";
import FormGroup from "@mui/material/FormGroup";
import Grid from "@mui/material/Grid";
import Menu from "@mui/material/Menu";
// import BrandImg from "../../assets/images/icons/brand-img.png";
import Sort from "../../assets/images/icons/sort.png";
import SortWhite from "../../assets/images/icons/sortwhite.png";
import FilterIcon from "../../assets/images/icons/filter-icon.png";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import List from "@mui/material/List";
// import ListItem from "@mui/material/ListItem";
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useTranslation } from "react-i18next";
import { get_all_customer } from "backendservice/firebasefunctions,";
import SearchComponent from "components/SearchInput";
import Sortlist from "components/Sortlist";
import Filter from "components/Filter";
import Loader from "components/Loader";
import Nodata from "components/Nodata";
import {
  useMaterialUIController,

} from "context";
function Customer() {
  const [controller, dispatch] = useMaterialUIController();
  const {

  selecetdCustomer,
  selected_clinic,

  module,
  permission
} = controller;
  const { t } = useTranslation();
  const navigate = useNavigate()
  const [anchorEl, setAnchorEl] = useState(null);
  const [brand, setBrand] = useState([]);
  const [brandbackup, setBrandBackup] = useState([]);
  // const [answersPost, setAnswersPost] = useState(false);
  const [loading,setLoading] = useState(false)
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [modalopen, setmodalopen] = React.useState(false);

  const loadData=async()=>{
    setLoading(true)
    try{
        var resp =await  get_all_customer()
        if(resp.length>0)
        {
           
                var brandData = []
                resp.map((data,index)=>{
                    var temps = {
                        id:data.id,
                        label:data.label
                    }
                    brandData.push({...temps,...data.data})
                })
            setBrand(brandData)
            setBrandBackup(brandData)
    
        }else{
          setBrand([])
          setBrandBackup([])
            setLoading(false)
        }
    
    
        setLoading(false)
    }catch(error)
    {
        console.log("error",error)
        setBrand([])
        setLoading(false)
    }
  }
  const isButtonDisabled = (moduleId) => {
    return !(
      !permission.module ||
      !permission.module[moduleId] ||
      permission.module[moduleId].accesstype === 2
    );
  };
  useEffect(()=>{
 
      loadData()
  },[permission])
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Loader loading={loading} />
      <MDBox pt={4} pb={3} className="xl-container customer-list">
        <Box>
          <MDBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <MDBox className="page-heading">
              <MDTypography variant="h5" gutterBottom sx={{ display: "flex" }}>
                {t("Customer list")}
              </MDTypography>
            </MDBox>
          </MDBox>
          <Box>
            <Box
              sx={{
                margin: "10px 0 0 0",
              }}
            >
              <MDBox
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
               <SearchComponent searchList = {brandbackup} onSearch={(e)=>setBrand(e)} />
            
                <Box className="filter-mobile">
                  <MDBox
                    color="text"
                    px={0}
                    sx={{ display: { xs: "block", sm: "flex" } }}
                  >
                    <Box
                      sx={{
                        display: { xs: "block", sm: "flex" },
                      }}
                    >
                        <Sortlist onSort={(e)=>setBrand(e)} sortlist={brandbackup} />
            
                        <Filter onFilter={(e)=>setBrand(e)} filterlist={brandbackup} module = "byName"/>
                    </Box>
                  </MDBox>
                </Box>
              </MDBox>
            </Box>
          </Box>
          <Box mt={2} className="group-main">
            <Box className="grid-card">
            {  brand.length>0 ?
              <Grid container spacing={3}>
              {brand.map((data,index)=>(
 <Grid item xs={12} md={4}>
 <Box className={"card "+(selecetdCustomer.id==data.id?"active":"")}>
   <Box className="thumbnail">
     <img src={data.logo} />
   </Box>
   <Box
     sx={{
       width: "100%",
     }}
   >
     <Box className="card-heading">
       <h3>{data.label}</h3>
 
     </Box>
     <Box className="card-body">
      <MDButton className="primary-btn btn xs"
        disabled={isButtonDisabled("REST01")}
     onClick={(e)=>navigate("/customer/edit/"+data.id)}>
         {t("Edit details")} &nbsp;
         <svg
           xmlns="http://www.w3.org/2000/svg"
           width="14"
           height="14"
           viewBox="0 0 13 13"
           fill="none"
         >
           <path
             d="M9.46592 1.45958C9.61162 1.31387 9.7846 1.19829 9.97497 1.11944C10.1653 1.04059 10.3694 1 10.5754 1C10.7815 1 10.9855 1.04059 11.1759 1.11944C11.3663 1.19829 11.5392 1.31387 11.685 1.45958C11.8307 1.60528 11.9462 1.77826 12.0251 1.96863C12.1039 2.159 12.1445 2.36304 12.1445 2.5691C12.1445 2.77515 12.1039 2.97919 12.0251 3.16956C11.9462 3.35993 11.8307 3.53291 11.685 3.67861L4.19571 11.1679L1.14453 12L1.97667 8.94883L9.46592 1.45958Z"
             stroke="white"
             stroke-width="1.5"
             stroke-linecap="round"
             stroke-linejoin="round"
           />
         </svg>
       </MDButton>
     </Box>
   </Box>
 </Box>
</Grid>
             ))}
             </Grid>:
             <Nodata/>}
            </Box>
          </Box>

          <Box
            pt={1}
            className="bottom-btn"
            sx={{
              display: "flex",
              justifyContent: "end",
              marginTop: "18px",
            }}
          >
            <MDButton
              className="secondary-btn "
              onClick={() => navigate("/customer/add")}
              disabled={isButtonDisabled("REST01")}
              sx={{
                padding: "11px 30px",
                fontSize: "16px",
                fontWeight: "normal",
                minWidth: "160px",
                textTransform: "capitalize",
              }}
              color="white"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="15"
                height="15"
                viewBox="0 0 15 15"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M9.20924 1.70924C9.20924 0.765253 8.44399 0 7.5 0C6.55601 0 5.79076 0.765253 5.79076 1.70924V5.79076H1.70924C0.765253 5.79076 0 6.55601 0 7.5C0 8.44399 0.765253 9.20924 1.70924 9.20924H5.79076V13.2908C5.79076 14.2347 6.55601 15 7.5 15C8.44399 15 9.20924 14.2347 9.20924 13.2908V9.20924H13.2908C14.2347 9.20924 15 8.44399 15 7.5C15 6.55601 14.2347 5.79076 13.2908 5.79076H9.20924V1.70924Z"
                  fill="white"
                />
              </svg>
              &nbsp;&nbsp; {t("Add client")}
            </MDButton>
          </Box>
        </Box>
      </MDBox>
    </DashboardLayout>
  );
}

export default Customer;
