import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import MDButton from "components/MDButton";
import Switch from "@mui/material/Switch";
import Filter from "../../components/Filter";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
//modal import
import { useTranslation } from "react-i18next";
import Loader from "components/Loader";

import { useNavigate } from "react-router-dom";
import {
  useMaterialUIController,
  setSelecetdCustomerrec,
  permission
} from "context";
import { get_clinic } from "backendservice/firebasefunctions,";
import { updateStatus } from "backendservice/firebasefunctions,";
import SearchComponent from "components/SearchInput";
import Sortlist from "components/Sortlist";
function Clinics() {
  const [controller, dispatch] = useMaterialUIController();
  const {
    selecetdCustomer,
   permission
  } = controller;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);
  const [clinicData,setCliniData] = useState([])
  const [clinicDatabackup,setCLinicDatabackup] = useState([])
  const loadData=async()=>{
    let customer  = selecetdCustomer.id
    if(customer!=="" && customer!==undefined)
    {
        setLoading(true)
        console.log("data =====>",customer)
        try{
            var clinicList = await get_clinic(customer)
            setCliniData(clinicList)
            setCLinicDatabackup(clinicList)
     
            setLoading(false)
        }catch(error)
        {
            setLoading(false)
            setCliniData([])
            setCLinicDatabackup([])
            console.log("error ==>",error)
        }
    }
  }
  const isButtonDisabled = (moduleId) => {
    return !(
      !permission.module ||
      !permission.module[moduleId] ||
      permission.module[moduleId].accesstype === 2
    );
  };
  useEffect(() => {
    if (selecetdCustomer.id) {
      loadData().then(() => setLoading(false));
    }
  }, [refresh,selecetdCustomer,permission]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Loader loading={loading} />
      <MDBox pt={4} pb={3} className="xl-container">
        <Box>
          <Box>
            <MDBox className="page-heading">
              <MDTypography
                variant="h5"
                gutterBottom
                sx={{ pb: "8px", display: "flex" }}
              >
                {t("Clinics")}
              </MDTypography>
            </MDBox>

            <Box>
              <Box
                sx={{
                  margin: "10px 0 0 0",
                }}
              >
                <MDBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  {/* <FormGroup className="search-input">
                    <Box className="search-icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="21"
                        viewBox="0 0 20 21"
                        fill="none"
                      >
                        <path
                          d="M9 17.5C13.4183 17.5 17 13.9183 17 9.5C17 5.08172 13.4183 1.5 9 1.5C4.58172 1.5 1 5.08172 1 9.5C1 13.9183 4.58172 17.5 9 17.5Z"
                          stroke="#16273C"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M19.0004 19.5004L14.6504 15.1504"
                          stroke="#16273C"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </Box>
                    <TextField
                      className="forminput"
                      placeholder={t("Search")}
                      variant="outlined"
                    />
                  </FormGroup> */}
                  <SearchComponent searchList = {clinicDatabackup} onSearch={(e)=>setCliniData(e)} />
                  <Box className="filter-mobile">
                    <MDBox
                      color="text"
                      px={0}
                      sx={{ display: { xs: "block", sm: "flex" } }}
                    >
                      <Box
                        sx={{
                          display: { xs: "block", sm: "flex" },
                        }}
                      >
                  
                        <Sortlist onSort={(e)=>setCliniData(e)} sortlist={clinicDatabackup} />
                        <Filter  onFilter={(e)=>{setCliniData(e)}} filterlist={clinicDatabackup} brandId ={selecetdCustomer.id} module = "clinic"/>
                 
                      </Box>
                    </MDBox>
                  </Box>
                </MDBox>
              </Box>
              <MDBox
                sx={{
                  marginTop: "20px",
                }}
              >
            {  clinicData.length==0 ? (<Box className="no-data">
                 <Box>
                  <span>!</span>
                 <h3>{t("Data not available!")}</h3>
                 </Box>

                </Box>):
                 <List className="datatable">
             {  clinicData.map((data,index)=>(
 <ListItem key={index}>
 <Box className="list-content">
   <Box className="left-content">
     <label className="heading">{data.data.storeName==undefined?data.data.fullName:data.data.storeName}</label>
   </Box>
    <Box className="blue-bg">
     <MDButton className="link-dot"
                  disabled={isButtonDisabled("REST03")}
     
     onClick = {(e)=>{
      console.log("hi")
      navigate("/clinic/edit/"+data.id) 
      
      // onClick={() => navigate("/clinic/add")}
    }
      }>
       <svg
         xmlns="http://www.w3.org/2000/svg"
         width="20"
         height="18"
         viewBox="0 0 17 15"
         fill="none"
       >
         <path
           d="M12.4649 1.08492C12.6572 0.899475 12.8855 0.752375 13.1367 0.652015C13.3879 0.551655 13.6572 0.5 13.9291 0.5C14.201 0.5 14.4703 0.551655 14.7215 0.652015C14.9727 0.752375 15.201 0.899475 15.3933 1.08492C15.5856 1.27036 15.7381 1.49051 15.8422 1.7328C15.9462 1.97509 15.9998 2.23478 15.9998 2.49703C15.9998 2.75928 15.9462 3.01897 15.8422 3.26126C15.7381 3.50355 15.5856 3.7237 15.3933 3.90915L5.50995 13.4409L1.4834 14.5L2.58155 10.6167L12.4649 1.08492Z"
           stroke="#16273C"
           stroke-linecap="round"
           stroke-linejoin="round"
         />
       </svg>
     </MDButton>
     <Box>
       <Box
         sx={{
           display: "flex",
           alignItems: "center",
         }}
         className={isButtonDisabled("REST03")? "disabled" :""}
       >
         <label className="active-text">{data.active?t("Active"):t("Inactive")}</label>
         <Switch
                  disabled={isButtonDisabled("REST03")}
         
           checked={data.active}
           onChange={async() => 
           
         {    setLoading(true);
          if(await updateStatus(!data.active,data.id)){
              setRefresh(!refresh)
            }else{
              setLoading(false);
            }
          
          }
          }

         />
       </Box>
     </Box>
   </Box> 
 </Box>
</ListItem>
             ))  }
                  {/* <ListItem>
                    <Box className="list-content">
                      <Box className="left-content">
                        <label className="heading">{t("Clinic 1")}</label>
                      </Box>
                      <Box className="blue-bg">
                        <MDButton className="link-dot">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="18"
                            viewBox="0 0 17 15"
                            fill="none"
                          >
                            <path
                              d="M12.4649 1.08492C12.6572 0.899475 12.8855 0.752375 13.1367 0.652015C13.3879 0.551655 13.6572 0.5 13.9291 0.5C14.201 0.5 14.4703 0.551655 14.7215 0.652015C14.9727 0.752375 15.201 0.899475 15.3933 1.08492C15.5856 1.27036 15.7381 1.49051 15.8422 1.7328C15.9462 1.97509 15.9998 2.23478 15.9998 2.49703C15.9998 2.75928 15.9462 3.01897 15.8422 3.26126C15.7381 3.50355 15.5856 3.7237 15.3933 3.90915L5.50995 13.4409L1.4834 14.5L2.58155 10.6167L12.4649 1.08492Z"
                              stroke="#16273C"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </MDButton>
                        <Box>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <label className="active-text">{t("Active")}</label>
                            <Switch
                              checked={followsMe}
                              onChange={() => setFollowsMe(!followsMe)}
                            />
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </ListItem>
                  <ListItem>
                    <Box className="list-content">
                      <Box className="left-content">
                        <label className="heading">{t("Clinic 1")}</label>
                      </Box>
                      <Box className="blue-bg">
                        <MDButton className="link-dot">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="18"
                            viewBox="0 0 17 15"
                            fill="none"
                          >
                            <path
                              d="M12.4649 1.08492C12.6572 0.899475 12.8855 0.752375 13.1367 0.652015C13.3879 0.551655 13.6572 0.5 13.9291 0.5C14.201 0.5 14.4703 0.551655 14.7215 0.652015C14.9727 0.752375 15.201 0.899475 15.3933 1.08492C15.5856 1.27036 15.7381 1.49051 15.8422 1.7328C15.9462 1.97509 15.9998 2.23478 15.9998 2.49703C15.9998 2.75928 15.9462 3.01897 15.8422 3.26126C15.7381 3.50355 15.5856 3.7237 15.3933 3.90915L5.50995 13.4409L1.4834 14.5L2.58155 10.6167L12.4649 1.08492Z"
                              stroke="#16273C"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </MDButton>
                        <Box>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <label className="active-text">{t("Active")}</label>
                            <Switch
                              checked={followsMe}
                              onChange={() => setFollowsMe(!followsMe)}
                            />
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </ListItem>
                  <ListItem>
                    <Box className="list-content">
                      <Box className="left-content">
                        <label className="heading">{t("Clinic 1")}</label>
                      </Box>
                      <Box className="blue-bg">
                        <MDButton className="link-dot">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="18"
                            viewBox="0 0 17 15"
                            fill="none"
                          >
                            <path
                              d="M12.4649 1.08492C12.6572 0.899475 12.8855 0.752375 13.1367 0.652015C13.3879 0.551655 13.6572 0.5 13.9291 0.5C14.201 0.5 14.4703 0.551655 14.7215 0.652015C14.9727 0.752375 15.201 0.899475 15.3933 1.08492C15.5856 1.27036 15.7381 1.49051 15.8422 1.7328C15.9462 1.97509 15.9998 2.23478 15.9998 2.49703C15.9998 2.75928 15.9462 3.01897 15.8422 3.26126C15.7381 3.50355 15.5856 3.7237 15.3933 3.90915L5.50995 13.4409L1.4834 14.5L2.58155 10.6167L12.4649 1.08492Z"
                              stroke="#16273C"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </MDButton>
                        <Box>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <label className="active-text">{t("Active")}</label>
                            <Switch
                              checked={followsMe}
                              onChange={() => setFollowsMe(!followsMe)}
                            />
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </ListItem>
                  <ListItem>
                    <Box className="list-content">
                      <Box className="left-content">
                        <label className="heading">{t("Clinic 1")}</label>
                      </Box>
                      <Box className="blue-bg">
                        <MDButton className="link-dot">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="18"
                            viewBox="0 0 17 15"
                            fill="none"
                          >
                            <path
                              d="M12.4649 1.08492C12.6572 0.899475 12.8855 0.752375 13.1367 0.652015C13.3879 0.551655 13.6572 0.5 13.9291 0.5C14.201 0.5 14.4703 0.551655 14.7215 0.652015C14.9727 0.752375 15.201 0.899475 15.3933 1.08492C15.5856 1.27036 15.7381 1.49051 15.8422 1.7328C15.9462 1.97509 15.9998 2.23478 15.9998 2.49703C15.9998 2.75928 15.9462 3.01897 15.8422 3.26126C15.7381 3.50355 15.5856 3.7237 15.3933 3.90915L5.50995 13.4409L1.4834 14.5L2.58155 10.6167L12.4649 1.08492Z"
                              stroke="#16273C"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </MDButton>
                        <Box>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <label className="active-text">{t("Active")}</label>
                            <Switch
                              checked={followsMe}
                              onChange={() => setFollowsMe(!followsMe)}
                            />
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </ListItem> */}
                  
                </List> 
                }
              </MDBox>
              <Box
                className="navigation-link"
              >
                <MDButton
                  className="primary-btn btn xl"
                  onClick={() => navigate("/clinic/add")}
                  disabled={isButtonDisabled("REST03")}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="13"
                    viewBox="0 0 12 13"
                    fill="none"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M7.36739 1.86739C7.36739 1.1122 6.75519 0.5 6 0.5C5.24481 0.5 4.63261 1.1122 4.63261 1.86739V5.13261H1.36739C0.612202 5.13261 0 5.74481 0 6.5C0 7.25519 0.612202 7.86739 1.36739 7.86739H4.63261V11.1326C4.63261 11.8878 5.24481 12.5 6 12.5C6.75519 12.5 7.36739 11.8878 7.36739 11.1326V7.86739H10.6326C11.3878 7.86739 12 7.25519 12 6.5C12 5.74481 11.3878 5.13261 10.6326 5.13261H7.36739V1.86739Z"
                      fill="white"
                    />
                  </svg>
                  &nbsp;&nbsp; {t("Add clinic")}
                </MDButton>
              </Box>
            </Box>
          </Box>
        </Box>
      </MDBox>
      
      {/* Modal mm-04 */}
      {/* <Modal
        open={modalopen}
        onClose={handlemodalClose}
        aria-labelledby="modal-modal-title"
        className="cs-modal slack-md"
        aria-describedby="modal-modal-description"
      >
        <Box className="modal-dialog">
          <Box className="modal-content">
            <Box className="modal-header">
              <MDButton
                className="modal-close"
                onClick={() => handlemodalClose()}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                >
                  <path
                    d="M13 1L1 13"
                    stroke="#282B3B"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M1 1L13 13"
                    stroke="#282B3B"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </MDButton>
            </Box>
            <Box className="modal-heading">
              <Typography variant="h2" component="h2">
                {t("Category")}
              </Typography>
            </Box>
            <Box className="modal-body">
              <FormGroup sx={{ mb: 2, mr: 2, width: "100%" }}>
                <InputLabel className="formlabel">{t("Name")}</InputLabel>
                <TextField
                  className="forminput"
                  placeholder={t("Enter name")}
                  variant="outlined"
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                />
              </FormGroup>
            </Box>
            <Box className="modal-footer">
              <MDButton
                className="secondary-btn"
                sx={{
                  padding: "11px 30px",
                  fontSize: "16px",
                  fontWeight: "normal",
                  minWidth: "160px",
                }}
                color="dark"
                onClick={(e) => handleSubmit(e)}
              >
                {t("Submit")}
              </MDButton>
            </Box>
          </Box>
        </Box>
      </Modal> */}
    </DashboardLayout>
  );
}

export default Clinics;
