import { useState, useEffect } from "react";
import React from "react";
// react-router-dom components
import { useLocation, NavLink, useNavigate } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
// @mui material components
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ResonanteDark from "../../assets/images/resonante/logo-dark.png";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 React example components
import SidenavCollapse from "examples/Sidenav/SidenavCollapse";
import SidenavCollapseDropdown from "examples/Sidenav/SidenavCollapseDropdown";
import SidenavList from "examples/Sidenav/SidenavList";
// Custom styles for the Sidenav
import SidenavRoot from "examples/Sidenav/SidenavRoot";
import sidenavLogoLabel from "examples/Sidenav/styles/sidenav";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
// Material Dashboard 2 React context
import {
  useMaterialUIController,
  setMiniSidenav,
  setTransparentSidenav,
  setWhiteSidenav,
  triggerTicketUpdate
} from "context";

import {
  collapseItem,
  collapseIconBox,
  collapseIcon,
  collapseText,
} from "examples/Sidenav/styles/sidenavCollapse";
import { setModule } from "context";
import { useTranslation } from "react-i18next";
import { get_notification_leave } from "backendservice/firebasefunctions,";
import { get_help_notification } from "backendservice/firebasefunctions,";

function Sidenav({ color, brand, brandName, active, routes, ...rest }) {
  const {t} = useTranslation()
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    transparentSidenav,
    whiteSidenav,
    darkMode,
    sidenavColor,
    selected_clinic,
    selecetdCustomer,
    permission,
    ticketUpdate
  } = controller;
  const location = useLocation();
  const collapseName = location.pathname.replace("/", "");
  const navigate = useNavigate();
  let textColor = "#009EE0";
  const [leave ,setLeave] = useState([])
  const [help,setHelp] = useState([])
  const [loading, setLoading] = useState(false);
  const isButtonDisabled = (moduleId) => {
    return !(
      !permission.module ||
      !permission.module[moduleId] ||
      permission.module[moduleId].accesstype === 2 ||
      permission.module[moduleId].accesstype === 0
    );
  };
  const loadData = async () => {
    try {
      setLoading(true);
      const datass = localStorage.getItem("user");
      const json = JSON.parse(datass);
      const customer = selecetdCustomer.id;
      if(isButtonDisabled("REST13")){
        setLoading(false)
        return
      }
      if (
        customer !== "" &&
        customer !== undefined &&
        selected_clinic.id !== "" &&
        selected_clinic.id !== undefined
      ) {
        var Leave = await get_notification_leave(customer, selected_clinic.id);
        var help = await get_help_notification(customer, selected_clinic.id)
        console.log(help,"from index");
        
        var data = Leave.map((doc) => ({
          id: doc.id,
          data: doc.data,
          label: doc.data.staff,
          createdTime: doc.data.createdTime,
          status: doc.status,
        }));
        setLeave(data);
        setHelp(help)
        
        setLoading(false);
      } // setLeavebackup(Leave)

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error, "got error");
    }
  };

  if (transparentSidenav || (whiteSidenav && !darkMode)) {
    textColor = "dark";
  } else if (whiteSidenav && darkMode) {
    textColor = "blue";
  }

  const closeSidenav = () => setMiniSidenav(dispatch, true);

  useEffect(() => {
    
    var data = sessionStorage.getItem("resFlg");
    // console.log("userdata == ",data)
    if (data == undefined || data == "") {
      navigate("/authentication/sign-in");
    }

    loadData()

    // A function that sets the mini state of the sidenav.
    function handleMiniSidenav() {
      setMiniSidenav(dispatch, window.innerWidth < 1200);
      setTransparentSidenav(
        dispatch,
        window.innerWidth < 1200 ? false : transparentSidenav
      );
      setWhiteSidenav(
        dispatch,
        window.innerWidth < 1200 ? false : whiteSidenav
      );
    }

    /** 
     The event listener that's calli ng the handleMiniSidenav function when resizing the window.
    */
    window.addEventListener("resize", handleMiniSidenav);

    // Call the handleMiniSidenav function to set the state with the initial value.
    handleMiniSidenav();
      setModule(dispatch,collapseName)
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleMiniSidenav);
  }, [dispatch, location,selecetdCustomer,selected_clinic,ticketUpdate]);
// console.log("location ==>",location,collapseName)
  const [expanded, setExpanded] = useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  // Render all the routes from the routes.js (All the visible items on the Sidenav)
  const renderRoutes = routes.map(
    ({
      id,
      type,
      name,
      authorization,
      icon,
      title,
      noCollapse,
      key,
      href,
      route,
      dropdownLink,
      collapse,
    }) => {
      // console.log("path ---- ",key)
      let returnValue;
      var datass = localStorage.getItem("user");
      var accessPage =[]
      var services = {}
      var checkUSer = "OT_00"
   if(datass!=="" && datass!=undefined )
   {

    var json = JSON.parse(datass);
    if(json.roleAccess!==undefined)
    {
      var access = json.roleAccess
      checkUSer = json.roleCode
     accessPage = access.module
    //  console.log(accessPage)
    }else{
      localStorage.clear()
      navigate('/')
    }
    
  // console.log(accessPage[id],accessPage[id]?.active)
    // navigate('*')
    // console.log("project ==",project,services[module]?.module)
   }
      if (type === "collapse" && accessPage[id]?.active && (authorization==undefined ||
        (authorization== checkUSer))) {
        returnValue = href ? (
          <Link
            href={href}
            key={key}
            target="_blank"
            rel="noreferrer"
            sx={{ textDecoration: "none" }}
          >
            <SidenavCollapse
              leave={leave}
              help={help}
              name={t(name)}
              icon={icon}
              active={key === collapseName}
              noCollapse={noCollapse}
            />
          </Link>
        ) : (
          <NavLink key={key} to={route}>
            <SidenavCollapse
              leave={leave}
              help={help}
              name={t(name)}
              icon={icon}
              active={key === collapseName}
            />
          </NavLink>
        );
      } else if (type === "title") {
        returnValue = (
          <MDTypography
            key={key}
            color={textColor}
            display="block"
            variant="caption"
            fontWeight="bold"
            textTransform="uppercase"
            pl={3}
            mt={2}
            mb={1}
            ml={1}
          >
            {title}
          </MDTypography>
        );
      } else if (type === "divider") {
        returnValue = (
          <Divider
            key={key}
            light={
              (!darkMode && !whiteSidenav && !transparentSidenav) ||
              (darkMode && !transparentSidenav && whiteSidenav)
            }
          />
        );
      } else if (type === "dropdown" && accessPage[id]?.active) {
        // console.log("mapping value ==>",collapse1)
        returnValue = (
          <div className="nav-main">
            <ListItem component="li" className="nav-link">
              <Accordion
                className="accordion-link"
                expanded={expanded === key}
                onChange={handleChange(key)}
              >
                <AccordionSummary
                  aria-controls="panel1bh-content"
                  className="accordion-summary"
                  id={key}
                  active={key === collapseName}
                >
                  <Box className={icon} sx={(theme) => collapseIcon(theme, { active })}>
                  <ListItemIcon
                    sx={(theme) =>
                      collapseIconBox(theme, {
                        transparentSidenav,
                        whiteSidenav,
                        darkMode,
                        active,
                      })
                    }
                  >
                    {typeof icon === "string" ? (
                      <Icon sx={(theme) => collapseIcon(theme, { active })}>
                        {icon}
                      </Icon>
                      
                    ) : (
                      icon
                    )}
                  </ListItemIcon>
                  </Box>
                  <ListItemText
                    primary={t(name)}
                    className="nav-heading"
                    sx={(theme) =>
                      collapseText(theme, {
                        miniSidenav,
                        transparentSidenav,
                        whiteSidenav,
                        active,
                      })
                    }
                  />  
                </AccordionSummary>
                <AccordionDetails className="down-menu">
                  {collapse.map((data, index) => (
                     accessPage[data.id]?.active &&  <NavLink className={active} key={data.key} to={data.route}  >
                         <SidenavCollapseDropdown
                          name={t(data.name)}
                          // icon={icon}
                          active={data.key === collapseName}
                        />
                         {/* {data.name} */}
                         {/* {console.log(data.key,collapseName,data.route,key)} */}
                    </NavLink>
                  ))}
                </AccordionDetails>
              </Accordion>
            </ListItem>
          </div>
        );
      }

      return returnValue;
    }
  );

  return (
    <SidenavRoot
      {...rest}
      variant="permanent"
      ownerState={{ transparentSidenav, whiteSidenav, miniSidenav, darkMode }}
      className="sidenav-root"
    >
      <MDBox textAlign="center" className="sidenav-main ">
        <MDBox
          display={{ xs: "block", xl: "none" }}
          position="absolute"
          top={-20}
          right={0}
          p={1.625}
          onClick={closeSidenav}
          sx={{ cursor: "pointer" }}
        >
          <MDTypography variant="h6" color="secondary">
            <Icon sx={{ fontWeight: "bold" }}>close</Icon>
          </MDTypography>
        </MDBox>
        <MDBox display="flex" alignItems="center" className="sidenav-logo">
          {brand && (
            <MDBox component="img" src={ResonanteDark} alt="Brand" />
          )}
          {/* <MDBox
            width={!brandName && "100%"}
            sx={(theme) => sidenavLogoLabel(theme, { miniSidenav })}
          >
            <MDBox component="h4" fontWeight="bold" color={textColor}>
              {brandName}
            </MDBox>
          </MDBox> */}
        </MDBox>
      </MDBox>
      {/* <Divider
        light={
          (!darkMode && !whiteSidenav && !transparentSidenav) ||
          (darkMode && !transparentSidenav && whiteSidenav)
        }
      /> */}
      <List>{renderRoutes}</List>
      <MDBox p={2} mt="auto">
        {/* <MDButton
          component="a"
          href="https://www.creative-tim.com/product/material-dashboard-pro-react"
          target="_blank"
          rel="noreferrer"
          variant="gradient"
          color={sidenavColor}
          fullWidth
        >
          upgrade to pro
        </MDButton> */}
      </MDBox>
    </SidenavRoot>
  );
}

// Setting default values for the props of Sidenav
Sidenav.defaultProps = {
  color: "info",
  brand: "",
};

// Typechecking props for the Sidenav
Sidenav.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
  ]),
  brand: PropTypes.string,
  brandName: PropTypes.string.isRequired,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Sidenav;
