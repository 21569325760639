// Import the functions you need from the SDKs you need
// import firebase from 'firebase';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {getAuth} from "firebase/auth"
import { collection, getFirestore } from "firebase/firestore"; 
import { getStorage } from "firebase/storage";
console.log( process.env.REACT_APP_API_KEY)
const firebaseConfig = {
  //production value
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGESENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID

};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage(app);
const analytics = getAnalytics(app);
const auth = getAuth()

// collection 
const loginsessionref =  (db, process.env.REACT_APP_LOGINSESSIONS)
const user = collection(db, process.env.REACT_APP_USER)
const userroles = collection(db, process.env.REACT_APP_USERROLES)
const usergroups = collection(db, process.env.REACT_APP_USERGROUPS)
const customers = collection(db, process.env.REACT_APP_CUSTOMERS)
const countryList = collection(db, process.env.REACT_APP_COUNTRYLIST)
const terms = collection(db,process.env.REACT_APP_TERMS)
const clinic = collection(db,process.env.REACT_APP_CLINIC)
const tickets = collection(db,process.env.REACT_APP_TICKET)
const contract = collection(db,process.env.REACT_APP_CONTRACT)
const employeeType = collection(db,process.env.REACT_APP_EMPLOYEE)
const activities = collection(db,process.env.REACT_APP_ACTIVITY)
const module = collection(db,process.env.REACT_APP_MODULE)
const staff = collection(db,process.env.REACT_APP_STAFF)
const leave = collection(db,process.env.REACT_APP_LEAVE)
const schedule = collection(db,process.env.REACT_APP_SCHEDULE)
const Chatlog = collection(db,process.env.REACT_APP_CHAT)
// const clinic = collection(db,process.env.REACT_APP_CLINIC)
// const messaging = getMessaging(app)
export {app,auth,db,loginsessionref,
  user,userroles,customers,usergroups,countryList,
  // services,messages,question_set,surveyresponse,devices,images,
terms,clinic,contract,module,
// licenses,
storage,
tickets,
employeeType,
activities,
staff,
leave,
schedule,
Chatlog
}